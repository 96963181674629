import { withSnackbar } from 'notistack';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import CustomTable from "../Core/CustomTable/CustomTable";
import {
    ActionButton,
    ActionButtonTypes,
    ActionButtonWrapper,
    ActionTypes
} from "../Core/CustomTable/TableActionButtons";
import {ColumnType} from "../Core/CustomTable/TableConfig";
import FormDialog, {FormInputType, FormType} from "../Core/Dialog/FormDialog/FormDialog";

const styles =  theme  => ({
    flexWrap: {
      flexWrap: "wrap",
    }
})
class LocationsEditTable extends React.Component {
    constructor(props) {
        super(props);

        const { t } = props;

        this.state = {
            tenantId: '',
            data: [],
            formInputs: {
                name:{
                    id:'name',
                    placeholder:t('Locations.LocationsEditTable.NameInputLabel'),
                    label:t('Locations.LocationsEditTable.NameInputLabel'),
                    required:true,
                    value:'',
                    type: FormInputType.textField
                }
            },
            formInputsCountry: {
                country:{
                    id:'country',
                    placeholder:t('Locations.LocationsEditTable.CountryInputLabel'),
                    label:t('Locations.LocationsEditTable.CountryInputLabel'),
                    required:true,
                    value:'',
                    type: FormInputType.textField
                },
                city:{
                    id:'city',
                    placeholder:t('Locations.LocationsEditTable.CityInputLabel'),
                    label:t('Locations.LocationsEditTable.CityInputLabel'),
                    required:true,
                    value:'',
                    type: FormInputType.textField
                }
            },
            formInputsPhone: {
                phoneNumber:{
                    id:'phoneNumber',
                    placeholder:t('Locations.LocationsEditTable.PhoneInputLabel'),
                    label:t('Locations.LocationsEditTable.PhoneInputLabel'),
                    required:true,
                    value:'',
                    type: FormInputType.textField
                }
            },
            formInputsAdress: {
                street:{ 
                    id:'street',
                    placeholder:t('Locations.LocationsEditTable.StreetInputLabel'),
                    label:t('Locations.LocationsEditTable.StreetInputLabel'),
                    required:false,
                    value:'',
                    type: FormInputType.textField
                },
                floor:{
                    id:'floor',
                    placeholder:t('Locations.LocationsEditTable.FloorInputLabel'),
                    label:t('Locations.LocationsEditTable.FloorInputLabel'),
                    required:false,
                    value:'',
                    type: FormInputType.textField
                },
                flatNo:{
                    id:'flatNo',
                    placeholder:t('Locations.LocationsEditTable.FlatNoInputLabel'),
                    label:t('Locations.LocationsEditTable.FlatNoInputLabel'),
                    required:false,
                    value:'',
                    type: FormInputType.textField
                },
                buildingNo:{
                    id:'buildingNo',
                    placeholder:t('Locations.LocationsEditTable.BuildInputLabel'),
                    label:t('Locations.LocationsEditTable.BuildInputLabel'),
                    required:false,
                    value:'',
                    type: FormInputType.textField
                }
            },
            formInputsDetail: {
                detail:{
                    id:'detail',
                    placeholder:t('Locations.LocationsEditTable.DetailInputLabel'),
                    label:t('Locations.LocationsEditTable.DetailInputLabel'),
                    required:false,
                    value:'',
                    type: FormInputType.textField
                }
            },
            dialogOpen: false,
            loading: true
        }
        this.getAllLocations = this.getAllLocations.bind(this)
    }
    
    componentDidMount() {
        let url = window.location.href;
        const { navigate } = this.props;
        let tenantId = url.substring(url.lastIndexOf('/') + 1);
            if (tenantId != null && tenantId != undefined && tenantId != "") {
            this.setState({ tenantId: tenantId },()=>{
                this.getAllLocations()
            });
            } else {
            navigate("/dashboard");
            }
    }
    isValid = (newData) => {
        if(newData && newData.name.trim().length !== 0){
            return true

        } else {return false}
    }

    getAllLocations = () => {
        HTTP_REQUESTS.LOCATION_SERVICE.GET_ALL(res => {    
            this.setState({data: res, loading: false});
        }, err => {
            this.setState({loading: false});
        })
    }

    formatDataForRequest = (data) => {
        let formattedData = {name:data.name,contact:{address:{}}}
        function setAdress (key,input){
            formattedData.contact.address[key] = input
        }

        Object.entries(data).forEach((val)=>{
            const key = val[0]
            const input = val[1]
            if(key === "id" || key === "name") return
            if(key === "country" || key ==="city" || key ==="phoneNumber"  )
                return formattedData.contact[key] = input
            return setAdress(key,input)
        })
        return formattedData
    }

    addLocation = (newData) => {
        const { t } = this.props;
        if(this.isValid(newData)){
            let formattedData = this.formatDataForRequest(newData)
           HTTP_REQUESTS.LOCATION_SERVICE.CREATE_A_LOCATION(formattedData, res => {
            this.setState(prevState => ({
                data: [...prevState.data, res]
              }));

           },err => {
            this.props.enqueueSnackbar(t('errorProperties.CATEGORIES_EDIT_TABLE.RENDER.NAME_CANNOT_BE_EMPTY'), {
                variant: 'error'
            });
           })
           
        }
        else {
            this.props.enqueueSnackbar(t('errorProperties.CATEGORIES_EDIT_TABLE.RENDER.NAME_CANNOT_BE_EMPTY'), {
                variant: 'error'
            });
        }
    }
    updateLocation = (newData) => {
        const {t} = this.props;
        if (this.isValid(newData)) {
            newData.name = newData.name.trim()
            let formattedData = this.formatDataForRequest(newData)
            HTTP_REQUESTS.LOCATION_SERVICE.UPDATE_A_LOCATION(newData.id, formattedData, res => {
                formattedData.id = newData.id
                let array = this.state.data.filter((x, i) => x.id !== formattedData.id)
                array.push(formattedData);
                this.setState({data: array})
                this.props.enqueueSnackbar(t('Locations.LocationsEditTable.LocationsTableSuccess'), {
                    variant: 'success'
                });
            }, err => {
                this.props.enqueueSnackbar(err.data.error.message, {
                    variant: 'error'
                });
            })

        } else {
            this.props.enqueueSnackbar(t('errorProperties.CATEGORIES_EDIT_TABLE.RENDER.NAME_CANNOT_BE_EMPTY'), {
                variant: 'error'
            });
        }
    }

    deleteLocation = (oldData,resolve,reject ) => {
        HTTP_REQUESTS.LOCATION_SERVICE.DELEtE_A_LOCATION(this.state.tenantId,oldData.id, res => {
            let array = this.state.data.filter((x,i)=> x.id !== oldData.id)
            this.setState({data: array})
            resolve()

           },err => {
            this.props.enqueueSnackbar(this.props.t('errorProperties.CATEGORIES_EDIT_TABLE.RENDER.NAME_CANNOT_BE_EMPTY'), {
                variant: 'error'
            });
               reject()
           })
            
    }

    generateColumns = () =>{
        const { t } = this.props;

        return[
            {
                id: 'action',
                title: t('Table.Actions'),
                cell: ({row}) => {
                    return (
                        ActionButtonWrapper([
                            ActionButton(
                                t('Locations.LocationsEditTable.Edit'),
                                {actionType: ActionTypes.custom, action: () =>
                                        this.openEditDialog(row)
                                    },
                                ActionButtonTypes.edit,
                                null,
                                row.original.status === 'deleted'
                            )
                        ]))
                },
                type: ColumnType.display,
                size: 100
            },
            {
                id: 'name',
                title: t('Locations.LocationsEditTable.Name'),
                validate: rowData => Boolean(rowData.name),
                type: ColumnType.accessor,
            },
            {
                id: 'contact.country',
                title: t('Locations.LocationsEditTable.CountryInputLabel'),
                validate: rowData => Boolean(rowData.contact.country),
                type: ColumnType.accessor,
            },
            {
                id: 'contact.city',
                title: t('Locations.LocationsEditTable.CityInputLabel'),
                validate: rowData => Boolean(rowData.contact.city),
                type: ColumnType.accessor,  
            },
            {
                id: 'contact.phoneNumber',
                title:  t('Locations.LocationsEditTable.PhoneInputLabel'),
                validate: rowData => Boolean(rowData.contact.phoneNumber),
                type: ColumnType.accessor,
            },
        ]
      }
    returnLocalization = () => {
        const { t } = this.props;
        const localization = {
            header: {
                actions: t('Locations.LocationsEditTable.Actions')
            },
            toolbar: {
              searchPlaceholder: t('Locations.LocationsEditTable.Search'),
              searchTooltip: t('Locations.LocationsEditTable.Search')
            },
            pagination: {
              labelRowsSelect: t('Locations.LocationsEditTable.Rows'),
              labelDisplayedRows: t('Locations.LocationsEditTable.Of'),
              firstTooltip: t('Locations.LocationsEditTable.First Page'),
              previousTooltip: t('Locations.LocationsEditTable.Prev Page'),
              nextTooltip: t('Locations.LocationsEditTable.Next Page'),
              lastTooltip: t('Locations.LocationsEditTable.Last Page')
            },
            body: {
              editTooltip: t('Locations.LocationsEditTable.Edit'),
              addTooltip: t('Locations.LocationsEditTable.Add'),  
              emptyDataSourceMessage: t('Locations.LocationsEditTable.No Records'),
              deleteTooltip: t('Locations.LocationsEditTable.Delete'),
              editRow: {
                deleteText: t('Locations.LocationsEditTable.Delete Text'),
                cancelTooltip: t('Locations.LocationsEditTable.Cancel'),
                saveTooltip: t('Locations.LocationsEditTable.Save')
              },
              filterRow: {
                filterTooltip: t('Locations.LocationsEditTable.Filter')
              }
            }
        }
        return localization;
    }


    // Dialog Functions Start
    handleDialogClose = (data, formType) => {
        this.setState({dialogOpen: false})

        if(data && formType){
            if(formType === FormType.create){
                this.addLocation(data)
            }
            else if(formType === FormType.edit){
                this.updateLocation(data)
            }
        }
    }

    openCreateDialog = () => {
        const { t } = this.props;

        this.setState({
            dialogOpen: true,
            dialogTitle: t('Locations.LocationsEditTable.Add'),
            formInputs: {
                ...this.state.formInputs,
                name: {
                    ...this.state.formInputs.name,
                    value: ''
                },
                id: ''
            },
            formInputsCountry: {
                ...this.state.formInputsCountry,
                country: {
                    ...this.state.formInputsCountry.country,
                    value: ''
                },
                city: {
                    ...this.state.formInputsCountry.city,
                    value:''
                },
            },
            formInputsAdress: {
                ...this.state.formInputsAdress,
                street: {
                    ...this.state.formInputsAdress.street,
                    value:''
                },
                floor: {
                    ...this.state.formInputsAdress.floor,
                    value:''
                },
                flatNo: {
                    ...this.state.formInputsAdress.flatNo,
                    value:''
                },
                buildingNo: {
                    ...this.state.formInputsAdress.buildingNo,
                    value:''
                },
            },
            formInputsDetail: {
                ...this.state.formInputsDetail,
                detail: {
                    ...this.state.formInputsDetail.detail,
                    value:''
                },
            },
            formInputsPhone: {
                ...this.state.formInputsPhone,
                phoneNumber: {
                    ...this.state.formInputsPhone.phoneNumber,
                    value:''
                },
            }
        })
    }

    openEditDialog = (row) => {
        const { t } = this.props;
        this.setState({
            dialogOpen: true,
            dialogTitle: t('Locations.LocationsEditTable.Edit'),
            formInputs: {
                ...this.state.formInputs,
                name: {
                    ...this.state.formInputs.name,
                    value:row.original.name || ''
                },
                id:row.original.id || ''
            },
            formInputsCountry: {
                ...this.state.formInputsCountry,
                country: {
                    ...this.state.formInputsCountry.country,
                    value:(row.original.contact && row.original.contact.country)|| ''
                },
                city: {
                    ...this.state.formInputsCountry.city,
                    value:(row.original.contact && row.original.contact.city) || ''
                },
            },
            formInputsAdress: {
                ...this.state.formInputsAdress,
                street: {
                    ...this.state.formInputsAdress.street,
                    value:row.original.contact && row.original.contact.address.street || ''
                },
                floor: {
                    ...this.state.formInputsAdress.floor,
                    value:row.original.contact && row.original.contact.address.floor || ''
                },
                flatNo: {
                    ...this.state.formInputsAdress.flatNo,
                    value:row.original.contact &&  row.original.contact.address.flatNo || ''
                },
                buildingNo: {
                    ...this.state.formInputsAdress.buildingNo,
                    value:row.original.contact &&  row.original.contact.address.buildingNo || ''
                },
            },
            formInputsDetail: {
                ...this.state.formInputsDetail,
                detail: {
                    ...this.state.formInputsDetail.detail,
                    value:row.original.contact &&  row.original.contact.address.detail || ''
                },
            },
            formInputsPhone: {
                ...this.state.formInputsPhone,
                phoneNumber: {
                    ...this.state.formInputsPhone.phoneNumber,
                    value:row.original.contact &&  row.original.contact.phoneNumber || ''
                },
            }
        })
    }
    // Dialog Functions End

    
    render() {
        const { t } = this.props;
        return (
            <div>
                <CustomTable
                    columns={this.generateColumns()}
                    data={this.state.data}
                    title={t('Locations.LocationsEditTable.Locations')}
                    headerActions={[
                        {
                            iconType: ActionButtonTypes.add,
                            tooltip: t('Locations.LocationsEditTable.Add'),
                            clickAction: {actionType: ActionTypes.custom, action: () => this.openCreateDialog()
                            }
                        }
                    ]}
                    loading={this.state.loading}
                />
                <FormDialog
                    title={this.state.dialogTitle}
                    open={this.state.dialogOpen}
                    onClose={this.handleDialogClose}
                    formInputs={this.state.formInputs}
                    formInputsCountry={this.state.formInputsCountry}
                    formInputsAdress={this.state.formInputsAdress}
                    formInputsDetail={this.state.formInputsDetail}
                    formInputsPhone={this.state.formInputsPhone}
                >
                </FormDialog>
            </div>
        )
    }
}
export default withTranslation()(withSnackbar(withStyles(styles)(LocationsEditTable)));
