import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import withRouter from '../../withRouter';
import { withTranslation,Trans } from 'react-i18next';
import { Grid, Card, CardContent,Typography, Link } from '@mui/material';
import { withStyles } from '@mui/styles'
import CustomTable from "../Core/CustomTable/CustomTable";
import {
    ActionButton,
    ActionButtonTypes,
    ActionButtonWrapper,
    ActionTypes
} from "../Core/CustomTable/TableActionButtons";
import {ColumnType} from "../Core/CustomTable/TableConfig";
import {convertUuidToDisplay} from "../../utils/Converters";

const styles =  theme  => ({
    flexWrap: {
      flexWrap: "wrap",
    }
})
class DevicesEditTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantId: "",
            devices: [],
            data: [],
            loading: true
        };
    };

    componentDidMount() {
        this.fetchDevices();
    }

    fetchDevices = () => {
        const { navigate } = this.props;
        const { t } = this.props;
        HTTP_REQUESTS.DEVICE_SERVICE.GET_ALL((devices) => {
            this.setState({ devices: devices, loading: false });
        }, (err) => {
            this.setState({loading: false});
            this.props.enqueueSnackbar(t('errorProperties.DEVICES_EDIT_TABLE.FETCH_DEVICES'), {
                variant: 'error'
            });
            setTimeout(() =>  navigate("/dashboard") , 1000);
        });
    };

    generateColumn = () => {
        const { t } = this.props;
        return [
            {
                id: 'actions',
                title: t('Table.Actions'),
                cell: ({row}) => {
                    return (
                        ActionButtonWrapper([
                            ActionButton(
                                t('Devices.DevicesEditTable.Edit.Edit_device'),
                                {actionType: ActionTypes.navigate, navigateTo: `${row.original.id}`},
                                ActionButtonTypes.edit
                            ),
                            ActionButton(
                                t('Devices.DevicesEditTable.Edit.Edit_device'),
                                {actionType: ActionTypes.navigate, navigateTo: `/calendar/${row.original.roomId}`},
                                ActionButtonTypes.calendar
                            )
                        ]))
                },
                type: ColumnType.display,
                size: 100
            },
            {
                id: 'name',
                title: t('Devices.DevicesEditTable.Device_name'),
                type: ColumnType.accessor
            },

            {
                id: 'roomName',
                title: t('Devices.DevicesEditTable.Bookable'),
                type: ColumnType.accessor
            },
            {
                id: 'id',
                title: t('Devices.DevicesEditTable.Device_ID'),
                type: ColumnType.accessor,
                render: row => convertUuidToDisplay(row)
            },
        ]
    };


    deleteDeviceById = (oldData,successCb,errorCb) => {
        const { t } = this.props;
        HTTP_REQUESTS.DEVICE_SERVICE.DELETE_BY_ID(oldData.id,(device) =>{
            this.props.enqueueSnackbar(t('DevicesEditTable.Device Deleted'), {
                variant: 'success'
            });
            this.fetchDevices();
            successCb();
        }, (err) => {
            this.props.enqueueSnackbar(t('errorProperties.DEVICES_EDIT_TABLE.DELETE_DEVICE'), {
                variant: 'error'
            });
            errorCb(err);
        });
    };

    render() {
        const { t } = this.props;
        return (
        <Grid container spacing={1}>
            <Grid item lg={3} sm={12} xs={12}>
                <Card style={{minHeight:"200px"}}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {t('Devices.DevicesEditTable.InformationMessage_first')}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {t('Devices.DevicesEditTable.InformationMessage_second')}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            <Trans
                                i18nKey="Devices.DevicesEditTable.InformationMessage_third"
                                t={t}
                                values={{linkText:t('Devices.DevicesEditTable.Here') }}
                                components={{ loginLink: <Link href={`${process.env.REACT_APP_DEVICE_URL}`}>{t('Devices.DevicesEditTable.Here')}</Link> }}
                            />
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={9} sm={12} xs={12}>
                <CustomTable
                    columns={this.generateColumn()}
                    data={this.state.devices}
                    title={t('Devices.DevicesEditTable.Devices')}
                    headerActions={[
                        {
                            iconType: ActionButtonTypes.add,
                            tooltip: t('Users.Users Edit Table.Add Users'),
                            clickAction: {actionType: ActionTypes.navigate, navigateTo: 'new'}
                        }
                    ]}
                    loading={this.state.loading}
                />
            </Grid>
        </Grid>
        )
    }
}

export default withRouter(withTranslation()(withSnackbar(withStyles(styles)(DevicesEditTable))));
