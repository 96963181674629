import {Button, Card, Grid, MenuItem, Select, TextField,} from '@mui/material'
import React, {Component} from 'react'
import FormLabel from '@mui/material/FormLabel';
import withRouter from '../../withRouter';
import {withSnackbar} from 'notistack';
import {withTranslation} from 'react-i18next';
import theme from '../../theme';
import {CustomConfirmDialog} from '../CustomConfirmDialog';
import {HTTP_REQUESTS} from '../../backendcomm/services/httpRequestService';
import emailStrIsValid from "../../helpers/Validation";
import {RoomMinimalData} from "../../models/RoomMinimalData";

const styles = theme  => ({
    swatch: {
      padding: "5px",
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
    square: {
        width: '40px'  ,
        height: '40px',
        minWidth: 'unset',
       
    },
    
  });

export class DeviceCrudForm extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            id:"",
            name:"",
            accountId:"",
            password:"",
            email:"",
            roomId:"",
            errorName:"",
            errorPassword:"",
            errorEmail:"",
            errorAllRoom:"",
            errorRoomId : "",
            allRooms:[],
            openConfirmModal:false,
            isReadyForRender:false,
            isNew:false,
        }
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeRoomId = this.onChangeRoomId.bind(this);

        this.checkValidations = this.checkValidations.bind(this);
        this.deleteDevice = this.deleteDevice.bind(this);
    }

    componentDidMount(){
        if(this.props.data)
        {
            this.setState({
                name: this.props.data.name,
                accountId: this.props.data.accountId,
                email: this.props.data.accountEmail,
                roomId: this.props.data.roomId,
                id: this.props.data.id,
                isReadyForRender:true
            })
        }
        this.getAllRooms()
        this.isNewForTitle();
    }

    isNewForTitle = () => {
        if (window.location.href.includes('new')) //this is looking to url if includes string "new"
        {
            this.setState({isNew:true})
        }
    }
    // Confirmation Start
    onCloseConfirmModal = () => {
        this.setState({openConfirmModal:false})
    }
    showConfirmModal = () => {
        this.setState({openConfirmModal:true})
    }
    // Confirmation End
    onChangeName(event){
        this.setState({name:event.target.value,errorName:""})
    }  
    
    onChangeEmail(event){
        this.setState({email:event.target.value,errorEmail:""})
    }
    
    onChangePassword(event){
        this.setState({password:event.target.value,errorPassword:""})
    } 

    onChangeRoomId(event){
        this.setState({roomId:event.target.value,errorRoomId:""})
    } 


    onClickSave(user){
        if(this.props.onSave)
        {
            this.props.onSave(user);
        }
    }

    deleteDevice(){
        if(this.props.onDelete)
        {
            this.props.onDelete();
        }
    }

    onClickCancel = () => {
        const { navigate } = this.props;
        navigate("/devices");
    }

    getAllRooms() {
        HTTP_REQUESTS.CALENDAR_SERVICE.GET_CALENDARS_MINIMAL_DATA(
            (res) => {
                const rooms = res.map((room) => {
                    return new RoomMinimalData(room.id, room.name);
                });
                this.setState({ allRooms: rooms });
            },
            (err) => {
                console.log('getAllRooms err', err);
            }
        );
    }

    checkNameIsValid(t){
        if(!this.state.name || this.state.name ===""){
            this.setState({errorName:t('errorProperties.DEVICES_FORM.NAME_CANNOT_BE_EMPTY')});
            return false;
        }else if(this.state.name.length<5){
            this.setState({errorName:t('errorProperties.DEVICES_FORM.NAME_LENGTH')});
            return false;            
        }
        return true
    }

    checkEmailIsValid(t){
        if(!this.state.email || this.state.email ===""){
            this.setState({errorEmail:t('errorProperties.DEVICES_FORM.EMAIL_CANNOT_BE_EMPTY')});
            return false 
        }
        if(this.state.email.length<6){
            this.setState({errorEmail:t('errorProperties.DEVICES_FORM.EMAIL_LENGTH')});
            return false             
        }
        if(!emailStrIsValid(this.state.email)){
            this.setState({errorEmail:t('errorProperties.DEVICES_FORM.EMAIL_NOT_VALID')});
            return false 
        }
        return true
    }

    checkPasswordIsValid(t){
        if(this.state.id === ""){ //it means method is update
            if(!this.state.password || this.state.password ===""){
                this.setState({errorPassword:t('errorProperties.DEVICES_FORM.PASSWORD_CANNOT_BE_EMPTY')});
                return false
            }else if(this.state.password.length<6 && this.state.password.length > 0){
                this.setState({errorPassword:t('errorProperties.DEVICES_FORM.PASSWORD_LENGTH')});
                return false 
            }
        }else{ // it means method is create
            if(this.state.password.length<6 && this.state.password.length > 0){
                this.setState({errorPassword:t('errorProperties.DEVICES_FORM.PASSWORD_LENGTH')});  
                return false
            }
        }
        return true
    }

    checkRoomIdIsValid(t){
        if(!this.state.roomId || this.state.roomId ===""){
            this.setState({errorRoomId:t('errorProperties.DEVICES_FORM.ROOM_ID_CANNOT_BE_EMPTY')});
            return false
        }
        return true
    }

    checkValidations(){
        const { t } = this.props;
        let formValid = false;
        let isNameValid = this.checkNameIsValid(t);
        let isEmailValid = this.checkEmailIsValid(t);
        let isRoomIdIsValid = this.checkRoomIdIsValid(t);
        let isPasswordValid = this.checkPasswordIsValid(t)

        if(isEmailValid && isNameValid && isRoomIdIsValid && isPasswordValid){
            formValid = true
        }

        if(formValid)
        {
            let accountObj = {
                email : this.state.email,
                id:this.state.accountId,
                name:this.state.name,
            }
            if(this.state.password && this.state.password !== "" ){
                accountObj.password = this.state.password
            }
            let device = {
                id : this.state.id,
                accountId : this.state.accountId,
                name: this.state.name,
                roomId: this.state.roomId,
                Account:accountObj
            }
            this.onClickSave(device)
        }
        else{
            this.props.enqueueSnackbar(t('errorProperties.USERS_FORM.VALIDATION_NOT_PASSED'), {
                variant: 'error',
            })
        }
    }


    render() {
        const { t } = this.props;
        return (
            <div>
            {
            this.state.isReadyForRender?
            <Card style={{'padding': '16px'}}>
                <Grid container item xs={12} direction="column" spacing={2} >
                    <Grid item xs={12} lg={6} >
                        <Grid container item xs={12} spacing={2}>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="name"
                                        fullWidth
                                        id="name"
                                        label={t('Devices.DeviceCrudForm.Name')}
                                        value={this.state.name}
                                        error={this.state.errorName != null && this.state.errorName.length > 0}
                                        helperText={(this.state.errorName != null && this.state.errorName.length > 0) ? t(this.state.errorName) : ''}
                                        onChange={this.onChangeName}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="email"
                                        fullWidth
                                        id="email"
                                        label={t('Devices.DeviceCrudForm.Email')}
                                        value={this.state.email}
                                        error={this.state.errorEmail != null && this.state.errorEmail.length > 0}
                                        helperText={(this.state.errorEmail != null && this.state.errorEmail.length > 0) ? t(this.state.errorEmail) : ''}
                                        onChange={this.onChangeEmail}
                                        disabled={this.state.accountId}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="password"
                                        fullWidth
                                        id="password"
                                        type={"password"}
                                        label={t('Devices.DeviceCrudForm.Password')}
                                        value={this.state.password}
                                        error={this.state.errorPassword != null && this.state.errorPassword.length > 0}
                                        helperText={(this.state.errorPassword != null && this.state.errorPassword.length > 0) ? t(this.state.errorPassword) : ''}
                                        onChange={this.onChangePassword}
                                        disabled={this.state.accountId}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} >
                                        <FormLabel  id="roomId">{t('Devices.DeviceCrudForm.RoomId')}</FormLabel>
                                        <Select
                                            fullWidth
                                            labelId="roomId"
                                            id="roomId"
                                            name="roomId"
                                            error={this.state.errorRoomId != null && this.state.errorRoomId.length > 0}
                                            value={this.state.roomId}
                                            onChange={this.onChangeRoomId}
                                            >
                                            {this.state.allRooms.map((oneRoom)=>(
                                                <MenuItem id='item' value={oneRoom.id} key={oneRoom.id}>{oneRoom.name}</MenuItem>
                                            ))}
                                        </Select>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-evenly">
                                    <Button variant="contained" onClick={this.onClickCancel}>
                                        {t('Devices.DeviceCrudForm.Cancel')}
                                    </Button>
                                    {this.state.isNew
                                    ? null
                                    :   <Button
                                        id='deviceDelete'
                                        variant="contained"
                                        style={theme.palette.danger}
                                        onClick={this.showConfirmModal}
                                        >
                                            {t('Devices.DeviceCrudForm.Delete')}
                                        </Button>
                                    }
                                    <Button
                                        id='deviceSave'
                                        variant="contained"
                                        color="primary"
                                        onClick={this.checkValidations}
                                    >
                                        {t('Devices.DeviceCrudForm.Save')}
                                    </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    {this.state.openConfirmModal?
                        <CustomConfirmDialog
                            forDelete={true}
                            cancel={t('CONFIRM_DIALOG.Cancel')}
                            open={this.state.openConfirmModal}
                            confirm={t('Devices.DeviceCrudForm.Delete Device')}
                            confirmCB={this.deleteDevice}
                            cancelCB={this.onCloseConfirmModal}
                            header={t('CONFIRM_DIALOG.Header')}
                            text={t('Devices.DeviceCrudForm.Delete Device Info')}>
                        </CustomConfirmDialog>
                    :null }
                </Grid>
            </Card>
            :null
            }
            </div>
        );
    }
}

export default withTranslation()(withSnackbar(withRouter(DeviceCrudForm)))
