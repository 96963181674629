import React from "react";
import { Link, Outlet } from "react-router-dom";
import "../superAdminStyles.css";

const SuperAdminTopbar = () => {
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    console.log("test");
  };

  return (
    <>
      <div className="topbar">
        <Link to="/">Dashboard</Link>
        <Link to="/tenants">Tenants</Link>
        <Link to="/users">Users</Link>
        <Link to="/create-tenant">Create Tenant</Link>
        <Link to="/login" onClick={logout}>
          Logout
        </Link>
      </div>
      <Outlet />
    </>
  );
};

export default SuperAdminTopbar;
