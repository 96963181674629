import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import './CustomDropzone.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";

const CustomDropzone = (props) => {
    const [files, setFiles] = useState([]);
    const { t } = useTranslation();
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        maxFiles: props.maxFiles||5,
        accept: { 'image/*': []},
        onDrop: useCallback(acceptedFiles => {
            setFiles([...files, ...acceptedFiles])
            props.setImages([...files, ...acceptedFiles])
        }, [files])
    });
    acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
    const removeFile = file => () => {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
        props.setImages(newFiles);
    }

    const removeAll = () => {
        setFiles([]);
        props.setImages([]);
    }

    const thumbs = files.map((file, index) => (
        <div className='thumb' key={`${file.name}_${index}`}  onClick={removeFile(file)}>
            <div className='thumb-inner'>
                <img
                    alt='thumb image'
                    src={file.preview}
                    className='img'
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
                <div className="thumb-avatar">
                    <Avatar sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#990808', backgroundColor: '#eeeee4'}} aria-label="delete">
                        <DeleteIcon/>
                    </Avatar>
                </div>

            </div>

        </div>

    ));

    useEffect(() => {
        acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <section className="container">
            <div className="dropzone-container">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>{t('Custom Dropzone.Drag Drop')}</p>
                    <FontAwesomeIcon icon={faCloudArrowUp} size="6x"/>
                </div>
                <div className="thumb-remove-all-btn">
                    {files.length > 0 && <Button startIcon={<DeleteIcon />} variant="outlined" onClick={removeAll}>{t('Custom Dropzone.Remove All')}</Button>}
                </div>
            </div>
            <aside className='thumbs-container'>
                {thumbs}
            </aside>
        </section>
    );
}

export default CustomDropzone;