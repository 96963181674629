import React, {useEffect, useRef, useState} from "react";
import DashboardAdmin from "./DashboardAdmin";
import {HTTP_REQUESTS} from "../../backendcomm/services/httpRequestService";
import CalendarEvent from "../../models/CalendarEvent";
import BufferEvent from "../../models/BufferEvent";
import moment from "moment";
import {useAppSelector} from "../../store/hooks";
import {selectSessionData} from "../../store/features/sessionSlice";
import RoomSelect from "../Booking/RoomSelect";
import EventCreation from "../PublicComponents/CreateEvent/EventCreation";
import {Accordion, AccordionDetails, AccordionSummary, Box, Chip, Stack} from "@mui/material";
import Delayed from "../Core/Delayed";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import DateRangeIcon from '@mui/icons-material/DateRange';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {selectTenantConfig} from "../../store/features/tenantConfigSlice";
import Alert from '@mui/material/Alert';

const Dashboard = () => {
    const sessionData = useAppSelector(selectSessionData)
    const mountedRef = useRef(false)
  
    const tenantConfig = useAppSelector(selectTenantConfig);

    type stepType = 'dashboard' | 'room-select' | 'checkout'

    const [currentStep, setCurrentStep] = useState<stepType>('dashboard')

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedStartTime, setSelectedStartTime] = useState(new Date());
    const [selectedEndTime, setSelectedEndTime] = useState(new Date());

    const date = `${selectedStartTime.getDate()}/${selectedStartTime.getMonth()+1}/${selectedStartTime.getFullYear()}`;
    const startTime = selectedStartTime.toLocaleTimeString([],{hour: '2-digit', minute: '2-digit'});
    const endTime = selectedEndTime.toLocaleTimeString([],{hour: '2-digit', minute: '2-digit'});
    const selectedStartDate= selectedStartTime.toLocaleDateString([],{year: 'numeric', month: 'numeric', day: 'numeric'});
    const selectedEndDate = selectedEndTime.toLocaleDateString([],{year: 'numeric', month: 'numeric', day: 'numeric'});

    const [dashboardError, setDashboardError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [fetchError, setFetchError] = useState(false);

    const [events, setEvents] = useState<any[]>([]);
    const [allLocationsWithEvents, setAllLocationWithEvents] = useState([]);
    const [locations, setLocations] = useState([]);

    const [selectedRoom, setSelectedRoom] = useState({name: '', id: ''});

    const [filter, setFilter] = useState({
        locations: [],
        entities: {},
        locationIds: [],
        roomIds: []
    })

    useEffect(() => {
        if(mountedRef.current){
            setCurrentStep('checkout')
        }
    }, [selectedRoom])

    const fetchEvents = () => {
        setIsLoading(true);
        HTTP_REQUESTS.BOOKING_SERVICE.GET_ALL_BOOKINGS_ALL_CALENDARS(sessionData.tenantId,filter,'',(res) => {
            const events: any[] = [];
            res.forEach(location => {
                location.rooms.forEach(room => {
                    room.events.forEach(event => {
                        {
                            let newCalendarEvent = new CalendarEvent(event.id, event.title,event.ownerAccountId, event.customerName, new Date(event.startDate), new Date(event.endDate), event.isAllDay, room.color, event.status , location, room).toUIJSON(sessionData.id);
                            events.push(newCalendarEvent);
                            if(!event.state && tenantConfig.features.bufferEvents){
                                let bufferTime = room.bufferTime
                                if(bufferTime > 0){
                                    let newBufferEvent = new BufferEvent(`${event.id}+buffer`,'Buffer Event',moment(event.start).subtract(bufferTime,'minutes').toDate(),moment(event.start).toDate(),"#353b48",room, true).toUIJSON();
                                    events.push(newBufferEvent)
                                }
                            }
                        }
                    })
                })
            } );

            res.forEach(location => {
                location.isChecked = false;
            })

            setAllLocationWithEvents(res)
            setEvents(events);
            setIsLoading(false)
            setFetchError(false);
            mountedRef.current = true;
        }, (err) => {
            console.log('Error while fetching events...')
            setFetchError(true);
        });
    }

    useEffect(() => {
        fetchEvents();
    }, [])

    const fetchLocations = () => {
        setIsLoading(true);
        HTTP_REQUESTS.ENTITY_SERVICE.GET_AVAILABLE_ENTITIES(selectedStartTime, selectedEndTime ,success => {
            setLocations(Object.values(success));
            setIsLoading(false);
            setFetchError(false);
        }, error => {
            console.log('Error while fetching locations')
            setFetchError(true);
        })
    }

    useEffect(() => {
        if(mountedRef.current){
            fetchLocations();
        }
        mountedRef.current = true;
    }, [selectedDate, selectedStartTime, selectedEndTime])

    const handleRoomSelect = (roomId: string)  => {
        HTTP_REQUESTS.CALENDAR_SERVICE.GET_ONE_CALENDAR(roomId, successCb => {
            setSelectedRoom(successCb.room);
        }, errorCb => {
            console.log('Error while fetching the room with id: ' + roomId)
        })
    }

    const handleAccordionChange = (step: stepType) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setCurrentStep(step);
    }

    return(
        <>
            <Accordion 
                expanded={currentStep === 'dashboard'} 
                onChange={handleAccordionChange('dashboard')}
                elevation={3}
                sx={{ 
                    borderRadius:"16px",
                    marginBottom:"16px",

                    //The following sections have been edited as they affect the content.
                    '&.MuiAccordion-root:last-of-type': {
                        borderBottomLeftRadius:'16px',
                        borderBottomRightRadius:'16px'
                    },
                    '&.MuiAccordion-root:first-of-type': {
                        borderTopLeftRadius:'16px',
                        borderTopRightRadius:'16px'
                    },
                    //////////////////////////////////////////////////////////////////////////////
                }}
            >
                {
                    currentStep !== 'dashboard' &&
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon sx={{color:"gray"}} />}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            "& .MuiAccordionSummary-content": {
                              flexGrow: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            },
                            "&.Mui-expanded": {
                              backgroundColor: "#ea004b",
                              borderRadius: "16px",
                              color: "white",
                            },
                          }}
                    >
                                
                                <Stack
                                justifyContent="center"
  spacing={{ xs: 1, sm: 2 }}
  direction="column"
  alignItems="center"
  flexWrap="wrap"
  flexGrow={1}
>
  
  {selectedStartDate === selectedEndDate ? (
    // If the user creates an event in a day, it shows the selected time
    <Stack direction="row" alignItems="center" spacing={1}>
      <Chip icon={<DateRangeIcon />} label={selectedStartDate} color="secondary" />
      <Chip icon={<ScheduleIcon />} label={startTime + " - " + endTime} color="secondary" />
    </Stack>
  ) : (
    <Chip icon={<DateRangeIcon />} label={selectedStartDate + ' - ' + selectedEndDate} color="secondary" />
  )}

{
  dashboardError && 
  <Stack>
    <Alert severity="error">{dashboardError}</Alert>
  </Stack>
}
</Stack>

                                
                                
                                
                        
                        

                    </AccordionSummary>
                }
                <AccordionDetails>
                    <DashboardAdmin
                        filter={filter}
                        setFilter={setFilter}
                        events={events}
                        locations={allLocationsWithEvents}
                        isLoading={isLoading}
                        setCurrentStep={setCurrentStep}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedEndTime={selectedEndTime}
                        setSelectedEndTime={setSelectedEndTime}
                        selectedStartTime={selectedStartTime}
                        setSelectedStartTime={setSelectedStartTime}
                        setDashboardError={setDashboardError}
                        fetchEvents={fetchEvents}
                    />
                    <Box width={250} height={100}/>
                </AccordionDetails>
            </Accordion>

            {
                currentStep !== 'dashboard' &&
                <Accordion 
                    expanded={currentStep === 'room-select'} 
                    onChange={handleAccordionChange('room-select')}
                    elevation={3}
                    sx={{ 
                        borderRadius:"16px",   
                        marginBottom:"16px",

                        //The following sections have been edited as they affect the content.
                        '&.MuiAccordion-root:last-of-type': {
                            borderBottomLeftRadius:'16px',
                            borderBottomRightRadius:'16px'
                        },
                        '&.MuiAccordion-root:first-of-type': {
                            borderTopLeftRadius:'16px',
                            borderTopRightRadius:'16px'
                        },
                        '&.MuiAccordion-root:before': {
                            display:"none",
                        },
                        //////////////////////////////////////////////////////////////////////////////
                    }}
                >
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon sx={{color:"gray"}} />}
                        sx={{'& .MuiAccordionSummary-content' : {justifyContent: 'center'},
                            '&.Mui-expanded' : {backgroundColor: "#ea004b", borderRadius: "16px", color: "white"}
                        }}
                    >
                        <div>Selected Room: {selectedRoom.name || 'No room selected'}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RoomSelect
                            locations={locations}
                            setLocations={setLocations}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            selectedEndTime={selectedEndTime}
                            setSelectedEndTime={setSelectedEndTime}
                            selectedStartTime={selectedStartTime}
                            setSelectedStartTime={setSelectedStartTime}
                            selectedRoom={selectedRoom}
                            isLoading={isLoading}
                            fetchError={fetchError}
                            tryAgain={() => fetchEvents()}
                            handleRoomSelect={handleRoomSelect}
                        />
                    </AccordionDetails>
                </Accordion>
            }

            {
                currentStep !== 'dashboard' && selectedRoom.id !== '' && Object.keys(selectedRoom).length > 0 &&
                <Delayed waitBeforeShow={250}>
                    <Accordion 
                        expanded={currentStep === 'checkout'}
                        onChange={handleAccordionChange('checkout')}
                        elevation={3}
                        sx={{ borderRadius:"16px",   

                            //The following sections have been edited as they affect the content.
                            '&.MuiAccordion-root:last-of-type': {
                                borderBottomLeftRadius:'16px',
                                borderBottomRightRadius:'16px'
                            },
                            '&.MuiAccordion-root:first-of-type': {
                                borderTopLeftRadius:'16px',
                                borderTopRightRadius:'16px'
                            },
                            '&.MuiAccordion-root:before': {
                                display:"none",
                            },
                            //////////////////////////////////////////////////////////////////////////////
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{color:"gray"}} />}
                            sx={{'& .MuiAccordionSummary-content' : {justifyContent: 'center'},
                            '&.Mui-expanded' : {backgroundColor: "#ea004b", borderRadius: "16px", color: "white"}
                            }}
                        >
                            <div>Public room card</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EventCreation
                                roomData={selectedRoom}
                                selectedDate={selectedDate}
                                selectedStart={selectedStartTime}
                                selectedEnd={selectedEndTime}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Delayed>
            }
        </>
    )
}

export default Dashboard;
