import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrash, faQuestion, faSquarePlus, faGear, faCalendar} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export const ActionButtonWrapper = (actionButtons) => {
    return(
        <div className="table-action-button-container">
            {actionButtons}
        </div>
    )
}

export const ActionButton = (toolTip, clickAction, iconType, iconSize, disabled) => {
    const navigate = useNavigate();

    const getAction = (clickAction) => {

        switch (clickAction.actionType){
            case (ActionTypes.navigate):
                return () => navigate(clickAction.navigateTo);
            case (ActionTypes.custom):
                return clickAction.action
            default:
                return clickAction
        }
    }

    const getIcon = (iconType) => {
        switch (iconType){
            case (ActionButtonTypes.edit):
                return faPen
            case (ActionButtonTypes.delete):
                return faTrash
            case (ActionButtonTypes.add):
                return faSquarePlus
            case (ActionButtonTypes.setting):
                return faGear
            case (ActionButtonTypes.calendar):
                return faCalendar
            default:
                return faQuestion
        }
    }

    return (
        <Tooltip key={iconType} title={toolTip} aria-disabled={disabled}>
            <span>
                <IconButton
                    sx={{margin:0, padding:0, "&.Mui-disabled": {opacity: "0.2", pointerEvents: "auto"}}}
                    onClick={getAction(clickAction)}
                    disabled={disabled ?? false}
                >
                <FontAwesomeIcon style={{color: '#000'}} icon={getIcon(iconType)} size={iconSize ?? ActionIconSize.sm}/>
            </IconButton>
            </span>
        </Tooltip>
    )


}

export const ActionButtonTypes = {
    edit: 'edit',
    delete: 'delete',
    add: 'add',
    setting: 'setting',
    calendar: 'calendar'
}

export const ActionTypes = {
    navigate: 'navigate',
    custom: 'custom'
}

export const ActionIconSize = {
    xs: 'xs',
    sm: 'sm',
    lg: 'lg',
    xl: 'xl'
}
