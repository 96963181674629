import Users from "../models/Users";
import { HTTP_REQUESTS } from "../backendcomm/services/httpRequestService";
import moment from "moment";

export function getUsers(cb, setUserCount) {
  let users = [];
  HTTP_REQUESTS.USER_SERVICE.GET_USERS(
    (res) => {
      let resUsers = res.users;
      let activeCount = 0;
      let totalCount = 0;
      let deletedCount = 0;
      resUsers.forEach(function (user, index) {
        let token = "";
        let newUsers = new Users(
          user.accountId,
          user.name,
          user.email,
          user.phoneNo,
          user.role,
          user.status,
          moment(user.createdAt).format("DD/MM/YYYY"),
          user.profilePic,
          token,
        ).toUIJSON();
        users.push(newUsers);
       
        totalCount = totalCount + 1;
      });
      cb(users);
      if (setUserCount) {
        setUserCount(activeCount, totalCount, deletedCount);
      }
    },
    (err) => {
      //TODO: implement this
    }
  );
}

export function getUsersForSelection(tenantId, successCb, errorCb) {
  HTTP_REQUESTS.USER_SERVICE.GET_ONE_TENANTS_USERS(
    tenantId,
    (res) => {
      const tenantUsers =  res.users;
      // const statusUsers = res.status_users;
      let usersForSelection = {};
      tenantUsers.forEach((user, index) => {
        if (tenantUsers[index].status === "active")
          usersForSelection[user._id] = user.email;
      });
      successCb(usersForSelection);
    },
    (err) => {
      errorCb(err);
    }
  );
}
export function getUsersForSelectionWithIdValues(successCb, errorCb) {
  HTTP_REQUESTS.USER_SERVICE.GET_USERS(
    (res) => {
      const tenantUsers =  res.users;
      // const statusUsers = res.status_users;
      let usersForSelection = {};
      tenantUsers.forEach((user, index) => {
        if (tenantUsers[index].status === "active") {
          usersForSelection[user.id] = {mail:user.email, id:user.id};
        }
      });
      successCb(usersForSelection);
    },
    (err) => {
      if(!errorCb){ //todo change this error handling
        console.log("ERROR-->getUsersForSelectionWithIdValues : ",err)
      }else{
        errorCb(err);
      }
    }
  );
}
