import React from "react";
import VariantAvatars from "../Login/CompanyAvatar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import LanguageDropdown from "../Menu/LanguageDropdown";
import {useAppSelector} from "../../store/hooks";
import {selectTenantConfig} from "../../store/features/tenantConfigSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  selector:{
    color:'white',
    fontSize:'medium',
    "& .MuiSvgIcon-root": { color: "black" }
  },
}));

const InviteForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const tenantConfig = useAppSelector(selectTenantConfig);

  return (
    <div className={classes.paper}>
      <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
        <FormControl>
        <LanguageDropdown classes={classes} />
        </FormControl>
      </Grid>
      <VariantAvatars />
      <Avatar className={classes.avatar}>
        <PersonAddIcon color="secondary" />
      </Avatar>
      <Typography style={{textAlign:'center'}} component="h1" variant="h5">
        {t('InvitationForm.Invited Join', { name: props.tenantName })}
      </Typography>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoComplete="name"
              name="name"
              variant="outlined"
              required
              fullWidth
              id="name"
              label={t('InvitationForm.Name')}
              autoFocus
              error={props.nameError != null && props.nameError.length > 0}
              helperText={(props.nameError != null && props.nameError.length > 0) ? t(props.nameError) : ''}
              onChange={props.nameChanged}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              name="email"
              disabled={true}
              value={props.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label={t('InvitationForm.Password')}
              type="password"
              id="password"
              autoComplete="current-password"
              error={props.pswError != null && props.pswError.length > 0}
              helperText={(props.pswError != null && props.pswError.length > 0) ? t(props.pswError) : ''}
              onChange={props.passwordChanged}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password2"
              label={t('InvitationForm.Confirm')}
              type="password"
              id="password2"
              autoComplete="current-password"
              error={props.pswError_2 != null && props.pswError_2.length > 0}
              helperText={(props.pswError_2 != null && props.pswError_2.length > 0) ? t(props.pswError_2) : ''}
              onChange={props.passwordChanged_2}
            />
          </Grid>
          {tenantConfig.features.subscription ?
          <Grid item xs={12}>
            <FormControlLabel
              className="checkPromotions"
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label={t('InvitationForm.Want Receive')}
              name="flag"
              onChange={props.flagChanged}
            />
          </Grid>
          : null          
          }          
        </Grid>
        <Button
            id={'join'}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={props.signUpCliked}
          disabled={props.opInProgress}
        >
          {props.opInProgress 
            ? t('InvitationForm.Attempt')
            : t('InvitationForm.Join')
          }
        </Button>
      </form>
    </div>
  );
};
export default InviteForm;
