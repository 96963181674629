export default {
  TODO: "remove this object. Make below variables parametric",
  GET_ALL_BOOKINGS: (calendarId) => `calendars/${calendarId}/bookings`,
  GET_ALL_BOOKINGS_BY_ACCOUNT_ID: (accountId) =>
    `calendars/forUser/${accountId}`,
  GET_ALL_BOOKINGS_ALL_CALENDARS: () => `bookings`,
  GET_A_EVENT: (bookingID) => `bookings/${bookingID}`,
  UPDATE_A_BOOKING: (bookingID) =>
    `bookings/${bookingID}`,
  DELETE_A_BOOKING: (bookingID) =>
    `bookings/${bookingID}`,
  UPDATE_BOOKINGS_FOR_MULTIPLE_ROOMS: (eventGroupID) =>
    `bookings/multiple/${eventGroupID}`,
  DELETE_BOOKINGS_FOR_MULTIPLE_ROOMS: (eventGroupID) =>
    `bookings/multiple/${eventGroupID}`,
  CREATE_A_BOOKING: () => `bookings`,
  CREATE_ONE_TENANTUSER: (tenantID) => `${tenantID}/addUser`,
  DELETE_ONE_USER: (accountId) => `${accountId}`,
  ACTIVATE_INVITATION: () => 'activateInvitation',
  UPDATE_ONE_TENANT_USER: (tenantID, userId) => `${tenantID}/user/${userId}`,
  UPDATE_PROFILE: () => `profile`,
  UPDATE_ONE_USER: (userId) => `${userId}`,
  UPDATE_ONE_INVITE_USER: (tenantID, userId) => `${tenantID}/newUser/${userId}`,
  GET_ONE_USER: (userID) => `${userID}`,
  UPDATE_ONE_USER_PHOTO: () => `profilePicture`,
  DELETE_ONE_USER_PHOTO: () => `profilePicture`,
  UPDATE_A_TENANT_USER: (tenantID) => `${tenantID}/updateStatus`,
  GET_ONE_TENANTS_USERS: (tenantID) => `tenant/${tenantID}`,
  GET_ONE_TENANT_USER: (tenantID, userID) => `${tenantID}/${userID}`,
  GET_ALL_CATEGORIES: (tenantID) => `tenants/${tenantID}/categories`,
  CREATE_A_CATEGORY: (tenantID) => `tenants/${tenantID}/categories`,
  DELETE_A_CATEGORY: (tenantID, categoryId) =>
    `tenants/${tenantID}/categories/${categoryId}`,
  UPDATE_A_CATEGORY: (tenantID, categoryId) =>
    `tenants/${tenantID}/categories/${categoryId}`,
  GOOGLE_GENEREATE_LINK: (tenantId) =>
    `entities/google/generateLink/${tenantId}`,
  OUTLOOK_GENEREATE_LINK: () => `entities/outlook/generateLink`,
  GOOGLE_AUTHORIZE: (tenantID) => `entities/google/authorize/${tenantID}`,
  OUTLOOK_AUTHORIZE: () => `entities/outlook/authorize`,
  GET_ALL_CALENDARS: () => `calendars`,
  GET_CALENDARS_MINIMAL_DATA: ()=>`rooms/names-ids`,
  GET_ALL_CALENDARS_WITH_BASIC_INFO: () => `rooms`,
  GET_ALL_CALENDARS_PUBLIC: () => `rooms`,
  UPDATE_CALENDAR: (calendarID) => `calendars/${calendarID}`,
  CREATE_CALENDAR: () => `calendars`,
  DELETE_CALENDAR: (calendarID) => `calendars/${calendarID}`,
  GET_ONE_CALENDAR: (calendarID) => `calendars/${calendarID}`,
  ENTITY_CREATE: (tenantID) => `tenants/${tenantID}/entities`,
  ENTITY_DELETE: (tenantID, entityID) =>
    `tenants/${tenantID}/entities/${entityID}`,
  GET_ONE_ENTITY: (tenantID, entityID) =>
    `tenants/${tenantID}/entities/${entityID}`,
  ENTITY_UPDATE: (tenantID, entityID) =>
    `tenants/${tenantID}/entities/${entityID}`,
  GET_ALL_ENTITIES: (tenantID) => `tenants/${tenantID}/entities`,
  GET_ONE_USERS_EVENTS: (userID) => `calendars/foruser/${userID}`,
  GET_AVAILABLE_ENTITIES: () => `rooms/available-rooms`,
  GET_ONE_PUBLIC_ROOM: (roomID) => `rooms/${roomID}`,
  CHECK_PSW: (userId) => `${userId}/checkPsw`,
  CALCULATE_PRICE_OF_BOOKING: () => `calendars/calculatePrice`,
  CALCULATE_PRICE_OF_BOOKING_PUBLIC: (roomID) =>
    `public/rooms/${roomID}/calculatePrice`,
  FORGOT_PASSWORD: "forgotPassword",
  CREATE_NEW_PASSWORD_TOKEN: "generatePasswordToken",
  VALIDATE_PASSWORD: "passwordValidate",
  CHANGE_PASSWORD: "changePassword",
  LOGIN: "login",
  REGISTER: "register",
  REGISTER_MANY: "registerMany",
  GET_TENANTS_NAMES: "getNames",
  VALIDATE_INVITED_USER: "invited",
  CREATE_NEW_TOKEN: "generateToken",
  AUTH_CALL: "me",
  LOGOUT: "logout",
  DEVICE_CREATE: "devices",
  DEVICE_GET_ALL: "devices",
  DEVICE_GET_ONE: (id) => `devices/${id}`,
  DEVICE_DELETE_BY_ID: (id) => `devices/${id}`,
  DEVICE_UPDATE: (id) => `devices/${id}`,
  DEVICE_CALENDAR_GET: (tenantID, deviceID) =>
    `${tenantID}/devices/${deviceID}/calendar`,
  DEVICE_CALENDAR_UPDATE: (tenantID, deviceID) =>
    `${tenantID}/devices/${deviceID}/calendar`,
  DEVICE_CALENDAR_GET_EVENTS: (tenantID, deviceID) =>
    `${tenantID}/devices/${deviceID}/calendar/bookings`,
  DEVICE_CALENDAR_CREATE_EVENT: (tenantID, deviceID) =>
    `${tenantID}/devices/${deviceID}/calendar/bookings`,
  DEVICE_CALENDAR_UPDATE_EVENT: (tenantID, deviceID, eventID) =>
    `${tenantID}/devices/${deviceID}/calendar/bookings/${eventID}`,
  DEVICE_CALENDAR_DELETE_EVENT: (tenantID, deviceID, eventID) =>
    `${tenantID}/devices/${deviceID}/calendar/bookings/${eventID}`,
  GET_ALL_LOCATIONS: "locations",
  GET_ALL_LOCATIONS_WITH_ROOMS: "rooms",
  CREATE_A_LOCATION: "locations",
  UPDATE_A_LOCATION: (locationId) => `locations/${locationId}`,
  DELETE_A_LOCATION: (locationId) => `locations/${locationId}`,
  GET_ALL_LAYOUTS: "layouts",
  UPLOAD_TEMP_IMAGE: "tempRoomImage",
  UPLOAD_MULTIPLE_TEMP_IMAGE: "tempRoomImages",
  REFRESH: "refresh",
  PROPERTY_CREATE: "properties",
  PROPERTY_GET_ALL: "properties",
  PROPERTY_GET_ONE: (propertyId) => `properties/${propertyId}`,
  PROPERTY_DELETE_BY_ID: (propertyId) => `properties/${propertyId}`,
  PROPERTY_UPDATE: (propertyId) => `properties/${propertyId}`,
  CREATE_ORDER: (tenantID) => `${tenantID}/payment/createOrder`,
  CAPTURE_PAYMENT: (tenantID) => `${tenantID}/payment/capturePayment`,
  GET_ONE_USERS_OWNED_EVENTS: (userID, tenantID) =>
    `tenants/${tenantID}/calendars/foruser/ownedEvents/${userID}`,
  UPDATE_A_BOOKING_PAYMENT_STATUS: (tenantID, bookingID) =>
    `tenants/${tenantID}/payment/updateStatus/${bookingID}`,
  IS_ORDER_MULTIPLE: (tenantID, bookingID) =>
    `tenants/${tenantID}/payment/updateStatus/${bookingID}`,
  CREATE_INVOICE: (tenantID) => `${tenantID}/payment/invoice/create`,
  GET_INVOICE_DATA: (tenantID) => `${tenantID}/invoiceData`,
  GET_TENANT_CONFIG: "config",
  GET_ALL_TENANTS: "tenants",
  GET_ALL_USERS: "users",
  GET_ONE_TENANT: (tenantID) => `${tenantID}`,
  UPDATE_A_TENANT: (tenantID) => `${tenantID}`,
  DELETE_A_TENANT: (tenantID) => `${tenantID}`,
};
