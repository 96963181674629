export default class Property { //  TODO change name by Calender
    constructor(
      id, 
      name, 
      type, 
      price, 
      priceType, 
      icon,
      isVisible
      ) {
      this.id = id;
      this.name = name;
      this.type = type;
      this.price = price;
      this.priceType = priceType;
      this.icon = icon;
      this.isVisible = isVisible;
    }
    
    toAPIJSON(data){
      let propertyObj = {};
      propertyObj.name = data.name;
      propertyObj.type = data.type;
      propertyObj.price = data.price;
      propertyObj.priceType = data.priceType;
      propertyObj.icon = data.icon
      propertyObj.isVisible = data.isVisible;
      return propertyObj
    }
  }