const BOOKING_SERVER_BASE_URL = process.env.NODE_ENV === 'production' ?  window.location.protocol + '//' + window.location.host : process.env.REACT_APP_BOOKING_API_BASE_URL;
const USERS_SERVER_BASE_URL   = process.env.NODE_ENV === 'production' ?  window.location.protocol + '//' + window.location.host : process.env.REACT_APP_USERS_API_BASE_URL;
const ADMIN_SERVER_BASE_URL   = process.env.REACT_APP_ADMIN_API_BASE_URL;

export default {
    BOOKING_SERVER_BASE_URL :BOOKING_SERVER_BASE_URL,
    USERS_SERVER_BASE_URL   :USERS_SERVER_BASE_URL,
    ADMIN_SERVER_BASE_URL   :ADMIN_SERVER_BASE_URL,
    BOOKING_API_POSIX       :"/api/v1/",
    AUTH_API_POSIX          :"/api/v1/auth/",
    USERS_API_POSIX         :"/api/v1/users/",
    USERS_TENANT_POSIX      :"/api/v1/tenants/",
    IMAGE_API_POSIX         :"/api/v1/images/",
    PUBLIC_API_POSIX        :"/api/v1/public/"
}