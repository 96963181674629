import React from "react";
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import { withSnackbar } from "notistack";
import { getUsers } from "../../data/getUsers";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import IconButton from "@mui/material/IconButton";
import StatusPopover from "./StatusPopover";
import withRouter from "../../withRouter";
import BulkUserInviteForm from "../Dashboard/BulkUserInviteForm";
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { Avatar } from "@mui/material";
import CustomTable from "../Core/CustomTable/CustomTable";
import {
  ActionButton,
  ActionButtonTypes,
  ActionButtonWrapper,
  ActionTypes
} from "../Core/CustomTable/TableActionButtons";
import {ColumnType} from "../Core/CustomTable/TableConfig";
import ConfirmationDialog from "../Core/Dialog/ConfirmationDialog/ConfirmationDialog";
import Tooltip from "@mui/material/Tooltip";
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../helpers/dataConverter";
import {store} from "../../store/store";

const styles =  theme  => ({
  flexWrap: {
    flexWrap: "wrap",
  }
})
class UsersEditTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailInput: "",
      aValidEmail: false,
      mailListforInvite: [],
      tenantId: "",
      data: [],
      users: [],
      open: false,
      id: null,
      anchorEl: null,
      inviteUrl: "",
      dialogOpen: false,
      loading: true
    };
  }
  environment = window.location.origin;
  statusIcons = {
    active: "#43A047",
    invited: "#1E88E5",
    deleted: "#E53935",
  };
  statusContainer = {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  };

  componentDidMount() {
    const getUsers__ = (newUsers) => {
      this.setState({ users: newUsers, loading: false });
    };
    const getUsers_ = () => {
      getUsers(getUsers__);
    };
    getUsers_();
  }

  getUsersProfilePicture = (row) => {
    const rowData = row.original;
    return  <Avatar sx={{height: '50px', width: '50px'}} src={rowData.profilePic} imgProps={{ style:{height: '100%', width: '100%',objectFit: 'contain'} }} />
  }

  getInviteIcon = (rowData) => {
    const { t } = this.props;
    if (rowData !== undefined) {
      return(
          <Tooltip title={t('Users.Users Edit Table.StatusTooltip.invited')}>
            <IconButton
                id="inviteButton"
                onClick={() => {
                  this.generateToken(rowData);
                }}
                size="large">
              <FileCopyOutlinedIcon color="primary"></FileCopyOutlinedIcon>
            </IconButton>
          </Tooltip>
      )
    } else {
      return null;
    }
  };

  getStatusIcon = (row) => {
    const { t } = this.props;
    const rowData = row.original;
    if (rowData !== undefined) {
      if(rowData.status === 'invited'){
       return (
           this.getInviteIcon(rowData)
       )
      }
      return (
        <div>
          <StatusPopover
            data={rowData}
            users={this.state.users}
          ></StatusPopover>
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  handleInvite = (status) => {
    if (status === "invited") {
      return false;
    } else {
      return true;
    }
  };

  notifyClipboard = () => {
    const { t } = this.props;
    this.props.enqueueSnackbar(
      t('Users.Users Edit Table.Invite Copied'),
      {
        variant: "success",
      }
    );
  };

  getUsersInviteForm = () =>{
    let tenantId = store.getState().sessionData.tenantId;
    const getUsers__ = (newUsers) => {
      this.setState({ users: newUsers });
    };
    const getUsers_ = () => {
      getUsers(getUsers__);
    };
    this.setState({ tenantId: tenantId }, getUsers_);
  }

  onPlusClick = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  createUserUpdateObj = (newData) =>{
    let userUpdateObj = {};
    userUpdateObj.name = newData.username;
    userUpdateObj.email = newData.email;
    return userUpdateObj
  }
  createTenantUserUpdateObj = (newData) =>{
    const { t } = this.props;
    let tenantUserUpdateObj = {};
        if (newData.status === "active" ||newData.status === "invited") {
          tenantUserUpdateObj.role = newData.role;
          tenantUserUpdateObj.status = newData.status;
          tenantUserUpdateObj.deletedBy = null;
          tenantUserUpdateObj.deletedTime = null;
          tenantUserUpdateObj.reasonForDelete = null;
          } 
        else if (newData.status === "deleted") {
          tenantUserUpdateObj.role = newData.role;
          tenantUserUpdateObj.status = newData.status;
          tenantUserUpdateObj.reasonForDelete =
          t('Users.Users Edit Table.Deleted By');
          tenantUserUpdateObj.deletedBy = this.props.sessionData.id;
          tenantUserUpdateObj.deletedTime = Date.now();
          }
    return tenantUserUpdateObj
  }

  deleteUser = (oldData) =>{
    const { t } = this.props;
    let tenantUserDeleteObj = {};
    tenantUserDeleteObj.reasonForDelete = t('Users.Users Edit Table.Deleted By');
    tenantUserDeleteObj.deletedBy = this.props.sessionData.id;
    tenantUserDeleteObj.deletedTime = Date.now();
      HTTP_REQUESTS.USER_SERVICE.DELETE_ONE_USER(
        oldData.userId,
        tenantUserDeleteObj,
        (success) => {
          const getUsers__ = (newUsers) => {
            this.setState({ users: newUsers });
          };
          const getUsers_ = () => {
            getUsers(getUsers__);
          };
          this.setState(
            { tenantId: this.state.tenantId },
            getUsers_
          );
        },
        (err) => {
          console.log("Tenant User Delete Failed => :" + err);
        }
      );
  
    }
      

  generateColumn = () =>{
    const { t, navigate } = this.props;
    let roleLookup = { 
      user: t('Users.Users Edit Table.User'),
      admin: t('Users.Users Edit Table.Admin'),
    };
    let statusLookup = {
      active: t('Users.Users Edit Table.Active'),
      //deleted: t('Users.Users Edit Table.Deleted'),
      invited: t('Users.Users Edit Table.Invited'),
    };
    return[
      {
        id: 'actions',
        title: t('Table.Actions'),
        cell: ({row}) => {
          return (
              ActionButtonWrapper(
                  [
                ActionButton(
                    t('Users.Users Edit Table.Edit User'),
                    {actionType: ActionTypes.custom, action: () => {
                      if(row.original.userId !== this.props.sessionData.id){
                        navigate(`user/${row.original.userId}`);
                      }else{
                        navigate('/profile')
                      }
                      }},
                    ActionButtonTypes.edit,
                    null,
                    row.original.status === 'deleted'
                ),
                ActionButton(
                    t('Customize.CustomizeEditTable.Edit.Delete_Property'),
                    {actionType: ActionTypes.custom, action: () => {
                      this.setState({dialogOpen: true,
                        dialogAction: (
                            () => this.deleteUser(row.original)
                        )
                      })
                    }},
                    ActionButtonTypes.delete,
                    null,
                    row.original.userId === this.props.sessionData.id || row.original.status === 'deleted'
                )
              ]))
        },
        type: ColumnType.display,
        size: 100
      },
      {
        id: 'profilePic',
        title: 'Avatar',
        cell: ({row}) => this.getUsersProfilePicture(row),
        type: ColumnType.display,
        size: 100
      },
      {
        id: 'username',
        title: t('Users.Users Edit Table.User Name'),
        type: ColumnType.accessor
      },
      {
        id: 'email',
        title: t('Users.Users Edit Table.Email'),
        type: ColumnType.accessor
      },
      {
        id: 'phoneNo',
        title: t('Users.Users Edit Table.Phone No'),
        type: ColumnType.accessor
      },
      {
        id: 'role',
        title: t('Users.Users Edit Table.Role'),
        type: ColumnType.accessor
      },
      {
        id: 'status',
        title: t('Users.Users Edit Table.Status'),
        cell: ({row}) => this.getStatusIcon(row),
        type: ColumnType.accessor
      }
    ]
  }

  generateToken = (rowData) => {
    let tenantId = this.props.sessionData.tenantId;
    let tenantName = this.props.sessionData.tenantName;
    let tokenObj = {
      tenantId: tenantId,
      email: rowData.email,
      tenantName: tenantName,
    };
    let inviteToken = "";
    HTTP_REQUESTS.USER_SERVICE.CREATE_NEW_TOKEN(tokenObj, (success) => {
      console.log('tokenObj 269 ',tokenObj);
      inviteToken = success.token;
      navigator.clipboard.writeText(
        this.environment + "/invite?code=" + encodeURIComponent(inviteToken)
      );
    });
    this.notifyClipboard();
  };

  prepareTableEditable = () => {
    return {
      isDeletable: rowData => rowData.userId !== this.props.sessionData.id,
      isDeleteHidden: rowData => rowData.status === "deleted",
      onRowDelete: (oldData) =>
        new Promise((resolve, reject) => {
            this.deleteUser(oldData,resolve,reject)
        }),
    }
  }

  handleDialogClose = (confirmation) => {
    this.setState({dialogOpen: false});
    if(confirmation){
      this.state.dialogAction()
    }
  }
  
  
  generateBulkUserInviteForm = () =>{
    return <BulkUserInviteForm
    getUsers = {this.getUsersInviteForm}
    userList={this.state.users}
    open={this.state.open}
    handleClose = {this.handleClose}
  />
  }

  returnLocalization = () => {
    const { t } = this.props;

    const localization = {

      header: {
          actions: t('Users.Users Edit Table.Actions')
      },
      toolbar: {
        searchPlaceholder: t('Users.Users Edit Table.Search'),
        searchTooltip : t('Users.Users Edit Table.Search')
      },
      pagination: {
        labelRowsSelect: t('Users.Users Edit Table.Rows'),
        labelDisplayedRows: t('Users.Users Edit Table.Of'),
        firstTooltip: t('Users.Users Edit Table.First Page'),
        previousTooltip: t('Users.Users Edit Table.Prev Page'),
        nextTooltip: t('Users.Users Edit Table.Next Page'),
        lastTooltip: t('Users.Users Edit Table.Last Page')
      },
      body: {
        emptyDataSourceMessage: t('Users.Users Edit Table.No Records'),
        deleteTooltip: t('Users.Users Edit Table.Delete'),
        editRow: {
          deleteText: t('Users.Users Edit Table.Delete Text'),
          cancelTooltip: t('Users.Users Edit Table.Cancel'),
          saveTooltip: t('Users.Users Edit Table.Save')
        },
        filterRow: {
          filterTooltip: t('Users.Users Edit Table.Filter')
        }
      }

    }

    return localization;
  }


  render() {
    const { t } = this.props;
    const { classes } = this.props;
    const { navigate } = this.props;
    return ( 
    <div>
      <CustomTable
          columns={this.generateColumn()}
          data={this.state.users}
          title={t('Users.Users Edit Table.Users')}
          headerActions={[
            {
              iconType: ActionButtonTypes.add,
              tooltip: t('Users.Users Edit Table.Add Users'),
              clickAction: {actionType: ActionTypes.custom, action: () => {
                  this.onPlusClick()
                }}
            }
          ]}
          loading={this.state.loading}
      />
      <ConfirmationDialog
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
          message={t('Users.Users Edit Table.Delete Text')}
          title={t('Users.Users Edit Table.Delete Title')}

      />
      {this.generateBulkUserInviteForm()}
    </div>
    );
  }
}

export default withTranslation()(withSnackbar(withStyles(styles)(withRouter(connect(mapSessionStateToProps)(UsersEditTable)))));
