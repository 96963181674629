import Card from "@mui/material/Card";
import {Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import {withSnackbar} from "notistack";
import {useTranslation} from "react-i18next";


const PriceInfoCard = (props) => {
    const {t} = useTranslation()

    return <Card style={{width:'100%',borderRadius:'16px',padding:'16px'}}>
        <Grid
              container
              direction="column"
              justifyContent="center"
              spacing={1}
        >
            <Grid item container id={'Room Pricing'}>
                <Grid item>
                    <Typography variant={"h6"} style={{width:'unset',fontWeight:'bold'}}> {t('Booking.InfoCard.roomPricing')}</Typography>
                </Grid>
                <Grid item container style={{marginLeft:'8px'}}>
                    <Grid item xs={8}>
                        <Typography variant={"subtitle2"} style={{width:'unset',textAlign:'start'}}> {t('Booking.InfoCard.duration')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant={"subtitle2"} style={{width:'unset',textAlign:'end'}}> €{props.priceObj.duration.toFixed(2)} </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container id={'Additional Options'}>
                <Grid item>
                    <Typography variant={"h6"} style={{width:'unset',fontWeight:'bold'}}> {t('Booking.InfoCard.additional')}</Typography>
                </Grid>
                <Grid item container style={{marginLeft:'8px'}}>
                    <Grid item xs={'auto'}>
                        <Typography variant={"subtitle2"} style={{textAlign:'start'}}> {t('Booking.InfoCard.Caterings')}</Typography>
                    </Grid>
                    <Grid item xs justifyContent={"flex-end"} style={{display:'flex'}}>
                        <Typography variant={"subtitle2"}> €{props.priceObj.additionalOptions.items.caterings.totalPrice.toFixed(2)}</Typography>
                    </Grid>
                </Grid>
                <Grid item container style={{marginLeft:'8px'}}>
                    <Grid item xs={'auto'}>
                        <Typography variant={"subtitle2"} style={{width:'unset',textAlign:'start'}}>{t('Booking.InfoCard.Moderation')}</Typography>
                    </Grid>
                    <Grid item xs justifyContent={"flex-end"} style={{display:'flex'}}>
                        <Typography variant={"subtitle2"} style={{width:'unset',textAlign:'end'}}>€{props.priceObj.additionalOptions.items.moderationEquipments.totalPrice.toFixed(2)}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container id={'Total Price'}>
                <Grid item container>
                    <Grid item xs={'auto'}>
                        <Typography variant={"h6"} style={{width:'unset',textAlign:'start',fontWeight:'bold'}}> {t('Booking.InfoCard.totalPrice')}</Typography>
                    </Grid>
                    <Grid item xs justifyContent={"flex-end"} style={{display:'flex'}}>
                        <Typography variant={"h6"} style={{width:'unset',textAlign:'end',fontWeight:'bold'}}> €{props.priceObj.total.toFixed(2)}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            { props.handleClickConfirm ? <Grid item container alignItems={'stretch'}>
                 <Button  variant={"contained"} fullWidth style={{backgroundColor:'#2D77FC',color:'#fff',borderRadius:'8px'}} onClick={props.handleClickConfirm}>
                     {t('Booking.InfoCard.confirm')}
                </Button>
            </Grid> : null}
        </Grid>
    </Card>
}
export default withSnackbar(PriceInfoCard);