import {Box, Button, Card, Grid, Skeleton} from "@mui/material";
import MiniCalendar from "../Calendar/MiniCalendar";
import FakeCalendar from "../Calendar/FakeCalendar";
import Typography from "@mui/material/Card";
import theme from "../../theme";
import "./skeletons.scss"

const DashboardAdminSkeleton = () => {

    return (
        <Grid item container spacing={3} sx={{pointerEvents: 'none'}} className="blurred-content">
            <Grid item xs={12} md={12} lg={3}>
                <Grid item container spacing={3}>
                    <Grid lg={12} sm={6} xs={12} item>
                        <MiniCalendar currentDate={new Date()}></MiniCalendar>
                    </Grid>
                    <Grid lg={12} sm={6} xs={12} item>
                        <Card>
                            <Box>
                                <Typography component="h3" variant="h3">Locations</Typography>
                                <Grid container spacing={3} className="filter-buttons">
                                    {[...Array(12)].map((item, index) =>
                                        <Grid item xs={6} md={4} key={`${index}`}>
                                            <Button
                                                style={{width:"100%", margin:theme.spacing(1)}}>
                                                {`Location ${index}`}
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={9}>
                <Grid container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      gap="10px"
                >
                    <Skeleton variant="rectangle" sx={{width: '400px', height: '50px'}} animation={false}></Skeleton>
                    <Skeleton variant="rectangle" sx={{width: '100px', height: '40px'}} animation={false}></Skeleton>
                    <Skeleton variant="rectangle" sx={{width: '100px', height: '40px'}} animation={false}></Skeleton>
                </Grid>

                <Grid container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      sx={{marginTop: "20px"}}
                >
                    <div style={{display: "flex", flexDirection: "row", gap: "2px"}}>
                        <Skeleton variant="rectangle" sx={{width: '90px', height: '30px'}} animation={false}></Skeleton>
                        <Skeleton variant="rectangle" sx={{width: '90px', height: '30px'}} animation={false}></Skeleton>
                        <Skeleton variant="rectangle" sx={{width: '90px', height: '30px'}} animation={false}></Skeleton>
                    </div>
                    <div>
                        <Skeleton variant="text" sx={{width: '200px', height: '30px'}} animation={false}></Skeleton>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", gap: "2px"}}>
                        <Skeleton variant="rectangle" sx={{width: '90px', height: '30px'}} animation={false}></Skeleton>
                        <Skeleton variant="rectangle" sx={{width: '90px', height: '30px'}} animation={false}></Skeleton>
                        <Skeleton variant="rectangle" sx={{width: '90px', height: '30px'}} animation={false}></Skeleton>
                        <Skeleton variant="rectangle" sx={{width: '90px', height: '30px'}} animation={false}></Skeleton>
                    </div>
                </Grid>
                <FakeCalendar></FakeCalendar>
            </Grid>
        </Grid>
    )
}

export default DashboardAdminSkeleton;
