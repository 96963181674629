import React from 'react';
import { Box, Button, Checkbox, Container, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { useNavigate } from 'react-router-dom';
import CustomSpinner from '../../components/CustomSpinner';

const SuperAdminLoginPage = ({callMe}) => {

    const [isLoading, setIsLoading] = React.useState(false);
    
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = React.useState('');

    // Check if already logged in, if it is, redirect to dashboard
    React.useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if(accessToken){
            navigate('/');
        }
    }, [])

    const resetLocalStorage = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        console.log({
          email: data.get('email'),
          password: data.get('password'),
        });
        const email = data.get('email');
        const password = data.get('password');

        setIsLoading(true);
        HTTP_REQUESTS.FIREBASE_SERVICE.LOGIN(email, password, 
            (data) => {
                if (data?.user?.stsTokenManager?.accessToken) {
                    localStorage.setItem('accessToken', data.user.stsTokenManager.accessToken);
                    localStorage.setItem('refreshToken', data.user.stsTokenManager.refreshToken);
                }               
                HTTP_REQUESTS.USER_SERVICE.AUTH_CALL(
                    data => {
                        if(data.tenants[0].role !== 'super_admin'){
                            //Show unauthorized error
                            setErrorMessage('Unauthorized');
                            resetLocalStorage();
                        }
                        else{
                            // Redirect to dashboard
                            navigate('/');
                        }
                        setIsLoading(false);
                    },
                    err => {
                        // Has firebase token but not in our database
                        setErrorMessage('Contact to the administrator');
                        setIsLoading(false);
                        resetLocalStorage();
                    }
                )
            }, 
            (err) => {
                // Wrong credentials
                setErrorMessage('Login failed');
                console.log(err);
                setIsLoading(false);
            }
        );
    }

    return(
        <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {
            !isLoading ?
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Sign In
            </Button>:
            <CustomSpinner/>
            
          }
          <div>
            {errorMessage}
          </div>
        </Box>
      </Box>
    </Container>
    )
}

export default SuperAdminLoginPage;