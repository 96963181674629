import React from 'react';
import {HTTP_REQUESTS} from "../../backendcomm/services/httpRequestService";
import CardMedia from '@mui/material/CardMedia';
import {withSnackbar} from 'notistack';
import withRouter from '../../withRouter';
import {withTranslation} from 'react-i18next';
import CustomTable from "../Core/CustomTable/CustomTable";
import {ColumnType} from "../Core/CustomTable/TableConfig";
import {
    ActionButton,
    ActionButtonTypes,
    ActionButtonWrapper,
    ActionTypes
} from "../Core/CustomTable/TableActionButtons";
import "./Entity.css"

class EntitiesEditTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true
        }
    }

    componentDidMount() {
        this.fetchCalendars();
    }

    getImage = (row) => {
        const rowData = row.original ?? {};
        return <CardMedia
            component="img"
            style={{ width: '100%', maxHeight: '100px'}}
            alt={rowData.description}
            image={rowData.imageUrl || "/images/meetingRoom.png"}
            title={rowData.description}
        />
    };

    generateColumn = () =>{
        const { t } = this.props;
        return [
            {
                id: 'actions',
                title: t('Entities.EntitiesEditTable.Actions'),
                tooltip:t('Entities.EntitiesEditTable.Edit Room'),
                cell: ({row}) => {
                    return (
                        ActionButtonWrapper([
                            ActionButton(
                                t('Entities.EntitiesEditTable.Edit Room'),
                                {actionType: ActionTypes.navigate, navigateTo: `room/${row.original.id}`},
                                ActionButtonTypes.edit
                            ),
                            ActionButton(
                                t('Entities.EntitiesEditTable.Display'),
                                {actionType: ActionTypes.navigate, navigateTo: `/calendar/${row.original.id}`},
                                ActionButtonTypes.calendar
                            ),
                    ]))
                },
                type: ColumnType.display
            },
            {
                id: 'imageUrl',
                title: t('Entities.EntitiesEditTable.Image'),
                cell: ({row}) => {
                    return this.getImage(row)
                },
                type: ColumnType.display
            },
            {
                id: 'name',
                title: t('Entities.EntitiesEditTable.Name'),
                validate : data => Boolean(data),
                type: ColumnType.accessor
            },
            {
                id: 'description',
                title: t('Entities.EntitiesEditTable.Description'),
                validate : data => Boolean(data),
                type: ColumnType.accessor
            },
            {
                id: 'locationName',
                type: ColumnType.accessor,
                title: t('Entities.EntitiesEditTable.LocationName')
            },
            {
                id: 'seat',
                title: t('Entities.EntitiesEditTable.Seats'),
                emptyValue: 0 ,
                validate: data => Boolean((parseInt(data.seat) && parseInt(data.seat) > 0) || !data.seat  || data.seat === "0" )
            }
        ]
    }

    fetchCalendars = () =>{
        const { t } = this.props;
        HTTP_REQUESTS.CALENDAR_SERVICE.GET_ALL_CALENDARS_WITH_BASIC_INFO(
            (data) => {
                this.setState({
                    data: data,
                    loading: false
                });
            }, (err) => {
                console.log(err)
                this.props.enqueueSnackbar(t('errorProperties.ENTITIES_EDIT_TABLE.COMPONENT_DID_MOUNT'), {
                    variant: 'error'
                });
            });

    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div>
                    <CustomTable
                        columns={this.generateColumn()}
                        data={[...this.state.data]}
                        title={t('Entities.EntitiesEditTable.Title')}
                        headerActions={[
                            {
                                iconType: ActionButtonTypes.add,
                                tooltip: t('Entities.EntitiesEditTable.New Room'),
                                clickAction: {actionType: ActionTypes.navigate, navigateTo: "room/new"},
                            }
                        ]}
                        loading={this.state.loading}
                    />
                </div>
            </div>
        );
    }
}

export default  withTranslation()(withSnackbar(withRouter(EntitiesEditTable)))
