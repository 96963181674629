import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import { FormHelperText, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
        maxWidth: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}));

const SeatsTextField = props => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [seats, setSeats] = useState((props.value) ? props.value : null);

    const handleSeatsChange = (event) => {
        setSeats(event.target.value);
        if(props.onChange) {
            props.onChange(event);
        }
    };

    useEffect(() => {
        if (props.value !== seats) {
            setSeats(props.value);
        }
    }, [props.value, seats]);
    return (
        <FormControl className={classes.formControl}>
            <TextField
                disabled={true}
                name={props.name}
                id="seats_text_field" 
                value={seats || ""}
                onChange={handleSeatsChange}
            >
            </TextField>
            {props.isRestricted ?  <FormHelperText>*{t('Booking.InfoCard.SeatHelper')}</FormHelperText> :null} 
         </FormControl>
        
    )
}

export default SeatsTextField;
