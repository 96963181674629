import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import theme from '../theme'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export const CustomConfirmDialog = (props) => {

  const {open,header,confirm,cancel,text,confirmCB,cancelCB,forDelete,confirmCBAll,confirmAll,textAll, confirmRecurring, isRecurred} = props;
  const generateInformation = () => {
    return (isRecurred) ? textAll : text;
  }
  const generateConfirm = () => {
    return (isRecurred) ? confirmRecurring : confirm;
  }
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="dialog-slide-title"
        aria-describedby="dialog-slide-description"
      >
        <DialogTitle id="dialog-slide-title">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-slide-description">
            {generateInformation()}
          </DialogContentText>
        </DialogContent>
        {
        forDelete?
            <DialogActions>
                <Button id='cancel' onClick={cancelCB} color="primary" variant="text">
                    {cancel}
                </Button>
                <Button id="delete" onClick={confirmCB} variant="contained" style={theme.palette.danger}>
                    {generateConfirm()}
                </Button>
                { isRecurred ?
                <Button id='confirmAll' onClick={confirmCBAll} style={theme.palette.danger} variant="contained">
                    {confirmAll}
                </Button> : null }
            </DialogActions>
        :
            <DialogActions>
                <Button onClick={cancelCB} style={theme.palette.danger} variant="text">
                    {cancel}
                </Button>
                <Button onClick={confirmCB} color="primary" variant="contained">
                    {confirmAll}
                </Button>
            </DialogActions>
        }
      </Dialog>
    </div>
  );
}
