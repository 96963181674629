import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface Provider {
    name: string,
    icon: string,
    btnTitle: string
}

export interface TenantConfigState {
    loading: boolean;
    adminAccountEmail: string;
    adminAccountName: string;
    adminAccountPw: string;
    allDayThreshold: number;
    companyName: string;
    defaultLocation: string;
    domainName: string;
    features: {
        addSelectedPropertiesToRoom: boolean;
        allowPublicBooking: boolean;
        allowReservationOverlap: boolean;
        calculateHourWithCeil: boolean;
        calculateMinPrice: boolean;
        calculateOutsidePrice: boolean;
        participantCount: boolean;
        payment: boolean;
        seeAllEventDetails: boolean;
        userAllowBooked: boolean;
        multipleRoomBooking: boolean;
        additionalOptions: boolean;
        customization: boolean;
        additionalOptionPrice: boolean;
        showOutsideOfWorkHoursPrice: boolean;
        register: boolean;
        hasLobby: boolean;
        device: boolean;
        bufferEvents: boolean;
        subscription: boolean;
        roomDetailIcon: boolean;
        allDayRoomPrice: boolean;
        halfDayRoomPrice: boolean;
        additionalPrice: boolean;
        onlyBookHourly: boolean;
        useEventSearchBar: boolean;
        showDescriptionInPriceInfo: boolean;
        roomPictureSlider: boolean;
    };
    timeZone: string;
    workingEndHour: string;
    workingStartHour: string;
    logo: string;
    loginLeftImage: string;
    providers: Provider[];
}

const initialState: TenantConfigState = {
    loading: true,
    adminAccountEmail: '',
    adminAccountName: '',
    adminAccountPw: '',
    allDayThreshold: 360,
    companyName: '',
    defaultLocation: '',
    domainName: '',
    features: {
        addSelectedPropertiesToRoom: false,
        allowPublicBooking: false,
        allowReservationOverlap: false,
        calculateHourWithCeil: false,
        calculateMinPrice: false,
        calculateOutsidePrice: false,
        participantCount: false,
        payment: false,
        seeAllEventDetails: false,
        userAllowBooked: false,
        multipleRoomBooking: false,
        additionalOptions: false,
        customization: false,
        additionalOptionPrice: false,
        showOutsideOfWorkHoursPrice: false,
        register: false,
        hasLobby: false,
        device: false,
        bufferEvents: false,
        subscription: false,
        roomDetailIcon: false,
        allDayRoomPrice: false,
        halfDayRoomPrice: false,
        additionalPrice: false,
        onlyBookHourly: false,
        useEventSearchBar: false,
        showDescriptionInPriceInfo: false,
        roomPictureSlider: false
    },
    timeZone: '',
    workingEndHour: '',
    workingStartHour: '',
    logo: '',
    loginLeftImage: '',
    providers: []
}

const tenantConfigSlice = createSlice({
    name: 'tenantConfig',
    initialState,
    reducers: {
        setTenantConfig(state, action: PayloadAction<TenantConfigState>){
            Object.assign(state, action.payload)
        }
    }
})

export const { setTenantConfig } = tenantConfigSlice.actions;
export const selectTenantConfig = (state: RootState) => state.tenantConfig;

export default tenantConfigSlice.reducer;
