import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {getCateringIcon} from '../../../helpers/IconSelecter';
import {Typography, Stack, Checkbox} from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DropDownInput from "./StyledInputBase";
import ListItemText from "@mui/material/ListItemText";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
        maxWidth: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    menuItemWrap: {
        flexWrap:'wrap'
    },
    box:{
        height: 32,
        borderRadius: 8,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '0px 26px 0px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 8,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 'fit-content',
        },
    },
};


const CateringDropdown = props => {
    const classes = useStyles();
    const { t } = useTranslation()
    const [cateringsData, setCateringsData] = React.useState(props.cateringsData);
    const [cateringsDataRaw, setCateringsDataRaw] = React.useState(props.cateringsDataRaw);
    const [cateringsEmpty, setCateringsEmpty] = React.useState(false);

    const handleCateringChange = (event) => {
        props.onChange(event)
    };

    React.useEffect(() => {
        filterCateringData()
    },[]);

    const filterCateringData = () =>{
        // datayı maple , eğer value arayınde filtrele varsa rawda filtrele deletedAt ine bak , yoksa datayı rawda filtrele deletedAt ine bak
        let snapshotValue = ""
        let newData = ""
        let newDataArray = [];
        for(const oneData of props.cateringsData){
            snapshotValue = props.value.filter((item)=>item === oneData)[0]
            if(snapshotValue){
                newData = cateringsDataRaw.filter((item)=>item.name === snapshotValue)[0]
                if(newData){
                    newDataArray.push(newData.name)
                }
            }else{
                newData = cateringsDataRaw.filter((item)=>item.name === oneData && !item.deletedAt)[0]
                if(newData && newData.isVisible === true){
                    newDataArray.push(newData.name)}
            }
        }
        setCateringsData(newDataArray)
    }

    const isNotAvailable = (value) =>{
        let notAvailable = false
        for(const catering of cateringsDataRaw){
            if(value === catering.name && catering.deletedAt){
                notAvailable = true
            }else if(value === catering.name && catering.isVisible === false){
                notAvailable = true
            }
        }
        return notAvailable
    }
    React.useEffect(() => {
        let res = true;
        cateringsDataRaw.forEach((item) => {
            if(item.deletedAt === null){
                res = false;
            }
        })

        setCateringsEmpty(res)

    }, [cateringsDataRaw]);

    const getCateringPrice = (name , cateringsDataRaw) => {
        let tempElement = cateringsDataRaw.filter((item) =>
            item.name === name
        );
        return (tempElement.length > 0) ? tempElement[0].price : 0;
    }
    const iconComponent = (props) => {
        return (
            <KeyboardArrowDownIcon className={props.className} style={{marginRight: '4px',display:"none"}}/>
        )};
    return (<div>
        <FormControl className={classes.formControl}>
            <Select
                input={<DropDownInput/>}
                IconComponent={iconComponent}
                id="entity_caterings"
                name={props.name}
                value={props.value}
                displayEmpty={true}
                multiple
                onChange={handleCateringChange}
                sx={{'& .MuiSelect-select': {paddingRight:'16px'}}}
                disabled={cateringsEmpty}
                style={{ width:'max-content'}} /* added to fix icon size.  */
                renderValue={() => (
                    <Stack direction="row" alignItems="center">
                        {
                            !cateringsEmpty && 
                                <AddIcon fontSize={'small'}/> 
                        }

                        <Typography sx={{fontWeight:'bold'}}> {t(!cateringsEmpty ? 'Booking.InfoCard.add' : 'No Equipment Available' )} </Typography>
                    </Stack>
                )}
                MenuProps={MenuProps} >
                {cateringsData.map((feature,index)=>(
                    <MenuItem key={`${feature}${index}`} value={feature} disabled={isNotAvailable(feature)}>
                        <Checkbox checked={props.value.indexOf(feature) > -1}/>
                        {getCateringIcon(feature, cateringsDataRaw)}
                        <ListItemText primary={feature} sx={{marginLeft: 1}}/>
                    </MenuItem>
                ))
                }
            </Select>
        </FormControl>
        </div>
    )
}
export default CateringDropdown;
