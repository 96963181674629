import React from 'react';
import TextField from "@mui/material/TextField";
import {InputAdornment, Autocomplete} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

function DebouncedInput(props) {

    const [value, setValue] = React.useState(props.initialValue || '')

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            props.onChange(value);
        }, props.debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <>
            {props.type === 'search' &&
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                }} sx={{minWidth: 200}} {...props} value={value} onChange={e => setValue(e.target.value)} variant="standard"/>
            }
            {props.type === 'number' &&
                <TextField  sx={{marginTop: 2 , maxWidth: 70, minWidth: 70}} {...props} value={value} onChange={e => setValue(e.target.value)} variant="standard"/>}
            {props.type === 'text' &&
                <Autocomplete
                    isOptionEqualToValue={(option, value) => {
                        // Accept empty string
                        if(value === option || value === "") { return true; }
                    }}
                    includeInputInList
                    sx={{maxWidth: 120, minWidth: 120}}
                    disablePortal
                    {...props}
                    value={value}
                    onChange={(e, newVal) => setValue(newVal)}
                    renderInput={(params) =>
                        <TextField
                            {...params} label={props.placeholder} variant="standard"/>}/>
            }
        </>
    )
}

export default DebouncedInput;
