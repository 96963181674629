import React, {useEffect} from 'react';
import {ReactComponent as Minus} from "../../MultiLabelComponent/icons/minus.svg";
import {ReactComponent as Plus} from "../../MultiLabelComponent/icons/plus.svg";
import checkFloat from "../../../helpers/checkFloat";
export default function ItemCounter(props) { // TODO methods should be received as props to make the component more reusable
    const [cateringsCounts, setCateringsCounts] = React.useState(props.cateringsCounts || []);
    useEffect(()=>{
        if(!props.cateringsCounts[props.catering]){
            let event = {
                target: {
                    name:props.catering,
                    id:'cateringsCount',
                    value: 1,
                }
            }
            setCateringsCounts((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value
            }))
            props.onChange(event)
        } else{
            setCateringsCounts(props.cateringsCounts)
        }
    },[])
    const handleCateringUpdateCount = (value) => {
        let number = props.cateringsCounts[props.catering] ? (Number(props.cateringsCounts[props.catering]) + value) : value
        if(number<1){
            props.handleDelete()
        }else{
            let event = {
                target: {
                    name:props.catering,
                    id:'cateringsCount',
                    value: String(number),
                }
            }
            setCateringsCounts((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value
            }))
            props.onChange(event)
        }
    }
    const handleCateringChangeCount = (event) => {
        if(event.target.value=== '0'){
            event.target.value='1'
        }
        event.target.value = checkFloat(event.target.value);
        setCateringsCounts((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
        props.onChange(event)
    };
    return (
        <div className="input-incremental">
            <Minus onClick={()=>{handleCateringUpdateCount(-1)}}/>
            <div className="line-5"/>
            <input
                   className="item-count-input"
                   style={{width: cateringsCounts[props.catering] ? cateringsCounts[props.catering].toString().length * 1.5 + "ch" : '0'.length * 1.5 + "ch", userSelect:'all'}}
                   type="text"
                   id={`cateringsCount`}
                   name={props.catering || ""}
                   value={cateringsCounts[props.catering] || 0}
                   onChange={handleCateringChangeCount}
            />
            <div className="line-6"/>
            <Plus onClick={() => handleCateringUpdateCount(1)}/>
        </div>
    )
}
