import React from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import {useAppSelector} from "../store/hooks";
import {selectTenantConfig} from "../store/features/tenantConfigSlice";

const useStyles = makeStyles((theme) => ({

    listItem: {
        marginBottom: '12px'
    },

    list: {
        padding: '24px 16px',
        fontSize: '16px',
        lineHeight: '1.3'
    }
}));


function EventTooltip(props) {
    const tenantConfig = useAppSelector(selectTenantConfig);

    const classes = useStyles();
    const { event,viewType } = props
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {(event) ? <div className={classes.list}>
                {event.title && <div className={classes.listItem}> {t('HELPERS.EVENT_TOOL_TIP.Event title' )} : {event.title}</div>}
                {event.location && event.location.name &&
                    <div className={classes.listItem}> {t('HELPERS.EVENT_TOOL_TIP.Location' )} : {event.location.name}</div>
                }
                {event.customerName && <div className={classes.listItem}> {t('HELPERS.EVENT_TOOL_TIP.Customer' )} : {event.customerName}</div>}
                {
                    tenantConfig.companyName === "freiraum" && event.totalprice &&
                        <div className={classes.listItem}> {t('HELPERS.EVENT_TOOL_TIP.TotalPrice' )} : {event.totalprice} €</div>
                }

                {viewType && (viewType === 'month' || viewType === 'week') ?
                <div>
                    <div className={classes.listItem}> {t('HELPERS.EVENT_TOOL_TIP.Start Date' )} : {moment(event.start).format('DD/MM/YYYY HH:mm')}</div>
                    <div className={classes.listItem}> {t('HELPERS.EVENT_TOOL_TIP.End Date' )} : {moment(event.end).format('DD/MM/YYYY HH:mm')}</div>
                </div>
                :  null }
            </div> : <div className={classes.listItem}> {t('HELPERS.EVENT_TOOL_TIP.Loading' )} </div> }
        </React.Fragment>
    );
}

export default EventTooltip
