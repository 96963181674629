import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {HTTP_REQUESTS} from '../../backendcomm/services/httpRequestService';
import {withSnackbar} from 'notistack';
import SignUpForm from './SignUpForm';
import {useTranslation} from 'react-i18next';
import Box from "@mui/material/Box";
import Copyright from "./Copyright";
import {Avatar} from "@mui/material";
import {LockOutlined} from "@mui/icons-material";
import VariantAvatars from "./CompanyAvatar";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import LanguageDropdown from "../Menu/LanguageDropdown";
import {makeStyles} from "@mui/styles";
import emailStrIsValid from "../../helpers/Validation";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        backgroundColor: '#f50057',
        alignSelf: 'center',
    },
    selector: {
        color: 'white',
        fontSize: 'medium',
        "& .MuiSvgIcon-root": {color: "black"}
    },
}));


function SignUp(props) {
    const [flag, setFlag] = React.useState(false)
    const [name, setName] = React.useState(null);
    const [nameError, setNameError] = React.useState(null);
    const [phoneNo, setPhoneNo] = React.useState(null);
    const [phoneNoErr, setPhoneNoErr] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [emailError, setEmailError] = React.useState(null);
    const [psw, setPsw] = React.useState(null);
    const [psw_2, setPsw_2] = React.useState(null);
    const [pswError, setPswError] = React.useState(null);
    const [pswError_2, setPswError_2] = React.useState(null);
    const [opInProgress, setOpInProgress] = React.useState(null);

    const {t} = useTranslation();
    const classes = useStyles();


    const flagChanged = event => {
        setFlag(event.target.checked);

    }
    const nameChanged = event => {
        setName(event.target.value);
        setNameError(null);
    }


    const emailChanged = event => {
        setEmail(event.target.value);
        setEmailError(null);
    }

    const passwordChanged = event => {
        setPsw(event.target.value);
        setPswError(null);
    }

    const passwordChanged_2 = event => {
        setPsw_2(event.target.value);
        setPswError_2(null);
    }

    const phoneNoChanged = event => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            setPhoneNo(inputValue);
            setPhoneNoErr(null);
        } else {
            event.target.value = phoneNo;
        }
    }


    const signUpCliked = () => {
        let formValid = true;
        if (!email || email === "") {
            setEmailError('errorProperties.SIGN_UP.SIGN_UP_CLICKED.SET_EMAIL_ERROR');
            formValid = false;
        }
        if (email && !emailStrIsValid(email)) {
            setEmailError('errorProperties.DEVICES_FORM.EMAIL_NOT_VALID');
            formValid = false;
        }
        if (!name || name === "") {
            setNameError('errorProperties.SIGN_UP.SIGN_UP_CLICKED.SET_NAME_ERROR');
            formValid = false;
        }
        if (name && name.length < 2 && name.length > 0) {
            setNameError('errorProperties.SIGN_UP.SIGN_UP_CLICKED.SET_NAME_ERROR_LENGTH');
            formValid = false
        }


        if (!phoneNo || phoneNo === "") {
            setPhoneNoErr('SignUp.Phone Required');
            formValid = false;
        }

        if (!psw || psw === "") {
            setPswError('errorProperties.SIGN_UP.SIGN_UP_CLICKED.SET_PSW_ERROR');
            formValid = false;
        } else if (psw.length < 6) {
            setPswError('errorProperties.SIGN_UP.SIGN_UP_CLICKED.SET_PSW_ERROR_LENGTH');
            formValid = false;
        }

        if (!psw_2 || psw_2 === "") {
            setPswError_2('errorProperties.SIGN_UP.SIGN_UP_CLICKED.SET_PSW_ERROR_2')
            formValid = false;
        } else if (psw !== psw_2) {
            setPswError_2('errorProperties.SIGN_UP.SIGN_UP_CLICKED.SET_PSW_ERROR_NOT_SAME')
            formValid = false;
        }

        if (formValid) processSignUp();

    }

    const processSignUp = () => {
        let params = {name: name, phoneNo: phoneNo, isSubscribed: flag};
        setOpInProgress(true);
        HTTP_REQUESTS.FIREBASE_SERVICE.REGISTER(email, psw, (userCredential) => {
            localStorage.setItem('accessToken', userCredential?.user?.accessToken);
            HTTP_REQUESTS.USER_SERVICE.REGISTER(
                params, (data) => {
                    setOpInProgress(false);
                    props.onRegistrationSuccess(email);
                }, (err) => {
                    // TODO handle delete user from firebase
                    props.enqueueSnackbar(t('errorProperties.SIGN_UP.PROCESS_SIGN_UP'), {variant: 'error'});
                    HTTP_REQUESTS.FIREBASE_SERVICE.DELETE_USER(userCredential?.user?.auth?.currentUser, () => {
                        console.log('Successfully deleted user');
                    }, (err) => {
                        console.log('Error deleting user:', err);
                    })
                    setOpInProgress(false);
                })
        }, (err) => {
            props.enqueueSnackbar(t(`errorProperties.SIGN_UP.${err.code}`), {variant: 'error'});
            setOpInProgress(false);
        })
    }

    return (
        <Container component="main" maxWidth="xs" className="signUpRoot">
            <CssBaseline/>
            <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
                <FormControl>
                    <LanguageDropdown classes={classes}/>
                </FormControl>
            </Grid>
            <Grid className={classes.container}>
                <VariantAvatars/>
                <Avatar className={classes.avatar}>
                    <LockOutlined/>
                </Avatar>
            </Grid>
            <SignUpForm
                nameChanged={nameChanged}
                phoneNoChanged={phoneNoChanged}
                emailChanged={emailChanged}
                passwordChanged={passwordChanged}
                passwordChanged_2={passwordChanged_2}
                flagChanged={flagChanged}
                signUpCliked={signUpCliked}
                nameError={nameError}
                phoneNoError={phoneNoErr}
                emailError={emailError}
                pswError={pswError}
                pswError_2={pswError_2}
                opInProgress={opInProgress}
                onSignInClicked={props.onSignInClicked}
            />
            <Box mt={5} className="box">
                <Copyright/>
            </Box>
        </Container>
    );
}

export default withSnackbar(SignUp)
