import InputBase from "@mui/material/InputBase";
import {styled} from "@mui/styles";

const DropDownInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        width: 'max-content',
        borderRadius: 12,
        position: 'relative',
        backgroundColor: 'transparent',
        border: '2px solid rgba(0, 0, 0, 0.54)',
        fontSize: 16,
        fontWeight:'bold',
        padding: '5px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Titillium Web',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            backgroundColor: 'transparent',
            borderRadius: 12,
        },
    },
}));
export default DropDownInput;