import {store} from "../../store/store";

const MIN_BOOKING_TIME_VALUES = {
    iotiq: {
        time :30,
        list : [15,30,45,60]
    },
    freiraum:{
        time :60,
        list : [60,90,120,180]
    },
    oz:{
        time :30,
        list : [15,30,45,60]
    }
}

export function  decideMinBookingTime ()  {
    let time = MIN_BOOKING_TIME_VALUES.iotiq.time
    if(store.getState().tenantConfig.companyName === "freiraum"){
        time = MIN_BOOKING_TIME_VALUES.freiraum.time
    }
    else if(store.getState().tenantConfig.companyName === "oz"){
        time = MIN_BOOKING_TIME_VALUES.oz.time
    }
    return time
}

export function decideMinBookingTimeList ()  {
    let list = MIN_BOOKING_TIME_VALUES.iotiq.list
    if(store.getState().tenantConfig.companyName === "freiraum"){
        list = MIN_BOOKING_TIME_VALUES.freiraum.list
    }
    else if(store.getState().tenantConfig.companyName === "oz"){
        list = MIN_BOOKING_TIME_VALUES.oz.list
    }
    return list
}
