export const convertUuidToDisplay = (uuid) => {
    if (uuid.length <= 6) {
        return uuid.toUpperCase();
    } else {
        return uuid.slice(-6).toUpperCase();
    }
}

export const isUuid = (str) => {
    const uuidRegex = /^[\da-f]{8}-[\da-f]{4}-[1-5][\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}$/i;
    return uuidRegex.test(str);
}
