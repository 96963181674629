import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import ForgotPassword from "./ForgotPassword";
import FormControl from '@mui/material/FormControl';
import LanguageDropdown from '../Menu/LanguageDropdown';
import {useAppSelector} from "../../store/hooks";
import {selectTenantConfig} from "../../store/features/tenantConfigSlice";



const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}));

function ForgotPasswordSide(props) {
    const classes = useStyles();
    const tenantConfig = useAppSelector(selectTenantConfig);

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} sx={{backgroundImage: `url(${tenantConfig.loginLeftImage})`}}/>
            <Grid item container justifyContent="center" alignItems="center" padding={"0px 30px"} xs={12} sm={8} md={5}>
                <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
                    <FormControl>
                        <LanguageDropdown classes={classes} />
                    </FormControl>
                </Grid>
                <ForgotPassword onSignInClicked={() => props.onSignInClicked()}/>
                <Grid item xs={12}></Grid>
            </Grid>
        </Grid>
    );
}

export default withSnackbar(ForgotPasswordSide);
