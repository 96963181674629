import React, {useEffect} from 'react'
import { withStyles } from "@mui/styles";
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import CustomSpinner from './CustomSpinner';
import LoadingPage from './LoadingPage';

const WhiteTextTypography = withStyles({
    root: {
      color: "#f7f1e3"
    }
  })(Typography);


 const ErrorPage = (props) => {

     const navigate = useNavigate();

     const useStyles = makeStyles({
      gridStyle: {
          minHeight: '100vh',
      },
        bgImageStyle: {
            height: '100vh',
            backgroundColor:'#102542',
        },
        fontSizeLarge : {
            fontSize:'60px'
        }
    });

    useEffect(() => {
        checkUrl();
    });

    const [errorMessage, setErrorMessage] = React.useState('');
    const [showButton, setShowButton] = React.useState(true);
    const classes = useStyles();

    const dashboardButtonHandler = () => {
        navigate("/dashboard");
    }

    const { t, i18n } = useTranslation();

    const checkUrl = () => {
        if (window.location.href.includes("403")) {
            setErrorMessage('Error Page.403 Message')
            setShowButton(true);
        }
        else if(window.location.href.includes("404")){
            setErrorMessage('Error Page.404 Message')
            setShowButton(true);
        }
        else {
   
            setErrorMessage('Error Page.Tenant Not Found Message')
            setShowButton(false);
        }
    }

    
        return (
            <div className={classes.bgImageStyle}>
                {
                    !props.isLoading ? 
                    <Grid   container
                            className={classes.gridStyle}
                            direction="column"
                            alignItems="center"
                            justifyContent="center">

                        <Grid item xs={12}  >
                            <WhiteTextTypography color="textPrimary" noWrap variant="h1"  >Oops!</WhiteTextTypography>
                        </Grid>

                        <Grid item xs={12} >
                            <WhiteTextTypography variant="h3"  >{(errorMessage != null && errorMessage.length > 0) ? t(errorMessage):''}</WhiteTextTypography>
                        </Grid>

                        <Grid item xs={12} >
                            <WhiteTextTypography variant="h3"  ><ErrorIcon className={classes.fontSizeLarge} color="textPrimary" fontSize="large" /></WhiteTextTypography>
                        </Grid>

                        {
                            showButton &&
                            <Grid item xs={12} >
                                <Button color="secondary" size='large' variant="contained" onClick={dashboardButtonHandler}>{t('Error Page.Go Dashboard')}</Button>
                            </Grid>
                        }
                    </Grid>
                    : <LoadingPage/>
                }
            </div>
        );
}

export default ErrorPage
