export default class Calendar { //  TODO change name by Calender
  constructor(
    id, 
    type, 
    summary, 
    description, 
    seat, 
    images, 
    color, 
    notes, 
    allDayRoomPrice,
    halfDayRoomPrice,
    additionalPrice,
    bufferTime,
    minBookingTime,
    locationId,
    categoryId,
    roomId,
    layoutSeatArray,
    imageURL,
    restrictedPercantage,
    bookableEntityId,
    canBeBookHourly,
    allowPublicBooking,
    locationName,
    ) {
    this.id = id;
    this.type = type;
    this.summary = summary;
    this.description = description;
    this.seat = seat;
    this.images = images;
    this.color = color;
    this.notes = notes;
    this.allDayRoomPrice = allDayRoomPrice;
    this.halfDayRoomPrice = halfDayRoomPrice;
    this.additionalPrice = additionalPrice;
    this.bufferTime = bufferTime;
    this.minBookingTime = minBookingTime;
    this.locationId = locationId;
    this.locationName = locationName;
    this.categoryId = categoryId;
    this.roomId = roomId;
    this.layoutSeatArray = layoutSeatArray;
    this.imageURL = imageURL;
    this.restrictedPercantage = restrictedPercantage;
    this.bookableEntityId = bookableEntityId;
    this.canBeBookHourly = canBeBookHourly;
    this.allowPublicBooking = allowPublicBooking;
  }
  layoutSeatArrayToAPI = (data) => {
    let tempLayoutSeatArray = [];
    for (const layoutsSeat in data) {
      tempLayoutSeatArray.push({layoutSeat:data[layoutsSeat], id:layoutsSeat})
    }
    return tempLayoutSeatArray;
  }
  layoutSeatArrayToUI = (data) => {
    if(data) {
      let tempLayoutsSeatObject = {};
      data.forEach((item) => {
        tempLayoutsSeatObject[item.layoutId] = item.layoutSeat
      })
      return tempLayoutsSeatObject;
    } else {
      return null;
    }
  }
  toUIJSON() {
    return {
      id: this.id,
      type: this.type,
      summary: this.summary,
      description: this.description,
      seat: this.seat,
      images: this.images,
      color: this.color,
      notes: this.notes,
      allDayRoomPrice: this.allDayRoomPrice,
      halfDayRoomPrice: this.halfDayRoomPrice,
      additionalPrice: this.additionalPrice,
      bufferTime: this.bufferTime,
      minBookingTime: this.minBookingTime,
      locationId: this.locationId,
      locationName: this.locationName,
      categoryId: this.categoryId,
      roomId: this.roomId,
      layoutSeatArray:this.layoutSeatArrayToUI(this.layoutSeatArray),
      bookableEntityId:this.bookableEntityId,
      imageURL:this.imageURL,
      restrictedPercantage:this.restrictedPercantage,
      canBeBookHourly:this.canBeBookHourly,
      resourceId: this.roomId,
      allowPublicBooking: this.allowPublicBooking,
    };
  }

  toAPIJSON() {
    return {
      id: this.id,
      type: this.type,
      summary: this.summary,
      description: this.description,
      seat: this.seat,
      images: this.images,
      color: this.color,
      notes: this.notes,
      allDayRoomPrice: this.allDayRoomPrice,
      halfDayRoomPrice: this.halfDayRoomPrice,
      additionalPrice: this.additionalPrice,
      bufferTime: this.bufferTime,
      minBookingTime: this.minBookingTime,
      locationId: this.locationId,
      locationName: this.locationName,
      categoryId: this.categoryId,
      roomId: this.roomId,
      layoutSeatArray:this.layoutSeatArrayToAPI(this.layoutSeatArray),
      bookableEntityId:this.bookableEntityId,
      restrictedPercantage:this.restrictedPercantage,
      canBeBookHourly:this.canBeBookHourly,
      allowPublicBooking: this.allowPublicBooking
    }

    // throw Error("Not implmeented yet"); //TODO: implement this
  }
}

/**
 * name : newCalendarInfo.summary,
  description : newCalendarInfo.description,
  seat: newCalendarInfo.seat,
  images : newCalendarInfo.images,
  color : newCalendarInfo.color,
  notes : newCalendarInfo.notes,
  allDayRoomPrice  : newCalendarInfo.allDayRoomPrice ,
  halfDayRoomPrice : newCalendarInfo.halfDayRoomPrice,
  additionalPrice: newCalendarInfo.additionalPrice,
  bufferTime : newCalendarInfo.bufferTime,
  minBookingTime : newCalendarInfo.minBookingTime,
  locationId : newCalendarInfo.locationId,
  categoryId: newCalendarInfo.categoryId,

 */
