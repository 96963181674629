import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { currentEvent, nextEvent } from '../../util';

const LobbyCard = (props) => {
    const [available, setAvailable] = useState([false]);
    const [roomData, setRoomData] = useState({});
    const [eventsData, setEventsData] = useState([]);
    const [currentEventData, setCurrentEventData] = useState([]);
    const [nextEventData, setNextEventData] = useState({});
    const { t } = useTranslation();

    useEffect(() => { 
        if(props.room) {
            setRoomData(props.room);
        }
    }, [props.room]);

    useEffect(() => { 
        if(props.event) {
            setEventsData(props.event);
        }
    }, [props.event]);

    useEffect(() => {
        let currentEventDataTemp = currentEvent(eventsData);
        let nextEventDataTemp = nextEvent(eventsData);
        setCurrentEventData(currentEventDataTemp);
        setNextEventData(nextEventDataTemp);
    }, [eventsData]);

    useEffect(() => {
        setAvailable((currentEventData.id) ? false : true);
    }, [currentEventData]);

    if(props.room) {
        return (
            <div className={`lobby-card ${available ? "available" : "booked"}`}>
                <div className='inner'>
                    <div className="room-status">{(available) ? t('Lobby.AVAILABLE') : t('Lobby.BOOKED')}</div>
                    <div style={{width:'100%'}}>
                        <div className="room-name">{roomData.summary}</div>
                        { (!available) && <div className="event-info">
                            <div className="title">{t('Lobby.Current Meeting')}</div>
                            <div className="event-title">{currentEventData.title}</div>
                            <div className="event-time">{moment(currentEventData.start).format('Do MMM')}</div>
                            <div className="event-time">{moment(currentEventData.start).format('HH:mm')} - {moment(currentEventData.end).format('HH:mm')}</div>
                        </div> }
                        { (available && nextEventData.id) && <div className="event-info">
                            <div className="title">{t('Lobby.Next Meeting')}</div>
                            <div className="event-title">{nextEventData.title}</div>
                            <div className="event-time">{moment(nextEventData.start).format('Do MMM')}</div>
                            <div className="event-time">{moment(nextEventData.start).format('HH:mm')} - {moment(nextEventData.end).format('HH:mm')}</div>
                        </div> }
                    </div>
                </div>
            </div>
        );   
    } else {
        return null;
    }
}
export default LobbyCard;