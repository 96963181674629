import React from 'react';
import { withSnackbar } from 'notistack';
import {Grid, Typography} from "@mui/material";
import {useLocation} from "react-router-dom";
import {calculatePriceDTOFrom, eventDTOFrom, sessionStateFrom} from "../../../helpers/dataConverter";
import {HTTP_REQUESTS} from "../../../backendcomm/services/httpRequestService";
import CreateEventForm from "./CreateEventForm";
import PriceInfoCard from "./PriceInfoCard";
import eventDates from "../helpers/EventDates";
import LoginOrRegisterModal from "../Login/LoginOrRegisterModal";
import { useNavigate } from 'react-router-dom'
import {useTranslation} from "react-i18next";
import EventCreationSkeleton from "../../Skeletons/EventCreationSkeleton";
import {useAppSelector} from "../../../store/hooks";
import {selectSessionData} from "../../../store/features/sessionSlice";
const EventCreation = (props) => {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const sessionData = useAppSelector(selectSessionData)

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isLoginFormOpen, setLoginFormOpen] = React.useState(false);

    // Load parameters from location state
    const {state} = useLocation();
    const {roomData, selectedDate, selectedStart, selectedEnd} = state || props;

    // Dates
    const eventDate = new eventDates(selectedDate, selectedStart, selectedEnd)
    // Additional Features
    const [additionalFeatures, setAdditionalFeatures] = React.useState(
        {
            notes:'',
            tableLayout:roomData.Layouts[0],
            caterings:[],
            moderationEquipment:[],
            cateringsCount:{}
        }
    );
    // Price
    const [priceObj, setPriceObj] = React.useState({});
    const [participantCount, setParticipantCount] = React.useState(0);
    const [title,setTitle] = React.useState('')
    const [seat,setSeat] = React.useState(); 

    const resetStates = () => {
        setAdditionalFeatures(
            {
                notes:'',
                tableLayout:roomData.Layouts[0],
                caterings:[],
                moderationEquipment:[],
                cateringsCount:{}
            }
        )
        setParticipantCount(0)
        setTitle('')
        setSeat()
    }

    // Reset states when roomData changes
    React.useEffect(() => {
        resetStates();
    },[props.roomData.id])


    React.useEffect(() => {
        calculatePrice()
    },[additionalFeatures.caterings,additionalFeatures.cateringsCount,additionalFeatures.moderationEquipment])
    React.useEffect(() => {
        calculateSeatCount()
    },[additionalFeatures.tableLayout])

    //******************************************************** Create
    const showBookingRequestError = (err) => {
        if(err.data && err.data.error && (err.data.error.code === 2001 || err.data.error.code === 2002 )){
            let errorDetails = err.data.error.details
            if( errorDetails.code === 100){
                let errorWord = t(`errorProperties.RCALENDAR.CREATE_EVENT.FIELDS.${errorDetails.key}`)
                props.enqueueSnackbar(t(`errorProperties.RCALENDAR.CREATE_EVENT.${errorDetails.code}.${errorDetails.type}`,{fieldName:errorWord}), {
                    variant: 'error'
                });
            }else if (errorDetails.code === 0){
                if(err.data.error.code === 2001){
                    props.enqueueSnackbar(t('errorProperties.RCALENDAR.CREATE_EVENT.109'), {
                        variant: 'error'
                    });
                }else{
                    props.enqueueSnackbar(t('errorProperties.RCALENDAR.CREATE_EVENT.112'), {
                        variant: 'error'
                    });
                }
            }else{
                props.enqueueSnackbar(t(`errorProperties.RCALENDAR.CREATE_EVENT.${errorDetails.code}`), {
                    variant: 'error'
                });
            }
        }else{
            props.enqueueSnackbar(t('errorProperties.RCALENDAR.CREATE_EVENT.109'), {
                variant: 'error'
            });
        }
    }
    const isFormValid = () => {
        return !(!title || title === '');
    }
    const createEventRequest = () => {
        setIsLoaded(false)
        let event = eventDTOFrom(additionalFeatures, eventDate, title, roomData.id, priceObj, seat, participantCount, sessionData.email);
        //eventObj = dataToEventObj()
        HTTP_REQUESTS.BOOKING_SERVICE.CREATE_A_BOOKING(false, event, (res) => {
            props.enqueueSnackbar(t('Booking.InfoCard.createEventSuccess'), {
                variant: 'success',
            })
            // TODO: Replace this when multiple room booking interfaces are ready
            navigate(`/summary/${res[0].id}`, {state: {roomId: roomData.id}})
        }, (err) => {
                setIsLoaded(true)
                showBookingRequestError(err)
            });
    }

    const saveModal = () => {
        let isLoggedIn = localStorage.getItem('accessToken')
        if (isFormValid()) {
            if (isLoggedIn) {
                createEventRequest()
            }
            else {
                setLoginFormOpen(true)
            }
        }
        else{
            props.enqueueSnackbar(t('errorProperties.RCALENDAR.CREATE_EVENT.100.required', {fieldName: t('Booking.InfoCard.eventName')})
        , {
                variant: 'error',
            })
        }
    }

    //********************************************************************
    const calculateSeatCount = () => {
        let layoutId = additionalFeatures.tableLayout.id
        if(layoutId){
            let currentTableLayout = roomData.RoomLayouts.filter(roomLayout => roomLayout.layoutId === layoutId)
            setSeat(currentTableLayout[0].layoutSeat)
        }
    }

    const calculatePrice = () => {
        const calculateObj = calculatePriceDTOFrom(additionalFeatures, eventDate)
        HTTP_REQUESTS.BOOKING_SERVICE.CALCULATE_PRICE_OF_BOOKING_PUBLIC(roomData.id, calculateObj, res => {
            setPriceObj(res)
            if(!isLoaded){
                setIsLoaded(true)
            }
        }, err=> {
            console.log(err, 'calculate price error')
        })
    }

    const inputGroupChangeHandlerAdditionalFeatures = (event) => { // sets additionalFeatures state to fill table layout, catering and moderation equipment dropdowns
        if (event.constructor.name === 'SyntheticEvent') {
            event.persist();
        }
        let dataType = event.target.id || ''
        if(dataType === 'cateringsCount') {
            setAdditionalFeatures((prevState) => ({
                ...prevState,
                [dataType] : {
                    ...prevState[dataType],
                    [event.target.name]: event.target.value
                }
            }));
        } else {
            setAdditionalFeatures((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value
            }));
        }

    }
    const updateParticipantCount = (increment)=>{
        let newParticipantCount = participantCount + increment
        if(newParticipantCount >= 0){
            setParticipantCount(newParticipantCount)
        }
    }

    const handleParticipantCountChangeEvent = (event) => {
        let newParticipantCount = Number(event.currentTarget.value);
        if(newParticipantCount >= 0){
            setParticipantCount(newParticipantCount)
        }
        else{
            setParticipantCount(0)
        }
    }

    return isLoaded ? <div style={{width:'90%',margin:'0 auto'}}>{isLoginFormOpen ? <LoginOrRegisterModal
            open={isLoginFormOpen}
            method='login'
            setLoginFormOpen={setLoginFormOpen} />
        : null
    }

        <Grid container item direction="column" justifyContent="flex-start" alignItems="flex-start" xs={12}>
            <Typography variant={"h4"}>{t('Booking.InfoCard.bookYourEvent')}</Typography>
            <Typography style={{opacity:'0.6'}} variant={'subtitle2'}>{t('Booking.InfoCard.bookYourEventInfo')}</Typography>
        </Grid>
        <Grid xs={12} container item style={{marginTop:'24px'}}>
            <Grid md={9} xs={12} item container>
                <CreateEventForm
                    priceObj={priceObj}
                    roomData={roomData}
                    title={title}
                    setTitle={setTitle}
                    additionalFeatures={additionalFeatures}
                    inputGroupChangeHandlerAdditionalFeatures={inputGroupChangeHandlerAdditionalFeatures}
                    isLoaded={isLoaded}
                    eventDate={eventDate}
                    seat={seat}
                    participantCount={participantCount}
                    handleParticipantCountChange={handleParticipantCountChangeEvent}
                    updateParticipantCount={updateParticipantCount}/>
            </Grid>
             <Grid md={3} xs={12} item container style={{height:'fit-content'}}>
                {<PriceInfoCard
                    priceObj={priceObj}
                    handleClickConfirm={saveModal} />}
            </Grid>
        </Grid>

    </div> : <EventCreationSkeleton/>
}
export default withSnackbar(EventCreation);
