import React, {useEffect} from 'react';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {faArrowUp, faArrowDown} from '@fortawesome/free-solid-svg-icons'

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    sortingFns,
    getSortedRowModel,
    flexRender,
    createColumnHelper
} from '@tanstack/react-table'

import {
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'

import "./CustomTable.scss"
import DebouncedInput from "./DebouncedInput";
import Filter from "./Filter";
import {ColumnType, RowsPerPageOptions} from "./TableConfig";
import {ActionButton, ActionIconSize} from "./TableActionButtons";
import IconButton from "@mui/material/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextField from "@mui/material/TextField";
import {MenuItem, Select} from "@mui/material";
import {t} from "i18next";
import CustomSpinner from "../../CustomSpinner";
import OverflowTip from "../../PublicComponents/helpers/OverflowTip";

/**
 * This table takes 4 properties to function
 * @param props.columns Array of column objects.
 *      column =>
 *          required= id:string, title:string, tooltip:string and type:ColumnType.
 *          optional= render:Fn(to render cells using the given function), cell:Fn(to customize rendering manually)
 * @param props.data Array of data objects. (keys of the data object should match exactly with the column object values)
 * @param props.title Title of the table:string
 * @param props.headerActions Array of headerAction objects.
 *       headerAction =>
 *          required= iconType:ActionButtonType, tooltip:string, clickAction: Object
 *              *clickAction* =>
 *                  required= actionType:ActionTypes
 *                  optional= navigateTo:string(if the actionType is "ActionTypes.navigate")
 *
 *
 */

const CustomTable = (props) => {
    const columnHelper = createColumnHelper();
    const columns = props.columns.map(column => {
        switch (column.type){
            case ColumnType.accessor:
                return columnHelper.accessor(column.id, {
                    id: column.id,
                    cell: column.cell ? column.cell : info => column.render ? column.render(info.getValue()) : info.getValue(),
                    header: () => column.title,
                    size: column.size
                })
            case ColumnType.display:
                return columnHelper.display({
                    id: column.id,
                    cell: column.cell ? column.cell : info => column.render ? column.render(info.getValue()) : info.getValue(),
                    header: () => column.title,
                    size: column.size
                })
            default:
                return columnHelper.accessor(column.id, {
                    id: column.id,
                    cell: column.cell ? column.cell : info => column.render ? column.render(info.getValue()) : info.getValue(),
                    header: () => column.title,
                    size: column.size
                })
        }
    });

    /*  FILTER  */

    const fuzzyFilter = (row, columnId, value, addMeta) => {
        // Rank the item
        const itemRank = rankItem(row.getValue(columnId), value)
        // Store the itemRank info
        addMeta({
            itemRank
        })

        // Return if item should be filtered
        return itemRank.passed;
    }

    /*  SORT  */

    const fuzzySort = (rowA, rowB, columnId) => {
        let dir = 0

        // Only sort by rank if the column has ranking information
        if (rowA.columnFiltersMeta[columnId]) {
            dir = compareItems(
                rowA.columnFiltersMeta[columnId].itemRank,
                rowB.columnFiltersMeta[columnId].itemRank
            )
        }

        // Provide an alphanumeric fallback for when the item ranks are equal
        return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
    }


    const [data, setData] = React.useState( [])
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [globalFilter, setGlobalFilter] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
    })

    return (
        <div className="table-container">
            <div className="table-header">
                <div className="table-title">
                    {props.title}
                </div>
                <DebouncedInput
                    type='search'
                    value={globalFilter || ''}
                    onChange={value => setGlobalFilter(value)}
                    placeholder={t('Table.General Search')}
                />
                <div className="header-actions">
                    {props.headerActions && props.headerActions.map(({tooltip, clickAction, iconType}, index) => {
                        return(
                            <div key={index}>
                                {ActionButton(tooltip, clickAction, iconType, ActionIconSize.xl)}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="upper-content">
                <table className="table">
                    <thead className="table-head">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <React.Fragment key={headerGroup.id}>
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th className="column-header" key={header.id} colSpan={header.colSpan} style={{width: header.getSize()}}>
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    <div
                                                        {...{
                                                            className: header.column.getCanSort()
                                                                ? 'cursor-pointer select-none column-header-container'
                                                                : 'column-header-container',
                                                            onClick: header.column.getToggleSortingHandler(),
                                                        }}
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {{
                                                            asc: <FontAwesomeIcon style={{color: 'gray', marginLeft: 5}} icon={faArrowDown} />,
                                                            desc: <FontAwesomeIcon style={{color: 'gray', marginLeft: 5}} icon={faArrowUp} />,
                                                        }[header.column.getIsSorted()] ?? null}
                                                    </div>

                                                </>
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                            <tr key={headerGroup.id + '-' + 'filter'}>
                                {headerGroup.headers.map((header, index) => {
                                    return (
                                        <th className="column-header" key={header.id} colSpan={header.colSpan}>
                                            {header.column.getCanFilter() ? (
                                                <div className="filter-container">
                                                    <Filter column={header.column} originalColumn={props.columns[index]} table={table} />
                                                </div>
                                            ) : null}
                                        </th>
                                    )
                                })}
                            </tr>
                        </React.Fragment>

                    ))}
                    </thead>

                    <tbody className="table-body">
                    {
                        table.getRowModel().rows.map((row, rowIndex) =>
                            <tr key={row.id} className="table-row">
                                {row.getVisibleCells().map((cell, index) => {
                                    return (
                                        <td key={`${row.id}-${cell.id}`}>
                                            <OverflowTip props={flexRender(cell.column.columnDef.cell, cell.getContext())}/>
                                        </td>                                     
                                    )
                                })}
                            </tr>
                        )
                    }
                    </tbody>
                </table>

                {
                    isLoading ? <CustomSpinner/> :
                    table.getRowModel().rows.length === 0 &&
                    <div className="no-record-msg"><p>{t('Table.NoRecordsMessage')}</p></div>
                }
            </div>
            <div className="table-footer">
                <div className="page-index-info">
                    {`${t('Table.Page')} `}
                    <strong>
                        {table.getState().pagination.pageIndex + 1} of{' '}
                        {table.getPageCount()}
                    </strong>
                </div>
                <div className="page-navigate">
                    <IconButton
                        className="navigate-arrow-button"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <KeyboardDoubleArrowLeftIcon/>
                    </IconButton>
                    <IconButton
                        className="navigate-arrow-button"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <KeyboardArrowLeftIcon/>
                    </IconButton>
                    <TextField
                        type="number"
                        variant="standard"
                        value={table.getState().pagination.pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                        }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        className="navigate-input"
                    />
                    <IconButton
                        className="navigate-arrow-button"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <KeyboardArrowRightIcon/>
                    </IconButton>
                    <IconButton
                        className="navigate-arrow-button"
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        <KeyboardDoubleArrowRightIcon/>
                    </IconButton>
                </div>
                <div className="page-select-rows-per-page">
                    <Select
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                        variant="standard"
                    >
                        {RowsPerPageOptions.map(pageSize => (
                            <MenuItem key={pageSize} value={pageSize}>
                                {`${t('Table.Show')} ${pageSize}`}
                            </MenuItem>
                        ))}
                    </Select>
                </div>

            </div>
        </div>
    )
}

export default CustomTable;
