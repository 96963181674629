import React from 'react';
import { makeStyles } from '@mui/styles';
import {Grid, Typography,TextField,Button,Select,MenuItem,FormLabel} from '@mui/material'
import { SketchPicker } from 'react-color';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {useAppSelector} from "../../store/hooks";
import {selectTenantConfig} from "../../store/features/tenantConfigSlice";

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: '1rem',
        fontWeight: theme.typography.fontWeightRegular,
    },
    swatch: {
        padding: "5px",
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
          position: 'absolute',
          zIndex: '2',
      },
      cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
      },
      square: {
          width: '40px'  ,
          height: '40px',
          minWidth: 'unset',
         
      },
}));

const AdvancedSettings = props => {
    const tenantConfig = useAppSelector(selectTenantConfig);

    const { t } = useTranslation();
  const classes = useStyles();
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false)
  const onChangeSeatCount = (event) => {
    props.onChangeSeatCount(event.target.value,event.target.id)
}  
  const onChangeMinTime = event => {
      props.onChangeMinTime(event.target.value)
  }
  const onChangePercantage = event => {
    props.onChangePercantage(event)
}
  const handleClick = () => {
    setDisplayColorPicker( !displayColorPicker )
    }
  const handleClose = () => {
        if(displayColorPicker){
            setDisplayColorPicker( false )
        } 
    }
  const onColorChange = event => {
    props.onColorChange(event.hex)
  }
  const layoutSeatValue = (layout) => {
    let tempValue;

    tempValue = props.layoutsSeats[layout.id];
    return (tempValue) ? tempValue  : 0;
  }
  return (
    
    <Card style={{'padding': '16px'}}>
    <div className={classes.root}>
    {/* <pre>{JSON.stringify(props.layouts, null, 2) }</pre> */}

        <Grid item xs={12} align='center' style={{'marginBottom': '16px'}}>
            <Typography variant="h5">{t('Entities.AdvancedSettings.Advanced')}</Typography>
        </Grid>
        <Grid item container xs={12} spacing={2}>
            
            <Grid item container xs={12} lg={12} spacing={2}>
            {props.layouts.map((layout, index) => {
                return <Grid key={index} item xs={12} lg={6}><TextField
                    name={`${layout.name}`}
                    fullWidth
                    id={layout.id}
                    label={`${tenantConfig.companyName === "oz" ? "" : layout.name} ${t('Entities.AdvancedSettings.Seats')}`}
                    value={layoutSeatValue(layout)}
                    onChange={onChangeSeatCount}
                    error={props.seatsError != null && props.seatsError.length > 0}
                    helperText={(props.seatsError != null && props.seatsError.length > 0) ? t(props.seatsError) : ''}
                    required
                /></Grid>
            })}
            </Grid>
            <Grid item container xs={12} lg={12} spacing={2}>
                <Grid item xs={12} lg={6} >
                    <FormLabel  id="location-label">{t('Entities.AdvancedSettings.Min Booking')}</FormLabel>
                        <Select
                        fullWidth
                        id="minBookingTime"
                        name="minBookingTime"
                        value={props.minTime}
                        onChange={onChangeMinTime}
                        >
                        {props.minTimeList.map((time, index)=>(
                            <MenuItem key={index} value={time}>{time}</MenuItem>
                        ))}
                        </Select>
                </Grid>
            </Grid>                            
        </Grid>
        <Grid direction='column' item container xs={12} lg={6} spacing={2} >
            <Grid item xs={12} lg={6}>
                <TextField
                    sx={{marginTop: 2, paddingRight: {lg: 1}}}
                    fullWidth
                    name="restrictedPercantage"
                    id="restrictedPercantage"
                    label= {t('Entities.AdvancedSettings.RestrictedPercantage')}
                    value={props.restrictedPercantage}
                    onChange={onChangePercantage}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 100 },endAdornment:'%'}}
                    helperText={t('Entities.AdvancedSettings.RestrictedHelper')}
                 />
            </Grid>  
            <Grid item style={{'display':'flex'}}>
               <Typography variant='p' style= {{'fontSize': '1rem', 'color' : 'rgba(0, 0, 0, 0.54)'}}>
                    {t('Entities.AdvancedSettings.Room Colour')}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                style= {{'backgroundColor': props.hex}}
                className={classes.square}
                onClick={ handleClick } 
                />
            </Grid>
                { displayColorPicker ? <div className={classes.popover} >
                <div id='coverr' className={classes.cover} onClick={handleClose} />
                <SketchPicker color={ props.hex } onChange={ onColorChange } />
                </div> : null }
        </Grid>
    </div>
    </Card>
    
  );
}
export default AdvancedSettings;
