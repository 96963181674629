import React from 'react'
import "./LandingPage.css"
import { faArrowRightLong, faDoorClosed, faPeopleGroup, faCalendar } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom'
import LanguageDropdown from "../components/Menu/LanguageDropdown";
import { useTranslation } from 'react-i18next';

const LandingPage = () => {

    const { t } = useTranslation();

    window.onload = () => {
        const sections = document.getElementsByClassName('scrollable');
        let currentDivIndex = 0;

        window.addEventListener('wheel', (event) => {
            event.preventDefault(); // Prevent default scrolling

            const scrollDirection = event.deltaY > 0 ? 1 : -1; // Determine scroll direction
            currentDivIndex += scrollDirection;

            currentDivIndex = Math.max(0, Math.min(currentDivIndex, sections.length -1));

            const currentSection = sections[currentDivIndex];

            currentSection.scrollIntoView({
                behavior: 'smooth',
                inline: 'nearest'
            })

        }, {passive: false})
    }

    const scrollInto = (sectionIndex) => {
        const sections = document.getElementsByClassName('scrollable');
        const currentSection = sections[sectionIndex];
        currentSection.scrollIntoView({
            behavior: 'smooth',
            inline: 'nearest'
        })
    }

    const navigate = useNavigate();

    const Service = ({icon, title, text}) => {
        return (
            <div className="service-container">
                <FontAwesomeIcon className="icon" icon={icon} size="3x"/>
                <h2>{t(`Landing.services.${title}`)}</h2>
                <p>{t(`Landing.services.${text}`)}</p>
            </div>
        )
    }


    return (
        <div className="landing-container">

            <div id="main" className="main-content scrollable">
                <div className="top-bar">
                    <div className="logo-container button"></div>
                    <div className="navigation-container">
                        <div className="navigation-button button" onClick={() => scrollInto(1)}>{t('Landing.main.services')}</div>
                        <div className="navigation-button button" onClick={() => scrollInto(2)}>{t('Landing.main.reviews')}</div>
                        <div className="navigation-button button" onClick={() => scrollInto(1)}>{t('Landing.main.about')}</div>
                        <div className="navigation-button button" onClick={() => scrollInto(1)}>{t('Landing.main.terms')}</div>
                        <div className="navigation-button button" onClick={() => scrollInto(1)}>{t('Landing.main.contact')}</div>
                        <div aria-label="main" className="navigation-button button">{t('Landing.main.getStarted')}</div>
                        <LanguageDropdown/>
                    </div>
                </div>
                <div className="main-content-container">
                    <div className="left-content">
                        <div className="caption">
                            {t('Landing.main.caption')}
                        </div>
                        <div className="description">
                            {t('Landing.main.description')}
                        </div>
                        <div className="big-button-container">
                            <div aria-label="normal" className="big-button button">
                                <p>{t('Landing.main.getStarted')}</p>
                                <FontAwesomeIcon icon={faArrowRightLong}/>
                            </div>
                            <div aria-label="inverted" className="big-button button" onClick={() => navigate('/login')}>
                                <p>{t('Landing.main.tryDemo')}</p>
                                <FontAwesomeIcon icon={faArrowRightLong}/>
                            </div>
                        </div>
                    </div>
                    <div className="right-image-container">
                    </div>
                </div>

            </div>
            <div id="services" className="services-content scrollable">
                <Service
                    icon={faDoorClosed}
                    title="0-title"
                    text="0-text"
                ></Service>
                <Service
                    icon={faPeopleGroup}
                    title="1-title"
                    text="1-text"
                >
                </Service>
                <Service
                icon={faCalendar}
                title="2-title"
                text="2-text"
                 ></Service>

            </div>
            <div id="reviews" className="client-reviews-content scrollable">

            </div>
        </div>
    )

}

export default LandingPage;
