import React from 'react';
import { makeStyles,  } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { getModerationEquipmentIcon } from './IconSelecter';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
        maxWidth: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const ModerationEquipmentDropdown = props => {
    const classes = useStyles();
    const [moderationEquipmentsData, setModerationEquipmentsData] = React.useState(props.moderationEquipmentsData);
    const moderationEquipmentsDataRaw = props.moderationEquipmentsDataRaw;


    const handleModerationEquipmentChange = (event) => {
        props.onChange(event)
    };

    React.useEffect(() => {
        const filterModerationEquipmentsData = () =>{
            // datayı maple , eğer value arayınde filtrele varsa rawda filtrele deletedAt ine bak , yoksa datayı rawda filtrele deletedAt ine bak
            let newData = ""
            let newDataArray = [];
            for(const oneData of props.moderationEquipmentsData){
                let snapshotValue = props.value.filter((item)=>item === oneData)[0]
                if(snapshotValue){
                    newData = moderationEquipmentsDataRaw.filter((item)=>item.name === snapshotValue)[0]
                    if(newData){
                        newDataArray.push(newData.name)
                    }
                }else{
                    newData = moderationEquipmentsDataRaw.filter((item)=>item.name === oneData && !item.deletedAt)[0]
                    if(newData){
                        newDataArray.push(newData.name)}
                }
            }
            setModerationEquipmentsData(newDataArray)
        }

        filterModerationEquipmentsData()
    },[moderationEquipmentsDataRaw, props.moderationEquipmentsData, props.value]);

    const isChecked = (value) =>{
        if(props.value) {
            return props.value.findIndex((defaultValue)=>defaultValue === value) > -1
        } else {
            return false;
        }
     }


     const isNotAvailable = (value) =>{
        let notAvailable = false
        for(const moderationEquipment of moderationEquipmentsDataRaw){
            if(value === moderationEquipment.name && moderationEquipment.deletedAt){
                notAvailable = true
            }
        }
        return notAvailable
    }

    return (
        <FormControl className={classes.formControl}>
            <Select
                id="entity_moderation_equipment"
                multiple
                name={props.name}
                value={props.value}
                onChange={handleModerationEquipmentChange}
                style={{ minHeight: '45px' }} /* added to fix icon size.  */
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {selected.map((value, index) => (
                            <div key={`${value}_${index}`} className={classes.chip}>
                                {getModerationEquipmentIcon(value,moderationEquipmentsDataRaw)}
                                {" "+value}
                            </div>
                        ))}
                    </div>
                )}
                disabled={props.disabled}
                MenuProps={MenuProps}>
                {moderationEquipmentsData.map((feature, index) => (
                    <MenuItem key={`${feature}_${index}`} value={feature} disabled={isNotAvailable(feature)}>
                        <Checkbox checked={isChecked(feature)}  />
                        <Chip
                            key={`${feature}_${index}`}
                            icon={getModerationEquipmentIcon(feature,moderationEquipmentsDataRaw)}
                            label={feature}
                            style={{backgroundColor:'transparent'}}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        
    )
}

export default ModerationEquipmentDropdown;
