import { Fade, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAllCalendars } from '../../data/getAllCalendars';
import { getAllEvents } from '../../data/getAllEvents';
import { currentEvent, filterCancelledEvents, groupEventsByRoom } from '../../util';
import DateTimeBar from "./DateTimeBar";
import LobbyCalendar from './LobbyCalendar';
import LobbyCard from './LobbyCard';
import { DATA_DURATION,SCREEN_DURATION} from '../../utils/Constants'
import {useAppSelector} from "../../store/hooks";
import {selectSessionData} from "../../store/features/sessionSlice";

const Lobby = (props) => {
    const sessionData = useAppSelector(selectSessionData)

    const [events, setEvents] = useState({});
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [entities, setEntities] = useState([]);
    const [screen, setScreen] = useState(1);
    const [intervalId, setIntervalId] = useState();
    const [callData, setCallData] = useState(true);

    const getEvents__ = (newEvents) => {
        const filteredEvents = filterCancelledEvents(newEvents)
        setEvents(groupEventsByRoom(filteredEvents));
        setCalendarEvents(filteredEvents);
    };

    const loadInitialData = () => {
        getAllEvents(sessionData.id, getEvents__, null, { locations: [], entities: {}, deleted:'false' });
        getAllCalendars(getCalendarsSuccessCb, getCalendarsFailCb);
    }

    const getCalendarsSuccessCb = (calendars) => {
        const entitiesWithCurrentEvents = findCurrentEvents(calendars)
        setEntities(entitiesWithCurrentEvents);
    }
    const getCalendarsFailCb = (err) => {
        console.log("error", err)
    }

    const findCurrentEvents = (entities) => {
        entities && entities.forEach(entity => {
            entity.currentEvent = currentEvent(events[entity.roomId]);
        });
        return entities
    }

    useEffect(() => {
        getAllCalendars(getCalendarsSuccessCb, getCalendarsFailCb);
    }, [events])

    useEffect(() => {
        console.log("123 SCREEN_DURATION : ",SCREEN_DURATION)
        let temp = (
            setInterval(function () {
                setScreen(screen => (screen === 1) ? 2 : 1);
            }, SCREEN_DURATION * 1000)
        );
        setIntervalId(temp);
        return () => {
            clearInterval(intervalId)
        }
    }, []);

    useEffect(() => {
        console.log("123 DATA_DURATION : ",DATA_DURATION)
        let temp = (
            setInterval(function () {
                setCallData(value => (value === true) ? false : true)
            }, DATA_DURATION * 1000)
        );
        setIntervalId(temp);
        return () => {
            clearInterval(intervalId)
        }
    }, []);

    useEffect(() => {
        loadInitialData();
    }, [callData]);



    return (
        <Grid container alignItems='center' justifyContent='flex-start' direction='column' className='lobby-main-wrapper'>
            <DateTimeBar />
            <Fade in={(screen === 1) ? true : false} unmountOnExit={true}>
                <Grid container alignItems='center' className='lobby-cards'>
                    {entities.map(room => {
                        return <Grid item xs={12} md={4} className="lobby-card-grid">
                            <LobbyCard room={room} event={events[room.roomId]} />
                        </Grid>
                    })}
                </Grid>
            </Fade>
            <Fade in={(screen === 2) ? true : false} unmountOnExit={true}>
                <Grid container className='lobby-calendar'>
                    <LobbyCalendar resourceMap={entities} events={calendarEvents} />
                </Grid>
            </Fade>
        </Grid>
    );
}
export default Lobby;
