import React from "react";
import {
	Button,
	Typography,
	Card,
	CardMedia,
	Grid,
	Divider,
} from "@mui/material";
import "date-fns";
import { withSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { Info, EventSeat } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../../helpers/dataConverter";
import {useAppSelector} from "../../../store/hooks";
import {selectTenantConfig} from "../../../store/features/tenantConfigSlice";

const buttonBackgroundColor = "#2d77fc";

const theme = createTheme();
theme.typography.h3 = {
	fontWeight: "normal",
	fontSize: "1.2rem",
	marginLeft: "6%",
	"@media (min-width:600px)": {
		fontSize: "1.5rem",
		marginLeft: "3%",
	},
	[theme.breakpoints.up("md")]: {
		fontSize: "2rem",
		marginLeft: "15.5%",
	},
};
theme.typography.h5 = {
	fontSize: "0.75rem",
	"@media (min-width:600px)": {
		fontSize: "0.8 rem",
	},
	[theme.breakpoints.up("md")]: {
		fontSize: "0.8rem",
	},
};
theme.typography.h6 = {
	fontSize: "0.6rem",
	"@media (min-width:600px)": {
		fontSize: "0.7rem",
	},
	[theme.breakpoints.up("md")]: {
		fontSize: "0.7rem",
	},
};
theme.typography.body2 = {
	fontSize: "0.7rem",
	"@media (min-width:600px)": {
		fontSize: "0.7rem",
	},
	[theme.breakpoints.up("md")]: {
		fontSize: "0.7rem",
	},
};

const PublicRoomCard = (props) => {
    const tenantConfig = useAppSelector(selectTenantConfig);

    const navigate = useNavigate();
	const { t } = useTranslation();
	const defaultRoomImage = "../../images/meetingRoom.png";

	const EventPeriod = moment.duration(moment(props.selectedEndTime).diff(moment(props.selectedStartTime))).asMinutes();
	const isEventLongerThanMinBookingTime = EventPeriod >= props.entity.minBookingTime;
	const getTooltipTitleDecider = () => {
		if (!isEventLongerThanMinBookingTime) {
			return (props.disableText + "(" + props.entity.minBookingTime + " min)");
		} else { return ""}
	};

	const tooltipTitle = getTooltipTitleDecider();

	const commaEditor = (str) => {
		const str2 = " " + t("Dashboard.MakeBooking.And") + " ";

		// Change all "," to ", " and put "and" instead of the last comma
		return str.replace(/,(?=[^,]+$)/, str2).replaceAll(",", ", ");
	};

	const getEntityPropertyNames = (properties) => {
		let moderationEquipmentArray = properties.filter(
			(item) =>
				item.type === "ModerationEquipment" &&
				item.name !== "Personalaufwand"
		);
		let propertyNamesWithCommas = " " + moderationEquipmentArray.map((property) => property.name);
		return commaEditor(propertyNamesWithCommas);
	};

	return (
		<Grid container>
            <ThemeProvider theme={theme}>
                <Card
                    sx={{
                        width: "100%",
                        minWidth: 300,
                        height: 125,
                        padding: "0px",
                        display: "flex",
                        border: "20%",
                        borderRadius: "10px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                    }}
                >
                    <Grid item xs={4}>
                        <CardMedia
                            component="img"
                            alt={props.entity.name}
                            height="100%"
                            image={
                                props.entity.imageUrl
                                    ? props.entity.imageUrl
                                    : defaultRoomImage
                            }
                            title={props.entity.name}
                            sx= {{"&.MuiCardMedia-media" : {objectFit:"cover"}}}
                        />
                    </Grid>

                    <Grid item container xs={8} direction="row">
                        <Grid
                            item
                            container
                            xs={12}
                            sx={{
                                alignItems:"flex-start",
                                marginTop:"5px",
                                marginRight:"5px"
                            }}
                            
                        >
                            <Divider
                                flexItem
                                orientation={"vertical"}
                                style={{
                                    width: "5px",
                                    borderRadius: "5px",
                                    backgroundColor: props.entity.color ? props.entity.color : "#f4247e",
                                    margin: "0px 5px",
                                    height: "35px",
                                }}
                            />
                            <Grid item container xs={6} spacing={2} direction="row">
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    sx={{
                                        flexWrap: "nowrap",
                                        height: "fit-content",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            whiteSpace: "nowrap",
                                        }}
                                        variant="h5"
                                        component="h2"
                                    >
                                        {props.entity.name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            display: { xs: "none", md: "flex" },
                                            alignItems: "center",
                                            marginLeft: "6px",
                                            marginRight: "5px",
                                        }}
                                        variant="h6"
                                    >
                                        <EventSeat sx={{fontSize:"medium", marginRight:"2px"}} />
                                        {props.entity.seat}
                                    </Typography>
                                </Grid>
                                <Tooltip title={props.entity.locationName} followCursor>
                                    <span style={{display: "grid"}}>
                                        <Typography
                                            sx={{
                                                marginLeft: "17px",
                                                display: "-webkit-box",
                                                textOverflow: "ellipsis",
                                                textAlign: "start",
                                                overflow: "hidden",
                                                WebkitBoxOrient: "vertical",
                                                WebkitLineClamp: 2,
                                            }}
                                            variant="body2"
                                            color="textSecondary"
                                            component="div"
                                            gutterBottom
                                        >
                                            {props.entity.locationName}
                                        </Typography>
                                    </span>
                                </Tooltip>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={5}
                                justifyContent="flex-end"
                                alignItems={"baseline"}
                                flexWrap={"wrap-reverse"}
                                sx={{ marginLeft: "5px" }}
                            >
                                <Typography
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                        marginRight: "2px",
                                        justifyContent: "end",
                                    }}
                                    variant="h5"
                                    component="p"
                                    gutterBottom
                                    width={60}
                                >
                                    {props.entity.allDayRoomPrice}{" €/h "}
                                </Typography>
                                <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    title={tooltipTitle}
                                >
                                    <span>
                                        <Button
                                            disabled={!isEventLongerThanMinBookingTime}
                                            size="small"
                                            sx={{
                                                marginTop: "2px",
                                                color: "white",
                                                background: buttonBackgroundColor,
                                                borderRadius: "8px",
                                                "&:hover": {
                                                    backgroundColor: "#102542",
                                                },
                                                "&:disabled": {
                                                    background: "#ebebeb",
                                                },
                                            }}
                                            onClick={() => {
                                                props.handleRoomSelect(props.entity.id)
                                            }}
                                        >
                                            {t("Dashboard.MakeBooking.Book")}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent="inline-flex"
                            alignItems="center"
                            sx={{ flexWrap: "nowrap", marginTop: "15px" }}
                        >
                            <Tooltip title={t("Dashboard.MakeBooking.Info", { what: getEntityPropertyNames(props.entity.properties)})} followCursor>
                                <span style={{display:"contents"}}>
                                    <Info
                                    fontSize="small"
                                    sx={{
                                        marginLeft: "3px",
                                        marginRight: "3px",
                                        display: "block",
                                    }}
                                />
                                    {tenantConfig.features.roomDetailIcon ? (
                                        <Typography
                                            align="left"
                                            sx={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                marginRight: "12px",
                                            }}
                                            variant="body2"
                                        >
                                            {t("Dashboard.MakeBooking.Info", { what: getEntityPropertyNames(props.entity.properties)})}
                                        </Typography>
                                    ) : null} 
                                </span>
                            </Tooltip>
                            
                        </Grid>
                    </Grid>
                </Card>
            </ThemeProvider>    
		</Grid>
	);
};
export default withSnackbar(PublicRoomCard);
