import React from 'react';
import {getModerationEquipmentIcon} from "../../../helpers/IconSelecter";
import {makeStyles} from "@mui/styles";
import {Stack, Typography} from "@mui/material";
import StyledChip from "./StyledChip";


const useStyles = makeStyles(() => ({
    chipLabel: {
        "& > *": {
            color:'white !important',
            textAlign:'center',
        },
    },
}));


export default function ModerationEquipmentChip(props) {
    const classes = useStyles();
    const [price, setPrice] = React.useState();
    React.useEffect(() => {
        let itemPrice = 0
        props.priceObj.additionalOptions.items.moderationEquipments.items.forEach(moderationEquipment => { //finds the price of the moderation equipment
                if(moderationEquipment.name === props.moderationEquipment){
                    itemPrice=moderationEquipment.price
                }
            }
        )
        setPrice(Number(itemPrice).toFixed(2))
    }, [props.priceObj])
    const handleDelete = () => {
        let newModerationEquipment= props.moderationEquipmentArray.filter((moderationEquipment) => moderationEquipment !== props.moderationEquipment)
        let event = {
            target: {
                name:'moderationEquipment',
                value: newModerationEquipment,
            }
        }
        props.handleCateringChange(event)
    }
    const chipLabel = () => {
        return (
            <Stack direction={'row'} alignItems={'center'} gap={1} className={classes.chipLabel}>
                <Typography>{props.moderationEquipment}</Typography>
                <Typography>€{price}</Typography>
            </Stack>
        )
    }
    return (
        <StyledChip icon={getModerationEquipmentIcon(props.moderationEquipment, props.moderationEquipmentDataRaw)} label={chipLabel()} onDelete={handleDelete}  />
    );
}