import React from 'react';
import { withSnackbar } from 'notistack';
import moment from "moment";

import Grid from '@mui/material/Grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import { withTranslation } from 'react-i18next';
import { calendarLanguagesData } from '../../data/languages';
import {TextField} from "@mui/material";

const iconColor = "#003db0";

const calendarLanguages = calendarLanguagesData
class dateBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentDate: new Date()
        }
    }

    componentDidMount() {
        //this.setState({headerText});
        // this.fixTimesWithLimits() Removed for the prevent time validation check
    }

    fixTimesWithLimits = () => {
        // Checking Late
        const late = new Date(); late.setHours(18,0,0);  moment(late).format("HH:mm");
        if(moment(this.state.currentDate).format("HH:mm") > moment(late).format("HH:mm")) {
            /* TODO EventTimes Limits Fix with Tenant's Info */
            let fixedCurrentDate = new Date(this.state.currentDate.setHours(17,30,0));
            this.props.onDateChange(fixedCurrentDate);
            this.setState({currentDate:new Date(fixedCurrentDate)});
        }
        // Checking Early
        const early = new Date(); early.setHours(9,0,0);  moment(early).format("HH:mm");
        if(moment(this.state.currentDate).format("HH:mm") < moment(early).format("HH:mm")) {
            /* TODO EventTimes Limits Fix with Tenant's Info */
            let fixedCurrentDate = new Date(this.state.currentDate.setHours(9,0,0));
            this.props.onDateChange(fixedCurrentDate);
            this.setState({currentDate:new Date(fixedCurrentDate)});
        }
    }
    handleNextClick =() =>{
        let curentDate = this.state.currentDate;
        let dayAfter = new Date(curentDate);
        dayAfter.setDate(curentDate.getDate() + 1);
        dayAfter = new Date(dayAfter);
        this.setState({currentDate: dayAfter});
        this.props.onDateChange(dayAfter);
    }

    handlePrevClick =() =>{
        let curentDate = this.state.currentDate;
        let dayBefore = new Date(curentDate);
        dayBefore.setDate(curentDate.getDate() - 1 );
        dayBefore = new Date(dayBefore);

        this.setState({ currentDate: dayBefore});
        this.props.onDateChange(dayBefore);

    }
    handleDateChange = (date)  => {
        this.setState({currentDate:date})
        this.props.onDateChange(date);
    };
    render() {
        const { t } = this.props;
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        {/* <NavigateBeforeIcon onClick = {this.handlePrevClick} style={{fontSize: "36px"}}/> */}
                    </Grid>
                    <Grid item xs={8} style={{fontSize: "20px", textAlign:'center'}}>
                    <LocalizationProvider adapterLocale={calendarLanguages[t('Calendar.MiniCalendar.Language')]} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="date-picker-dialog"
                            inputFormat="dd/MM/yyyy"
                            value={this.state.currentDate}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            renderInput={(params) =>
                                <TextField
                                    variant="standard"
                                    {...params}
                                    sx={{ svg: { paddingLeft: 0, marginRight: "10px", color: iconColor } }}
                                />}
                        />
                    </LocalizationProvider>
                    {/* {moment(this.state.currentDate).format("DD/MM/YYYY")} */}
                    </Grid>
                    <Grid item xs={2}>
                        {/* <NavigateNextIcon onClick = {this.handleNextClick} style={{fontSize: "36px"}}/> */}
                    </Grid>
                </Grid>

            </div>

        );
    }
}

export default withTranslation()(withSnackbar(dateBar));
