import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import MakeBooking from './MakeBooking';
import BookingsList from './BookingsList';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import RoomSelect from '../Booking/RoomSelect';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    spacing:20,
    height: '100%',
    textAlign: 'center'
    
  }, 
  scrollingContainer: {
    display: 'inline-block',
    justifyContent: 'center',
  },
});
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
export default function BookingsTab(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <Paper className={classes.root}>
      <Tabs
        className={classes.scrollingContainer}
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        
        
      >
        <Tab label={t('Dashboard.BookingTab.Make Booking')} {...a11yProps(0)} >
            
        </Tab>
        <Tab label={t('Dashboard.BookingTab.All Bookings')} {...a11yProps(1)} >
       
        </Tab>
      </Tabs>
      <TabPanel value={value} index={0}>
          <RoomSelect
              locations={props.locations}
              setLocations={props.setLocations}
              selectedDate={props.selectedDate}
              setSelectedDate={props.setSelectedDate}
              selectedEndTime={props.selectedEndTime}
              setSelectedEndTime={props.setSelectedEndTime}
              selectedStartTime={props.selectedStartTime}
              setSelectedStartTime={props.setSelectedStartTime}
              isLoading={props.isLoading}
              fetchError={props.fetchError}
              tryAgain={props.tryAgain}
              handleRoomSelect={props.handleRoomSelect}
              selectedRoom={props.selectedRoom}
          />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BookingsList bookingsList = {props.bookingsList} userEventsList = {props.userEventsList} />
      </TabPanel>
    </Paper>
  );
}
