import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {PROPERTIES_DEFAULT_TABLE_LAYOUT} from '../../../helpers/IconSelecter';
import {Typography} from "@mui/material";
import DropDownInput from "./StyledInputBase";
import {KeyboardArrowDown} from "@mui/icons-material";

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
        maxWidth: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}));

const TableLayoutDropdown = props => {
    const classes = useStyles();
    const [selectedLayout, setSelectedLayout] = useState((props.value) ? props.value : PROPERTIES_DEFAULT_TABLE_LAYOUT);
    const [tableLayouts, setTableLayouts] = React.useState(props.layoutsData);
    const iconComponent = (props) => {
        return (
            <KeyboardArrowDown className={props.className} style={{marginRight: '4px'}}/>
        )};
    const oldDesign = () => {
        return <FormControl className={classes.formControl}>
            <Select
                input={<DropDownInput/>}
                name={props.name}
                id="entity_tableLayouts"
                IconComponent={iconComponent}
                value={selectedLayout}
                onChange={handleTableLayoutsChange}
                style={{ width: 'fit-content'}} /* added to fix icon size.  */
                renderValue={(selected) => (
                     <Typography sx={{fontWeight: '700'}}>
                        { selected.name ? selected.name : 'Select One'}
                    </Typography>
                )}
                disabled={props.disabled}
            >
                {tableLayouts.map((tableLayout,index)=>
                    (
                        <MenuItem key={`${tableLayout.id}_${index}`} value={tableLayout}>
                            {tableLayout.name}
                        </MenuItem>
                    )
                )
                }
            </Select>
        </FormControl>
    }
    const handleTableLayoutsChange = (event) => {

        setSelectedLayout(event.target.value);
        if(props.onChange) {
            props.onChange(event);
        }

    };
    React.useEffect(() => {
        setSelectedLayout(props.value);
    },[props]);

    return oldDesign()
}

export default TableLayoutDropdown;