import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import sampleEvents from '../../data/sample-events'
import {  Box, Card, Grid, TextField } from '@mui/material';
import { withSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import moment from "moment";
import { withTranslation } from 'react-i18next';
import hex2rgba from '../../helpers/colorFunctions';
import FakeCalendar from './FakeCalendar';
import { mapSessionStateToProps} from '../../helpers/dataConverter';
import 'moment/locale/de'
import 'moment/locale/tr'
import EventTooltip from '../../helpers/EventTooltip';
import Tooltip from '@mui/material/Tooltip';
import {connect} from "react-redux";
import {EVENT_STATUS, ROLE} from "../../utils/Constants";

class RCalendar extends React.Component {
    constructor(props){
        super(props);
    }
    state =
        {
            calendarHeight: window.innerHeight - 220,
            data: [],
            slotData: false,
            newEventTitle: null,
            events: process.env.REACT_APP_LOAD_CALENDAR_WITH_DUMMY_DATA === "true" ? sampleEvents : [],
            newEventId: new Date(),
            entityID: null,
            entityName:null,
            view:"week",
            method:'',
            errorMessage:'',
            addNewEntityButton:false,
            t:this.props.t,
            calendarFilter:{
                locations:[],
                entities:{},
                roomIds: [],
                locationIds: []
            },
            personId: {},
            defaultTimeFormats: {
                /* eventTimeRangeFormat: range =>
                  `${moment(range.start).format('HH:mm')} – ${moment(range.end).format('HH:mm')}`, */
                eventTimeRangeFormat:' ',
                timeGutterFormat:'HH:mm',
                agendaTimeRangeFormat:range =>
                `${moment(range.start).format('HH:mm')} – ${moment(range.end).format('HH:mm')}`
                
            },
            room:{},
            isReady:false,
            lang:this.props.i18n.language.split('-')[0],
            localizer:momentLocalizer(moment),
            initialTime:moment().set('hour', 8).toDate(),
            openSearchBar:false,
            searchValue:"",
            filterEvents:false,
            selectable:true
        }
    componentDidMount() {
        moment.updateLocale(this.state.lang, {
            week: {
                dow: 1,
                doy: 1,
            },
        })
        this.setState({localizer:momentLocalizer(moment)})

        window.addEventListener('resize', this.updateCalendarSize);
        this.updateCalendarSize();
        this.setState({
            selectedBook:
            {
                data: null,
                open: false,
            },
            calendarFilter:this.props.calendarFilter,
            events: this.props.events
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let lang = this.props.i18n.language.split('-')[0];
        if(lang !== prevState.lang) {
            this.setState({lang:lang})
            moment.updateLocale(lang, {
                week: {
                    dow: 1,
                    doy: 1,
                },
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateCalendarSize);
    }

    updateCalendarSize = () => {
        this.setState({ calendarHeight: window.innerHeight - 220 });
    }

    handleShowInfoModal = (obj) => {
        const { t } = this.props;
        let roleOfOccupant = this.props.sessionData.role
        if(obj.start< new Date() && roleOfOccupant === "user"){
            this.props.enqueueSnackbar(t('errorProperties.RCALENDAR.HANDLE_SHOW_INFO_MODAL.CREATED_PAST_DATE_EVENT'), {
                variant: 'error'
            });
        }
        if(obj.isBuffer === true){
            return
        }
        let bookingHolderId = obj.eventObj.ownerAccountId
        let tempParticipants = obj.eventObj.participants
        let userId = this.props.sessionData.id


        this.setState({personId : bookingHolderId})

        if(obj.eventObj.state === "cancelled" && roleOfOccupant === "user"){
           return
        }
        if (bookingHolderId !== userId && roleOfOccupant === "user" && obj.eventObj.state === "cancelled"){
            this.props.enqueueSnackbar(t('errorProperties.RCALENDAR.HANDLE_SHOW_INFO_MODAL.NOT_INVOLVED'), {
                variant: 'error'
            });
            return
        } else {
            this.setState({
                data: obj
            })
        }
    }

    showEventModal = (data) => {
        const { t } = this.props;

        // Create new event
        if(data.slots && this.state.selectable) {
            
            if(data.slots.length < 2){
                this.setState({view:"week"})
                this.props.setSelectedDate(new Date(data.slots[0]))
            }else{
                const early = new Date();
                early.setHours(8,0,0);  moment(early).format("HH:mm")
                const late = new Date();
                late.setHours(18,0,0);  moment(late).format("HH:mm")
                let startDate = new Date(data.slots[0]);
                let endDate = new Date(data.slots[data.slots.length - 1]);
                if(startDate< new Date()){ // Check past time
                    this.props.enqueueSnackbar(t('errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.PASTE_DATE_EVENT'), {
                        variant: 'error'
                    });
                    return;
                }else if((moment(startDate).format("HH:mm") < moment(early).format("HH:mm")) || (moment(late).format("HH:mm") < moment(endDate).format("HH:mm"))){
                    this.props.setDashboardError(t('errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.START_DATE_EARLY'))
                }else if(moment(late).format("HH:mm") <= moment(startDate).format("HH:mm")){
                    this.props.setDashboardError(t('errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.START_DATE_LATE'))
                    return;
                }
                
                this.props.setSelectedDate(startDate);
                this.props.setSelectedStartTime(startDate);
                this.props.setSelectedEndTime(endDate); 
            }
            this.props.setCurrentStep('room-select')
        }
        // Update event
        else {
            if(this.state.selectable){
                this.props.setSelectedEvent(data);
            }
        }
    }

    handleEventModalClose = () => {
        if(this.state.method === 'create') {
            this.state.events.pop();
        }
        this.setState({ showEventModal: false, data:[] })
    }

    handleDefaultView = (event) =>{
        this.setState({view:event})   
    }

    showSearchBar = () =>{
        // todo convert to component
        const { t } = this.props;
        return(
            <Grid container item xs={6}style={{marginBottom : "15px"}} alignItems='center'>
                <Grid item xs={8} >
                    <TextField
                        margin="dense"
                        id="searchBar"
                        label={t('Entities.RCalendar.SearchBar')}
                        type="text"
                        variant="outlined"
                        name="searchBar"
                        value={this.state.searchValue}
                        onChange={this.handleChangeSearchValue}
                        size='medium'
                        fullWidth
                    />
                </Grid>
                <Grid item container xs={4} >
                    <Button
                        onClick={this.handleSearchButton}
                        color="primary"
                        variant="contained"
                        style={{marginLeft: "5px"}}
                    >
                        {t('Entities.RCalendar.SearchButton')}
                    </Button>
                    <Button
                        onClick={this.handleClearSearchButton}
                        color="primary"
                        style={{marginLeft: "5px"}}
                    >
                        {t('Entities.RCalendar.SearchClearButton')}
                    </Button>
                </Grid>
            </Grid>
        )
    }

    handleChangeSearchValue = (event) =>{
        this.setState({searchValue : event.target.value})
    }

    handleSearchButton = () =>{
        this.setState({filterEvents:true})
    }

    handleClearSearchButton = () =>{
        this.setState({searchValue : "",filterEvents:true})
    }

    Event = ({event}) => {
        const { t } = this.props;
        return(
            <span>
                {t('HELPERS.EVENT_TOOL_TIP.Event title' )} : {event.title}

                <div>
                    {event.location && event.location.name &&
                        <p>{t('HELPERS.EVENT_TOOL_TIP.Location' )} : {event.location.name}</p>
                    }
                    { event.customerName && <p>{t('HELPERS.EVENT_TOOL_TIP.Customer' )} : {event.customerName}</p>}
                    {event.totalprice && <p>{t('HELPERS.EVENT_TOOL_TIP.TotalPrice' )} : {event.totalprice} €</p>}
                </div>

            </span>
        )
    }

    EventDefault = ({event}) => {
        if(!event.isBuffer) {
            return(
                <Tooltip placement="bottom" title={<EventTooltip viewType={this.state.view} event={event}/>}>
                    <span>{event.title}</span>
                </Tooltip>
            )
        } else {
            return(<span>{event.title}</span>)
        }
    }

    EventDay = ({event}) => {
        const marginLeft="32px";
        if(!event.isBuffer) {
            return(
                <Tooltip placement="bottom" title={<EventTooltip viewType={this.state.view} event={event}/>}>
                <span>
                    {event.title}
                    {(event) &&
                    <span style={{marginLeft:marginLeft}}>
                        {event.room && event.location && event.location.name &&
                            <span style={{marginRight:marginLeft}}>{event.location.name}</span>
                        }
                        {event.customerName && <span style={{marginRight:marginLeft}}>{event.customerName}</span>}
                    </span>
                    }
                </span>
                </Tooltip>
            )
        } else {
            return(<span>{event.title}</span>);
        }
    }

    returnMessages = () => {
        const { t } = this.props;
        const messages={
            next:       t('Calendar.RCalendar.Next'),
            previous:   t('Calendar.RCalendar.Back'),
            today:      t('Calendar.RCalendar.Today'),
            month:      t('Calendar.RCalendar.Month'),
            week:       t('Calendar.RCalendar.Week'),
            day:        t('Calendar.RCalendar.Day'),
            agenda:     t('Calendar.RCalendar.Agenda'),
            noEventsInRange: t('Calendar.RCalendar.No Events')
        }
        return messages;
    }

    render() {
        const { t } = this.props;

        if (!this.state.addNewEntityButton && this.props.hasEntity === false) {
            return  <div style={{ margin: "10px 0px" }}>
                        { (this.state.errorMessage != null && this.state.errorMessage.length > 0) ? t(this.state.errorMessage):'' }
                        <Box>
                            <FakeCalendar buttonLabel={t("Dashboard.FakeCalendar.Add Room")} navigateUrl={"/entities/room/new"} role={this.props.sessionData.role}></FakeCalendar>
                        </Box>
                    </div>
        }
        else {
            return (
                <div style={{ maxHeight: "100%" }}>
                     <div>
                     {
                         this.state.entityName ?
                             <div style={{ marginBottom: "15px" }}>
                                 <h4  style={{fontSize:20 ,padding:0, marginTop:6, marginBottom:6, textAlign:'center'}}>{this.state.entityName}</h4>
                             </div> : null
                     }
                         <Card >
                             {this.props.tenantConfig.features.useEventSearchBar ? this.showSearchBar(): null}
                             <Calendar popup={true} localizer={this.state.localizer}
                                       longPressThreshold={100}
                                       formats={this.state.defaultTimeFormats}
                                       events={this.state.events.filter
                                       (event =>
                                           !event.isBuffer &&
                                           event.title.toLowerCase().includes(this.state.searchValue.toLowerCase()) &&
                                           (this.props.calendarFilter.roomIds.length > 0 ? this.props.calendarFilter.roomIds.includes(event.room.id) : true)
                                       )}
                                       tooltipAccessor={event => {return null}}
                                       startAccessor="start"
                                       endAccessor="end"
                                       date={this.props.currentDate}
                                       onNavigate={this.props.changeDate}
                                       style={{ height: this.state.calendarHeight }}
                                       onSelectEvent={this.showEventModal}
                                       selectable={this.state.selectable}
                                       onSelectSlot={this.showEventModal}
                                       onView = {this.handleDefaultView}
                                       view = {this.state.view}
                                       components={{
                                           day: {
                                               event: this.EventDay,
                                           },
                                           week: {
                                               event: this.EventDefault,
                                           },
                                           month: {
                                               event: this.EventDefault,
                                           },
                                           agenda: {
                                               event: this.Event,
                                           },
                                       }}
                                       scrollToTime={moment().subtract(1, 'hour').startOf('hour').minutes(0)
                                           .toDate()}
                                       
                                       messages={this.returnMessages()}
                                       eventPropGetter={
                                           (event, start, end, isSelected) => {
                                               let newStyle = {
                                                   backgroundColor: "#ffff",
                                                   color: 'black',
                                                   borderRadius: "8px",
                                                   border: "none",
                                                   opacity: '1'
                                               };
                                               if(event) {
                                                   if (event.room && event.room.color) {
                                                       newStyle.border = `1px solid ${event.room.color}`
                                                       newStyle.backgroundColor = hex2rgba(event.room.color, 0.4);
                                                   }
                                                   if(event.state && event.state === "cancelled"){
                                                       newStyle.border = `1px solid #C8C8C8`;
                                                       newStyle.backgroundColor=hex2rgba('#C8C8C8',0.4)
                                                   }
                                               }else{
                                                   if(event.isBuffer === true) {
                                                       newStyle.border = `1px solid ${event.color}`
                                                       newStyle.backgroundColor = hex2rgba(event.color,1);
                                                       newStyle.color='#fff'
                                                   }

                                               }
                                               if(event.status){
                                                   if(event.status === EVENT_STATUS.RESERVED){
                                                       newStyle.opacity = '0.4'
                                                   }
                                                   else if(event.status === EVENT_STATUS.CANCELED){
                                                       newStyle.backgroundColor = 'grey'
                                                   }

                                               }
                                               return {
                                                   className: "",
                                                   style: newStyle
                                               };
                                           }
                                       }
                             />
                         </Card>
                    </div>
                </div>
            )
        }

    }
}

export default withTranslation()(connect(mapSessionStateToProps)(withSnackbar(RCalendar)));
