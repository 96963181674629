import React, { useEffect, useState } from 'react';
import { Grid, Typography } from "@mui/material"
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const DateTimeBar = (props) => {
    const { i18n  } = useTranslation();
    const [lang, setLang] = useState(i18n.language.split('-')[0]);
    moment.updateLocale(lang)
    const [now, setNow] = useState(moment().format('dddd HH:mm:ss, DD.MM.YYYY'));
    useEffect(() => {
        setInterval(function () 
        { setNow(moment().format('dddd HH:mm:ss, DD.MM.YYYY'))}, 1000);
        return () => {
            clearInterval();
        }
    }, []);

    useEffect(() => {
        moment.updateLocale(lang)
    }, [lang]);

    return (
    <Grid 
    container
    justifyContent='center'
    alignItems='center'
    className="datetime-bar">
        <Grid item xs={12}>
            <Typography className="datetime-bar-text" component="p" variant="string" align="center">
                {now}
            </Typography>
        </Grid>
    </Grid>)
}
export default DateTimeBar;
