import {initializeApp} from "firebase/app";
import {
    getAuth,
    createUserWithEmailAndPassword,
    deleteUser,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    updatePassword
} from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth();
const user = auth.currentUser;

export const register =  (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
}

export const deleteCurrentUser = (user)=> {
    return deleteUser(user);
};

export const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
}

export const refresh = () => {
    return new Promise((resolve, reject) => {
        if (user){
            resolve(user.getIdToken(true));
        }else {
            reject('logged out');
        }
    });
}

export const forgetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
}


export const logout = () => {
    return signOut(auth);
}

export const  changePassword = (newPassword) => {
    return updatePassword(auth.currentUser, newPassword);
}

export default firebaseApp;