
export default class CalendarEvent{
    constructor(eventId,title,ownerId,ownerName,startDate,endDate,isAllDay,color,status,location,room){
        this.eventId    = eventId;
        this.title      = title;
        this.startDate  = startDate;
        this.endDate    = endDate;
        this.isAllDay   = isAllDay?true:false;
        this.color      = color;
        this.ownerId    = ownerId;
        this.ownerName  = ownerName;
        this.status     = status;
        this.location   = location;
        this.room       = room;
    }

    toUIJSON(loggedInUserId){
        let owner_id = loggedInUserId;
        return {
            id          : this.eventId,
            title       : this.title,
            start       : this.startDate,
            end         : this.endDate,
            allDay      : this.allDay,
            color       : this.color,
            editable    : this.ownerId === owner_id && this.startDate > new Date(),
            status      : this.status,
            ownerName   : this.ownerName,
            location    : this.location,
            room        : this.room,
            eventObj    : this.eventObj,
            isBuffer    : false,
            resourceId  : this.room
        }
    }

    toAPIJSON(){
        throw Error('Not implmeented yet'); //TODO: implement this
    }

    
}
