import React from "react";
import "../styles/loaders.scss"

export default function LoadingPage() {
    return (
        <div className="loading-page-container">
            <div className="image-container">
                <img className="company-image" src='/iotiq.ico' alt="company-logo"/>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <div className="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    );
}
