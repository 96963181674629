export default class Users {
  constructor(userId, username, email, phoneNo, role, status, date, profilePic,token, deletedBy,deletedTime,reasonForDelete) {
    this.userId         = userId;
    this.username       = username;
    this.email          = email;
    this.role           = role;
    this.phoneNo        = phoneNo;
    this.status         = status;
    this.date           = date;
    this.token          = token;
    this.deletedBy      = deletedBy;
    this.deletedTime    = deletedTime;
    this.reasonForDelete= reasonForDelete;
    this.profilePic     = profilePic;
  }

  toUIJSON() {
    return {
      userId          : this.userId,
      username        : this.username,
      email           : this.email,
      role            : this.role,
      phoneNo         : this.phoneNo,
      status          : this.status,
      date            : this.date,
      token           : this.token,
      deletedBy       : this.deletedBy,
      deletedTime     : this.deletedTime,
      reasonForDelete : this.reasonForDelete,
      profilePic      : this.profilePic
    };
  }

  toAPIJSON() {
    throw Error("Not implmeented yet"); //TODO: implement this
  }
}
