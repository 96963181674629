import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {Box, Button, Grid, Paper} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutConfirmDialog from "../Menu/LogoutConfirmDialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import React from "react"
import LanguageDropdown from "../Menu/LanguageDropdown";
import {hasFeature} from "../../guards/RoleFeatures";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectSessionData} from "../../store/features/sessionSlice";
import {selectSidebarOpen, sidebarClosed, sidebarOpened} from "../../store/features/navigationSlice";

export default function TopBar(props) {
    const navigate = useNavigate();

    const sessionData = useAppSelector(selectSessionData)
    const isSidebarOpen = useAppSelector(selectSidebarOpen)
    const dispatch = useAppDispatch();

    const [openLogout, setOpenLogout] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const { t } = useTranslation();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenLogout = () => {
        setOpenLogout(true);
        handleClose();
    };

    const handleCloseLogout = () => {
        setOpenLogout(false);
    };

    const handleOpenProfile = () => {
        navigate("/profile")
        handleClose();
    };

    const toggleSidebar = () => {
        if(isSidebarOpen){
            dispatch(sidebarClosed());
        }else{
            dispatch(sidebarOpened())
        }
    }

    const profileMenu = () => {

        return <Menu
            anchorEl={anchorEl}
            id="menu-appbar"
            open={Boolean(anchorEl!)}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <Paper sx={{display: {xs: 'block', md: 'none'}}}>
                <Typography align='center' style={{display: 'block', fontWeight: 'bold'}}>
                    {sessionData.name}
                    test
                </Typography>
            </Paper>

            {
                hasFeature('view-profile') &&
                <MenuItem onClick={handleOpenProfile}>
                    <ListItemIcon>
                        <AccountCircleOutlinedIcon fontSize="large" style={{marginRight:"6px"}}/>
                    </ListItemIcon>
                    {t('Right Menu.Profile')}
                </MenuItem>
            }

            {
                hasFeature('view-my-bookings') &&
                <MenuItem onClick={() => navigate("/myBookings")}>
                    <ListItemIcon>
                        <EventAvailableIcon fontSize="large" style={{marginRight:"6px"}}/>
                    </ListItemIcon>
                    {t('Right Menu.MyBookings')}
                </MenuItem>
            }


            {
                hasFeature('change-language') &&
                <Paper sx={{display: {xs: 'block', lg: 'none'}}}>
                    <MenuItem >
                        <ListItemIcon >
                            <FormControl  >
                                <LanguageDropdown />
                            </FormControl>
                        </ListItemIcon>
                    </MenuItem>
                </Paper>
            }

            <MenuItem onClick={handleOpenLogout} key={'Logout'}>
                <ListItemIcon>
                    <PowerSettingsNewOutlinedIcon fontSize="large" style={{color:"red"}}/>
                </ListItemIcon>
                {t('Right Menu.Logout')}
            </MenuItem>
        </Menu>
    }

    const isLoggedIn = sessionData.auth_token;

    return (
        <AppBar
            position="sticky"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <Toolbar>
                <Grid container spacing={2}>
                    {
                        hasFeature('view-sidebar') && isLoggedIn ?
                        <Grid item xs={2} md={4} container alignItems="center">
                            <IconButton
                                sx={{transform: (isSidebarOpen ? "rotate(0)" : "rotate(90deg)"), transition: "all 0.2s linear"}}
                                color="inherit"
                                onClick={toggleSidebar}
                                className="toggle-button"
                                edge="start"
                                size="large">
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                            :
                            <Grid item xs={2} md={4} container alignItems="center">
                                {window.location.hostname.toUpperCase()}
                            </Grid>
                    }

                    {
                        hasFeature('view-dashboard') &&
                        <Grid item container xs={8} md={4} justifyContent="center" alignItems="center">
                            <div>
                                <Typography component={'div'} variant="h6" align="center" style={{maxWidth: '100%'}}>
                                    <span style={{cursor : "pointer" }} onClick={() => { navigate("/dashboard/") }}>
                                      <Box component="div" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                                        {sessionData.tenantName}
                                      </Box>
                                    </span>
                                </Typography>
                            </div>
                        </Grid>
                    }

                    <Grid item container xs={2} md={4} justifyContent="flex-end" alignItems="center">
                        {
                            hasFeature('change-language') &&
                            <Box sx={{display: {xs: 'none', lg: 'block'}, backgroundColor: 'primary.main'}}>
                                <FormControl>
                                    <LanguageDropdown />
                                </FormControl>
                            </Box>
                        }

                        <Box sx={{display: {xs: 'none', md: 'block'}, backgroundColor: 'primary.main'}}>
                            <Typography style={{display: 'inline-block',maxWidth: '150px'}}>
                                {sessionData.name}
                            </Typography>
                        </Box>
                        {
                            isLoggedIn ?
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                    size="large">
                                    <AccountCircle />
                                </IconButton>:
                                <Button variant='contained' color='secondary' onClick = {() => props.setLoginFormOpen(true)} style={{textTransform:'none',maxWidth:'100%'}}>
                                    <Typography variant='subtitle2'>Sign In </Typography>
                                </Button>
                        }

                        {profileMenu()}
                        <LogoutConfirmDialog  open = {openLogout} onClose={handleCloseLogout}/>

                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}
