import React from 'react';
import './App.scss';
import './helpers/DateFormatter';
import SignInSide from './components/Login/Login';
import ForgotPassword from './components/Login/ForgotPassword';
import ChangePassword from './components/Login/ChangePassword';
import DashboardSelect from './components/Dashboard/DashboardSelect';
import Entities from './components/Entity/Entities';
import Invitation from './components/Invitation/Invitation';
import EntitiesEditTable from './components/Entity/EntitiesEditTable';
import CategoriesEditTable from './components/Category/CategoriesEditTable';
import UsersEditTable from './components/Users/UsersEditTable';
import DevicesEditTable from './components/Device/DevicesEditTable';
import EntitiesCrudDataHandler from './components/Entity/EntitiesCrudDataHandler';
import DeviceCrudDataHandler from './components/Device/DeviceCrudDataHandler';
import ErrorPage from './components/ErrorPage';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  Routes,
  Route
} from "react-router-dom";
import UserCrudDataHandler from './components/Users/UserCrudDataHandler';
import Profile from './components/Menu/Profile/Profile';
import LocationsEditTable from './components/Locations/LocationsEditTable';
import mobileCheck from './helpers/isMobile';
import UserEntities from './components/Entity/UserEntities';
import UserDashboard  from './components/Dashboard/UserDashboard';
import Lobby from './components/Lobby/Lobby';
import CustomizeEditTable from './components/Customize/CustomizeEditTable'
import PropertyCrudDataHandler from './components/Customize/CustomizeCrudDataHandler';
import {PayPalScriptProvider} from '@paypal/react-paypal-js'
import PaymentsTable from './components/Payments/PaymentsTable';
import PaymentsPaidTable from './components/Payments/PaymentsPaidTable';
import PaymentPayTable from './components/Payments/PaymentPayEvents'
import InvoiceSettingsCrudDataHandler from './components/InvoiceSettings/InvoiceSettingsCrudDataHandler';
import PublicDashboard from './components/PublicComponents/PublicDashboard/PublicDashboard';
import CustomRouter from "./CustomRouter";
import history from './history'
import EventCreation from "./components/PublicComponents/CreateEvent/EventCreation";
import Checkout from "./components/PublicComponents/CreateEvent/Checkout";
import UserBookingsPage from "./pages/UserBookingsPage";
import ConditionalWrapper from "./components/PublicComponents/helpers/ConditionalWrapper";
import LandingPage from "./pages/LandingPage";
import NavigationLayout from "./components/Main/NavigationLayout";
import ProtectedRoute from "./components/Main/ProtectedRoute";
import {useAppDispatch, useAppSelector} from './store/hooks'
import {restartSession, setIsLoading, setIsMobile, setSession} from './store/features/sessionSlice'
import {HTTP_REQUESTS} from "./backendcomm/services/httpRequestService";
import {sessionStateFrom, tenantConfigFrom} from "./helpers/dataConverter";
import {selectTenantConfig, setTenantConfig} from "./store/features/tenantConfigSlice";
import Dashboard from "./components/Dashboard/Dashboard";
library.add(fab)

const App = () => {
  const dispatch = useAppDispatch();

  const tenantConfig = useAppSelector(selectTenantConfig);
  const [tenantConfigLoaded, setTenantConfigLoaded] = React.useState(false);
  const [isLoadingState, setIsLoadingState] = React.useState(true);

  React.useEffect(() => {

    // Get tenant config
    HTTP_REQUESTS.TENANT_SERVICE.GET_TENANT_CONFIG(data => {
      dispatch(setTenantConfig(tenantConfigFrom(data)));
      setTenantConfigLoaded(true);
      setIsLoadingState(false);
    }, err => {
      setIsLoadingState(false);
      setTenantConfigLoaded(false);
    })

    dispatch(setIsMobile(mobileCheck()));

    const accessToken = localStorage.getItem('accessToken');

    if(accessToken){
      callMe();
    }
    else{
      dispatch(setIsLoading(false));
    }

  }, []);

  const callMe = () => {
    HTTP_REQUESTS.USER_SERVICE.AUTH_CALL(
        data => {
          dispatch(setSession(sessionStateFrom(data)));
        },
        err => {
          console.log("Error: ", err);
          let language = localStorage.getItem('language') ?? '';
          localStorage.clear();
          localStorage.setItem('language' , language);
          dispatch(restartSession())
        }
    )
  }

  return (
      <ConditionalWrapper
          condition={tenantConfig.features.payment}
          wrapper={children => <PayPalScriptProvider options={{
            // Update client id from env when development finished. Currently, we are using a sandbox client id.
            "client-id":process.env.REACT_APP_PAYPAL_CLIENT_ID ?? '',
            "currency":"EUR" // currency should be same with order currency.
            //for other options check https://developer.paypal.com/sdk/js/configuration/
            //for payment fields https://developer.paypal.com/docs/checkout/apm/reference/js-sdk-params-payment-fields/#style
          }}>{children}</PayPalScriptProvider> } >
        <CustomRouter history={history} >
          <div className="App">
            <Routes>
              {
                tenantConfigLoaded &&
                  <>
                    <Route path="/forgotPassword" element={<ForgotPassword />}/>
                    <Route path="/changePassword" element={<ChangePassword />}/>
                    <Route path="/login" element={<SignInSide />}/>
                    <Route path="/invite" element={<Invitation />}/>

                    <Route path="/landing" element={<LandingPage />}/>
                    <Route element={<NavigationLayout/>}>
                      <Route path={"/public-booking/*"} element={ <PublicDashboard type="public"/> }/>
                      <Route path={"/summary/:eventId"} element={<ProtectedRoute feature="view-summary" component={<Checkout />}/>}/>
                      <Route path={"/dashboard/*"} element={<ProtectedRoute feature="view-dashboard" component={<Dashboard />}/>}/>
                      <Route path={"/"} element={<ProtectedRoute feature="view-dashboard" component={<Dashboard />}/>}/>
                      <Route path={"/calendar/*"} element={<ProtectedRoute feature="view-dashboard" component={<Entities />}/>}/>
                      <Route path={"/entities/*"} element={<ProtectedRoute feature="view-room-table" component={<EntitiesEditTable />}/>}/>
                      <Route path={"/rooms/*"}  element={<ProtectedRoute feature="view-room-table" component={<UserEntities />}/>}/>
                      <Route path={"/locations/*"} element={<ProtectedRoute feature="view-location-table" component={<LocationsEditTable />}/>}/>
                      <Route path={"/users/*"} element={<ProtectedRoute feature="view-user-table" component={<UsersEditTable />} />}/>
                      <Route path={"/profile/*"} element={<ProtectedRoute feature="view-profile" component={<Profile />} />}/>
                      <Route path={"/myBookings/*"} element={<ProtectedRoute feature="view-my-bookings" component={<UserBookingsPage/>}/>}/>
                      <Route path={"/quick-booking/*"} element={<ProtectedRoute feature="view-quick-booking" component={<PublicDashboard type="user" />}/>}/>
                      <Route path={"/entities/room/:calendarId"} element={<ProtectedRoute feature="view-room-details" component={<EntitiesCrudDataHandler />}/>}/>
                      <Route path={"/categories/*"} element={<ProtectedRoute feature="view-categories" component={<CategoriesEditTable />}/>}/>
                      <Route path={"/users/user/:userId"} element={<ProtectedRoute feature="view-user-details" component={<UserCrudDataHandler />}/>}/>
                      <Route path={"/devices/:id"} element={<ProtectedRoute feature="view-device-details" component={<DeviceCrudDataHandler/>}/>}/>
                      <Route path={"/devices"} element={<ProtectedRoute feature="view-device-table" component={<DevicesEditTable/>}/>}/>
                      <Route path={"/lobby/*"} element={<ProtectedRoute feature="view-lobby" component={<Lobby />}/>}/>
                      <Route path={"/customize/property/:propertyId"} element={<ProtectedRoute feature="view-customize-details" component={<PropertyCrudDataHandler />}/>}/> : null
                      <Route path={"/customize/*"} element={<ProtectedRoute feature="view-customize-table" component={<CustomizeEditTable />}/>}/>
                      <Route path={"/payments/pay/*"} element={<ProtectedRoute feature="view-payment-table" component={<PaymentPayTable />}/>}/>
                      <Route path={"/payments/paid/*"} element={<ProtectedRoute feature="view-payment" component={<PaymentsPaidTable />}/>}/>
                      <Route path={"/payments/*"} element={<ProtectedRoute feature="view-payment" component={<PaymentsTable />}/>}/>
                      <Route path={"/invoice-settings/*"} element={<ProtectedRoute feature="view-invoice-settings" component={<InvoiceSettingsCrudDataHandler />}/>}/>
                    </Route>
                  </>
              }

              <Route path={"/*"} element={<ErrorPage isLoading={isLoadingState}/>} />
            </Routes>
          </div>
        </CustomRouter>
      </ConditionalWrapper>
  );
}

export default App;
