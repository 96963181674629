const DATA_DURATION = parseInt(process.env.REACT_APP_LOBBY_DATA_DURATION)

const SCREEN_DURATION = parseInt(process.env.REACT_APP_LOBBY_SCREEN_DURATION)

const PAYMENT_STATUS = {
    PAID:"PAID",
    NOT_PAID:"NOT_PAID"
}

const ROLE = {
    ADMIN:"admin",
    USER:"user",
    PUBLIC_USER:"public_user"
}

const EVENT_STATUS = {
    RESERVED: "reserved",
    CANCELED: "canceled"
}

const ACCOUNT_STATUS = {
    ACTIVE: "active",
    INVITED: "invited" 
}

module.exports = { DATA_DURATION,SCREEN_DURATION,PAYMENT_STATUS,ROLE,EVENT_STATUS,ACCOUNT_STATUS}
