import { createTheme } from '@mui/material/styles';
import {store} from "./store/store";

let font = "'Titillium Web', sans-serif" //default
if(store.getState().tenantConfig.companyName === "freiraum"){
    font = "'stolzl', 'Poppins', sans-serif";
}

const theme = createTheme({

    palette: {
        primary: {
            main: '#102542'
        },
        secondary: {
            main: '#FFFFFF'
        },
        danger: {
            backgroundColor: '#A70C00',
            color: '#ffffff',
          },
        disabled: {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.26)',
        }
    },
    typography: {
        fontFamily: font,
    },
    overrides:{
        MuiCssBaseline:{
            '@global': {
                backgroundColor: '#fafafa'
            }
        },
        // MuiTypography:{
        //     h6: {
        //         position: 'relative',
        //         width:'fit-content',
        //         paddingRight: '5px',
        //        ' &::after':{
        //             position: 'absolute', 
        //             left: '2px',
        //             bottom: '7px', 
        //             width: '100%', 
        //             content: " '' ", 
        //             background: '#3f51b5',
        //             height: '5px' ,
        //             opacity: '0.4'
        //         }
        //     }    
        // },
        MuiCard:{
            root: {
                padding: '16px'
            }
        },
        MuiCheckbox: {
            colorSecondary: {
              color: '#102542F',
              '&$checked': {
                color: '#102542',
              },
            },
        },
        MuiRadio: {
            colorSecondary: {
              color: '#102542F',
              '&$checked': {
                color: '#102542',
              },
            },
        }


    }
})
theme.overrides.MuiCssBaseline['@global'] = {
    body: {
        backgroundColor: theme.palette.secondary.main
    }
}
// theme.overrides.MuiPaper = {
//     root: {
//         backgroundColor: theme.palette.secondary.main
//     }
// }
// theme.overrides.MuiPaper = {
//     root: {
//         backgroundColor: theme.palette.secondary.main
//     }
// }

export default theme;
