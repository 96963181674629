import DebouncedInput from "./DebouncedInput";
import React from 'react';
import './CustomTable.scss'
import {t} from "i18next";
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import {convertUuidToDisplay, isUuid} from "../../../utils/Converters";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {calendarLanguagesData} from "../../../data/languages";
import {Box} from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
const calendarLanguages = calendarLanguagesData;

function Filter(props) {
    const firstValue = props.table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(props.column.id)

    const [columnFilterValue, setColumnFilterValue] = React.useState(null);
    const [isCalendarOpen, setCalendarOpen] = React.useState(false);

    const setColumnValue = (newVal) => {
        props.column.setFilterValue(newVal);
    }

    const sortedUniqueValues = React.useMemo(
        () =>
            typeof firstValue === 'number'
                ? []
                : Array.from(props.column.getFacetedUniqueValues().keys()).reduce((reduced, val) => {
                    // Convert uuid strings
                    if(isUuid(val)){
                        reduced.push(convertUuidToDisplay(val));
                    }
                    // Filter untruthful texts and texts that only contains empty spaces
                    else if(val && val.replace(/\s/g, '').length){
                        reduced.push(val);
                    }

                    return reduced;
                }, []) .sort(),
        [props.column.getFacetedUniqueValues()]
    )

    return typeof firstValue === 'number' ? (
        <div>
            <div className="filter-number-container">
                <DebouncedInput
                    type="number"
                    min={Number(props.column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(props.column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue)?.[0] ?? ''}
                    onChange={value =>
                        props.column.setFilterValue((old) => [value, old?.[1]])
                    }
                    placeholder={`Min ${
                        props.column.getFacetedMinMaxValues()?.[0]
                            ? `(${props.column.getFacetedMinMaxValues()?.[0]})`
                            : '(0)'
                    }`}
                />
                <DebouncedInput
                    type="number"
                    min={Number(props.column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(props.column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue)?.[1] ?? ''}
                    onChange={value =>
                        props.column.setFilterValue((old) => [old?.[0], value])
                    }
                    placeholder={`Max ${
                        props.column.getFacetedMinMaxValues()?.[1]
                            ? `(${props.column.getFacetedMinMaxValues()?.[1]})`
                            : ''
                    }`}
                    className="w-24 border shadow rounded"
                />
            </div>
            <div className="h-1" />
        </div>
    ) : (
        props.originalColumn.filterType && props.originalColumn.filterType === "date" ?
            <div>
                <LocalizationProvider adapterLocale={calendarLanguages[t('Calendar.MiniCalendar.Language')]} dateAdapter={AdapterDateFns}>
                    <DatePicker
                        inputFormat={"dd/MM/yyyy"}
                        open={isCalendarOpen}
                        onOpen={() => setCalendarOpen(true)}
                        onClose={() => setCalendarOpen(false)}
                        value={(columnFilterValue)}
                        onChange={value => {
                            setCalendarOpen(false);
                            props.column.setFilterValue(moment(value).format('YYYY-MM-DD'));
                            setColumnFilterValue(moment(value).format('YYYY-MM-DD'));
                        }}
                        renderInput={(props) =>
                            <TextField
                                onClick={() => setCalendarOpen(true)}
                                sx={{maxWidth: 130, minWidth: 130, marginTop: 2, input: {
                                        color: 'black',
                                        "&::placeholder": {
                                            opacity: 0.6
                                        }
                                    }}}
                                {...props}
                                variant="standard"
                                InputProps={{
                                    endAdornment: (
                                        columnFilterValue ?
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setCalendarOpen(false);
                                                    setColumnFilterValue(null);
                                                    setColumnValue(null)
                                                }}
                                                sx={{opacity: '0.6'}}
                                            >
                                                <ClearIcon/>
                                            </IconButton>
                                            :
                                            <CalendarTodayIcon sx={{opacity: '0.6'}}/>
                                    )
                                }}
                                inputProps={{
                                    ...props.inputProps,
                                    placeholder:`${t('Table.Search')} (${sortedUniqueValues.length})`,
                                }}
                            />}
                    />
                </LocalizationProvider>
            </div> :
            <>
                <datalist id={props.column.id + 'list'}>
                    {sortedUniqueValues.slice(0, 5000).map((value, index) => (
                        <option value={value} key={value + '_' + index} />
                    ))}
                </datalist>
                <DebouncedInput
                    type="text"
                    value={(columnFilterValue ?? '')}
                    onChange={value => props.column.setFilterValue(value)}
                    placeholder={`${t('Table.Search')} (${sortedUniqueValues.length})`}
                    list={props.column.id + 'list'}
                    options={sortedUniqueValues}
                />
                <div className="h-1" />
            </>

    )
}

export default Filter;
