import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import VariantAvatars from './CompanyAvatar';
import InputAdornment from '@mui/material/InputAdornment';
import {HTTP_REQUESTS} from '../../backendcomm/services/httpRequestService';
import Copyright from './Copyright';
import {withSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Divider} from "@mui/material";
import CustomSpinner from "../CustomSpinner";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {restartSession, setSession} from "../../store/features/sessionSlice";
import {sessionStateFrom} from "../../helpers/dataConverter";
import {selectTenantConfig} from "../../store/features/tenantConfigSlice";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
        paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: "100%",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#f50057',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(0),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    centered: {
        justifyContent: 'center',
        marginLeft: '0',
        marginRight:'0',
        display:'flex'
    },
    selector:{
        color:'white',
        fontSize:'medium',
        "& .MuiSvgIcon-root": { color: "black" }
    },
    box: {
        marginTop: "20px"
    }
}));

function SignIn(props) {
    const classes = useStyles();
    const navigate = useNavigate();

    const tenantConfig = useAppSelector(selectTenantConfig);

    const dispatch = useAppDispatch();

    const [uname, setUname] = React.useState(props.email || '');
    const [psw, setPsw] = React.useState('');
    const [unameError, setUnameError] = React.useState('');
    const [pswError, setPswError] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const usernameChanged = event => {
        setUname(event.target.value);
        setUnameError(null);
    }

    const passwordChanged = event => {
        setPsw(event.target.value);
        setPswError(null);
    }

    const keyPress = event => {
        if(event.key=="Enter")
        {
            signInClicked();
        }
    }
    

    const signInClicked = () => {
        let formValid = true;

        if (!uname || uname === "") {
            setUnameError(t('errorProperties.SIGN_IN.SIGN_IN_CLICKED.SET_U_NAME_ERROR'));
            formValid = false;
        }

        if (!psw || psw === "") {
            setPswError(t('errorProperties.SIGN_IN.SIGN_IN_CLICKED.SET_PSW_ERROR'));
            formValid = false;
        } else if (psw.length < 6) {
            setPswError(t('errorProperties.SIGN_IN.SIGN_IN_CLICKED.SET_PSW_ERROR_LENGTH'));
            formValid = false;
        }

        if (formValid) continueSignIpProcess();
    }

    const continueSignIpProcess = () => {
        setLoading(true);
        HTTP_REQUESTS.FIREBASE_SERVICE.LOGIN(uname, psw, (data) => {
            console.log("success", data.user);
            if (data?.user?.stsTokenManager?.accessToken) {
                localStorage.setItem('accessToken', data.user.stsTokenManager.accessToken);
                localStorage.setItem('refreshToken', data.user.stsTokenManager.refreshToken);

                HTTP_REQUESTS.USER_SERVICE.AUTH_CALL(
                    (data) => {
                        dispatch(setSession(sessionStateFrom(data)));
                        props.handelSigIn();
                    },
                    err => {
                        setLoading(false);
                        props.enqueueSnackbar(t('errorProperties.SIGN_IN.FIREBASE_SUCCESS_BUT_ME_UNSUCCESSFUL'))
                        console.log("Error: ", err);
                        let language = localStorage.getItem('language') ?? '';
                        localStorage.clear();
                        localStorage.setItem('language' , language);
                        dispatch(restartSession())
                    });

            }

        },(err) => {
            setLoading(false);
            props.enqueueSnackbar(t('errorProperties.SIGN_IN.CONTINUE_SIGN_IP_PROCCES'), {
                variant: 'error'
            });
            console.log("fail", err)
        })
    }

    function renderGridButtons() {
        return <Grid mt={1} container>
            <Grid item xs={6}>
                <Link style={{float: "left"}} className="cursor-ptr" onClick={props.onForgotPassword} variant="body2">
                    {t('SignIn.Forgot')}
                </Link>
            </Grid>
            {
                tenantConfig.features.register ? <Grid item xs={6}>
                <Link style={{ float: "right" }} className="cursor-ptr" onClick={props.onSignUpClicked} variant="body2">
                    {t('SignIn.Have Acco')}
                </Link>
            </Grid> : null
            }
        </Grid>;
    }

    const isLoginPage = () => {
        return window.location.pathname === '/login'
    }

    return (
        <>
                <div className={classes.paper} >
                    <VariantAvatars />
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('SignIn.Sign In')}
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t('SignIn.Email')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={usernameChanged}
                            value={uname}
                            helperText={(unameError != null && unameError.length > 0) ? t(unameError) : ''}
                            error={unameError != null && unameError.length > 0}
                            onKeyPress={keyPress}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t('SignIn.Password')}
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            onChange={passwordChanged}
                            helperText={(pswError != null && pswError.length > 0) ? t(pswError) : ''}
                            error={pswError != null && pswError.length > 0}
                            onKeyPress={keyPress}
                            
                            InputProps={{  endAdornment:(
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )}}
                        />
                        <Grid className={classes.centered}>
                            <FormControlLabel
                                className={classes.centered}
                                control={<Checkbox value="remember" color="primary" />}
                                label={t('SignIn.Remember')}
                            />
                        </Grid>

                        {
                            isLoading ?
                                <CustomSpinner/> :
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={signInClicked}
                                >
                                    {t('SignIn.Sign In')}
                                </Button>
                        }
                        

                        {renderGridButtons()}
                        {isLoginPage() &&
                            <Box sx={{mt:8}}>
                                <Divider>{t('SignIn.Or')}</Divider>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>{  window.location.href = '/public-booking'}}
                                    sx={{mt:2}}
                                >
                                    {t('SignIn.Guest')}
                                </Button>
                            </Box>
                        }
                        <Box mt={5} className={classes.box}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
        </>
    );
}

export default withSnackbar(SignIn);
