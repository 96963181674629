import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService'
import withRouter from '../../withRouter';
import EntitiesCrudForm from './EntitiesCrudForm'
import Calendar from '../../models/Calendar';
import queryString from 'query-string';
import {PROPERTIES_DEFAULT_CATERING,PROPERTIES_DEFAULT_TABLE_LAYOUT,PROPERTIES_DEFAULT_MODERATION_EQUIPMENT} from '../../helpers/IconSelecter';
import { withTranslation } from 'react-i18next';
import CustomSpinner from '../CustomSpinner';
export class EntitiesCrudDataHandler extends Component {
    constructor(props) {
        super(props)

        this.state = {
            note: null,
            defaultCatering : PROPERTIES_DEFAULT_CATERING.map((a)=> a.title),
            defaultTableLayout: PROPERTIES_DEFAULT_TABLE_LAYOUT.title,
            defaultModerationEquipment: PROPERTIES_DEFAULT_MODERATION_EQUIPMENT.map((a)=> a.title),
            data:{},
            isReady:false,
            calendarId:'',
            loading:false,
            isNew:false,
            Events: []
        }
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount(){
        this.setCalendarId();
        this.setEvents();
        this.setData();
    }

    setEvents(){
        if(this.props['params']['calendarId']!=='new'){

            HTTP_REQUESTS.BOOKING_SERVICE.GET_ALL(this.props['params']['calendarId'],"", res => {
                this.setState({ Events: res})
            },err=>{
                console.log('errrrrr',err)
            })
        }
        else {
            this.setState({isNew:true})
        }
    }

    setCalendarId(){
        let calendar = this.props['params']['calendarId'];
        queryString.stringify(calendar)

        if(calendar)
        {
            this.setState({calendarId:calendar})
        }

    }
    setData(){
        const { t } = this.props;
        if( this.props['params']['calendarId'] !== 'new' ){
            HTTP_REQUESTS.CALENDAR_SERVICE.GET_ONE_CALENDAR(this.props['params']['calendarId'], data=>{
                        this.setState({data:data},()=>{
                            this.setState({isReady:true})

                        })
                    }, (err) => {
                        this.props.enqueueSnackbar(t('errorProperties.ENTITIES_EDIT_TABLE.RENDER.ON_ROW_UPDATE.ERROR_OCCURED'), {
                            variant: 'error'})
                        })

        } else {
            let oneCalendar = new Calendar('', "Custom", '', '', null)
            this.setState({data:oneCalendar},()=>{
                this.setState({isReady:true})

            })
        }
    }
    handleCateringChange = (catering) => {
        this.setState({defaultCatering : catering})
    };
    handleTableLayoutChange = (TableLayout) => {
        this.setState({defaultTableLayout: TableLayout})
    }
    handleModerationEquipmentChange = (ModerationEquipment) => {
        this.setState({defaultModerationEquipment: ModerationEquipment})
    }
    handleNoteChange = (note) => {
        this.setState({note: note})
    }
    onSave(obj){
        this.setState({loading:true})
        const { t, navigate } = this.props;
        let oneCalendar = new Calendar(
            this.state.calendarId,
            "Custom",
            obj.summary,
            obj.description,
            obj.seat,
            obj.images,
            obj.color,
            obj.notes,
            obj.allDayRoomPrice,
            obj.halfDayRoomPrice,
            obj.additionalPrice,
            obj.bufferTime,
            obj.minBookingTime,
            obj.locationId,
            obj.categoryId,
            '',
            obj.layoutsSeats,
            '', // imageURL
            obj.restrictedPercantage,
            obj.bookableEntityId,
            obj.canBeBookHourly,
            obj.allowPublicBooking
        );
        if(this.state.isNew){

            HTTP_REQUESTS.CALENDAR_SERVICE.CREATE_CALENDAR(
                oneCalendar.toAPIJSON(),
                () => {
                    navigate(`/entities`)
                    this.props.enqueueSnackbar(t('Entities.EntitiesCrudDataHandler.Created'), {
                        variant: 'success'
                    });
                }, (err) => {
                    let tempError = (err.data && err.data.error && err.data.error.details) ? `errorProperties.ENTITIES_EDIT_TABLE.RENDER.ON_ROW_ADD.${err.data.error.details}` : `errorProperties.ENTITIES_EDIT_TABLE.RENDER.ON_ROW_ADD.ERROR_OCCURED`;
                    this.props.enqueueSnackbar((tempError != null && tempError.length > 0) ? t(tempError):'' , {
                      variant: "error"
                    });
                    this.setState({loading:false})
                    //alert(`Failed to fetch entities. Details: ${JSON.stringify(err)}`)
                });
        } else{

        HTTP_REQUESTS.CALENDAR_SERVICE.UPDATE_CALENDAR(this.state.calendarId, oneCalendar.toAPIJSON(),(res)=>{
            this.setState({loading:false})
            navigate(`/entities`)
            this.props.enqueueSnackbar(t('Entities.EntitiesCrudDataHandler.Updated'), {
                variant: 'success'
            });
        }, (err) => {
            console.log(err)
            let tempError = (err.data && err.data.error && err.data.error.details) ? `errorProperties.ENTITIES_EDIT_TABLE.RENDER.ON_ROW_UPDATE.${err.data.error.details}` : `errorProperties.ENTITIES_EDIT_TABLE.RENDER.ON_ROW_UPDATE.ERROR_OCCURED`;
            this.props.enqueueSnackbar((tempError != null && tempError.length > 0) ? t(tempError):'' , {
              variant: "error"
            });
            this.setState({loading:false})
        })
        }


        }



    onDelete(){
        this.setState({loading:true})
        const { t, navigate } = this.props;
        HTTP_REQUESTS.CALENDAR_SERVICE.DELETE_CALENDAR(
            this.state.calendarId,
            () => {
                this.setState({loading:false})
                navigate(`/entities`)
                this.props.enqueueSnackbar(t('Entities.EntitiesCrudDataHandler.Deleted'), {
                    variant: 'success'
                });

            }, (err) => {
                this.setState({loading:false})
                this.props.enqueueSnackbar(t('errorProperties.ENTITIES_EDIT_TABLE.RENDER.ON_ROW_DELETE'), {
                    variant: 'error'
                });
                //alert(`Failed to fetch entities. Details: ${JSON.stringify(err)}`)
            });

    }

    render() {
        if(this.state.loading === true){
            return <CustomSpinner />
        } else {
        return (
            <div>
                {
                this.state.isReady ?
                <EntitiesCrudForm
                 data={this.state.data}
                 /*note = {this.state.note}
                 handleNoteChange = {this.handleNoteChange}
                 handleCateringChange = {this.handleCateringChange}
                 defaultCatering = {this.state.defaultCatering}
                 defaultTableLayout = {this.state.defaultTableLayout}
                 handleTableLayoutChange = {this.handleTableLayoutChange}
                 defaultModerationEquipment = {this.state.defaultModerationEquipment}
                 handleModerationEquipmentChange = {this.handleModerationEquipmentChange}*/
                 onSave={this.onSave}
                 onDelete={this.onDelete}
                 Events={this.state.Events}
                 isNew={this.state.isNew}
                 />
                :null
                }
            </div>
        )
    }
}
}
export default withTranslation()(withSnackbar(withRouter(EntitiesCrudDataHandler)))
