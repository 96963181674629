import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import TenantForm, { Tenant, defaultTenant } from "../components/TenantForm";
import { error } from "console";

const TenantFormContainer: React.FC<{
  tenantId: string;
  handleSubmit: (tenant: Tenant) => void;
}> = ({ tenantId, handleSubmit }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tenant, setTenant] = useState<Tenant>(defaultTenant);

  useEffect(() => {
    if (tenantId) {
      setIsLoading(true);
      HTTP_REQUESTS.TENANT_SERVICE.GET_ONE_TENANT(
        tenantId,
        (success) => {
          console.log(success);
          setTenant(success.tenant);
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    } else {
      setIsLoading(false);
    }
  }, [tenantId]);

  return (
    <div>
      {isLoading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <TenantForm
            onSubmit={handleSubmit}
            tenant={tenant}
            setTenant={setTenant}
          />
        </div>
      )}
    </div>
  );
};

export default TenantFormContainer;
