import {useNavigate} from 'react-router-dom'
import React, {useEffect} from 'react'
import {hasFeature} from "../../guards/RoleFeatures";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectSessionData, setSession, restartSession} from "../../store/features/sessionSlice";
import {ACCOUNT_STATUS} from "../../utils/Constants";
import InvitationPopup from "../Invitation/InvitationPopup";

const ProtectedRoute = ({ component, feature }): JSX.Element => {
    const navigate = useNavigate();

    const sessionData = useAppSelector(selectSessionData)
    const dispatch = useAppDispatch();

    const isAuthenticated: boolean = !!sessionData.auth_token; // Check if user is authenticated
    const status: string = sessionData.status;
    const isLoading: boolean = sessionData.isLoading;
    const canAccess: boolean = hasFeature(feature);


    useEffect(() => {
        if(!isLoading){
            if (!isAuthenticated) {
                navigate('/login');
                dispatch(restartSession())
            } else if (!canAccess) {
                navigate('/403')
            }
        }
    }, [sessionData.auth_token, sessionData.isLoading])

    return (
        <>
            {!isLoading && (
                <>
                    {status === ACCOUNT_STATUS.ACTIVE ? (
                        component
                    ) : status === ACCOUNT_STATUS.INVITED && (
                        <InvitationPopup/>
                    )}
                </>
            )}
        </>
    );
};

export default ProtectedRoute;

