import {Outlet} from "react-router-dom";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import React from 'react';
import {hasFeature} from "../../guards/RoleFeatures";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectSidebarOpen, sidebarClosed} from "../../store/features/navigationSlice";
import {selectSessionData} from "../../store/features/sessionSlice";
import LoginOrRegisterModal from "../PublicComponents/Login/LoginOrRegisterModal";

export default function NavigationLayout() {

    const dispatch = useAppDispatch();
    const sessionData = useAppSelector(selectSessionData);

    const [isLoginFormOpen, setLoginFormOpen] = React.useState(false);

    const isSidebarOpen = useAppSelector(selectSidebarOpen)

    return (
        <>
            {
                !sessionData.isLoading &&
        <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
            {
                hasFeature('view-top-bar') &&
                <TopBar setLoginFormOpen={setLoginFormOpen}/>
            }
            <div style={{display: 'flex', width: '100%', height: '100%'}} onClick={() => {
                if(isSidebarOpen){
                    dispatch(sidebarClosed())
                }
            }}>

                {
                    hasFeature('view-sidebar') && !!sessionData.auth_token &&
                    <Sidebar/>
                }
                <div style={{flexGrow: 1, padding: '20px'}}>
                    <Outlet/>

                    {isLoginFormOpen &&
                        <LoginOrRegisterModal
                            open={isLoginFormOpen}
                            method='login'
                            setLoginFormOpen={setLoginFormOpen} />}
                </div>
            </div>
        </div>
            }
        </>

    )
}
