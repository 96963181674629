import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService'
import withRouter from '../../withRouter';
import DeviceCrudForm from './DeviceCrudForm'
import Device from '../../models/Device';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import CustomSpinner from '../CustomSpinner';
import {CreateDeviceRequest} from "../../backendcomm/messages/requests/devices/CreateDeviceRequest";


export class DeviceCrudDataHandler extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            data:{},
            id:"",
            isReady:false,
            accountId:'',
            loading:false,
            isNew:false,
            Events: [],
        }
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }
    
    componentDidMount(){
        this.setId();
        this.setAccountId();
        this.setData();
    }

    setId(){
        let id = this.props['params']['id'];
        if(id)
        {
            this.setState({id:id})
        }
    }

    setAccountId(){
        let account = this.props['params']['accountId'];
        queryString.stringify(account)
        if(account)
        {
            this.setState({accountId:account})
        }
    }
    setData(){
        const { t } = this.props;
        if( this.props['params']['id'] !== 'new' ){
            HTTP_REQUESTS.DEVICE_SERVICE.GET_ONE(this.props['params']['id'], data=>{
                        this.setState({data:data},()=>{
                            this.setState({isReady:true})
                        })
                    }, () => {
                        this.props.enqueueSnackbar(t('errorProperties.DEVICES_EDIT_TABLE.GET_ONE'), {
                            variant: 'error'})
                        })

        } else {
            let oneDevice = new Device('', "", '', '', {password:"",email:""})
            this.setState({data:oneDevice},()=>{
                this.setState({isReady:true})

            })
        }
    }

    createDevice = (data) => {
        this.setState({loading:true})
        const { t } = this.props;
        const { navigate } = this.props;
        const request = new CreateDeviceRequest(data.name, data.Account.email, data.Account.password, data.roomId);
        HTTP_REQUESTS.DEVICE_SERVICE.CREATE(request, () => {
            navigate("/devices")
            this.props.enqueueSnackbar(t('DevicesEditTable.Device Created'), {
                variant: 'success'
            });
        }, (err) => {
            let errorMessage = t('errorProperties.DEVICES_EDIT_TABLE.CREATE_DEVICE')
            this.props.enqueueSnackbar(t(errorMessage), {
                variant: 'error'
            });
            if(err && err.data && err.data.error && err.data.error.code === 1008){
                let errorMessage = t('errorProperties.DEVICES_EDIT_TABLE.DEVICE_ERROR_CODE.'+[err.data.error.code]+'')
                this.props.enqueueSnackbar(t(errorMessage), {
                    variant: 'error'
                }); 
            }
            this.setState({loading:false})
        });
    };

    updateDevice = (data) => {
        this.setState({loading:true})
        const { t } = this.props;
        const { navigate } = this.props;
        let newDevice = new Device()
        const requestData = newDevice.toAPIJSON(data)
        HTTP_REQUESTS.DEVICE_SERVICE.UPDATE(this.state.id, requestData, () => {
            this.setState({loading:false})
            navigate("/devices")
            this.props.enqueueSnackbar(t('DevicesEditTable.Device Updated'), {
                variant: 'success'
            });
        }, (err) => {
            let errorMessage = t('errorProperties.DEVICES_EDIT_TABLE.UPDATE_DEVICE')
            this.props.enqueueSnackbar(t(errorMessage), {
                variant: 'error'
            });
            if(err && err.data && err.data.error.code === 1008){
                let errorMessage = t('errorProperties.DEVICES_EDIT_TABLE.DEVICE_ERROR_CODE.'+[err.data.error.code]+'')
                this.props.enqueueSnackbar(t(errorMessage), {
                    variant: 'error'
                }); 
            }
            this.setState({loading:false})
        });
    };

    onSave(obj){
        this.setState({loading:true})
        let oneDevice = new Device(
            obj.id,
            obj.name,
            obj.accountId,
            obj.roomId,
            obj.Account
        );
        if(obj.id === ''){
            this.createDevice(oneDevice)
        } else{
            this.updateDevice(oneDevice)
        }
    }
    
    onDelete(){
        this.setState({loading:true})
        const { t } = this.props;
        const { navigate } = this.props;
        HTTP_REQUESTS.DEVICE_SERVICE.DELETE_BY_ID(
            this.state.id,
            () => {
                this.setState({loading:false})
                navigate("/devices")
                this.props.enqueueSnackbar(t('Devices.DeviceCrudDataHandler.Deleted'), {
                    variant: 'success'
                });
            }, () => {
                this.setState({loading:false})
                this.props.enqueueSnackbar(t('errorProperties.DEVICES_EDIT_TABLE.DELETE_DEVICE'), {
                    variant: 'error'
                });
            });
        
    }

    render() {
        if(this.state.loading === true){
            return <CustomSpinner />
        } else {
            return (
            <div>
                {
                this.state.isReady ?    
                <DeviceCrudForm
                 data={this.state.data}
                 onSave={this.onSave} 
                 onDelete={this.onDelete}
                 />
                :null
                }
            </div>
        )
    }
}
}
export default withTranslation()(withSnackbar(withRouter(DeviceCrudDataHandler)))
