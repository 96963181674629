import React, {useState, useEffect} from "react";
import {HTTP_REQUESTS} from "../../backendcomm/services/httpRequestService";
import BookingInfoCard from "../Booking/BookingInfoCard";

import {useTranslation} from "react-i18next";

const EventEdit = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [event, setEvent] = useState({id: ''});
    const { t } = useTranslation();

    const fetchEvent = () => {
        setLoading(true);
        const eventId = props.selectedEvent.id;

        HTTP_REQUESTS.BOOKING_SERVICE.GET_A_EVENT(eventId, success => {
            setEvent(success);
            setLoading(false);
        }, error => {
            console.log('Error while fetching event: ' + eventId);
        })
    }

    useEffect(() => {
        if(props.selectedEvent.id){
            fetchEvent();
        }
    }, [props.selectedEvent])


    return <>
        {
            isLoading ?
                <div>loading...</div>:
                <div style={{maxHeight: 'calc(100vh - 160px)', overflowY: 'auto'}}>
                    <BookingInfoCard
                        isLoading={isLoading}
                        bookingData={event}
                        data={event}
                        method={'update'}
                        eventEditCloser={props.eventEditCloser}
                        cancelEvent={props.cancelEvent}

                    />
                {/*    <Box>*/}
                {/*        <Grid container item spacing={2} xs={12}>*/}
                {/*            <Grid item xs={12}>*/}
                {/*                <Grid container alignItems="center">*/}
                {/*                    <Grid item lg={6}><h4>Update Booking</h4></Grid>*/}
                {/*                    <Grid item lg={6} container justifyContent="flex-end">*/}
                {/*                        <div>*/}
                {/*                            <Button color="primary" >{t('Booking.InfoCard.Clone Booking')}</Button>*/}
                {/*                            <Button>{t('Booking.InfoCard.Cancel Booking')}</Button>*/}
                {/*                            <Button>{t('Booking.InfoCard.Delete Booking')}</Button>*/}
                {/*                        </div>*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*                <Grid>*/}
                {/*                    <FormLabel sx={{marginBottom: '20px'}} component="legend">{t('Booking.InfoCard.Date Time')}</FormLabel>*/}
                {/*                    <LocalizationProvider adapterLocale={calendarLanguages[t('Calendar.MiniCalendar.Language')]} dateAdapter={AdapterDateFns}>*/}
                {/*                        <Grid container>*/}
                {/*                            <Grid item container xs={12} lg={6} justifyContent="flex-start">*/}
                {/*                                <DatePicker*/}
                {/*                                    inputFormat="dd-MM-yyyy"*/}
                {/*                                    margin="normal"*/}
                {/*                                    label={t('Booking.InfoCard.Date')}*/}
                {/*                                    value={newEvent.selectedDate}*/}
                {/*                                    onChange={setselectedDateHandle}*/}
                {/*                                    KeyboardButtonProps={{*/}
                {/*                                        'aria-label': 'change date',*/}
                {/*                                    }}*/}
                {/*                                    disabled = {disableForPaymentStatus || props.isUserDashboard || disableForViewer}*/}
                {/*                                    renderInput={(params) => <TextField {...params} />}*/}
                {/*                                />*/}

                {/*                            </Grid>*/}
                {/*                            <Grid item container xs={12} lg={6} justifyContent="space-around">*/}
                {/*                                <TimePicker*/}
                {/*                                    ampm={false}*/}
                {/*                                    variant="inline"*/}
                {/*                                    margin="normal"*/}
                {/*                                    label={t('Booking.InfoCard.From')}*/}
                {/*                                    value={newEvent.selectedStart}*/}
                {/*                                    minutesStep={1}*/}
                {/*                                    onChange={setselectedStartHandle}*/}
                {/*                                    InputProps={{ readOnly: true }}*/}
                {/*                                    KeyboardButtonProps={{*/}
                {/*                                        'aria-label': 'change from',*/}
                {/*                                    }}*/}
                {/*                                    disabled = {disableForPaymentStatus || props.isUserDashboard || disableForViewer}*/}
                {/*                                    renderInput={(params) => <TextField {...params} />}*/}
                {/*                                />*/}
                {/*                                <TimePicker*/}
                {/*                                    ampm={false}*/}
                {/*                                    variant="inline"*/}
                {/*                                    margin="normal"*/}
                {/*                                    label={t('Booking.InfoCard.To')}*/}
                {/*                                    minutesStep={1}*/}
                {/*                                    value={newEvent.selectedEnd}*/}
                {/*                                    onChange={setselectedEndHandle}*/}
                {/*                                    InputProps={{ readOnly: true }}*/}
                {/*                                    KeyboardButtonProps={{*/}
                {/*                                        'aria-label': 'change to',*/}
                {/*                                    }}*/}
                {/*                                    disabled = {disableForPaymentStatus || props.isUserDashboard || disableForViewer}*/}
                {/*                                    renderInput={(params) => <TextField {...params} />}*/}
                {/*                                />*/}
                {/*                            </Grid>*/}

                {/*                        </Grid>*/}
                {/*                    </LocalizationProvider>*/}
                {/*                </Grid>*/}
                {/*                <Grid className={classes.formControl}>*/}
                {/*                    <TextField*/}
                {/*                        margin="dense"*/}
                {/*                        id="name"*/}
                {/*                        label={t('Booking.InfoCard.Enter Reserv')}*/}
                {/*                        type="text"*/}
                {/*                        name="title"*/}
                {/*                        defaultValue={newEvent.title}*/}
                {/*                        onChange={inputGroupChangeHandler}*/}
                {/*                        fullWidth*/}
                {/*                        disabled = {disableForViewer}*/}
                {/*                    />*/}
                {/*                </Grid>*/}
                {/*                {roomLoaded ?*/}
                {/*                    (checkDashboardOrRoomsCalendar() ?*/}
                {/*                            <Grid className={classes.formControl}>*/}
                {/*                                {*/}
                {/*                                    entities.length > 0 &&*/}
                {/*                                    <TextField*/}
                {/*                                        fullWidth*/}
                {/*                                        select*/}
                {/*                                        label={t('Booking.InfoCard.Room')}*/}
                {/*                                        id="room"*/}
                {/*                                        name="room"*/}
                {/*                                        disabled = {disableForPaymentStatus || (props.selectedEntity !== "" && props.selectedEntity !== [] && props.selectedEntity !== null) || method === 'update'}*/}
                {/*                                        SelectProps={{*/}
                {/*                                            multiple: isMultiple,*/}
                {/*                                            value: newEvent.room === '' || newEvent.room === [] ? [] : newEvent.room,*/}
                {/*                                            onChange: inputGroupChangeHandler*/}
                {/*                                        }}*/}
                {/*                                    >*/}
                {/*                                        {entities.map((entity,index)=>(*/}
                {/*                                            <MenuItem value={entity.id} key={index}>{entity.name}</MenuItem>*/}
                {/*                                        ))}*/}

                {/*                                    </TextField>*/}
                {/*                                }*/}

                {/*                            </Grid> :*/}
                {/*                            <Grid className={classes.formControl}>*/}
                {/*                                <FormLabel id="room-label">{t('Booking.InfoCard.Room')}</FormLabel>*/}
                {/*                                <CustomErrorBox content={t('Booking.InfoCard.NoAvailableRooms')} />*/}
                {/*                            </Grid>*/}
                {/*                    )*/}
                {/*                    :null*/}
                {/*                }*/}
                {/*                <Grid className={classes.formControl}>*/}
                {/*                    {*/}
                {/*                        Object.keys(users).length > 0 &&*/}
                {/*                        <TextField*/}
                {/*                            fullWidth*/}
                {/*                            select*/}
                {/*                            label={t('Booking.InfoCard.Booking Holder')}*/}
                {/*                            id="bookingHolder"*/}
                {/*                            name="bookingHolder"*/}
                {/*                            value={newEvent.bookingHolder}*/}
                {/*                            onChange={inputGroupChangeHandler}*/}
                {/*                            disabled= {disableForPaymentStatus || holderDisable || disableForViewer}>*/}
                {/*                            {Object.keys(users).map((key) => (*/}
                {/*                                <MenuItem value={users[key].id} key={users[key].id}>{users[key].mail}</MenuItem>*/}
                {/*                            ))}*/}
                {/*                        </TextField>*/}
                {/*                    }*/}

                {/*                </Grid>*/}
                {/*                <Grid className={classes.formControl}>*/}
                {/*                    <TextField*/}
                {/*                        margin="dense"*/}
                {/*                        id="customerName"*/}
                {/*                        label={t('Booking.InfoCard.Contact')}*/}
                {/*                        type="text"*/}
                {/*                        name="customerName"*/}
                {/*                        value={newEvent.customerName || ''}*/}
                {/*                        disabled={disableForViewer}*/}
                {/*                        onChange={inputGroupChangeHandler}*/}
                {/*                        fullWidth*/}
                {/*                    />*/}
                {/*                </Grid>*/}
                {/*                {COWORKING_CLIENT.IsAllowedToUseParticipantCount() ?*/}
                {/*                    <Grid className={classes.formControl}>*/}
                {/*                        <TextField*/}
                {/*                            margin="dense"*/}
                {/*                            id="participantCount"*/}
                {/*                            name="participantCount"*/}
                {/*                            label={t('Booking.InfoCard.Participants')}*/}
                {/*                            type="number"*/}
                {/*                            onChange={inputGroupChangeHandler}*/}
                {/*                            value={newEvent.participantCount || ''}*/}
                {/*                            disabled={disableForViewer}*/}
                {/*                            fullWidth*/}
                {/*                        />*/}
                {/*                    </Grid>:*/}
                {/*                    <Grid className={classes.formControl}>*/}
                {/*                        <Autocomplete*/}
                {/*                            multiple*/}
                {/*                            id="contactCustomer"*/}
                {/*                            name="contactCustomer"*/}
                {/*                            options={usersAutoComplete}*/}
                {/*                            onChange={inputAutocompleteChangeHandler}*/}
                {/*                            value={contactCustomerView || []}*/}
                {/*                            disableCloseOnSelect*/}
                {/*                            disabled = {disableForViewer}*/}
                {/*                            getOptionLabel={(option) => option.mail}*/}
                {/*                            isOptionEqualToValue={(option, value) => option.id === value.id}*/}
                {/*                            renderOption={(props,option, { selected }) => (*/}
                {/*                                <li {...props}>*/}
                {/*                                    <Checkbox*/}
                {/*                                        icon={icon}*/}
                {/*                                        checkedIcon={checkedIcon}*/}
                {/*                                        style={{ marginRight: 8 }}*/}
                {/*                                        checked={selected}*/}
                {/*                                    />*/}
                {/*                                    {option.mail}*/}
                {/*                                </li>*/}
                {/*                            )}*/}

                {/*                            fullWidth*/}
                {/*                            renderInput={(params) => (*/}
                {/*                                <TextField {...params} label={t('Booking.InfoCard.Participants')}/>*/}
                {/*                            )}*/}
                {/*                        />*/}
                {/*                    </Grid>*/}

                {/*                }*/}

                {/*                {disableForViewer*/}
                {/*                    ? null*/}
                {/*                    : <Grid className={classes.formControl}>*/}
                {/*                        <TextField*/}
                {/*                            margin="dense"*/}
                {/*                            id="billingAddress"*/}
                {/*                            name="billingAddress"*/}
                {/*                            label={t('Booking.InfoCard.Billing')}*/}
                {/*                            type="text"*/}
                {/*                            onChange={inputGroupChangeHandler}*/}
                {/*                            value={newEvent.billingAddress || ''}*/}
                {/*                            fullWidth*/}
                {/*                        />*/}
                {/*                    </Grid>}*/}

                {/*                {disableForViewer*/}
                {/*                    ? null*/}
                {/*                    : <Grid className={classes.formControl}>*/}
                {/*                        <TextField*/}
                {/*                            value={newEvent.sendConfirmationTo || ''}*/}
                {/*                            margin="dense"*/}
                {/*                            id="sendConfirmationTo"*/}
                {/*                            label={t('Booking.InfoCard.Send Confirmation')}*/}
                {/*                            type="email"*/}
                {/*                            name="sendConfirmationTo"*/}
                {/*                            onChange={inputGroupChangeHandler}*/}
                {/*                            fullWidth*/}
                {/*                        />*/}
                {/*                    </Grid>*/}
                {/*                }*/}
                {/*                {COWORKING_CLIENT.IsAllowedToManageDevices() ?*/}
                {/*                    disableForViewer*/}
                {/*                        ? null*/}
                {/*                        : <Grid item xs={12}>*/}
                {/*                            <Typography variant="h6">{t('Booking.InfoCard.WelcomeMessage')}</Typography>*/}
                {/*                            <NotesTextField*/}
                {/*                                name="welcomeMessage"*/}
                {/*                                value={newEvent.welcomeMessage || []}*/}
                {/*                                onChange={inputGroupChangeHandler}></NotesTextField>*/}
                {/*                        </Grid>*/}
                {/*                    :null*/}
                {/*                }*/}
                {/*                {*/}
                {/*                    disableForViewer || (newEvent.deviceId && method === 'update') ? null :*/}
                {/*                        allowOpenAddOptions === true &&*/}
                {/*                        <Grid className={classes.formControl} container alignItems="center">*/}
                {/*                            <Typography className={classes.mr2}>{t('Booking.InfoCard.Booking Options')}</Typography>*/}
                {/*                            <Button onClick={openAddOptionsHandle}>*/}
                {/*                                <SettingsIcon color="primary" fontSize="large" />*/}
                {/*                            </Button>*/}
                {/*                        </Grid>*/}
                {/*                }*/}
                {/*                /!* {tempData.slots && <BookingRawInfo data={tempData} />} *!/*/}

                {/*                /!* <Hidden mdDown><BookingActionButtonGroup canBeSaved={canBeSaved()} formValid={formValid} handleSave={handleSave} handleClose={props.handleClose}/></Hidden>  *!/*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={12} xl={6}>*/}
                {/*                {openRepeatingBooking === true && method ==="create" &&*/}
                {/*                    <Grid item xs={12}>*/}
                {/*                        <RepeatingBooking selectedDate={newEvent.selectedDate} repeatingBookingData={newEvent.repeatingBookingData} repeatingBookingChanged={repeatingBookingHandle} handleIsRepeatBookingValidated={handleIsRepeatBookingValidated}></RepeatingBooking>*/}
                {/*                    </Grid>*/}
                {/*                }*/}
                {/*                {openAddOptions === true &&*/}
                {/*                    <Grid item xs={12}>*/}
                {/*                        {generateEntitiesSettings()}*/}
                {/*                    </Grid>*/}
                {/*                }*/}
                {/*                /!* <Hidden lgUp>*/}
                {/*    <BookingActionButtonGroup canBeSaved={canBeSaved()} formValid={formValid} handleSave={handleSave} handleClose={props.handleClose}/>*/}
                {/*</Hidden> *!/*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*        {disableForViewer*/}
                {/*            ? null*/}
                {/*            : <Grid item container xs={12} spacing={2}>*/}
                {/*                {generateShowPriceInformations() }*/}
                {/*                {generateOldPrice()}*/}
                {/*                {generatePrice()}*/}
                {/*                <Grid item xs={12}>*/}
                {/*                    <BookingActionButtonGroup canBeSaved={canBeSaved()} formValid={formValid} handleSave={handleSave} handleClose={props.handleClose}/>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*        }*/}
                {/*        { // An event is a past event and it is not owned by the viewer*/}
                {/*            disableForViewerPastEvent && disableForViewer*/}
                {/*                ? <Grid item container xs={12} spacing={2}>*/}
                {/*                    {generateShowPriceInformations() }*/}
                {/*                    {generateOldPrice()}*/}
                {/*                    {generatePrice()}*/}
                {/*                </Grid>*/}
                {/*                : null*/}
                {/*        }*/}
                {/*        {openConfirmModal ?*/}
                {/*            <CustomConfirmDialog*/}
                {/*                forDelete={true}*/}
                {/*                cancel={t('CONFIRM_DIALOG.Cancel')}*/}
                {/*                open={openConfirmModal}*/}
                {/*                confirm={t('CONFIRM_DIALOG.Delete')}*/}
                {/*                confirmCB={handleDeleteBooking}*/}
                {/*                cancelCB={onCloseConfirmModal}*/}
                {/*                header={t('CONFIRM_DIALOG.Header')}*/}
                {/*                text={t('CONFIRM_DIALOG.Information')}*/}
                {/*                isRecurred={newEvent.isReccured}*/}
                {/*                confirmRecurring={t('CONFIRM_DIALOG.Delete Recurring')}*/}
                {/*                confirmCBAll={() => handleDeleteBooking(true)}*/}
                {/*                confirmAll={t('CONFIRM_DIALOG.Delete All')}*/}
                {/*                textAll={t('CONFIRM_DIALOG.Information All')}*/}
                {/*            >*/}
                {/*            </CustomConfirmDialog>*/}
                {/*            :null}*/}
                {/*    </Box>*/}

                </div>
        }
    </>

}

export default EventEdit
