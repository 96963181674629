import React from "react";
import Tooltip from "@mui/material/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MODERATION_EQUIPMENT = [
	"Moderationskoffer",
	"Flipchart",
	"Leinwand",
	"Beamer",
];


const MODERATION_EQUIPMENT_TOOLTIP = {
	Moderationskoffer: "Moderationskoffer",
	Flipchart: "Flipchart",
	Leinwand: "Leinwand",
	Beamer: "Beamer",
};



const CATERING = [
	"item1",
	"item2",
	"item3",
	"item4",
	"item5",
	"item6",
	"item7",
];

const CATERING_TOOLTIP = {
	item1: "Wasser, Kaffee und Tee inkl.",
	item2: "Flasche Wasser (0,5l) 0,50€ netto",
	item3: "Bio-Fruchtsaft (0,7l) 3,30€ netto",
	item4: "Kekse (Packg.) 4,40€ netto",
	item5: "Obst 2,20€ netto pro Person/Tag",
	item6: "½ Belegtes Brötchen 2,70€ netto",
	item7: "Blechkuchen (Stück) 2,20€ netto",
};

const TABLE_LAYOUT = ["item1", "item2", "item3", "item4"];

const TABLE_LAYOUT_TOOLTIP = {
	item1: "Stuhlkreis",
	item2: "Reihenbestuhlung",
	item3: "Tische in U-Form",
	item4: "Parlament",
};

export const THEME_COLOR = "#3f51b5";

export const PROPERTIES_MODERATION_EQUIPMENT = MODERATION_EQUIPMENT.map(
	(property) => {
		return { key: property, title: MODERATION_EQUIPMENT_TOOLTIP[property] };
	}
);

export const PROPERTIES_DEFAULT_MODERATION_EQUIPMENT = [
	{ key: "Moderationskoffer", title: MODERATION_EQUIPMENT_TOOLTIP["Moderationskoffer"] },
	{ key: "Flipchart", title: MODERATION_EQUIPMENT_TOOLTIP["Flipchart"] },
	{ key: "Leinwand", title: MODERATION_EQUIPMENT_TOOLTIP["Leinwand"] }
];

export const PROPERTIES_CATERING = CATERING.map((catering) => {
	return { key: catering, title: CATERING_TOOLTIP[catering] };
});

export const PROPERTIES_DEFAULT_CATERING = [
	{ key: "item1", title: CATERING_TOOLTIP["item1"] },
];

export const PROPERTIES_TABLE_LAYOUT = TABLE_LAYOUT.map((tableLayout) => {
	return { key: tableLayout, title: TABLE_LAYOUT_TOOLTIP[tableLayout] };
});

export const PROPERTIES_DEFAULT_TABLE_LAYOUT = {
	key: "item1",
	title: TABLE_LAYOUT_TOOLTIP["item1"],
};

/**
 * used to create icon corresponding to the property.
 * @param {*} property
 */
export function getModerationEquipmentIcon(title, moderationEquipmentsDataRaw) {
	let moderationEquipmentItemData = moderationEquipmentsDataRaw.filter(
		(item) => item.name === title
	)[0];
	if (moderationEquipmentItemData) {
		const SpecificIcon = moderationEquipmentItemData.icon;
		return (
			<Tooltip title={title} placement="top">
				{/* <SpecificIcon style={{ color: THEME_COLOR }} /> */}
				<FontAwesomeIcon
					style={{ color: THEME_COLOR }}
					icon={SpecificIcon}
					size={"1x"}
				/>
			</Tooltip>
		);
	} else {
		return null;
	}
}

export function getCateringIcon(title,cateringsDataRaw) {
    let cateringItemData = cateringsDataRaw.filter(item => item.name === title)[0];
    if(cateringItemData){
        const SpecificIcon = cateringItemData.icon
        return <Tooltip title={title} placement="top">
            {/* <SpecificIcon style={{ color: THEME_COLOR }} /> */}
            <FontAwesomeIcon style={{color:THEME_COLOR}}  icon={SpecificIcon} size={"lg"}  />
        </Tooltip>
    }else{
        return null
    }
};

/**
 * used to create icons corresponding to the properties specified in the given bookableEntity.
 * @param {*} bookableEntity
 */
export function getEntityDetailIconsGroup(bookableEntity) {
	// TODO: Get icon colors from tenant-theme.
	let moderationEquipmentArray = bookableEntity.room.Properties.filter(
		(item) =>
			item.type === "ModerationEquipment" &&
			item.name !== "Personalaufwand"
	);
	return (
		<div
			style={{
				textOverflow: "ellipsis",
				overflow: "hidden",
				whiteSpace: "nowrap",
				display: "inline-flex",
			}}
		>
			{moderationEquipmentArray.map((property, index) => {
				return (
					<div style={{ marginRight: "3px" }} key={index}>
						<Tooltip title={property.name} placement="top">
							<FontAwesomeIcon
								style={{ color: THEME_COLOR }}
								icon={property.icon}
								size={"1x"}
							/>
						</Tooltip>
					</div>
				);
			})}
		</div>
	);
}

export const UsableIcons = [
	"apple-alt",
	"bacon",
	"birthday-cake",
	"bread-slice",
	"candy-cane",
	"carrot",
	"cheese",
	"cookie",
	"drumstick-bite",
	"hamburger",
	"pizza-slice",
	"coffee",
	"cocktail",
	"beer",
	"wine-glass-alt",
	"glass-cheers",
	"glass-martini-alt",
	"glass-whiskey",
	"hotdog",
	"eye",
	"desktop",
	"square",
];
