import {CardMedia, Grid, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TableLayoutAddBox from "../helpers/TableLayoutAddBox";
import CateringAddBox from "../helpers/CateringAddBox";
import ModerationEquipmentAddBox from "../helpers/ModerationEquipmentAddBox";
import React, { useEffect, useLayoutEffect, useRef} from "react";
import {withSnackbar} from "notistack";
import "../../MultiLabelComponent/multi-label.scss";
import {ReactComponent as Minus} from "../../MultiLabelComponent/icons/minus.svg";
import {ReactComponent as Plus} from "../../MultiLabelComponent/icons/plus.svg";
import EventSeatIcon from '@mui/icons-material/EventSeat';
import CateringCountChip from "../helpers/CateringCountChip";
import ModerationEquipmentChip from "../helpers/ModerationEquipmentChip";
import TableLayoutIcon from '@mui/icons-material/PivotTableChart';
import CateringsIcon from '@mui/icons-material/EmojiFoodBeverage';
import ModerationEquipmentIcon from '@mui/icons-material/CoPresent';
import NotesIcon from '@mui/icons-material/Notes';
import {makeStyles} from "@mui/styles";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
const roomImagePlaceHolder = {
    MeetingRoom0: "../../images/meetingRoom.png",
}

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiTypography-h6": {
            fontSize: '24px',
            fontWeight: '600'
        },
        "& .MuiTypography-h4": {
            fontSize: '32px',
            fontWeight: '700'
        },
        "& .MuiTypography-subtitle2": {
            fontSize: '18px',
            fontWeight: '600'
        },
    }
}));

const CreateEventForm = (props) => {
    const { t } = useTranslation();

    const [isLoaded, setIsLoaded] = React.useState(false);
    // Caterings
    const [cateringsOfRoomData, setCateringsOfRoomData] = React.useState([]);
    const [cateringsOfRoomDataRaw, setCateringsOfRoomDataRaw] = React.useState([]);
    //*******
    // Moderation Equipments
    const [moderationEquipmentsOfRoomData, setModerationEquipmentsOfRoomData] = React.useState([]);
    const [moderationEquipmentsOfRoomDataRaw, setModerationEquipmentsOfRoomDataRaw] = React.useState([]);

    const [dividerLength, setDividerLength] = React.useState(0);
    const [dividerMargin, setDividerMargin] = React.useState(0);

    const date = props.eventDate.getFormattedDate();
    const startTime = props.eventDate.getFormattedStartTime();
    const endTime = props.eventDate.getFormattedEndTime();
    const classes = useStyles()
    const width = useRef(null)
    const height = useRef(null)
    useLayoutEffect(() => {
        if(isLoaded){
            setDividerMargin(width.current.clientWidth);
            setDividerLength(height.current.clientHeight);
        }
    }, [isLoaded,props.additionalFeatures]);

    React.useEffect(() => {
        if(!isLoaded){
            handlePropertiesOfRoom(props.roomData)
        }
    }, [])

    useEffect(() => {
        function handleWindowResize() {
            setDividerMargin(width.current.clientWidth);
            setDividerLength(height.current.clientHeight);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const itemCounter = () => {
        return (
            <div className="input-incremental" style={{marginLeft: '8px'}}>
                <Minus onClick={() => props.updateParticipantCount(-1)}/>
                <div className="line-5"/>
                <input className="item-count-input" style={{width: props.participantCount.toString().length * 1.5 + "ch"}} type="number"
                       value={props.participantCount}
                       onChange={(event) => props.handleParticipantCountChange(event)}/>
                <div className="line-6"/>
                <Plus onClick={() => props.updateParticipantCount(1)}/>
            </div> )
    }

    const handlePropertiesOfRoom = (room) => { // sets Caterings and Moderation Equipments to fill their dropdowns
        let tempCateringsOfRoomArray = []
        let tempCateringsOfRoomArrayRaw = []
        let tempModerationEquipmentOfRoomArray = []
        let tempModerationEquipmentOfRoomArrayRaw = []

        room.Properties.forEach((item) => {
            if (item.type === 'Catering') {
                tempCateringsOfRoomArray.push(item.name)
                tempCateringsOfRoomArrayRaw.push(item)
            } else if(item.type === 'ModerationEquipment'){
                tempModerationEquipmentOfRoomArray.push(item.name)
                tempModerationEquipmentOfRoomArrayRaw.push(item)
            }
        })

        setCateringsOfRoomData(tempCateringsOfRoomArray);
        setCateringsOfRoomDataRaw(tempCateringsOfRoomArrayRaw);
        setModerationEquipmentsOfRoomData(tempModerationEquipmentOfRoomArray);
        setModerationEquipmentsOfRoomDataRaw(tempModerationEquipmentOfRoomArrayRaw);
        setIsLoaded(true)
    }


    return isLoaded ? <Grid xs={12} item container spacing={4} className={classes.root} ref={height}>
        <Grid xs={12} item container sx={{position:'relative'}}>
            {/*<Divider color='#FC2190' orientation="vertical" role="presentation" sx={{width:'8px', borderRadius:'5px',position:'absolute',height:dividerLength-32,marginLeft:`${dividerMargin+12}px`}}/>*/}
            <Grid xs item container>
                <Grid item xs={4} ref={width}>
                    <CardMedia
                        component="img"
                        style={{height:'213px',width:'100%',borderRadius:'16px'}}
                        image = {props.roomData.images[0] ? props.roomData.images[0].imageUrl : roomImagePlaceHolder['MeetingRoom0']}/>
                </Grid>
                <Grid item container direction="column" justifyContent="flex-start" xs={8} spacing={1}>
                    <Grid item>
                        <Box  sx={{ display: 'flex', alignItems: 'center', height:'fit-content'}} style={{marginLeft:'32px', width:'100%'}}>
                            {/* icon and calendar input */}
                            <Typography variant={"h4"} style={{color:'#003EB0'}} >
                                {date}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box  sx={{ display: 'flex', alignItems: 'center', height:'fit-content'}} style={{marginLeft:'32px'}}>
                            {/* icon and calendar input */}
                            <Typography variant={"h4"} style={{color:'#003EB0'}} >
                                {startTime} - {endTime}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box  sx={{ display: 'flex', alignItems: 'center', height:'fit-content' }} style={{paddingLeft:'32px'}}>
                            <Typography variant={"h6"}> {t('Booking.InfoCard.eventName')} </Typography>
                            <TextField
                                id="name"
                                type="text"
                                name="title"
                                placeholder={t('Booking.InfoCard.eventNameLabel')}
                                value={props.title}
                                onChange={(event)=>{props.setTitle(event.target.value)} }
                                variant="standard"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} item container>
                <Grid item container xs={4} justifyContent={"flex-end"}>
                    <Typography variant={"h6"} > {props.roomData.name}</Typography>
                </Grid>
                <Grid item container xs={8} style={{paddingLeft:'32px'}} >
                    <Stack direction={'row'} alignItems='center' gap={1} >
                        <EventSeatIcon/>
                        {itemCounter()}
                        <Typography variant={'subtitle2'} > {t('Booking.InfoCard.peopleAttending')} </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>

        <Grid xs={12} item container spacing={1}>
            <Grid xs={12} item container id={'tableLayout'}>
                <Grid item container alignItems={"center"}  justifyContent={"flex-end"} style={{textAlign:"end"}} xs={4}>
                    <Stack direction={'row'} alignItems='center' gap={1}>
                        {/* <TableLayoutIcon/> */}
                        <Typography variant={"h6"}> {t('Booking.InfoCard.Table')}</Typography>
                    </Stack>
                </Grid>
                <Grid item container xs={8} alignItems={"center"} justifyContent={"flex-start"} style={{paddingLeft:'32px'}} >
                    <Stack direction={'row'} alignItems='center' gap={1}>
                        <TableLayoutAddBox name="tableLayout" layoutsData={props.roomData.Layouts} value={props.additionalFeatures.tableLayout} onChange={props.inputGroupChangeHandlerAdditionalFeatures} />
                        <Stack direction={'row'} alignItems='center' gap={1}><EventSeatIcon/><Typography variant={"h6"}> {props.seat}</Typography></Stack>
                    </Stack>
                </Grid>
            </Grid>
            <Grid xs={12} item container id={'catering'}>
                <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs={4} style={{textAlign:"end"}}>
                    <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs>
                        <Stack direction={'row'} alignItems='center' gap={1}>
                            {/* <CateringsIcon/> */}
                            <Typography variant={"h6"}> {t('Booking.InfoCard.Caterings')}</Typography>
                        </Stack>

                    </Grid>
                </Grid>
                <Grid item container xs={8} style={{paddingLeft:'32px'}}>
                    <Stack direction={'row'} alignItems='start'>
                        <Stack direction={'row'} alignItems='center'>
                            <Stack direction={'row'} alignItems='center'>
                                <CateringAddBox name="caterings" cateringsDataRaw={cateringsOfRoomDataRaw} cateringsData={cateringsOfRoomData} value={props.additionalFeatures.caterings} cateringsCounts={props.additionalFeatures.cateringsCount} onChange={props.inputGroupChangeHandlerAdditionalFeatures} onChangeCount={props.inputGroupChangeHandlerAdditionalFeatures}/>
                            </Stack>
                        </Stack>
                        <Stack ml={2}>
                            {props.additionalFeatures.caterings.map((catering, index)=>{
                               return <CateringCountChip key={index} cateringsArray={props.additionalFeatures.caterings} priceObj={props.priceObj} cateringsCounts={props.additionalFeatures.cateringsCount} catering={catering} cateringsDataRaw={cateringsOfRoomDataRaw} handleCateringChange={props.inputGroupChangeHandlerAdditionalFeatures}/>
                            })}
                        </Stack>
                    </Stack>
                </Grid>

            </Grid>
            <Grid xs={12} item container id={'moderationEquipment'}>
                <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs={4} style={{textAlign:"end"}}>
                    <Grid item container alignItems={"center"} justifyContent={"flex-end"} xs style={{textAlign:"end"}}>
                        <Stack direction={'row'} alignItems='center' gap={1} style={{textAlign:"end"}}>
                            {/* <ModerationEquipmentIcon/> */}
                            <Typography variant={"h6"}> {t('Booking.InfoCard.Moderation')}</Typography>
                        </Stack>

                    </Grid>
                </Grid>
                <Grid item container xs={8} style={{paddingLeft:'32px'}} >
                    <Stack direction={'row'} alignItems='start'>
                        <Stack direction={'row'} alignItems='center'>
                            <Stack direction={'row'} alignItems='center'>
                                <ModerationEquipmentAddBox name="moderationEquipment" moderationEquipmentsDataRaw={moderationEquipmentsOfRoomDataRaw} moderationEquipmentsData={moderationEquipmentsOfRoomData} value={props.additionalFeatures.moderationEquipment} onChange={props.inputGroupChangeHandlerAdditionalFeatures}/>
                            </Stack>
                        </Stack>
                        <Stack ml={2}>
                            {props.additionalFeatures.moderationEquipment.map((moderationEquipment, index)=>{
                                return <ModerationEquipmentChip key={index} moderationEquipmentArray={props.additionalFeatures.moderationEquipment} priceObj={props.priceObj} moderationEquipment={moderationEquipment} moderationEquipmentDataRaw={moderationEquipmentsOfRoomDataRaw} handleCateringChange={props.inputGroupChangeHandlerAdditionalFeatures}/>
                            })}
                        </Stack>
                    </Stack>
                </Grid>

            </Grid>
            <Grid xs={12} item container id={'note'}>
                <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs={4} style={{textAlign:"end"}}>
                    <Grid item container alignItems={"center"} justifyContent={"flex-end"} xs style={{textAlign:"end"}}>
                        <Stack direction={'row'} alignItems='center' gap={1} style={{textAlign:"end"}}>
                            {/* <NotesIcon/> */}
                            <Typography variant={"h6"}> {t('Booking.InfoCard.Notes')}</Typography>
                        </Stack>

                    </Grid>
                </Grid>
                <Grid item container xs={8} style={{paddingLeft:'32px'}} >
                    <Stack direction={'row'} alignItems='start'>
                        <Stack direction={'row'} alignItems='center'>
                            <Stack direction={'row'} alignItems='center' sx={{
                                '& .MuiTextField-root': {width: '50ch' },
                            }}>
                                <TextField
                                    id="filled-multiline-flexible"
                                    label= {t('Booking.InfoCard.noteLabel')}
                                    name='notes'
                                    multiline
                                    minRows={4}
                                    margin={"dense"}
                                    value={props.additionalFeatures.notes}
                                    onChange={props.inputGroupChangeHandlerAdditionalFeatures}
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': { borderRadius:'12px',border:'1px solid rgba(0, 0, 0, 0.54)' },
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>

            </Grid>
        </Grid>

    </Grid> : null
}
export default withSnackbar(CreateEventForm);
