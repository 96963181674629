import SignInSide from './SignInSide';
import React,{useEffect, useState} from 'react';
import SignUp from './SignUp';
import Slide from '@mui/material/Slide';
import { withSnackbar } from 'notistack';
import { useTranslation, withTranslation } from 'react-i18next';
import SignUpSuccess from "./SignUpSuccess";
import ForgotPasswordSide from "./ForgotPasswordSide";
import { useNavigate } from 'react-router-dom';



function Login(props){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [content, setContent] = useState(<SignInSide onSignUpClicked={()=>onSignUpClicked()} onForgotPassword={() => onForgotPassword()}/>);
    const [animationStart, setAnimationStart] = useState(true);
    const [timeout, setTimeoutState] = useState(0);
    const [direction, setDirection] = useState("up");

    
    const onSignUpClicked = () => {
        const signUpComponent = (
            <SignUp
                onSignInClicked={() => onSignInClicked()}
                onRegistrationSuccess={(email) => {
                    setContent(<SignUpSuccess />);
                    setTimeout(() => {
                        onSignInClicked(email, true);
                        const message = t('Login.Login Now');
                        props.enqueueSnackbar(message, { variant: 'info' });
                    }, 3000);
                }}
            />
        );
        setTimeoutState(200);
        setAnimationStart(false)

        setTimeout(()=>{
            setContent(signUpComponent)
            setAnimationStart(true)
            setDirection("left")
        },300)

    }


    const onForgotPassword = () => {
        const forgotPasswordComponent = <ForgotPasswordSide onSignInClicked={() => onSignInClicked()}/>
        setTimeoutState(200);
        setAnimationStart(false);
        setTimeout(() => {
            setContent(forgotPasswordComponent);
            setAnimationStart(true);
            setDirection("left");
        }, 300);
    }
    
    const onSignInClicked = (email = null,withoutAnimation = null) =>{
        const signInComponent = <SignInSide onSignUpClicked={() => onSignUpClicked()} onForgotPassword={() => onForgotPassword()} email={email}/>;
        if(withoutAnimation){
            setContent(signInComponent);
            return;
        }
        setAnimationStart(false)
        setTimeout(()=>{
            setContent(signInComponent)
            setAnimationStart(true)
            setDirection("up")
        },300)
    }
    
    useEffect(() =>{
        
        const token = localStorage.getItem("accessToken");

        if(token){
            navigate("/dashboard")
        }
    },[])

    return (
            <div>
            <Slide direction={direction} timeout={timeout} in={animationStart} mountOnEnter unmountOnExit>
            <div>
                {content}
            </div>
            </Slide>
            </div>

    )
    
}

export default withTranslation()(withSnackbar(Login));
