import React from "react";
import { Box } from "@mui/material";

export default function CustomErrorBox(props) {
    return (
        <div>
            <Box component="div" bgcolor="rgb(253, 236, 234)" color="color: rgb(97, 26, 21)" padding={2} marginY={1}>{props.content}</Box>
        </div>
    );
}
