import React, {useEffect, useState} from "react";
import { Grid } from "@mui/material";
import { withSnackbar } from 'notistack'
import moment from "moment";
import DashboardAdminSkeleton from "../Skeletons/DashboardAdminSkeleton";
import RCalendar from "../Calendar/RCalendar";
import {useAppSelector} from "../../store/hooks";
import {selectSessionData} from "../../store/features/sessionSlice";
import DashboardAdminFilter from "./DashboardAdminFilter";
import MiniCalendar from "../Calendar/MiniCalendar";
import BookingInfoCard from "../Booking/BookingInfoCard";
import EventEdit from "./EventEdit";
import {HTTP_REQUESTS} from "../../backendcomm/services/httpRequestService";

  /* TODO: (EMRE) Check methods and variables if they need or not */

const DashboardAdmin = (props) => {
  const sessionData = useAppSelector(selectSessionData)

  const [selectedEvent, setSelectedEvent] = useState({id: ''})

  const handleNavigate = (date) => {
    props.setSelectedDate(moment(date).toDate())
  }
  const eventEditCloser = () => {
    setSelectedEvent({id:''});
    props.fetchEvents();
  }
  const cancelEvent = () => {
    const eventId = selectedEvent.id;
    HTTP_REQUESTS.BOOKING_SERVICE.DELETE_A_BOOKING(eventId, (success) => {
        console.log('Error while canceling event: ' + eventId);
    }, (error) => {       
        eventEditCloser();
    })
  }

  return (
      <div>
        {props.isLoading ?
            <DashboardAdminSkeleton/>
            :
            <Grid item container spacing={3}>

              {
                !selectedEvent.id &&
                  <Grid item xs={12} md={12} lg={3}>
                    <Grid item container spacing={3}>
                      {!sessionData.isMobile ?
                          <Grid lg={12} sm={6} xs={12} item >
                            <MiniCalendar currentDate={props.selectedDate} changeDate={handleNavigate}></MiniCalendar>
                          </Grid>
                          : null }
                      <Grid lg={12} sm={6} xs={12} item>
                        <DashboardAdminFilter locations={props.locations} setFilter={props.setFilter}></DashboardAdminFilter>
                      </Grid>
                    </Grid>
                  </Grid>
              }
              <Grid item xs={12} md={12} lg={9}>
                <RCalendar
                    setSelectedEvent={setSelectedEvent}
                    setCurrentStep={props.setCurrentStep}
                    events={props.events}
                    hasEntity={true}
                    selectedDate={props.selectedDate}
                    setSelectedDate={props.setSelectedDate}
                    selectedEndTime={props.selectedEndTime}
                    setSelectedEndTime={props.setSelectedEndTime}
                    selectedStartTime={props.selectedStartTime}
                    setSelectedStartTime={props.setSelectedStartTime}
                    setDashboardError={props.setDashboardError}
                    currentDate={props.selectedDate}
                    calendarFilter={props.filter}
                    changeDate={handleNavigate}
                    calendarMode="all" />
              </Grid>
              {
                selectedEvent.id &&
                  <Grid item xs={12} md={12} lg={3}>
                    <EventEdit
                        selectedEvent={selectedEvent}
                        eventEditCloser={eventEditCloser}
                        cancelEvent={cancelEvent}
                    />
                  </Grid>
              }
            </Grid>
        }
      </div>

  );
}

export default withSnackbar(DashboardAdmin);
