import React, { useEffect, useState } from "react";
import { TextField, FormControlLabel, Checkbox } from "@mui/material";

interface TenantFormProps {
  onSubmit: (tenant: Tenant) => void;
  tenant: Tenant;
  setTenant: React.Dispatch<React.SetStateAction<Tenant>>;
}

export interface Tenant {
  allDayThreshold: number;
  companyLogo: string;
  companyName: string;
  config: {
    timeZone: string;
    workingEndHour: string;
    workingStartHour: string;
    features: {
      addSelectedPropertiesToRoom: boolean;
      allowPublicBooking: boolean;
      allowReservationOverlap: boolean;
      calculateHourWithCeil: boolean;
      calculateMinPrice: boolean;
      calculateOutsidePrice: boolean;
      participantcount: boolean;
      payment: boolean;
      seeAllEventDetails: boolean;
      userAllowBooked: boolean;
    };
  };
  domainName: string;
  id: string;
}

export const defaultTenant: Tenant = {
  allDayThreshold: 0,
  companyLogo: "",
  companyName: "",
  config: {
    timeZone: "",
    workingEndHour: "",
    workingStartHour: "",
    features: {
      addSelectedPropertiesToRoom: false,
      allowPublicBooking: false,
      allowReservationOverlap: false,
      calculateHourWithCeil: false,
      calculateMinPrice: false,
      calculateOutsidePrice: false,
      participantcount: false,
      payment: false,
      seeAllEventDetails: false,
      userAllowBooked: false,
    },
  },
  domainName: "",
  id: "",
};

const TenantForm: React.FC<TenantFormProps> = ({
  onSubmit,
  tenant,
  setTenant,
}) => {
  useEffect(() => {}, []);

  const inputGroupChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;
    setTenant((prevTenant) => {
      const newValue = type === "checkbox" ? checked : value;
      return { ...prevTenant, [name]: newValue };
    });
  };

  const configFeatureChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;
    setTenant((prevTenant) => {
      const { config } = prevTenant;
      const newConfig = {
        ...config,
        features: {
          ...config.features,
          [name]: type === "checkbox" ? checked : value,
        },
      };
      return { ...prevTenant, config: newConfig };
    });
  };

  const configChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setTenant((prevTenant) => {
      const { config } = prevTenant;
      const newConfig = {
        ...config,
        [name]: type === "checkbox" ? checked : value,
      };
      return { ...prevTenant, config: newConfig };
    });
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(tenant);
  };

  return (
    <form
      onSubmit={submitHandler}
      style={{ display: "flex", flexDirection: "column", gap: 8 }}
    >
      <TextField
        required
        name="companyName"
        id="companyName"
        label="Company Name"
        type="text"
        value={tenant.companyName}
        onChange={inputGroupChangeHandler}
      />
      <TextField
        required
        name="domainName"
        id="domainName"
        label="Domain Name"
        type="text"
        value={tenant.domainName}
        onChange={inputGroupChangeHandler}
      />
      <TextField
        required
        name="allDayThreshold"
        id="allDayThreshold"
        label="All Day Threshold"
        type="number"
        value={tenant.allDayThreshold}
        onChange={inputGroupChangeHandler}
      />
      <TextField
        required
        name="timeZone"
        id="timeZone"
        label="Time Zone"
        type="text"
        value={tenant.config.timeZone}
        onChange={configChangeHandler}
      />
      <TextField
        required
        name="workingStartHour"
        id="workingStartHour"
        label="Working Start Hour"
        type="text"
        value={tenant.config.workingStartHour}
        onChange={configChangeHandler}
      />
      <TextField
        required
        name="workingEndHour"
        id="workingEndHour"
        label="Working End Hour"
        type="text"
        value={tenant.config.workingEndHour}
        onChange={configChangeHandler}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="addSelectedPropertiesToRoom"
            checked={tenant.config.features.addSelectedPropertiesToRoom}
            onChange={configFeatureChangeHandler}
          />
        }
        label="Add Selected Properties to Room"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="allowPublicBooking"
            checked={tenant.config.features.allowPublicBooking}
            onChange={configFeatureChangeHandler}
          />
        }
        label="Allow Public Booking"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="allowReservationOverlap"
            checked={tenant.config.features.allowReservationOverlap}
            onChange={configFeatureChangeHandler}
          />
        }
        label="Allow Reservation Overlap"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="calculateHourWithCeil"
            checked={tenant.config.features.calculateHourWithCeil}
            onChange={configFeatureChangeHandler}
          />
        }
        label="Calculate Hour with Ceiling"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="calculateMinPrice"
            checked={tenant.config.features.calculateMinPrice}
            onChange={configFeatureChangeHandler}
          />
        }
        label="Calculate Minimum Price"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="calculateOutsidePrice"
            checked={tenant.config.features.calculateOutsidePrice}
            onChange={configFeatureChangeHandler}
          />
        }
        label="Calculate Outside Price"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="participantcount"
            checked={tenant.config.features.participantcount}
            onChange={configFeatureChangeHandler}
          />
        }
        label="Participant Count"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="payment"
            checked={tenant.config.features.payment}
            onChange={configFeatureChangeHandler}
          />
        }
        label="Payment"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="seeAllEventDetails"
            checked={tenant.config.features.seeAllEventDetails}
            onChange={configFeatureChangeHandler}
          />
        }
        label="See All Event Details"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="userAllowBooked"
            checked={tenant.config.features.userAllowBooked}
            onChange={configFeatureChangeHandler}
          />
        }
        label="User Allow Booked"
      />
      <TextField
        required
        name="companyLogo"
        id="companyLogo"
        label="Company Logo"
        type="text"
        value={tenant.companyLogo}
        onChange={inputGroupChangeHandler}
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default TenantForm;
