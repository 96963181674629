import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import withRouter from '../../withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { Check, Close } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {THEME_COLOR} from "../../helpers/IconSelecter"
import CustomTable from "../Core/CustomTable/CustomTable";
import {
    ActionButton,
    ActionButtonTypes,
    ActionButtonWrapper,
    ActionTypes
} from "../Core/CustomTable/TableActionButtons";
import {ColumnType} from "../Core/CustomTable/TableConfig";
import ConfirmationDialog from "../Core/Dialog/ConfirmationDialog/ConfirmationDialog";
const styles =  theme  => ({
    flexWrap: {
      flexWrap: "wrap",
    }
})
class CustomizeEditTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantId: "",
            properties: [],
            loading: true,
            dialogOpen: false
        };
    };

    componentDidMount() {
        this.fetchProperties();
    }

    fetchProperties = () => {
        const { t, navigate } = this.props;
        HTTP_REQUESTS.PROPERTY_SERVICE.GET_ALL((properties) => {
            this.setState({ properties:properties, loading: false });
        }, (err) => {
            this.setState({loading: false});
            this.props.enqueueSnackbar(t('errorProperties.CUSTOMIZE_EDIT_TABLE.FETCH_PROPERTIES'), {
                variant: 'error'
            });
            setTimeout(() =>  navigate("/dashboard") , 1000);
        });
    };

    generateColumn = () => {
        const { t } = this.props;
        return [
            {
                id: 'actions',
                title: t('Entities.EntitiesEditTable.Actions'),
                tooltip:t('Entities.EntitiesEditTable.Edit Room'),
                cell: ({row}) => {
                    return (
                        ActionButtonWrapper([
                            ActionButton(
                                t('Customize.CustomizeEditTable.Edit.Edit_Property'),
                                {actionType: ActionTypes.navigate, navigateTo: `property/${row.original.id}`},
                                ActionButtonTypes.edit
                            ),
                            ActionButton(
                                t('Customize.CustomizeEditTable.Edit.Delete_Property'),
                                {actionType: ActionTypes.custom, action: () => {
                                        this.setState({dialogOpen: true,
                                            dialogAction: (
                                                () => this.deletePropertyById(row.original)
                                        )
                                        })
                                    }},
                                ActionButtonTypes.delete
                            )
                        ]))
                },
                type: ColumnType.display,
                size: 100
            },
            {
                id: 'name',
                title: t('Customize.CustomizeEditTable.Property.Name'),
                type: ColumnType.accessor
            },
            {
                id: 'type',
                title: t('Customize.CustomizeEditTable.Property.Type'),
                type: ColumnType.accessor
            },
            {
                id: 'price',
                title: t('Customize.CustomizeEditTable.Property.Price'),
                type: ColumnType.accessor
            },
            {
                id: 'priceType',
                title: t('Customize.CustomizeEditTable.Property.PriceType'),
                type: ColumnType.accessor
            },
            {
                id: 'icon',
                title: t('Customize.CustomizeEditTable.Property.Icon'),
                cell: ({row}) => (
                    <FontAwesomeIcon style={{color:THEME_COLOR}}  icon={row.original.icon} size={"2x"} />
                ),
                type: ColumnType.accessor
            },
            {
                id: 'isVisible',
                title: t('Customize.CustomizeEditTable.Property.IsVisible'),
                cell: ({row}) => (
                    row.original.isVisible ? <Check/> : <Close/>
                ),
                type: ColumnType.display
            },
        ]
    };

    prepareTableEditable = () => {
        return {
                onRowDelete : (oldData) =>
                new Promise((resolve, reject)=>{
                    this.deletePropertyById(oldData,resolve,reject);
                })
        }
    };



    
    deletePropertyById = (oldData) => {
        const { t } = this.props;
        HTTP_REQUESTS.PROPERTY_SERVICE.DELETE_BY_ID(oldData.id,(device) =>{
            this.props.enqueueSnackbar(t('Customize.CustomizeEditTable.Property Deleted'), {
                variant: 'success'
            });
            this.fetchProperties();
        }, (err) => {
            this.props.enqueueSnackbar(t('errorProperties.CUSTOMIZE_EDIT_TABLE.DELETE_PROPERTY'), {
                variant: 'error'
            });
        });
    };

    handleDialogClose = (confirmation) => {
        this.setState({dialogOpen: false});
        if(confirmation){
            this.state.dialogAction()
        }
    }

    render() {
        const { t } = this.props;
        return <div>

            <CustomTable
                columns={this.generateColumn()}
                data={[...this.state.properties]}
                title={t('Customize.CustomizeEditTable.TableTitle')}
                headerActions={[
                    {
                        iconType: ActionButtonTypes.add,
                        tooltip: t('Customize.CustomizeEditTable.Edit.New_Property'),
                        clickAction: {actionType: ActionTypes.navigate, navigateTo: "property/new"},
                    }
                ]}
                loading={this.state.loading}
            />
            <ConfirmationDialog
                open={this.state.dialogOpen}
                onClose={this.handleDialogClose}
                message={t('Customize.CustomizeEditTable.Delete Text')}
                title={t('Customize.CustomizeEditTable.Delete Title')}
            />
        </div>
    }
};

export default withTranslation()(withSnackbar(withStyles(styles)(withRouter(CustomizeEditTable))));
