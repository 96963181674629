import { Grid, Skeleton, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import TableLayoutIcon from "@mui/icons-material/PivotTableChart";
import CateringsIcon from "@mui/icons-material/EmojiFoodBeverage";
import ModerationEquipmentIcon from "@mui/icons-material/CoPresent";
import NotesIcon from "@mui/icons-material/Notes";
import "./skeletons.scss"

const EventCreationSkeleton = () => {

    const useStyles = makeStyles(() => ({
        root: {
            "& .MuiTypography-h6": {
                fontSize: '24px',
                fontWeight: '600'
            },
            "& .MuiTypography-h4": {
                fontSize: '32px',
                fontWeight: '700'
            },
            "& .MuiTypography-subtitle2": {
                fontSize: '18px',
                fontWeight: '600'
            },
        }
    }));

    const classes = useStyles()

    return (
      <div style={{width:'90%',margin:'0 auto'}} className="blurred-content">
          <Grid container item direction="column" justifyContent="flex-start" alignItems="flex-start" xs={12}>
              <Typography variant={"h4"} >Book your event</Typography>
              <Typography style={{opacity:'0.6'}} variant={'subtitle2'} >Booking event info</Typography>
          </Grid>
          <Grid xs={12} container item style={{marginTop:'24px'}}>
              <Grid md={9} xs={12} item container>
                  <Grid xs={12} item container spacing={4} className={classes.root}>
                      <Grid xs={12} item container sx={{position:'relative'}}>
                          <Grid xs item container>
                              <Grid item xs={4} >
                                  <Skeleton style={{height:'213px',width:'100%',borderRadius:'16px'}} animation={false}/>
                              </Grid>
                              <Grid item container direction="column" justifyContent="flex-start" xs={8} spacing={1}>
                                  <Grid item>
                                      <Box  sx={{ display: 'flex', alignItems: 'center', height:'fit-content'}} style={{marginLeft:'32px', width:'100%'}}>
                                          {/* icon and calendar input */}
                                          <Typography variant={"h4"} style={{color:'#003EB0'}}  >
                                              Today
                                          </Typography>
                                      </Box>
                                  </Grid>
                                  <Grid item>
                                      <Box  sx={{ display: 'flex', alignItems: 'center', height:'fit-content'}} style={{marginLeft:'32px'}}>
                                          <Typography variant={"h4"} style={{color:'#003EB0'}} >
                                              13:00 - 13:30
                                          </Typography>
                                      </Box>
                                  </Grid>
                                  <Grid item>
                                      <Box  sx={{ display: 'flex', alignItems: 'center', height:'fit-content' }} style={{paddingLeft:'32px'}}>
                                          <Typography variant={"h6"} >Event Name:</Typography >
                                          <TextField 
                                                     variant="standard"
                                          />
                                      </Box>
                                  </Grid>
                              </Grid>
                          </Grid>
                          <Grid xs={12} item container>
                              <Grid item container xs={4} justifyContent={"flex-end"}>
                                  <Typography variant={"h6"} >Room Name</Typography>
                              </Grid>
                              <Grid item container xs={8} style={{paddingLeft:'32px'}} >
                                  <Stack direction={'row'} alignItems='center' gap={1} >
                                      <EventSeatIcon/>
                                      <Typography variant={'subtitle2'} >People will be attending</Typography>
                                  </Stack>
                              </Grid>
                          </Grid>
                      </Grid>

                      <Grid xs={12} item container spacing={1}>
                          <Grid xs={12} item container id={'tableLayout'}>
                              <Grid item container alignItems={"center"}  justifyContent={"flex-end"} style={{textAlign:"end"}} xs={4}>
                                  <Stack direction={'row'} alignItems='center' gap={1} >
                                      <TableLayoutIcon/>
                                      <Typography variant={"h6"}>Table Layout</Typography>
                                  </Stack>
                              </Grid>
                              <Grid item container xs={8} alignItems={"center"} justifyContent={"flex-start"} style={{paddingLeft:'32px'}} >
                                  <Stack direction={'row'} alignItems='center' gap={1} >
                                      Add Table Layout
                                      <Stack direction={'row'} alignItems='center' gap={1}><EventSeatIcon/><Typography variant={"h6"}>5</Typography></Stack>
                                  </Stack>
                              </Grid>
                          </Grid>
                          <Grid xs={12} item container id={'catering'}>
                              <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs={4} style={{textAlign:"end"}}>
                                  <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs>
                                      <Stack direction={'row'} alignItems='center' gap={1} >
                                          <CateringsIcon/>
                                          <Typography variant={"h6"}>Caterings</Typography>
                                      </Stack>

                                  </Grid>
                              </Grid>
                              <Grid item container xs={8} style={{paddingLeft:'32px'}}>
                                  <Stack direction={'row'} alignItems='start' >
                                      <Stack direction={'row'} alignItems='center'>
                                          <Stack direction={'row'} alignItems='center'>
                                              +Add
                                          </Stack>
                                      </Stack>
                                      <Stack ml={2}>
                                      </Stack>
                                  </Stack>
                              </Grid>

                          </Grid>
                          <Grid xs={12} item container id={'moderationEquipment'}>
                              <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs={4} style={{textAlign:"end"}}>
                                  <Grid item container alignItems={"center"} justifyContent={"flex-end"} xs style={{textAlign:"end"}}>
                                      <Stack direction={'row'} alignItems='center' gap={1} style={{textAlign:"end"}} >
                                          <ModerationEquipmentIcon/>
                                          <Typography variant={"h6"}> Moderation Equipment</Typography>
                                      </Stack>

                                  </Grid>
                              </Grid>
                              <Grid item container xs={8} style={{paddingLeft:'32px'}} >
                                  <Stack direction={'row'} alignItems='start'>
                                      <Stack direction={'row'} alignItems='center'>
                                          <Stack direction={'row'} alignItems='center'>
                                              +Add
                                          </Stack>
                                      </Stack>
                                      <Stack ml={2}>
                                      </Stack>
                                  </Stack>
                              </Grid>

                          </Grid>
                          <Grid xs={12} item container id={'note'}>
                              <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs={4} style={{textAlign:"end"}}>
                                  <Grid item container alignItems={"center"} justifyContent={"flex-end"} xs style={{textAlign:"end"}}>
                                      <Stack direction={'row'} alignItems='center' gap={1} style={{textAlign:"end"}} >
                                          <NotesIcon/>
                                          <Typography variant={"h6"}>Notes</Typography>
                                      </Stack>

                                  </Grid>
                              </Grid>
                              <Grid item container xs={8} style={{paddingLeft:'32px'}} >
                                  <Stack direction={'row'} alignItems='start'>
                                      <Stack direction={'row'} alignItems='center' >
                                          <Stack direction={'row'} alignItems='center' sx={{
                                              '& .MuiTextField-root': {width: '50ch' },
                                          }}>
                                              <TextField
                                                  multiline
                                                  minRows={4}
                                                  margin={"dense"}
                                                  sx={{
                                                      '& .MuiOutlinedInput-notchedOutline': { borderRadius:'12px',border:'1px solid rgba(0, 0, 0, 0.54)' },
                                                  }}
                                              />
                                          </Stack>
                                      </Stack>
                                  </Stack>
                              </Grid>

                          </Grid>
                      </Grid>

                  </Grid>
              </Grid>
              <Grid md={3} xs={12} item container style={{height:'fit-content'}}>
                  <Card style={{width:'100%',padding:'16px'}}>
                      <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          spacing={1}
                      >
                          <Grid item container id={'Room Pricing'}>
                              <Grid item>
                                  <Typography variant={"h6"} style={{width:'unset',fontWeight:'bold'}} >Room Pricing</Typography>
                              </Grid>
                              <Grid item container style={{marginLeft:'8px'}}>
                                  <Grid item xs={8}>
                                      <Typography variant={"subtitle2"} style={{width:'unset',textAlign:'start'}} > Duration</Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                      <Typography variant={"subtitle2"} style={{width:'unset',textAlign:'end'}} > €5.00 </Typography>
                                  </Grid>
                              </Grid>
                          </Grid>

                          <Grid item container id={'Additional Options'}>
                              <Grid item>
                                  <Typography variant={"h6"} style={{width:'unset',fontWeight:'bold'}} > Additional Options</Typography>
                              </Grid>
                              <Grid item container style={{marginLeft:'8px'}}>
                                  <Grid item xs={'auto'}>
                                      <Typography variant={"subtitle2"} style={{textAlign:'start'}} >Caterings</Typography>
                                  </Grid>
                                  <Grid item xs justifyContent={"flex-end"} style={{display:'flex'}}>
                                      <Typography variant={"subtitle2"} > €5.00</Typography>
                                  </Grid>
                              </Grid>
                              <Grid item container style={{marginLeft:'8px'}}>
                                  <Grid item xs={'auto'}>
                                      <Typography variant={"subtitle2"} style={{width:'unset',textAlign:'start'}} >Moderation Equipment</Typography>
                                  </Grid>
                                  <Grid item xs justifyContent={"flex-end"} style={{display:'flex'}}>
                                      <Typography variant={"subtitle2"} style={{width:'unset',textAlign:'end'}} >€5.00</Typography>
                                  </Grid>
                              </Grid>
                          </Grid>

                          <Grid item container id={'Total Price'}>
                              <Grid item container>
                                  <Grid item xs={'auto'}>
                                      <Typography variant={"h6"} style={{width:'unset',textAlign:'start',fontWeight:'bold'}} >Total Price</Typography>
                                  </Grid>
                                  <Grid item xs justifyContent={"flex-end"} style={{display:'flex'}}>
                                      <Typography variant={"h6"} style={{width:'unset',textAlign:'end',fontWeight:'bold'}} > €5.00</Typography>
                                  </Grid>
                              </Grid>
                          </Grid>
                          <Grid item container alignItems={'stretch'}>
                              <Button  variant={"contained"} fullWidth style={{backgroundColor:'#2D77FC',color:'#fff',borderRadius:'8px'}} >
                                  Confirm
                              </Button>
                          </Grid>
                      </Grid>
                  </Card>
              </Grid>
          </Grid>
      </div>
    )

}

export default EventCreationSkeleton;
