import React from "react";
import TenantFormContainer from "../components/TenantFormContainer";
import { Tenant } from "../components/TenantForm";
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";

const SuperAdminCreateNewTenantPage = () => {
  const handleSubmit = (tenant: Tenant) => {
    HTTP_REQUESTS.TENANT_SERVICE.CREATE_A_TENANT(
      tenant,
      (success) => {
        console.log(success);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div>
      <TenantFormContainer handleSubmit={handleSubmit} tenantId="" />
    </div>
  );
};

export default SuperAdminCreateNewTenantPage;
