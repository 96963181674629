import { Button, Card, Grid, TextField, Typography, Select, MenuItem, FormHelperText } from '@mui/material'
import React, { Component } from 'react'
import FormLabel from '@mui/material/FormLabel';
import withRouter from '../../withRouter';
import { withSnackbar } from 'notistack';
import { withStyles } from '@mui/styles';
import AdvancedSettings from './AdvancedSettings';
import { withTranslation } from 'react-i18next';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import theme from '../../theme';
import BookingsList from '../Dashboard/BookingsList';
import { CustomConfirmDialog } from '../CustomConfirmDialog';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import checkFloat from '../../helpers/checkFloat';
import CustomSpinner from '../CustomSpinner';
import {decideMinBookingTime,decideMinBookingTimeList} from './helper'
import CustomDropzone from "../Core/CustomDropzone/CustomDropzone";
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../helpers/dataConverter";


const styles = theme  => ({
    swatch: {
      padding: "5px",
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
    square: {
        width: '40px'  ,
        height: '40px',
        minWidth: 'unset',
       
    },
    
  });



export class EntitiesCrudForm extends Component {
    

    constructor(props) {
        super(props)
        this.state = {
            images: [],
            uploadImageArray : [],
            minBookingTime: decideMinBookingTime(),
            minBookingTimeList: decideMinBookingTimeList() ,
            bufferTime: 0,
            bufferTimeList: [0,15,30,45,60],
            summary: '',
            description: '',
            locationId: '',
            seat: 0,
            color: '#DD7C15',
            halfDayRoomPrice: 0,
            allDayRoomPrice: 0,
            additionalPrice: 0,
            bookableEntityId:'',
            isActive:false,
            isReadyForRender:false,
            errorUserName:"",
            errorEmail:"",
            allLocations : [],
            isNew: false,
            notes:"",
            summaryError: '',
            descriptionError: '',
            locationError: '',
            allDayRoomPriceError: '',
            halfDayRoomPriceError: '',
            additionalPriceError: '',
            seatsError: '',
            minBookingTimeError: '',
            layouts:[],
            layoutsSeats:{},
            dropzoneClicked:false,
            openConfirmModal:false,
            restrictedPercantage:0,
            restrictedPercantageError:'',
            canBeBookHourly: this.props.tenantConfig.features.onlyBookHourly,
            isLoading:true,
            allowPublicBooking: false
        }
        this.getAllLocations = this.getAllLocations.bind(this);
        this.getAllLayouts = this.getAllLayouts.bind(this);
        this.decideDialogText = this.decideDialogText(this)
    }
    layoutSeatArrayToUI = (data) => {
        let tempLayoutsSeatObject = {};
        data.map((item) => {
            tempLayoutsSeatObject[item.layoutId] = item.layoutSeat
        })
        return tempLayoutsSeatObject;
    }
    componentDidMount=()=>{
        let bookableEntityId = '';
        let initialEntityData = this.props.data.room;
        let updateObjectTemp = {bookableEntityId:bookableEntityId}
        if(initialEntityData) { // For Updating
            let uploadedImageArray = []
            if(initialEntityData.images && initialEntityData.images.length > 0){
                for(const oneImageObj of initialEntityData.images){
                    uploadedImageArray.push(oneImageObj.imageUrl)
                }
            }
            updateObjectTemp = {
                bookableEntityId:bookableEntityId,
                summary: initialEntityData.name,
                description: initialEntityData.description,
                seat: initialEntityData.seat, 
                locationId:initialEntityData.locationId,
                color:initialEntityData.color,
                allDayRoomPrice:initialEntityData.allDayRoomPrice,
                halfDayRoomPrice:initialEntityData.halfDayRoomPrice,
                bufferTime:initialEntityData.bufferTime,
                minBookingTime:initialEntityData.minBookingTime,
                additionalPrice:initialEntityData.additionalPrice,
                layoutsSeats:this.layoutSeatArrayToUI(initialEntityData.RoomLayouts),
                images:initialEntityData.images,
                restrictedPercantage:initialEntityData.restrictedPercantage,
                canBeBookHourly:initialEntityData.canBeBookHourly,
                allowPublicBooking:initialEntityData.allowPublicBooking,
                uploadImageArray:uploadedImageArray,
                ...updateObjectTemp
            }
        }
        this.setState(
            updateObjectTemp, () => {
                        this.setState({isReadyForRender:true,isLoading:false}, () => {
                            this.getAllLocations();
                            this.getAllLayouts();
                        })
                    })
        this.isNewForTitle();
    }
    onChangeCanBeBookHourly = event => {
        this.setState({canBeBookHourly: event.target.checked})
    }
    onChangeAllowPublicBooking = event => {
        this.setState({allowPublicBooking: event.target.checked})
    }
    onColorChange = color => {
        this.setState({color: color})
    }
    deleteRoom = () => {
        const { t } = this.props;
        if (this.props.data.id === 'new') {
            this.props.enqueueSnackbar(t('errorProperties.ENTITIES_EDIT_TABLE.RENDER.ON_ROW_ADD.ERROR_OCCURED'), {
                variant: 'error'
            });
        }
        else{
            this.props.onDelete()
        }
    }
    onCloseConfirmModal = () => {
        this.setState({openConfirmModal:false})
    }
    showConfirmModal = () => {
        this.setState({openConfirmModal:true})
    }
    onClickCancel = () => {
        const { navigate } = this.props;
        navigate('/entities')
    }
    onChangeSummary = (event) => {
        this.setState({summary:event.target.value})
        this.setState({summaryError: ""})
    } 
    onChangeDescription = (event) => {
        this.setState({description:event.target.value})
        this.setState({descriptionError: ""})
    } 
    onChangeLocation = (event) => {
        this.setState({locationId:event.target.value})
        this.setState({locationError: ""})
    }
    onChangeSeat = (seat,layoutId) =>{
        if(!Number.isInteger(Number(seat))) {
            seat = 0
        }
        this.setState((prevState) => ({
            ...prevState,
            layoutsSeats:{...prevState.layoutsSeats, [layoutId]: Number(seat)}
        })) 
        // this.setState({seat:seat})
        this.setState({seatsError: ""})
    }
    onChangeminBookingTime = (minBookingTime) => {
        this.setState({minBookingTime: minBookingTime})
        this.setState({minBookingTimeError: ""})
    } 
    onChangeBufferTime =(event) => {
        this.setState({bufferTime: event.target.value})
    }
    onChangeAllDayRoomPrice = event => {
        event.target.value = checkFloat(event.target.value);
        this.setState({allDayRoomPrice: event.target.value})
        this.setState({allDayRoomPriceError: ""})
    }
    onChangeHalfDayRoomPrice = event => {
        event.target.value = checkFloat(event.target.value);
        this.setState({halfDayRoomPrice: event.target.value})
        this.setState({halfDayRoomPriceError: ""})
    }
    onChangeAdditionalPrice = event => {
        event.target.value = checkFloat(event.target.value);
        this.setState({additionalPrice: event.target.value})
        this.setState({additionalPriceError: ""})
    }
    onChangePercantage = event => {
        event.target.value = checkFloat(event.target.value);
        this.setState({restrictedPercantage:event.target.value,restrictedPercantageError:''});
    }
    checkSeat = (num , fieldName) =>{
        return(
             (isNaN(num)) || typeof(num) === "undefined" || num === "" || num < 0 || num ===0 ? 
            fieldName + " should be number" : num)
    } 
    checkValidation = () => {
        const { t } = this.props;
        let formValid = true;
        let updateObj = this.props.data;
        // Set Values Into Sent Object
        updateObj.bookableEntityId = (this.state.bookableEntityId) ? this.state.bookableEntityId : '';
        updateObj.summary = (this.state.summary) ? this.state.summary : '';
        updateObj.description = (this.state.description) ? this.state.description : '';
        updateObj.color = (this.state.color) ? this.state.color : '#dd4d15';
        updateObj.notes = (this.state.notes) ? this.state.notes : '';
        updateObj.locationId = (this.state.locationId) ? this.state.locationId : '';
        updateObj.seat = (this.state.seat) ? this.state.seat : 0;
        updateObj.allDayRoomPrice = (this.state.allDayRoomPrice) ? this.state.allDayRoomPrice : 0;
        updateObj.halfDayRoomPrice = (this.state.halfDayRoomPrice) ? this.state.halfDayRoomPrice : 0;
        updateObj.additionalPrice = (this.state.additionalPrice) ? this.state.additionalPrice : 0;
        updateObj.minBookingTime = (this.state.minBookingTime) ? this.state.minBookingTime : 0;
        updateObj.bufferTime = (this.state.bufferTime) ? this.state.bufferTime : 0;
        updateObj.images = (this.state.uploadImageArray) ? this.state.uploadImageArray : []
        updateObj.layoutsSeats = this.state.layoutsSeats
        if(!this.props.tenantConfig.features.onlyBookHourly){
            updateObj.allDayRoomPrice = this.checkSeat(this.state.allDayRoomPrice , "allDayRoomPrice")
            updateObj.halfDayRoomPrice = this.checkSeat(this.state.halfDayRoomPrice , "halfDayRoomPrice")
        }
        updateObj.additionalPrice = this.checkSeat(this.state.additionalPrice , "additionalPrice")
        updateObj.minBookingTime = this.checkSeat(this.state.minBookingTime , "minBookingTime")
        updateObj.restrictedPercantage = this.state.restrictedPercantage
        updateObj.canBeBookHourly = this.state.canBeBookHourly
        updateObj.allowPublicBooking = this.state.allowPublicBooking
/*         if(updateObj.id === ''){
            if(!updateObj.summary || !updateObj.description){
                this.props.enqueueSnackbar(t('errorProperties.ENTITIES_EDIT_TABLE.RENDER.ON_ROW_ADD.ERROR_OCCURED'), {
                     variant: 'error'
                 });
                 formValid = false
             }
             else if(updateObj.seat === "Seat should be number"){
                 updateObj.seat = 0
             }
        }  */
        if(updateObj.summary === "" || 
            updateObj.description === "" || 
                updateObj.locationId === "" || 
                    updateObj.seat === "Seat should be number" || 
                        updateObj.allDayRoomPrice === "allDayRoomPrice should be number" || 
                            updateObj.halfDayRoomPrice === "halfDayRoomPrice should be number" || 
                                updateObj.additionalPrice === "additionalPrice should be number" || 
                                    updateObj.minBookingTime === "minBookingTime should be number")
        {
            console.log('error');
            this.props.enqueueSnackbar(t('Entities.EntitiesCrudForm.Please Fill'), {
                variant: 'error'
            });
            formValid = false
        }

        this.checkIfEmpty(updateObj); //Checking if any field empty or not for error message
         
        if(formValid){
            this.sendUploadImagesRequest(updateObj)
        }
    }

    checkIfEmpty = (updateObj) => {
        if(updateObj.summary === "") {
            this.setState({summaryError: "Entities.EntitiesCrudForm.Summary Required"})
        }
        if(updateObj.description === "") {
            this.setState({descriptionError: "Entities.EntitiesCrudForm.Description Required"})
        }
        if(updateObj.locationId === "") {
            this.setState({locationError: "Entities.EntitiesCrudForm.Location Required"})
        }
        if(updateObj.allDayRoomPrice === "allDayRoomPrice should be number") {
            this.setState({allDayRoomPriceError: "Entities.EntitiesCrudForm.All Day Error"})
        }
        if(updateObj.halfDayRoomPrice === "halfDayRoomPrice should be number") {
            this.setState({halfDayRoomPriceError: "Entities.EntitiesCrudForm.Half Day Error"})
        }
        if(updateObj.additionalPrice === "additionalPrice should be number") {
            this.setState({additionalPriceError: "Entities.EntitiesCrudForm.Additional Error"})
        }
        if(updateObj.seat === "Seat should be number") {
            this.setState({seatsError: "Entities.EntitiesCrudForm.Seat Error"})
        }
        if(updateObj.minBookingTime === "minBookingTime should be number") {
            this.setState({minBookingTimeError: "Entities.EntitiesCrudForm.Min Booking Error"})
        }
    }

    isNewForTitle = () => {
        if (window.location.href.includes('new')) //this is looking to url if includes string "new"
        {
            this.setState({isNew:true})
        }
    }
    
    getAllLocations = () => {
        HTTP_REQUESTS.LOCATION_SERVICE.GET_ALL(res=> {
            this.setState({allLocations:res})
        },err=>{

        })
    }
    getAllLayouts = () => {
        HTTP_REQUESTS.LAYOUTS_SERVICE.GET_ALL_LAYOUTS(res=> {
            this.setState({layouts:res})
        },err=>{

        })
    }

    handleUploadImageState = (files) => {
        console.log(files)
        this.setState({uploadImageArray: files})
    }

    onClickDropzone = () => {
        this.setState({dropzoneClicked:true})
    }

    decideDialogText = () =>{
        if(this.props.data.room){
            let hasDevice = this.props.data.room.hasDevice //boolean
            let Events =  this.props.Events //array
            if(hasDevice && Events.length > 0){
                return 'Entities.EntitiesCrudForm.Delete Room With Events And Device'
            }
            else if(hasDevice){
                return 'Entities.EntitiesCrudForm.Delete Room With Device'
            }
            else if(Events.length > 0){
                return 'Entities.EntitiesCrudForm.Delete Room With Events'
            }else{
                return 'Entities.EntitiesCrudForm.Delete Room Info'
            }
        }
    }

    sendUploadImagesRequest = (updateObj) =>{
        this.setState({isLoading:true})
        const fd = new FormData();
        let images = this.state.uploadImageArray;
        for(const image of images){
            fd.append('image', image)
        }
        if(this.state.uploadImageArray.length > 0){
            HTTP_REQUESTS.IMAGE_SERVICE.UPLOAD_MULTIPLE_TEMP_IMAGE(fd,success => {
                this.setState({uploadImageArray:success.result},()=>{
                    updateObj.images=success.result;
                    this.props.onSave(updateObj)

                })
            },fail=>{
            this.props.onSave(updateObj)
            });
        }
        else{
            this.props.onSave(updateObj)
        }

    }
    render() {
        const { t } = this.props;
        return this.state.isLoading ? <CustomSpinner /> :
        <Card style={{'padding': '16px'}}>
             <div>
                {
                    this.state.isReadyForRender?
                    <Grid container xs={12} item spacing ={2}>
                         {/* <pre>{JSON.stringify(this.state.layoutsSeats, null, 2) }</pre> */}
                        <Grid item xs={12} lg={6} >
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} align='center'>
                                    {this.state.isNew
                                        ?   <Typography variant="h5">{t('Entities.EntitiesCrudForm.Room New')}</Typography>
                                        :   <Typography variant="h5">{t('Entities.EntitiesCrudForm.Room Edit')}</Typography>
                                    }
                                </Grid>
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12}>
                                        <div onClick={this.onClickDropzone}  id='dropzone'>
                                            <CustomDropzone
                                                maxFiles={5}
                                                setImages={this.handleUploadImageState}>
                                            </CustomDropzone>
                                        </div>
                                    </Grid>
                                </Grid>
                                {this.props.tenantConfig.features.bufferEvents ?
                                    <Grid item container xs={12} spacing={2}>
                                        <Grid item xs={12} >
                                            <FormLabel  id="location-label">{t('Entities.EntitiesCrudForm.Buffer')}</FormLabel>
                                                <Select
                                                fullWidth
                                                labelId="bufferTime-label"
                                                id="bufferTime"
                                                name="bufferTime"
                                                value={this.state.bufferTime}
                                                onChange={this.onChangeBufferTime}
                                                >
                                                {this.state.bufferTimeList.map((time, index)=>(
                                                    <MenuItem value={time} key={index}>{time}</MenuItem>
                                                ))}
                                                </Select>
                                        </Grid>
                                    </Grid>:
                                    null
                                }
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} >
                                        <TextField
                                            name="summary"
                                            fullWidth
                                            id="summary"
                                            label= {t('Entities.EntitiesCrudForm.Summary')}
                                            value={this.state.summary}
                                            required={ this.props.data.summary === '' ? true:false }
                                            onChange={this.onChangeSummary}
                                            error={this.state.summaryError != null && this.state.summaryError.length > 0}
                                            helperText={t('Entities.EntitiesCrudForm.Summary Required')}
                                            inputProps={{ maxLength: 18 }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} >
                                        <TextField
                                            name="description"
                                            fullWidth
                                            id="description"
                                            label={t('Entities.EntitiesCrudForm.Description')}
                                            required={ this.props.data.description === '' ? true:false }
                                            value={this.state.description}
                                            onChange={this.onChangeDescription}
                                            error={this.state.descriptionError != null && this.state.descriptionError.length > 0}
                                            helperText={t('Entities.EntitiesCrudForm.Description Required')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12}  spacing={2}>
                                    <Grid item xs={12} >
                                        <FormLabel  id="location-label">{t('Entities.EntitiesCrudForm.Location')}</FormLabel>
                                        {
                                            this.state.allLocations.length > 0 &&
                                            <Select
                                                fullWidth
                                                labelId="location-label"
                                                id="locationId"
                                                name="locationId"
                                                value={this.state.locationId}
                                                onChange={this.onChangeLocation}
                                                error={this.state.locationError != null && this.state.locationError.length > 0}
                                            >
                                                {this.state.allLocations.map((location, index)=>(
                                                    <MenuItem value={location.id} key={index}>{location.name}</MenuItem>
                                                ))}
                                            </Select>
                                        }

                                            <FormHelperText
                                                error={this.state.locationError != null && this.state.locationError.length > 0}>
                                                {t('Entities.EntitiesCrudForm.Location Required')}
                                            </FormHelperText>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12}  spacing={2}>
                                    {this.props.tenantConfig.features.allDayRoomPrice ?
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                            name="allDayRoomPrice"
                                            fullWidth
                                            id="allDayRoomPrice"
                                            label={t('Entities.EntitiesCrudForm.allDayRoomPrice')}
                                            required
                                            value={this.state.allDayRoomPrice}
                                            onChange={this.onChangeAllDayRoomPrice}
                                            error={this.state.allDayRoomPriceError != null && this.state.allDayRoomPriceError.length > 0}
                                            helperText={t('Entities.EntitiesCrudForm.All Day Error')}
                                            />
                                        </Grid>
                                        :null
                                    }
                                    {this.props.tenantConfig.features.halfDayRoomPrice ?
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                name="halfDayRoomPrice"
                                                fullWidth
                                                id="halfDayRoomPrice"
                                                label={t('Entities.EntitiesCrudForm.halfDayRoomPrice')}
                                                required
                                                value={this.state.halfDayRoomPrice}
                                                onChange={this.onChangeHalfDayRoomPrice}
                                                error={this.state.halfDayRoomPriceError != null && this.state.halfDayRoomPriceError.length > 0}
                                                helperText={t('Entities.EntitiesCrudForm.Half Day Error')}
                                            />
                                         </Grid>:
                                         null
                                    }
                                    {this.props.tenantConfig.features.additionalPrice ?
                                        <Grid item xs={12} lg={4} >
                                            <TextField
                                                name="additionalPrice"
                                                fullWidth
                                                id="additionalPrice"
                                                label={t('Entities.EntitiesCrudForm.additionalPricePerHour')}
                                                required
                                                value={this.state.additionalPrice}
                                                onChange={this.onChangeAdditionalPrice}
                                                error={this.state.additionalPriceError != null && this.state.additionalPriceError.length > 0}
                                                helperText={t('Entities.EntitiesCrudForm.Additional Error')}
                                            />
                                        </Grid>:
                                        null
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                {this.props.tenantConfig.features.onlyBookHourly ? null :

                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.canBeBookHourly} onChange={this.onChangeCanBeBookHourly} name="canBeBookHourly" />}
                                            label={t('Entities.EntitiesCrudForm.Can Be Book Hourly')}
                                        />

                                }
                                </Grid>
                                <Grid item xs={6}>
                                {this.props.tenantConfig.features.allowPublicBooking ?
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.allowPublicBooking} onChange={this.onChangeAllowPublicBooking} name="allowPublicBooking" />}
                                            label={t('Entities.EntitiesCrudForm.Available To Book By Public Users')}
                                        />
                                    : null
                                }
                                </Grid>
                                <Grid item xs={12}   >
                                    <AdvancedSettings
                                        seatCount = {this.state.seat}
                                        restrictedPercantage={this.state.restrictedPercantage}
                                        onChangePercantage={this.onChangePercantage}
                                        onChangeSeatCount = {this.onChangeSeat}
                                        minTime = {this.state.minBookingTime}
                                        onChangeMinTime = {this.onChangeminBookingTime}
                                        minTimeList = {this.state.minBookingTimeList}
                                        hex = {this.state.color}
                                        onColorChange = {this.onColorChange}
                                        seatsError = {this.state.seatsError}
                                        minBookingTimeError = {this.state.minBookingTimeError}
                                        layouts={this.state.layouts}
                                        layoutsSeats={this.state.layoutsSeats}
                                        >
                                    </AdvancedSettings>
                                </Grid>
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item container justifyContent="space-evenly">
                                        <Button variant="contained" onClick={this.onClickCancel}>
                                            {t('Entities.EntitiesCrudForm.Cancel')}
                                        </Button>
                                        {this.state.isNew
                                        ?   null
                                        :    <Button
                                            id='Delete'
                                            variant="contained"
                                            style={theme.palette.danger}
                                            onClick={this.showConfirmModal}
                                            >
                                            {t('Entities.EntitiesCrudForm.Delete')}
                                            </Button>
                                        }
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.checkValidation}
                                            id='Save'
                                        >
                                            {t('Entities.EntitiesCrudForm.Save')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                        !this.props.isNew ?
                        <Grid item xs={12} lg={6} >
                            <BookingsList
                            usereventslist={this.props.Events}
                            />
                        </Grid>:null
                        }
                        {this.state.openConfirmModal?
                        <CustomConfirmDialog
                            forDelete={true}
                            cancel={t('CONFIRM_DIALOG.Cancel')}
                            open={this.state.openConfirmModal}
                            confirm={t('Entities.EntitiesCrudForm.Delete Room')}
                            confirmCB={this.deleteRoom}
                            cancelCB={this.onCloseConfirmModal}
                            header={t('CONFIRM_DIALOG.Header')}
                            text={t(this.decideDialogText)}>
                        </CustomConfirmDialog>
                        :null }
                    </Grid>

                    :null
                }
            </div>
        </Card>;
    }
}

export default  withTranslation()(connect(mapSessionStateToProps)(withStyles(styles)(withSnackbar(withRouter(EntitiesCrudForm)))))


