export default class Users {
  constructor(id,name, overlapLimit) {
    this.id            = id;
    this.name          = name;
    this.overlapLimit  = overlapLimit;
  }

  toUIJSON() {
    return {
      id            : this.id,
      name          : this.name,
      overlapLimit  : this.overlapLimit
    };
  }

  toAPIJSON() {
    throw Error("Not implmeented yet"); //TODO: implement this
  }
}
