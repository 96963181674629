import React from 'react';
import { makeStyles } from '@mui/styles';
import {LocalizationProvider, StaticDatePicker} from '@mui/x-date-pickers';
import 'date-fns';
import moment from "moment";
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import { calendarLanguagesData } from '../../data/languages';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function DatePickers(props) {
  const calendarLanguages = calendarLanguagesData
  const classes = useStyles();
  const { t } = useTranslation();

    const handleDate = (date) =>{
        props.changeDate(date);
    }

  return (
    <Card>
    <LocalizationProvider adapterLocale={calendarLanguages[t('Calendar.MiniCalendar.Language')]} dateAdapter={AdapterDateFns}>
      <Grid container justifyContent='center' >
        <StaticDatePicker
          openTo="day"
          displayStaticWrapperAs="desktop"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={t('Calendar.MiniCalendar.Date Picker')}
          value={moment(props.currentDate).format("YYYY-MM-DD")}
          onChange={handleDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Grid>
    </LocalizationProvider>
    </Card>
  );
}
