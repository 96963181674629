import React, {useEffect, useRef, useState} from 'react';
import { Tooltip } from "@mui/material";

const OverflowTip = ({props}) => {
    const textElementRef = useRef();
    const compareSize = () => {
        const compare =
          textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
        setHover(compare);
    };
    
      // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        compareSize();

        window.addEventListener('resize', compareSize);

        return () => {
            window.removeEventListener('resize', compareSize);
        }
    }, []);
    
      // Define state and function to update the value
    const [hoverStatus, setHover] = useState(false);
    
    return (
        <Tooltip
          title={props}
          disableHoverListener={!hoverStatus}
        >
            <div
                ref={textElementRef}
                style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
                }}
            >
                {props}
            </div>
        </Tooltip>
    );
};
export default OverflowTip;
