import React, { Component } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { withSnackbar } from 'notistack';
import BookingsList from "./BookingsList";
import BookingsTab from "./BookingsTab";
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import CreateBookingPopover from "../Booking/CreateBookingPopover";
import sampleEvents from '../../data/sample-events'
import {dataToEventObj, mapSessionStateToProps} from "../../helpers/dataConverter";
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    marginLeft: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    bookings: [],
    categories: [],
    upcomingEvents: [],
    allEvents: [],
    isCreateButtonClicked: false,
    showModal: false,
    slotData: {
        action: "select",
        bounds: {
            bottom: 558,
            left: 1269,
            right: 1271,
            top: 507,
            x: 1269,
            y: 558
        },
        start: new Date(),
        end: new Date(),
        slots: [],
        box: null,
        resourceId: null,
      },
      selectedEntity: '',
      events: process.env.REACT_APP_LOAD_CALENDAR_WITH_DUMMY_DATA === "true" ? sampleEvents : [],
      isEventCreated : false,
        isLoading: false
    };
  }
  getEvents = () => {
    HTTP_REQUESTS.ENTITY_SERVICE.GET_ONE_USERS_EVENTS(
      this.props.sessionData.id,
      (success) => {
        console.log('suc',success)
        let upcomingEvents = success.filteredEvents
        let allEvents = success.allEvents
        this.setState({ upcomingEvents: upcomingEvents });
        this.setState({ allEvents: allEvents })
      },
      (fail) => {
        //alert(fail);
      }
    );
  }
  componentDidMount() {
    this.getEvents()
  }

  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item lg={9} md={12} xs={12} >
            <BookingsTab
              userEventsList={this.state.allEvents}
              categoriesList={this.state.categories}
              isCreateButtonClickedHandler={this.isCreateButtonClickedHandler}
              isEventCreated={this.state.isEventCreated}
              locations={this.props.locations}
              setLocations={this.props.setLocations}
              selectedDate={this.props.selectedDate}
              setSelectedDate={this.props.setSelectedDate}
              selectedEndTime={this.props.selectedEndTime}
              setSelectedEndTime={this.props.setSelectedEndTime}
              selectedStartTime={this.props.selectedStartTime}
              setSelectedStartTime={this.props.setSelectedStartTime}
              isLoading={this.props.isLoading}
              fetchError={this.props.fetchError}
              tryAgain={this.props.tryAgain}
              handleRoomSelect={this.props.handleRoomSelect}
              selectedRoom={this.props.selectedRoom}
            />
          </Grid>

          <Grid item lg={3} md={12} xs={12}>
            <BookingsList
              userEventsList={this.state.upcomingEvents}
              heading='Upcoming Events'
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(connect(mapSessionStateToProps)(withSnackbar(UserDashboard)));
