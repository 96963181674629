import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface NavigationState {
    isSidebarOpen: boolean;
}

const initialState: NavigationState = {
    isSidebarOpen: true,
}

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        sidebarOpened(state){
            state.isSidebarOpen = true;
        },
        sidebarClosed(state){
            state.isSidebarOpen = false;
        }
    }
})

export const { sidebarOpened, sidebarClosed } = navigationSlice.actions;
export const selectSidebarOpen = (state: RootState) => state.navigation.isSidebarOpen;

export default navigationSlice.reducer;
