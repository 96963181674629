import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import TypographyCard  from '@mui/material/Card';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import NotesTextField from '../../helpers/NotesTextField';
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../helpers/dataConverter";

class PriceInformations extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    
    render() {
        const { allDayRoomPrice, additionalPrice, halfDayRoomPrice, t, type,description } = this.props;
        if(type === 'RoomInformation') {
        return <div>
            <TypographyCard variant={'outlined'}> {t('Entities.EntitiesCrudForm.allDayRoomPrice')} : {allDayRoomPrice} € </TypographyCard>
            <TypographyCard variant={'outlined'}> {t('Entities.EntitiesCrudForm.halfDayRoomPrice')} : {halfDayRoomPrice}€ </TypographyCard>
            <TypographyCard variant={'outlined'}> {t('Entities.EntitiesCrudForm.additionalPricePerHour')} : {additionalPrice} €</TypographyCard>
        </div>
        } else {
            return <Grid item container xs={12}>
                <Grid item xs={12} >
                    <Typography variant="p"> {t('Entities.EntitiesCrudForm.allDayRoomPrice')} : {allDayRoomPrice} € </Typography>
                </Grid>
                <Grid item xs={12} >
                        <Typography variant="p"> {t('Entities.EntitiesCrudForm.halfDayRoomPrice')} : {halfDayRoomPrice}€ </Typography>
                </Grid>
                <Grid item xs={12} >
                    <Typography variant="p"> {t('Entities.EntitiesCrudForm.additionalPricePerHour')} : {additionalPrice} €</Typography>
                </Grid>
                {this.props.tenantConfig.features.showDescriptionInPriceInfo ?
                    <Grid item xs={12}>
                        <Typography variant="p"> {t('Entities.EntitiesCrudForm.Description')}</Typography>
                        <NotesTextField name="notes" disabled={true} value={description} small={true}></NotesTextField>
                    </Grid>
                    : null
                }
            </Grid>
        }
    }
}
export default withTranslation()(connect(mapSessionStateToProps)(PriceInformations))
