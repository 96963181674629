import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';

const BookingActionButtonGroup = props => {
    const { t } = useTranslation();
    const [disableActions, setDisableActions] = React.useState(false);

    const clickHandler = (event) => { 
        setDisableActions(true);
        props.handleSave()
    }

    const calculateDisabled = () => {
        if(!props.formValid) {
            return true;
        }
        if(disableActions) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div style={{ float: "right" }}>
            <Button id='cancel' onClick={props.handleClose} style={theme.palette.danger}>
                {t('Booking.ButtonGroup.Cancel')}
            </Button>
            {
                (props.canBeSaved) && <Button id='save' disabled={calculateDisabled()}  onClick={clickHandler} color="primary">{t('Booking.ButtonGroup.Save')}</Button>
            }
        </div>
    )
}
BookingActionButtonGroup.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired
}

export default BookingActionButtonGroup;
