import React from 'react';
import { withSnackbar } from "notistack"
import { useTranslation } from 'react-i18next';
import { Button, Card, Checkbox, FormLabel, Grid, TextField, Typography } from '@mui/material';
import InvoiceSettings from '../../models/InvoiceSettings';
import emailStrIsValid from '../../helpers/Validation';
import {useNavigate} from "react-router-dom";

const InvoiceSettingsCrudForm = props => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let errorObject = {};
    const [state, setState] = React.useState({
        ...new InvoiceSettings(),
        ...errorObject,
        isReadyForRender:true,
    });
    const hiddenFields = [];
    const requiredFields = [
         'companyName',
         'generalManager',
         'city',
         'state',
         'postalCode',
         'country',
         'taxNumber',
         'invoiceEmail',
         'taxRate'
    ];
    const mailFields = [
        'invoiceEmail'
    ];
    const checkboxFields = [
        'taxIncluded'
    ]
    const numberFields = [
        'taxRate'
    ];
    const numericFields = [
        'postalCode'
    ];
    // Set initial data to form
    React.useEffect(()=>{
        setState( (prevState) =>({...prevState, ...props.data}) );
    },[props.data]);

    let formFields = Object.keys(new InvoiceSettings());
    // Generate error fields
    formFields.forEach(
        (
            (item) => { 
                if (!hiddenFields.includes(item) && requiredFields.includes(item)) {
                    errorObject[`error${item}`] = '';                 
                }
            }
        )
    );
    
    
    const onChangeInput = (event) => {
        event.persist();
        let tempObject;
        let tempValue = event.target.value 
        if(checkboxFields.includes(event.target.name)){
            tempValue = event.target.checked;
        }
        if (numberFields.includes(event.target.name)) {
            tempValue = (parseInt(tempValue) && !isNaN(tempValue)) ? parseInt(tempValue) : '';
        }
        if (numericFields.includes(event.target.name)) {
            tempValue = !isNaN(tempValue) ? tempValue : '';
        }
        tempObject = (requiredFields.includes(event.target.name)) ?
            {[event.target.name]:tempValue, [`error${event.target.name}`]:""} :
            tempObject = {[event.target.name]:tempValue};
            setState( (prevState) => 
            (
                {...prevState, ...tempObject}
            )
        );
    }
    const onClickSave = (settingsData) => {
        props.onSave(settingsData);
    }
    const checkValidations = () => {
        let formValid = true;
        requiredFields.forEach((item) => {
            if(mailFields.includes(item)) {
                if(!state[item] || !(emailStrIsValid(state[item]))) {
                    setState((prevState) => ({...prevState, [`error${item}`]:"Invoice Settings.INVOICE_SETTINGS_FORM.MAIL_ERROR"}));
                }                    
            } else {
                if(!state[item] || state[item] ==="") {
                    formValid=false;
                    setState((prevState) => ({...prevState,  [`error${item}`]:"Invoice Settings.INVOICE_SETTINGS_FORM.REQUIRED_ERROR"})); 
                }   
            }
        })
        if(formValid) {
            let settings = new InvoiceSettings();
            let tempSettings = Object.assign(settings, state);
            (Object.keys(tempSettings)).forEach((item) => {
               if(!Object.keys(new InvoiceSettings()).includes(item)) {
                delete tempSettings[item];
               }
            });
            onClickSave(tempSettings);
        }
    }
    const onClickCancel = () => {
        navigate(`/dashboard`)
    }
    return (
        <Card style={{'padding': '16px'}}>
            <div>
                {
                state.isReadyForRender?
                    <Grid container md={6} xs={12} spacing={2}>
                        {/*JSON.stringify(state)*/}
                        <Grid item xs={12}>
                            <Typography variant="h6">{t('Invoice Settings.Invoice Settings')}</Typography>
                        </Grid>
                        {formFields.map((item) => {
                            const requiredLabel = (requiredFields.includes(item)) ? '(*)' : '';
                            return ((!hiddenFields.includes(item))) ? <Grid item xs={12} xl={4}>
                                {
                                    (checkboxFields.includes(item)) ? <FormLabel component="legend">{t(`Invoice Settings.INVOICE_SETTINGS_FORM.${item}`)}<Checkbox
                                    name={item}
                                    onChange={onChangeInput}
                                    checked={(state[item]) ? true : false}
                                /></FormLabel> :
                                    <TextField
                                        name={item}
                                        fullWidth
                                        id={item}
                                        label={t(`Invoice Settings.INVOICE_SETTINGS_FORM.${item}`) + requiredLabel}
                                        value={state[item] || ""}
                                        error={state[`error${[item]}`] != null && state[`error${[item]}`].length > 0}
                                        helperText={(state[`error${[item]}`] != null && state[`error${[item]}`].length > 0) ? t(state[`error${[item]}`]) : ''}
                                        onChange={onChangeInput}
                                    />
                                }
                            </Grid> : null
                        })}
                        <Grid item xs={12}>
                        <Grid container justifyContent="space-evenly">
                            <Button variant="contained" onClick={onClickCancel}>   
                                {t('Invoice Settings.INVOICE_SETTINGS_FORM.Cancel')}
                            </Button>
                            <Button
                                id={'save'}
                            variant="contained"
                            color="primary"
                            onClick={checkValidations}>   
                                {t('Invoice Settings.INVOICE_SETTINGS_FORM.Save')}
                            </Button>
                        </Grid>
                        </Grid>
                    </Grid> 
                    : null 
                }
            </div>
        </Card>
    );
}
export default withSnackbar(InvoiceSettingsCrudForm);
