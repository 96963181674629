export default class Device { //  TODO change name by Calender
    constructor(
      id, 
      name, 
      accountId, 
      roomId, 
      Account, 
      ) {
      this.id = id;
      this.name = name;
      this.accountId = accountId;
      this.roomId = roomId;
      this.Account = Account;
    }

    static emptyDevice(){
      return new Device(null,null,null,null,null)
  }
    
    toAPIJSON(data){
      let deviceObj = {};
      deviceObj.name = data.Account.name;
      deviceObj.password = data.Account.password;
      deviceObj.email = data.Account.email;
      deviceObj.devices = {};
      deviceObj.devices.roomId = data.roomId;
      deviceObj.devices.name = data.name;
      return deviceObj
    }
  }