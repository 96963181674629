
export default class PaypalCreateOrderObj{
    constructor(paypalDatas,eventIdArray){
        this.paypalDatas    = paypalDatas;
        this.eventIdArray   = eventIdArray;
    }

    toUIJSON(){
        throw Error('Not implmeented yet'); //TODO: implement this
    }

    toAPIJSON(){
        throw Error('Not implmeented yet'); //TODO: implement this
    }

    
}