import CalendarEvent from '../models/CalendarEvent';
import { HTTP_REQUESTS } from '../backendcomm/services/httpRequestService';
import BufferEvent from '../models/BufferEvent';
import moment from 'moment'
import { store } from '../store/store'

export function getEvents(loggedInUserId, entityID,cb,errorCallback,search) {

    let events = [];
    HTTP_REQUESTS.BOOKING_SERVICE.GET_ALL(entityID,search,(res) => {
        res.forEach(function (event) {
            let newCalendarEvent = new CalendarEvent(event.id, event.title,event.owner_id, event.owner_name, new Date(event.start), new Date(event.end), true,event.color,event.status,event.location, event.eventObj.roomId, event.eventObj).toUIJSON(loggedInUserId);
            events.push(newCalendarEvent);
            if(!event.eventObj.state && store.getState().tenantConfig.bufferEvents){
                let bufferTime = event.eventObj.Room.bufferTime
                if(bufferTime > 0 ){
                    let newBufferEvent = new BufferEvent(`${event.id}+buffer`,'Buffer Event',new Date(moment(event.start).subtract(bufferTime,'minutes')),new Date(moment(event.start)),"#353b48").toUIJSON();
                    events.push(newBufferEvent)
                }
            }
        });
        cb(events);

    }, (err) => {
        errorCallback();
        //TODO: implement this
    });

}
