import React from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import {getCateringIcon} from './IconSelecter';
import { TextField } from '@mui/material';
import checkFloat from './checkFloat';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
        maxWidth: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    menuItemWrap: {
        flexWrap:'wrap'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const CateringDropdown = props => {
    const classes = useStyles();
    const [cateringsData, setCateringsData] = React.useState(props.cateringsData);
    const cateringsDataRaw = props.cateringsDataRaw;
    const [cateringsCounts, setCateringsCounts] = React.useState(props.cateringsCounts || {});

    const handleCateringChange = (event) => {
        props.onChange(event)
    };
    const handleCateringChangeCount = (event) => {
        event.target.value = checkFloat(event.target.value);
        setCateringsCounts((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        })) 
        props.onChangeCount(event)
    };

    React.useEffect(() => {
        const filterCateringData = () =>{
            // datayı maple , eğer value arayınde filtrele varsa rawda filtrele deletedAt ine bak , yoksa datayı rawda filtrele deletedAt ine bak
            let newData = ""
            let newDataArray = [];
            for(const oneData of props.cateringsData){
                let snapshotValue = props.value.filter((item)=>item === oneData)[0]
                if(snapshotValue){
                    newData = cateringsDataRaw.filter((item)=>item.name === snapshotValue)[0]
                    if(newData){
                        newDataArray.push(newData.name)
                    }
                }else{
                    newData = cateringsDataRaw.filter((item)=>item.name === oneData && !item.deletedAt)[0]
                    if(newData && newData.isVisible === true){
                        newDataArray.push(newData.name)}
                }
            }
            console.log()
            setCateringsData(newDataArray)
        }

        filterCateringData()
    },[cateringsDataRaw, props.cateringsData, props.value]);


    const isNotAvailable = (value) =>{
        let notAvailable = false
        for(const catering of cateringsDataRaw){
            if(value === catering.name && catering.deletedAt){
                notAvailable = true
            }else if(value === catering.name && catering.isVisible === false){
                notAvailable = true
            }
        }
        return notAvailable
    }

    const isChecked = (value) =>{
       if(props.value) {
        return props.value.findIndex((defaultValue)=>defaultValue === value) > -1
       } else {
           return false;
       }
    }
    const isDisabled = (value) =>{
       if(props.value) {
        return props.value.findIndex((defaultValue)=>defaultValue === value) > -1
       } else {
           return false;
       }
    }

    const getCateringPrice = (name , cateringsDataRaw) => {
        let tempPrice = 0; let tempElement;
        tempElement = cateringsDataRaw.filter((item) => 
            item.name === name
        );
        tempPrice = (tempElement.length > 0) ? tempElement[0].price : 0;
        return tempPrice;
    }
    
    return (
        <FormControl className={classes.formControl}>
            {/* <pre>{JSON.stringify(cateringsCounts, null, 2) }</pre> */}
            {/* <pre>{JSON.stringify(cateringsData, null, 2) }</pre>
            <pre>{JSON.stringify(cateringsDataRaw, null, 2) }</pre> */}
            <Select 
                id="entity_caterings"
                name={props.name}
                value={props.value}
                multiple
                onChange={handleCateringChange}
                style={{ minHeight: '45px' }} /* added to fix icon size.  */
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {selected.map((value, index) => (
                            <div key={`${value}_${index}`} className={classes.chip}>
                                {getCateringIcon(value,cateringsDataRaw)}
                                {" "+value}
                                {" "+getCateringPrice(value, cateringsDataRaw) + "€"}
                            </div>
                        ))}
                    </div>
                )}
                disabled={props.disabled}
                MenuProps={MenuProps}>                                        
                {cateringsData.map((catering,index)=>(
                    <MenuItem key={`${catering}_${index}`} value={catering} className={classes.menuItemWrap} 
                    onKeyDown={e => e.stopPropagation()} disabled={isNotAvailable(catering)}>
                        <Checkbox checked={isChecked(catering)}  />
                        <Chip
                            key={`${catering}_${index}`}
                            icon={getCateringIcon(catering,cateringsDataRaw)}
                            label={catering + " " + getCateringPrice(catering, cateringsDataRaw) + "€"}
                            style={{backgroundColor:'transparent'}}
                        />
                        <TextField
                            key={`count-${catering}_${index}`}
                            onClick={(e)=>{e.stopPropagation();}}
                            margin="dense"
                            id={`count${catering}_${index}`}
                            label={"Count"}
                            type="text"
                            name={`${catering}`}
                            onChange={handleCateringChangeCount}
                            value = {(!isDisabled(catering)) ? 0 : cateringsCounts[catering] || ""}
                            fullWidth
                            disabled={!isDisabled(catering)}
                            inputProps={{'data-type': 'cateringsCount'}}
                        />
                    </MenuItem>
                ))     
                }
            </Select>
        </FormControl>
        
    )
}

export default CateringDropdown;
