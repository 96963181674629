import React from 'react';
import { Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';


const SignUpSuccess = () => {
    const { t } = useTranslation();
    return (
        <div style={{
            position: "fixed",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }}>
            
            <Typography component="h1" variant="h5" align="center"  >
                {t('SignUpSuccess.Congrats')}
            </Typography>
            <Typography component="h1" variant="h5" align='center'>
                {t('SignUpSuccess.Success')}
            </Typography>
            <CheckCircleOutlineIcon style={{ fontSize: 200, color: green[500], width:'100%' }} align='center' />
            <Typography component="h1" variant="h6" align='center'>
                {t('SignUpSuccess.Redirected')}
            </Typography>
            
            
        </div>
    )
}

export default SignUpSuccess;

