import React, {useEffect, useState, useRef} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Grid} from "@mui/material";
import {withSnackbar} from "notistack";
import {HTTP_REQUESTS} from "../../../backendcomm/services/httpRequestService";
import RoomSelect from "../../Booking/RoomSelect";
import EventCreation from "../CreateEvent/EventCreation";
import Delayed from "../../Core/Delayed";
import UserDashboard from "../../Dashboard/UserDashboard";

const PublicDashboard = (props) => {
    const mountedRef = useRef(false)

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedStartTime, setSelectedStartTime] = useState(new Date());
    const [selectedEndTime, setSelectedEndTime] = useState(new Date());

    const [isLoading, setIsLoading] = useState(true);
    const [fetchError, setFetchError] = useState(false);

    const [locations, setLocations] = useState([]);

    const [selectedRoom, setSelectedRoom] = useState({id: '', name: ''});

    const [currentStep, setCurrentStep] = useState('room-select')


    const tryAgain = () => {
        fetchLocations();
    };

    const fetchLocations = () => {
        setIsLoading(true);
        
        // Determine the request type based on the access token presence
        let requestType = localStorage.getItem("accessToken") ? "GET_AVAILABLE_ENTITIES" : "GET_AVAILABLE_ENTITIES_PUBLIC";

        // Call the respective method based on the specified request type
        HTTP_REQUESTS.ENTITY_SERVICE[requestType](selectedStartTime, selectedEndTime ,success => {
            setLocations(Object.values(success));
            setIsLoading(false);
            setFetchError(false);
        }, error => {
            console.log('Error while fetching public locations')
            setFetchError(true);
        })
    }

    useEffect(() => {
        if(mountedRef.current){
            fetchLocations();
        }
    }, [selectedDate, selectedStartTime, selectedEndTime])

    useEffect(() => {
        if(mountedRef.current){
            setCurrentStep('checkout')
        }
        mountedRef.current = true;
    }, [selectedRoom])

    const handleRoomSelect = (roomId) => {
        // Determine the request type based on the access token presence
        const isPublicUser = !localStorage.getItem("accessToken");
        const requestService = isPublicUser ? "ENTITY_SERVICE" : "CALENDAR_SERVICE";
        const requestAction = isPublicUser ? "GET_ONE_PUBLIC_ROOM" : "GET_ONE_CALENDAR";

        HTTP_REQUESTS[requestService][requestAction](roomId, successCb => {
            setSelectedRoom(successCb.room);
            setCurrentStep('checkout')
        }, errorCb => {
            console.log('Error while fetching the room with id: ' + roomId)
        })
    }

    const handleAccordionChange = (step) => {
        setCurrentStep(step);
    }

  return (
      <div>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
              <Accordion expanded={currentStep === 'room-select'} onChange={() => handleAccordionChange('room-select')}>
                  {
                      currentStep !== 'room-select' &&
                      <AccordionSummary>
                          <p>Selected Room: {selectedRoom.name || 'No room selected'}</p>
                      </AccordionSummary>
                  }
                  <AccordionDetails>
                    {
                        props.type === 'public' &&
                        <RoomSelect
                        locations={locations}
                        setLocations={setLocations}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedEndTime={selectedEndTime}
                        setSelectedEndTime={setSelectedEndTime}
                        selectedStartTime={selectedStartTime}
                        selectedRoom={selectedRoom}
                        setSelectedStartTime={setSelectedStartTime}
                        isLoading={isLoading}
                        fetchError={fetchError}
                        tryAgain={tryAgain}
                        handleRoomSelect={handleRoomSelect}
                    />
                    }
                    {
                        props.type === 'user' &&
                        <UserDashboard
                        locations={locations}
                        setLocations={setLocations}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedEndTime={selectedEndTime}
                        setSelectedEndTime={setSelectedEndTime}
                        selectedStartTime={selectedStartTime}
                        setSelectedStartTime={setSelectedStartTime}
                        selectedRoom={selectedRoom}
                        isLoading={isLoading}
                        fetchError={fetchError}
                        tryAgain={tryAgain}
                        handleRoomSelect={handleRoomSelect}
                  />
                    }

                  </AccordionDetails>
              </Accordion>
              {
                  selectedRoom.id !== '' &&
                  <Delayed waitBeforeShow={250}>
                      <Accordion expanded={currentStep === 'checkout'} onChange={() => handleAccordionChange('checkout')}>
                      <AccordionSummary
                        >
                            <div>Public room card</div>
                        </AccordionSummary>
                          <AccordionDetails>
                              <EventCreation
                                  roomData={selectedRoom}
                                  selectedDate={selectedDate}
                                  selectedStart={selectedStartTime}
                                  selectedEnd={selectedEndTime}
                              />
                          </AccordionDetails>
                      </Accordion>
                  </Delayed>
              }

          </Grid>
        </Grid>
      </div>
  );
}
export default withSnackbar(PublicDashboard);
