import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    centered: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: "relative",
    },
}));

const SignUpForm = props => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <div className={classes.centered}>
                <Typography component="h1" variant="h5">
                    {t('SignUpForm.Sign Up')}
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="name"
                                name="name"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label={t('SignUpForm.Name')}
                                autoFocus
                                error={props.nameError && props.nameError.length > 0}
                                helperText={t(props.nameError)}
                                onChange={props.nameChanged}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={t('SignUpForm.Email')}
                                name="email"
                                autoComplete="email"
                                error={props.emailError && props.emailError.length > 0}
                                helperText={t(props.emailError)}
                                onChange={props.emailChanged}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="phoneNo"
                                label={t('SignUpForm.Phone Number')}
                                id="phoneNo"
                                error={props.phoneNoError && props.phoneNoError.length > 0}
                                helperText={t(props.phoneNoError)}
                                onChange={props.phoneNoChanged}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('SignUpForm.Password')}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                error={props.pswError && props.pswError.length > 0}
                                helperText={t(props.pswError)}
                                onChange={props.passwordChanged}
                                InputProps={{  endAdornment:(
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  )}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password2"
                                label={t('SignUpForm.Confirm')}
                                type={showConfirmPassword ? 'text' : 'password'}
                                id="password2"
                                autoComplete="current-password"
                                error={props.pswError_2 && props.pswError_2.length > 0}
                                helperText={t(props.pswError_2)}
                                onChange={props.passwordChanged_2}
                                InputProps={{  endAdornment:(
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownConfirmPassword}
                                        edge="end"
                                      >
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  )}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel className="checkPromotions"
                                              control={<Checkbox value="allowExtraEmails" color="primary"/>}
                                              label={t('SignUpForm.Want Receive')}
                                              name="flag"

                                              onChange={props.flagChanged}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={props.signUpCliked}
                        disabled={props.opInProgress}
                    >
                        {props.opInProgress
                            ? t('SignUpForm.Attempt')
                            : t('SignUpForm.Sign Up')
                        }
                    </Button>
                    <Grid container justifyContent="center">
                        <Grid item alignContent="center">
                            <Link className="cursor-ptr"  variant="body2" onClick={props.onSignInClicked}>
                                {t('SignUpForm.Already Have')}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}
export default SignUpForm;
