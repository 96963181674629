import { Button, Grid, TextField, Typography ,Select, MenuItem, FormControl,} from '@mui/material'
import React, { Component } from 'react'
import FormLabel from '@mui/material/FormLabel';
import withRouter from '../../withRouter';
import { withSnackbar } from 'notistack';
import { Card } from '@mui/material';
import { withTranslation } from 'react-i18next';
import theme from '../../theme';
import { CustomConfirmDialog } from '../CustomConfirmDialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import checkFloat from '../../helpers/checkFloat';
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Chip from '@mui/material/Chip';
import {UsableIcons,THEME_COLOR} from "../../helpers/IconSelecter"
import { withStyles } from '@mui/styles';
library.add(fas)


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const styles = theme  => ({
    swatch: {
      padding: "5px",
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
    square: {
        width: '40px'  ,
        height: '40px',
        minWidth: 'unset',
       
    },
    dropdown: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
    }
    
  });


export class PropertyCrudForm extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            id:"",
            name:"",
            type:"",
            price:0,
            priceType:"",
            icon:"",
            isVisible:false,
            errorName:"",
            errorType:"",
            errorPrice:"",
            errorPriceType:"",
            errorIcon : "",
            openConfirmModal:false,
            isReadyForRender:false,
            isNew:false,
            allTypes:[{name:"Catering",value:"Catering"},{name:"Moderation Equipment",value:"ModerationEquipment"}],
            iconList :Object
            .keys(library.definitions.fas)
            .filter(key => UsableIcons.includes(key) )
            .map(icon => icon)
        }
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.onChangePrice = this.onChangePrice.bind(this);
        this.onChangePriceType = this.onChangePriceType.bind(this);
        this.onChangeIcon = this.onChangeIcon.bind(this);
        this.onChangeIsVisible = this.onChangeIsVisible.bind(this);

        this.checkValidations = this.checkValidations.bind(this);
        this.deleteDevice = this.deleteDevice.bind(this);
    }

    componentDidMount(){
        if(this.props.data)
        {
            this.setState({
                name:this.props.data.name,
                type:this.props.data.type,
                price:this.props.data.price,
                priceType:this.props.data.priceType,
                icon:this.props.data.icon,
                isVisible:this.props.data.isVisible,
                isReadyForRender:true,
                id:this.props.data.id,
            })
        }
        this.isNewForTitle();
    }

    isNewForTitle = () => {
        if (window.location.href.includes('new')) //this is looking to url if includes string "new"
        {
            this.setState({isNew:true})
        }
    }
    // Confirmation Start
    onCloseConfirmModal = () => {
        this.setState({openConfirmModal:false})
    }
    showConfirmModal = () => {
        this.setState({openConfirmModal:true})
    }
    // Confirmation End
    onChangeName(event){
        this.setState({name:event.target.value,errorName:""})
    }  
    
    onChangeType(event){
        this.setState({type:event.target.value,errorType:""})
    }
    
    onChangePrice(event){
        event.target.value = checkFloat(event.target.value);
        this.setState({price:event.target.value,errorPrice:""})
    } 

    onChangePriceType(event){
        this.setState({priceType:event.target.value,errorPriceType:""})
    }
    
    onChangeIcon(event){
        this.setState({icon:event.target.value,errorIcon:""})
    }
    
    onChangeIsVisible(event){
        this.setState({isVisible:event.target.checked})
    } 


    onClickSave(property){
        if(this.props.onSave)
        {
            this.props.onSave(property);
        }
    }

    deleteDevice(){
        if(this.props.onDelete)
        {
            this.props.onDelete();
        }
    }

    onClickCancel = () => {
        const { navigate } = this.props;
        navigate('customize')
    }

    checkNameIsValid(t){
        if(!this.state.name || this.state.name ===""){
            this.setState({errorName:t('errorProperties.CUSTOMIZE_FORM.NAME_CANNOT_BE_EMPTY')});
            return false;
        }else{
            return true
        }
    }

    checkTypeIsValid(t){
        if(!this.state.type || this.state.type ===""){
            this.setState({errorType:t('errorProperties.CUSTOMIZE_FORM.TYPE_CANNOT_BE_EMPTY')});
            return false 
        }else{
            return true
        }
    }

    checkPriceIsValid(t){
        if(!this.state.price || this.state.price === ""){
            this.setState({errorPrice:t('errorProperties.CUSTOMIZE_FORM.PRICE_CANNOT_BE_EMPTY')});
            return false
        }else{
            return true
        }
    }

    checkPriceTypeIsValid(t){
        if(!this.state.priceType || this.state.priceType ===""){
            this.setState({errorPriceType:t('errorProperties.CUSTOMIZE_FORM.PRICE_TYPE_CANNOT_BE_EMPTY')});
            return false
        }else{
            return true
        }
    }

    checkIconIsValid(t){
        if(!this.state.icon || this.state.icon ===""){
            this.setState({errorIcon:t('errorProperties.CUSTOMIZE_FORM.ICON_CANNOT_BE_EMPTY')});
            return false
        }else{
            return true
        }
    }

    checkValidations(){
        const { t } = this.props;
        let formValid = false;
        let isNameValid = this.checkNameIsValid(t);
        let isTypeValid = this.checkTypeIsValid(t);
        let isPriceValid = this.checkPriceIsValid(t);
        let isPriceTypeValid = this.checkPriceTypeIsValid(t)
        let isIconValid = this.checkIconIsValid(t)
        if(isTypeValid && isNameValid && isPriceValid && isPriceTypeValid && isIconValid){
            formValid = true
        }

        if(formValid)
        {
            let propertyObj = {
                id:this.state.id,
                name:this.state.name,
                type:this.state.type,
                price:this.state.price,
                priceType:this.state.priceType,
                icon:this.state.icon,
                isVisible:this.state.isVisible
            }
            this.onClickSave(propertyObj)
        }
        else{
            this.props.enqueueSnackbar(t('errorProperties.USERS_FORM.VALIDATION_NOT_PASSED'), {
                variant: 'error',
            })
        }
    }


    render() {
        const { t } = this.props;
        const { classes } = this.props;
        return (
            <div>
            {
            this.state.isReadyForRender?
            <Card style={{'padding': '16px'}}>
                <Grid container item xs={12} direction="column" spacing={2} >
                    <Grid item xs={12} lg={6} >
                        <Grid container item xs={12} spacing={2}>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="name"
                                        fullWidth
                                        id="name"
                                        label={t('Customize.CustomizeCrudForm.Name')}
                                        value={this.state.name}
                                        error={this.state.errorName != null && this.state.errorName.length > 0}
                                        helperText={(this.state.errorName != null && this.state.errorName.length > 0) ? t(this.state.errorName) : ''}
                                        onChange={this.onChangeName}
                                        required
                                        disabled={!this.state.isNew}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="price"
                                        fullWidth
                                        id="price"
                                        label={t('Customize.CustomizeCrudForm.Price')}
                                        required
                                        value={this.state.price}
                                        error={this.state.errorPrice != null && this.state.errorPrice.length > 0}
                                        helperText={(this.state.errorPrice != null && this.state.errorPrice.length > 0) ? t(this.state.errorPrice) : ''}
                                        onChange={this.onChangePrice}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="priceType"
                                        fullWidth
                                        id="priceType"
                                        label={t('Customize.CustomizeCrudForm.PriceType')}
                                        value={this.state.priceType}
                                        error={this.state.errorPriceType != null && this.state.errorPriceType.length > 0}
                                        helperText={(this.state.errorPriceType != null && this.state.errorPriceType.length > 0) ? t(this.state.errorPriceType): ''}
                                        onChange={this.onChangePriceType}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <FormLabel  id="icon">{t('Customize.CustomizeCrudForm.Icon')}</FormLabel>
                                        <Select
                                                    fullWidth
                                                    labelId="icon"
                                                    id="icon"
                                                    name="icon"
                                                    style={{ minHeight: '45px' }}
                                                    error={this.state.errorIcon != null && this.state.errorIcon.length > 0}
                                                    value={this.state.icon}
                                                    onChange={this.onChangeIcon}
                                                    required
                                                    MenuProps={{ classes: { paper: classes.dropdown } }}
                                                    >
                                                    {this.state.iconList.map((oneIcon, index)=>(
                                                        <MenuItem value={oneIcon} key={index}>
                                                            <Chip
                                                            //key={`${catering}_${index}`}
                                                            icon={
                                                                    <Tooltip placement="top">
                                                                    {/* <SpecificIcon style={{ color: THEME_COLOR }} /> */}
                                                                    <FontAwesomeIcon style={{color:THEME_COLOR}}  icon={oneIcon} size={"2x"} />
                                                                    </Tooltip>
                                                                }
                                                            style={{backgroundColor:'transparent'}}
                                                            />
                                                        </MenuItem>
                                                    ))}
                                        </Select>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} >
                                        <FormLabel  id="type">{t('Customize.CustomizeCrudForm.Type')}</FormLabel>
                                        <Select
                                            fullWidth
                                            labelId="type"
                                            id="type"
                                            name="type"
                                            error={this.state.errorType != null && this.state.errorType.length > 0}
                                            value={this.state.type}
                                            onChange={this.onChangeType}
                                            required
                                            >
                                            {this.state.allTypes.map((oneType, index)=>(
                                                <MenuItem value={oneType.value} key={index}>{oneType.name}</MenuItem>
                                            ))}
                                        </Select>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} >
                                        <FormControlLabel
                                                id={'showProperty'}
                                                control={<Checkbox checked={this.state.isVisible} onChange={this.onChangeIsVisible} name="isVisible" />}
                                                label={t('Customize.CustomizeCrudForm.isVisible')}
                                            />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-evenly">
                                    <Button variant="contained" onClick={this.onClickCancel}>
                                        {t('Customize.CustomizeCrudForm.Cancel')}
                                    </Button>
                                    {this.state.isNew
                                    ? null
                                    :   <Button
                                        variant="contained"
                                        style={theme.palette.danger}
                                        onClick={this.showConfirmModal}
                                        >
                                            {t('Customize.CustomizeCrudForm.Delete')}
                                        </Button>
                                    }
                                    <Button
                                        id={'save'}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.checkValidations}
                                    >
                                        {t('Customize.CustomizeCrudForm.Save')}
                                    </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    {this.state.openConfirmModal?
                        <CustomConfirmDialog
                            forDelete={true}
                            cancel={t('CONFIRM_DIALOG.Cancel')}
                            open={this.state.openConfirmModal}
                            confirm={t('Customize.CustomizeCrudForm.DeleteProperty')}
                            confirmCB={this.deleteDevice}
                            cancelCB={this.onCloseConfirmModal}
                            header={t('CONFIRM_DIALOG.Header')}
                            text={t('Customize.CustomizeCrudForm.DeletePropertyInfo')}>
                        </CustomConfirmDialog>
                    :null }
                </Grid>
            </Card>
            :null
            }
            </div>
        );
    }
}

export default withTranslation()(withSnackbar(withStyles(styles)(withRouter(PropertyCrudForm))));
