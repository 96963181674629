import React from "react";
import { TextField,Button,Grid, Typography} from "@mui/material";
import { makeStyles } from '@mui/styles'
import UserMailList from './UserMailList';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme=>({
  form:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position:"relative",
    justify:"center",
    alignContent:"center",
    maxHeight:"50vh",
    marginTop:theme.spacing(4)
  },
  others:{
    justify:"center",
    alignItems:"center",
    alignContent:"center",
    textAlign:"center",
    position:"relative",
    marginTop:theme.spacing(4)
  }
}));
const AddItem = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {showHelperText} = props;
  return (
    <div style={{marginTop: '16px'}}>
      {showHelperText ? 
      <div>
        <Typography component="h1" variant="h5" style={{marginBottom:20}}>
          {t('AddUsersForm.Add Users')}
        </Typography>
        <Typography component="h1" variant="body2" style={{marginBottom:20}}>
          {t('AddUsersForm.Invite Users')}
        </Typography>
      </div> : null}
      <div className={classes.others}>
      <Grid container spacing={6} justifyContent="center" alignItems="center" >
          <Grid item >
          <TextField
        autoComplete="email"
        name="email"
        variant="outlined"
        id="email"
        autoFocus
        label={t('AddUsersForm.Email')}
        value={props.mailInput}
        onChange = {props.onChangeUsers}
        error={props.restoreMessage}
        helperText={(props.restoreMessage ? t('Dashboard.BulkUserInviteForm.RestoreMessage') :'')}
          />
      </Grid>
      </Grid>
      <Button aria-label="add"
            color="primary"
            style = {({color:props.validMail===true?'white':'#43A047'}, {backgroundColor:props.validMail===true?'#43A047':'white', marginTop: 10})}
            type="button"
            variant="contained"
            size="small"
            onClick={props.onAddUser}
            disabled={!props.validMail}>
          {props.restoreMessage ? t("Dashboard.BulkUserInviteForm.Restore") : t('AddUsersForm.New User')}
      </Button>
        </div>
        <div className={classes.form}>
      <UserMailList userList={props.userList} deletable={true} deleteUser={props.deleteUser}></UserMailList>
      </div>
    </div>
  );
};
export default AddItem;
