import DateFormatter from "./DateFormatter";
import mobileCheck from "./isMobile";
import {getUserFeatures} from "../guards/RoleFeatures";
import {store} from "../store/store";

export function generateOldPriceObjectFrom(priceObj){
    let additionalPriceItems=[]
    let caterings = priceObj.additionalOptions.items.caterings.items
    let moderationEquipment = priceObj.additionalOptions.items.caterings.items
    additionalPriceItems = caterings.concat(moderationEquipment)
    console.log('addOns', additionalPriceItems)
}
export function calculatePriceDTOFrom(additionalFeatures,eventDates){
    return {
        startDate: eventDates.getStartTime(),
        endDate: eventDates.getEndTime(),
        caterings: additionalFeatures.caterings,
        moderationEquipment: additionalFeatures.moderationEquipment,
        cateringsCount: additionalFeatures.cateringsCount || {}
    }
}
export function generateAddOnsBreakdownForPriceInfoFrom(addOnsItems){
    let addOnsBreakdown = {
        catering: {
            totalPrice:0,
            breakdown: {},
        },
        moderationEquipment:{
            totalPrice:0,
            breakdown: {}
        }
    }
    if(addOnsItems.caterings.items.length>0){
        addOnsBreakdown.catering.totalPrice = addOnsItems.caterings.totalPrice
        addOnsItems.caterings.items.forEach((item)=>{
            addOnsBreakdown.catering.breakdown[item.name] = {
                    currency: 'EUR',
                    amount: item.amount,
                    name: item.name,
                    price: item.total,
                    unitPrice: item.price,
                    unit:''
            }
        })
    }
    if(addOnsItems.moderationEquipments.items.length>0){
        addOnsBreakdown.moderationEquipment.totalPrice = addOnsItems.moderationEquipments.totalPrice
        addOnsItems.moderationEquipments.items.forEach((item)=>{
            addOnsBreakdown.moderationEquipment.breakdown[item.name]={
                currency: 'EUR',
                amount: 1,
                name: item.name,
                price: item.price,
                unitPrice: item.price,
                unit:''
            }
        })
    }
    return addOnsBreakdown
}
export function generatePriceInfoFrom(priceObj) {
    let priceInfo = {
        totalPrice: priceObj.total,
        breakdown: {
            duration: {
                currency:'EUR',
                price: priceObj.duration,
                name:'duration'
            },
            addOns:{
                totalPrice: priceObj.additionalOptions.totalPrice,
                breakdown: {}
            }
        },
    }
    priceInfo.breakdown.addOns.breakdown = generateAddOnsBreakdownForPriceInfoFrom(priceObj.additionalOptions.items)
    return priceInfo
}
export function eventDTOFrom(additionalFeatures,eventDates,title,roomId,priceObj,seat,participantCount, email){
    let eventObj = {
        roomIds : [roomId],
        startDate : eventDates.getStartTime(),
        endDate :  eventDates.getEndTime(),
        eventInfo: {
            title : title,
            status : 'reserved', // TODO-eventStatus
            note: additionalFeatures.notes,
            ownerId: '',
            welcomeMessage: 'welcome message',
            confirmMail: email || '',
            roomUtilizationInfo: {
                catering : additionalFeatures.caterings,
                cateringsCount: additionalFeatures.cateringsCount || {},
                moderationEquipment : additionalFeatures.moderationEquipment,
                tableLayoutId: additionalFeatures.tableLayout.id,
                seat: seat,
                participantCount: String(participantCount),
            }
        },
        priceObj:[priceObj],
        paymentInfo: {
            billingAddress: 'some billing address',
            customerName: 'customer name',
            priceInfo: generatePriceInfoFrom(priceObj),
        },
    };
    return eventObj
}

export function dataToEventObj(data){
    let eventObj = {
        startDate : DateFormatter.combineDates(data.selectedDate, data.selectedStart),
        endDate : DateFormatter.combineDates(data.selectedDate, data.selectedEnd),
        eventInfo : {
            title : data.title,
            status : data.bookingStatus,
            note : data.additionalFeatures.notes,
            ownerId : data.bookingHolder,
            welcomeMessage : data.welcomeMessage,
            confirmMail : data.sendConfirmationTo,
            roomUtilizationInfo : {
                catering : data.additionalFeatures.caterings,
                cateringsCount : data.additionalFeatures.cateringsCount || {},
                moderationEquipment : data.additionalFeatures.moderationEquipment,
                tableLayoutId : data.additionalFeatures.tableLayout.id,
                seat : data.additionalFeatures.seats,
                participantCount : handleParticipantCount(),
            }
        },
        priceObj : data.createdEventSnapshot.priceObj || {},
        paymentInfo : {
            billingAddress : data.billingAddress,
            customerName : data.customerName,
            priceInfo : data.createdEventSnapshot.priceInfo,

        }
    };
    function handleParticipantCount(){
        if(store.getState().tenantConfig.features.participantCount){
            return data.participantCount;
        }else{
            return "0";
        }
    }
    return eventObj
}

export function contactCustomerToParticipants (contactCustomer){
    let tempParticipiants = [];
    for (const elementItem of contactCustomer) {
        tempParticipiants.push(elementItem.id);
    }
    return tempParticipiants;
}

export function sessionStateFrom(data){
    return {
        auth_token: data.auth_token,
        isSubscribed: data.isSubscribed,
        name: data.name,
        phoneNo: data.phoneNo,
        profilePic: data.profilePic,
        allowBooked: data.allowBooked,
        status: data.tenants[0].status,
        role: data.tenants[0].role,
        tenantEndHour: data.tenants[0].tenantEndHour,
        tenantStartHour: data.tenants[0].tenantStartHour,
        id: data._id,
        isMobile: mobileCheck(),
        isLoading: false,
        tenantId: data.tenants[0].tenant,
        tenantName: data.tenants[0].name,
        username: '',
        email: data.email,
        welcomeMessageRequest: true,
        userFeatures: getUserFeatures(data.tenants[0].role),
    };
}

export function mapSessionStateToProps(state) {
    return {
        sessionData: state.sessionData,
        tenantConfig: state.tenantConfig
    }
}

export function tenantConfigFrom(rawData) {
    const data = rawData['configJson']
    return {
        loading: false,
        adminAccountEmail: data.adminAccountEmail,
        adminAccountName: data.adminAccountName,
        adminAccountPw: data.adminAccountPw,
        allDayThreshold: data.allDayThreshold,
        companyName: data.companyName,
        defaultLocation: data.defaultLocation,
        domainName: data.domainName,
        features: {
            addSelectedPropertiesToRoom: data.features.addSelectedPropertiesToRoom,
            allowPublicBooking: data.features.allowPublicBooking,
            allowReservationOverlap: data.features.allowReservationOverlap,
            calculateHourWithCeil: data.features.calculateHourWithCeil,
            calculateMinPrice: data.features.calculateMinPrice,
            calculateOutsidePrice: data.features.calculateOutsidePrice,
            participantCount: data.features.participantcount,
            payment: data.features.payment,
            seeAllEventDetails: data.features.seeAllEventDetails,
            userAllowBooked: data.features.userAllowBooked,
            multipleRoomBooking: true,
            additionalOptions: true,
            customization: true,
            additionalOptionPrice: true,
            showOutsideOfWorkHoursPrice: true,
            register: true,
            hasLobby: true,
            device: true,
            bufferEvents: true,
            subscription: true,
            roomDetailIcon: true,
            allDayRoomPrice: true,
            halfDayRoomPrice: true,
            additionalPrice: true,
            onlyBookHourly: true,
            useEventSearchBar: true,
            showDescriptionInPriceInfo: true,
            roomPictureSlider: true,
        },
        timeZone: data.timeZone,
        workingEndHour: data.workingEndHour,
        workingStartHour: data.workingStartHour,
        logo: '/images/companyLogoIotiq.png',
        loginLeftImage: '/images/loginLeftSideOld.jpg',
        providers: [
            {
                name: "google",
                icon: "../../images/google-icon.png",
                btnTitle: "RegisterEntityCard.Google"
            },
            {
                name: "outlook",
                icon: "../../images/outlook-icon.png",
                btnTitle: "RegisterEntityCard.Outlook"
            }
        ]
    }
}
