import {Button, Checkbox, Grid, TextField, Typography} from '@mui/material'
import React, { Component } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import withRouter from '../../withRouter';
import { withSnackbar } from 'notistack';
import { Card } from '@mui/material';
import { withTranslation } from 'react-i18next';
import theme from '../../theme';
import { CustomConfirmDialog } from '../CustomConfirmDialog';
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../helpers/dataConverter";

export class UserCrudForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            userName:"",
            email:"",
            role:"user",
            isActive:false,
            isReadyForRender:false,
            errorUserName:"",
            errorEmail:"",
            openConfirmModal:false,
            allowBooked :false
        }
        this.onChangeUserName = this.onChangeUserName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.handleRoleChanged = this.handleRoleChanged.bind(this);
        this.checkValidations = this.checkValidations.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentDidMount(){
        if(this.props.user)
        {
            this.setState({userName:this.props.user.name,role:this.props.user.role,email:this.props.user.email,isReadyForRender:true})
            if (this.props.user.allowBooked!==0)
                this.setState({allowBooked : this.props.user.allowBooked})
        }
    }
    // Confirmation Start
    onCloseConfirmModal = () => {
        this.setState({openConfirmModal:false})
    }
    showConfirmModal = () => {
        this.setState({openConfirmModal:true})
    }
    // Confirmation End
    onChangeUserName(event){
        this.setState({userName:event.target.value,errorUserName:""})
    }

    onChangeEmail(event){
        this.setState({email:event.target.value,errorEmail:""})
    }

    handleRoleChanged(event){
        this.setState({role:event.target.value})
    }

     handleAllowBookedChanged = () => {
         this.setState({allowBooked:!this.state.allowBooked})
     }

    onClickSave(user){
        if(this.props.onSave)
        {
            this.props.onSave(user);
        }
    }

    deleteUser = () => {
        if(this.props.onDelete)
        {
            this.props.onDelete();
        }
    }

    onClickCancel = () => {
        const { navigate } = this.props;
        navigate('/users')
    }

    checkValidations(){

        let formValid = true;
        const { t } = this.props;

        if(!this.state.userName || this.state.userName ===""){
            formValid=false;
            this.setState({errorUserName:"errorProperties.USERS_FORM.NAME_CANNOT_BE_EMPTY"});
        }
        if (this.state.userName.length < 2 && this.state.userName.length > 0) {
            this.setState({errorUserName:"errorProperties.USERS_FORM.SET_NAME_ERROR_LENGTH"});
            formValid = false
        }


        if(formValid)
        {
            let user = {
                name:this.state.userName,
                role:this.state.role,
            }
            if (this.props.tenantConfig.features.userAllowBooked){
                user.allowBooked=this.state.allowBooked
            }
            this.onClickSave(user)
        }
        else{
            this.props.enqueueSnackbar(t('errorProperties.USERS_FORM.VALIDATION_NOT_PASSED'), {
                variant: 'error',
            })
        }
    }


    render() {
        const { t } = this.props;
        return (
            <div>
            {
            this.state.isReadyForRender?
            <Card style={{'padding': '16px'}}>
                <Grid container direction="column" spacing={2} >
                    <Grid item xs={2}>
                        {this.state.userName ? <Typography variant="h6">{this.state.userName}</Typography> : null}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="userName"
                            fullWidth
                            id="userName"
                            label={t('UserCrudForm.Username')}
                            value={this.state.userName}
                            error={this.state.errorUserName != null && this.state.errorUserName.length > 0}
                            helperText={(this.state.errorUserName != null && this.state.errorUserName.length > 0) ? t(this.state.errorUserName) : ''}
                            onChange={this.onChangeUserName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="email"
                            fullWidth
                            id="email"
                            label={t('UserCrudForm.Email')}
                            disabled={true}
                            value={this.state.email}
                            error={this.state.errorEmail != null && this.state.errorEmail.length > 0}
                            helperText={this.state.errorEmail}
                            onChange={this.onChangeEmail}
                        />
                    </Grid>
                    {this.props.isMe === true ? null:<Grid item xs={6}>
                        <FormControl component="fieldset" style={{display: 'flex', flexDirection: 'row'}}>
                            <FormLabel component="legend">{t('UserCrudForm.Role')}</FormLabel>
                                <RadioGroup row={true} aria-label="role" name="role" value={this.state.role} onChange={this.handleRoleChanged}>
                                    <FormControlLabel value="admin" control={<Radio />} label={t('UserCrudForm.Admin')} />
                                    <FormControlLabel value="user" control={<Radio />} label={t('UserCrudForm.User')} />
                                </RadioGroup>
                            {this.props.tenantConfig.features.userAllowBooked ?
                                <FormControlLabel  checked={this.state.allowBooked} onChange={this.handleAllowBookedChanged} style={{marginLeft:'25px'}} control={<Checkbox id={'allowBookedEvents'}  />} label={t('UserCrudForm.allowBookedEvents')} />
                                : null}
                        </FormControl>
                    </Grid>}

                    <Grid item xs={6}>
                        <Grid container justifyContent="space-evenly">
                        <Button variant="contained" onClick={this.onClickCancel}>
                            {t('UserCrudForm.Cancel')}
                        </Button>
                        {this.props.isMe === true ? null:<Button
                            variant="contained"
                            style={theme.palette.danger}
                            onClick={this.showConfirmModal}
                        >
                            {t('UserCrudForm.Delete')}
                        </Button>}


                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.checkValidations}
                        >
                            {t('UserCrudForm.Save')}
                        </Button>
                        </Grid>
                    </Grid>
                    {this.state.openConfirmModal?
                        <CustomConfirmDialog
                            forDelete={true}
                            cancel={t('CONFIRM_DIALOG.Cancel')}
                            open={this.state.openConfirmModal}
                            confirm={t('UserCrudForm.Delete')}
                            confirmCB={this.deleteUser}
                            cancelCB={this.onCloseConfirmModal}
                            header={t('CONFIRM_DIALOG.Header')}
                            text={t('UserCrudForm.Information')}>
                        </CustomConfirmDialog>
                    :null }
                </Grid>
            </Card>
            :null
            }

            </div>
        );
    }
}

export default withTranslation()(withSnackbar(connect(mapSessionStateToProps)(withRouter(UserCrudForm))))
