import React,{useState,useEffect} from 'react'
import { PayPalButtons } from '@paypal/react-paypal-js';
import { withSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import {useAppSelector} from "../../store/hooks";
import {selectSessionData} from "../../store/features/sessionSlice";

const PaypalCheckoutButton = (props) =>{
    const { t } = useTranslation();
    const {newOrderObj} = props;
    const [error , setError] = useState(false)

    const sessionData = useAppSelector(selectSessionData)

    useEffect(()=>{
        if(error){
            props.enqueueSnackbar(t(`${error}`), {
                variant: 'error'
            });
            setError(false)
        }
    },[error])


    const createOrderToApi = () => {
         return new Promise((resolve,reject) => {
            HTTP_REQUESTS.PAYMENT_SERVICE.CREATE_ORDER(sessionData.tenantId,newOrderObj,(res) =>{
                resolve(res)
            }, (err)=>{
                resolve(err.data)
                if(err.data && err.data.error.code){
                    const errorCode = err.data.error.code
                    props.enqueueSnackbar(t(`Payments.PaypalCheckoutButton.Errors.Code.${errorCode}`), {
                        variant: 'error'
                    });                
                }else{
                    props.enqueueSnackbar(t('Payments.PaypalCheckoutButton.Errors.Default'), {
                        variant: 'error'
                    });
                }
            })
        })
    }
    return (
        // Customize Button  https://developer.paypal.com/docs/checkout/standard/customize/buttons-style-guide/
        // For other props https://paypal.github.io/react-paypal-js/?path=/story/example-paypalbuttons--default
        // For charge the customer in a different currency than defined in initial options, add it in the amount object
        <PayPalButtons 
        style={{
            color:"blue",
            layout:"horizontal",
            label:"pay",
            shape:"pill",
            height:35
        }}
        onClick={(data,actions)=>{
            //Validation for client or server side
            if(newOrderObj && newOrderObj.eventIdArray.length > 0 && newOrderObj.paypalDatas.currency && newOrderObj.paypalDatas.description){
                return actions.resolve()
            }else{
                setError("Payments.PaypalCheckoutButton.Errors.Validation")
                return actions.reject()
            }
        }}
        createOrder={(data,actions)=>{
            return createOrderToApi().then((createdOrder)=>{
                if(createdOrder.error){
                    return null
                }else{
                    return createdOrder.id
                }
            })
        }}
        onApprove={(data,actions)=>{
            props.handleApprove(data.orderID)
        }}
        onCancel={()=>{
            props.enqueueSnackbar(t("Payments.PaypalCheckoutButton.Errors.Cancelled"), {
                variant: 'error'
            });
        }}
        /*onError={(err)=>{
            // no need if we dont return errors from paypal to customer
            //setError(err);
            console.log("Paypal error : ",err)
        }}*/
    />
    );
}

export default withSnackbar(PaypalCheckoutButton)
