import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
const Languages = ['de-DE','en-US'];
const currentLang = localStorage.getItem('language');

i18n
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)

  .init({
    lng: currentLang,
    debug: true,
    fallbackLng: "de-DE",
    react: { 
      useSuspense: true
    },
    whitelist:Languages,
    interpolation: {
      escapeValue: false,
    },
    returnedObjectHandler(key, value, options) {
        console.log(key)
        console.log(value)
        console.log(options)
    }
  }, () => console.log('i18n loaded')).catch(err => console.log("i18n error", err));


export default i18n;
