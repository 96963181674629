import React, { useEffect } from "react";
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import CustomTable from "../../components/Core/CustomTable/CustomTable";
import {
  ActionButton,
  ActionButtonTypes,
  ActionButtonWrapper,
  ActionTypes,
} from "../../components/Core/CustomTable/TableActionButtons";
import { ColumnType } from "../../components/Core/CustomTable/TableConfig";
import { convertUuidToDisplay } from "../../utils/Converters";
const SuperAdminTenantsPage = () => {
  const [tenants, setTenants] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const generateColumns = () => {
    return [
      {
        id: "actions",
        title: "actions",
        cell: ({ row }) => {
          return ActionButtonWrapper([
            ActionButton(
              "Edit",
              {
                actionType: ActionTypes.navigate,
                navigateTo: `${row.original.id}`,
              },
              ActionButtonTypes.edit
            ),
            ActionButton(
              "Delete",
              {
                actionType: ActionTypes.custom,
                action: () => {
                  console.log("delete", row.original.id);
                },
              },
              ActionButtonTypes.delete
            ),
          ]);
        },
        type: ColumnType.display,
        size: 100,
      },
      {
        id: "id",
        title: "Tenant ID",
        type: ColumnType.accessor,
        render: (row) => convertUuidToDisplay(row),
      },
      {
        id: "companyName",
        title: "Company Name",
        type: ColumnType.accessor,
      },
      {
        id: "domainName",
        title: "Domain Name",
        type: ColumnType.accessor,
      },
      {
        id: "workingStartHour",
        title: "Start Hour",
        type: ColumnType.accessor,
      },
      {
        id: "workingEndHour",
        title: "End Hour",
        type: ColumnType.accessor,
      },
      {
        id: "config.defaultLocation",
        title: "Default Location",
        type: ColumnType.accessor,
      },
      {
        id: "config.tenantType",
        title: "Tenant Type",
        type: ColumnType.accessor,
      },
      {
        id: "config.timeZone",
        title: "Time Zone",
        type: ColumnType.accessor,
      },
    ];
  };
  useEffect(() => {
    HTTP_REQUESTS.TENANT_SERVICE.GET_ALL_TENANTS(
      (success) => {
        setIsLoading(false);
        setTenants(success);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  }, []);

  return (
    <>
      {isLoading ? (
        <h1>Loading...</h1>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CustomTable
            columns={generateColumns()}
                headerActions={[
                        {
                            iconType: ActionButtonTypes.add,
                            tooltip: 'Add new tenant',
                            clickAction: {actionType: ActionTypes.navigate, navigateTo: '/create-tenant'
                            }
                        }
                    ]}
            data={tenants}
            title="Tenants"
            loading={isLoading}
          />
        </div>
      )}
    </>
  );
};

export default SuperAdminTenantsPage;
