import React from 'react';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { Button, CardActionArea, CardContent, CardMedia, Grid, Typography, CardActions, Card } from '@mui/material';
import withRouter from "../../withRouter";
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { getEntityDetailIconsGroup } from '../../helpers/IconSelecter';
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../helpers/dataConverter";
const styles =  theme  => ({
    root: {
        width:'100%',
        height:400,
        padding: "4px",
        display: "grid",
        gridTemplateRows: "minmax(0, 2fr) minmax(0, 1fr) minmax(0, 0.4fr)",
      },
      pickers: {
        padding: "5px",
      },
      tags: {
        margin: "5px",
        display: "flex",
        justifyContent: "center",
        
      },
      rooms: {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
      },
      card: {
        padding: "10px",
        textAlign: "center"
      },
      CardAction: {
        flexDirection: 'column'
      },
      centeredItem: {
        width: '100%',
        textAlign: 'center'
      },
      secondaryButton: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: "12px",
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        },
        '&:disabled': theme.palette.disabled
        
      },
      hideWithLength: {
        textOverflow:"ellipsis",
        overflow:"hidden",
        whiteSpace:"nowrap"
      },
})
class UserEntities extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isReadyForRender: false,
            entities: [],
            categoryImages: {
                MeetingRoom0: "../../images/meetingRoom.png",
                MeetingRoom1: "../../images/meetingRoom2.png",
                MeetingRoom2: "../../images/meetingRoom3.png",
            }

        }
        
    }
    componentDidMount() {
        HTTP_REQUESTS.CALENDAR_SERVICE.GET_ALL_CALENDARS(
         response => {
            let roomsArray = []
            response.returnArray.map((entity)=>{
                roomsArray.push(entity)
            })
            this.setState({entities: roomsArray},
                () => { 
                    this.setState({isReadyForRender: true})
                })
        },
        err => {
            console.log(err)
        })
    }
    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const { navigate } = this.props;
        return (
            <Card>
            <Grid container className={classes.rooms}>
                <Typography className={classes.centeredItem} gutterBottom variant="h5" component="h5" >
                    {t('Entities.UserEntities.AllRooms')}
                </Typography>
                <br />
                {this.state.entities.map((entity, index) => (
                    <Grid xl={3} lg={3} md={4} sm={6} xs={12}  item key={index} className={classes.card}>
                        <Card
                            className={classes.root}
                            style={{border: '3px solid', borderColor: entity.room.color}}
                        >
                            <CardMedia
                                component="img"
                                alt={entity.room.name}
                                image = {(entity.room.images[0]) ? entity.room.images[0]?.imageUrl : this.state.categoryImages['MeetingRoom0'] }
                                title={entity.room.name}
                            />
                            <CardContent justify="center">
                                <Typography gutterBottom variant="h5" component="h2" className={classes.hideWithLength}>
                                    {entity.room.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    gutterBottom
                                >
                                    {entity.room.Location.name}
                                </Typography>
                                {this.props.tenantConfig.features.roomDetailIcon ?
                                    <Grid item align="center">
                                        {getEntityDetailIconsGroup(entity)}
                                    </Grid>
                                    : null
                                }
                            </CardContent>
                            <CardActions className={classes.CardAction}>
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                    navigate("/calendar/" + entity.room.id);
                                    }}
                                >
                                    {t('Dashboard.MakeBooking.Show Calendar')}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    ))
                }
            </Grid>
            </Card>
        )
    }
}

export default  withTranslation()(withSnackbar(withStyles(styles)(withRouter(connect(mapSessionStateToProps)(UserEntities)))))
