import React from 'react';
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import { withSnackbar } from 'notistack';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Category from '../../models/Category';
import withRouter from '../../withRouter';
import { withTranslation } from 'react-i18next';


class CategoriesEditTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tenantId: "",
      data:[]
    }
  }

  componentDidMount() {
    const { t } = this.props;
    const { navigate } = this.props;
    let url = window.location.href;
    let tenantId = url.substring(url.lastIndexOf('/') + 1);

    if (tenantId != null && tenantId != undefined && tenantId != "") {
      this.setState({ tenantId: tenantId });
    } else {
      navigate("/dashboard");
    }
    let limitedLookup = { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 };

    HTTP_REQUESTS.CATEGORY_SERVICE.GET_ALL(
      tenantId,
      categories => {
        let categoryArray = [];
        categories.forEach(function (category,index) {
            let oneCategory = new Category(category._id, category.name, category.overlapLimit).toUIJSON();
            categoryArray.push(oneCategory);

        });

        this.setState({
          columns: [
            { title: t('Categories.CategoriesEditTable.Name'), field: 'name' , validate: rowData => Boolean(rowData.name)},
            {
              title: t('Categories.CategoriesEditTable.Overlap'),
              field: 'overlapLimit',
              lookup: limitedLookup,
              initialEditValue: 1
            }
          ],
          data: categoryArray
        });
      },
      err => {
        console.log("Error: " + err);
        this.props.enqueueSnackbar(t('errorProperties.CATEGORIES_EDIT_TABLE.COMPONENT_DID_MOUNT'), {
          variant: 'error'
        });
      }
    );

  }

  render() {
    const { t } = this.props;
    return (
        <div>
            categories
        </div>
    );
  }
}

export default withTranslation()(withSnackbar(withRouter(CategoriesEditTable)));
