import Path from "../paths/RequestPaths";
import RequestURLs from "../paths/RequestURLs";

export const REQUEST_PATHS_FOR = {
  BOOKING_SERVICE: {
    GET_ALL: (tenantId, calendarId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_ALL_BOOKINGS(tenantId, calendarId)}`;
    },
    GET_ALL_BY_ACCOUNT_ID: (accountId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_ALL_BOOKINGS_BY_ACCOUNT_ID(accountId)}`;
    },
    GET_ALL_BOOKINGS_ALL_CALENDARS: (tenantId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_ALL_BOOKINGS_ALL_CALENDARS(tenantId)}`;
    },
    UPDATE_A_BOOKING: (bookingID) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.BOOKING_API_POSIX
        }${Path.UPDATE_A_BOOKING(bookingID)}`
      );
    },
    UPDATE_BOOKINGS_FOR_MULTIPLE_ROOMS: (eventGroupID) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.BOOKING_API_POSIX
        }${Path.UPDATE_BOOKINGS_FOR_MULTIPLE_ROOMS(eventGroupID)}`
      );
    },
    GET_A_EVENT: (bookingID) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.BOOKING_API_POSIX
        }${Path.GET_A_EVENT(bookingID)}`
      );
    },
    DELETE_A_BOOKING: (bookingID) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.BOOKING_API_POSIX
        }${Path.DELETE_A_BOOKING(bookingID)}`
      );
    },
    DELETE_BOOKINGS_FOR_MULTIPLE_ROOMS: (eventGroupID) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.BOOKING_API_POSIX
        }${Path.DELETE_BOOKINGS_FOR_MULTIPLE_ROOMS(eventGroupID)}`
      );
    },
    CREATE_A_BOOKING: () => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.BOOKING_API_POSIX
        }${Path.CREATE_A_BOOKING()}`
      );
    },
    GET_ALL_BOOKINGS_FOR_PUBLIC_USER: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.PUBLIC_API_POSIX
      }${Path.GET_ALL_BOOKINGS_ALL_CALENDARS()}`;
    },
    GET_ALL_ENTITIES: (tenantID) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.BOOKING_API_POSIX
        }${Path.GET_ALL_ENTITIES(tenantID)}`
      );
    },
    CALCULATE_PRICE_OF_BOOKING: () => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.BOOKING_API_POSIX
        }${Path.CALCULATE_PRICE_OF_BOOKING()}`
      );
    },
    CALCULATE_PRICE_OF_BOOKING_PUBLIC: (roomID) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.BOOKING_API_POSIX
        }${Path.CALCULATE_PRICE_OF_BOOKING_PUBLIC(roomID)}`
      );
    },
    UPDATE_A_BOOKING_PAYMENT_STATUS: (tenantId, bookingID) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.BOOKING_API_POSIX
        }${Path.UPDATE_A_BOOKING_PAYMENT_STATUS(tenantId, bookingID)}`
      );
    },
    IS_ORDER_MULTIPLE: (tenantId, bookingID) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.BOOKING_API_POSIX
        }${Path.IS_ORDER_MULTIPLE(tenantId, bookingID)}`
      );
    },
  },
  USERS_SERVICE: {
    LOGIN: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_API_POSIX}${Path.LOGIN}`;
    },
    REGISTER: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.AUTH_API_POSIX}${Path.REGISTER}`;
    },
    REGISTER_MANY_USERS: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_API_POSIX}${Path.REGISTER_MANY}`;
    },
    CREATE_A_TENANT: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_TENANT_POSIX}`;
    },
    GET_USERS: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${
        RequestURLs.USERS_API_POSIX
      }`;
    },
    CREATE_ONE_USER: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${
          RequestURLs.USERS_API_POSIX
      }`;
    },
    GET_ONE_TENANT: (tenantID) => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.GET_ONE_TENANT(tenantID)}`;
    },
    GET_ONE_TENANTS_USERS: (tenantID) => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${
        RequestURLs.USERS_API_POSIX
      }${Path.GET_ONE_TENANTS_USERS(tenantID)}`;
    },
    GET_ONE_TENANT_USER: (tenantID, userID) => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${
        RequestURLs.USERS_API_POSIX
      }${Path.GET_ONE_TENANT_USER(tenantID, userID)}`;
    },
    CREATE_ONE_TENANTUSER: (tenantID) => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.CREATE_ONE_TENANTUSER(tenantID)}`;
    },
    UPDATE_ONE_TENANT_USER: (tenantID, userId) => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${
        RequestURLs.USERS_API_POSIX
      }${Path.UPDATE_ONE_TENANT_USER(tenantID, userId)}`;
      },
    UPDATE_PROFILE: () => {
      return String.format(
          `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_API_POSIX}${Path.UPDATE_PROFILE()}`
      );
    },
    DELETE_ONE_USER: (accountId) => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${
        RequestURLs.USERS_API_POSIX
      }${Path.DELETE_ONE_USER(accountId)}`;
    },
    GET_TENANTS_NAMES: () => {
      return String.format(
        `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_TENANT_POSIX}${Path.GET_TENANTS_NAMES}`
      );
    },
    VALIDATE_INVITED_USER: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_API_POSIX}${Path.VALIDATE_INVITED_USER}`;
    },
    UPDATE_ONE_USER: (userId) => {
      return String.format(
        `${RequestURLs.USERS_SERVER_BASE_URL}${
          RequestURLs.USERS_API_POSIX
        }${Path.UPDATE_ONE_USER(userId)}`
      );
    },
    UPDATE_ONE_INVITE_USER: (tenantId, userId) => {
      return String.format(
        `${RequestURLs.USERS_SERVER_BASE_URL}${
          RequestURLs.USERS_API_POSIX
        }${Path.UPDATE_ONE_INVITE_USER(tenantId, userId)}`
      );
    },
    ACTIVATE_INVITATION: () => {
      return String.format(
        `${RequestURLs.USERS_SERVER_BASE_URL}${
          RequestURLs.USERS_API_POSIX
        }${Path.ACTIVATE_INVITATION()}`
      );
    },
    UPDATE_ONE_USER_PHOTO: () => {
      return String.format(
        `${RequestURLs.USERS_SERVER_BASE_URL}${
          RequestURLs.IMAGE_API_POSIX
        }${Path.UPDATE_ONE_USER_PHOTO()}`
      );
    },
    DELETE_ONE_USER_PHOTO: () => {
      return String.format(
        `${RequestURLs.USERS_SERVER_BASE_URL}${
          RequestURLs.IMAGE_API_POSIX
        }${Path.DELETE_ONE_USER_PHOTO()}`
      );
    },
    UPDATE_A_TENANT_USER: (tenantID) => {
      return String.format(
        `${RequestURLs.USERS_SERVER_BASE_URL}${
          RequestURLs.USERS_TENANT_POSIX
        }${Path.UPDATE_A_TENANT_USER(tenantID)}`
      );
    },
    CREATE_NEW_TOKEN: () => {
      return String.format(
        `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_API_POSIX}${Path.CREATE_NEW_TOKEN}`
      );
    },
    CHECK_PSW: (userId) => {
      return String.format(
        `${RequestURLs.USERS_SERVER_BASE_URL}${
          RequestURLs.USERS_API_POSIX
        }${Path.CHECK_PSW(userId)}`
      );
    },
    AUTH_CALL: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.AUTH_API_POSIX}${Path.AUTH_CALL}`;
    },
    LOGOUT: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.AUTH_API_POSIX}${Path.LOGOUT}`;
    },
    GET_ONE_USER: (userId) => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${
        RequestURLs.USERS_API_POSIX
      }${Path.GET_ONE_USER(userId)}`;
    },
    FORGOT_PASSWORD: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_API_POSIX}${Path.FORGOT_PASSWORD}`;
    },
    CREATE_NEW_PASSWORD_TOKEN: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_API_POSIX}${Path.CREATE_NEW_PASSWORD_TOKEN}`;
    },
    VALIDATE_PASSWORD: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_API_POSIX}${Path.VALIDATE_PASSWORD}`;
    },
    CHANGE_PASSWORD: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_API_POSIX}${Path.CHANGE_PASSWORD}`;
    },
    REFRESH: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.USERS_API_POSIX}${Path.REFRESH}`;
    },
  },
  CATEGORY_SERVICE: {
    GET_ALL: (tenantID) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_ALL_CATEGORIES(tenantID)}`;
    },
    CREATE_A_CATEGORY: (tenantID) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.CREATE_A_CATEGORY(tenantID)}`;
    },
    DELETE_A_CATEGORY: (tenantID, categoryId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.DELETE_A_CATEGORY(tenantID, categoryId)}`;
    },
    UPDATE_A_CATEGORY: (tenantID, categoryId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.UPDATE_A_CATEGORY(tenantID, categoryId)}`;
    },
  },
  CALENDAR_SERVICE: {
    GOOGLE_GENEREATE_LINK: (tenantId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GOOGLE_GENEREATE_LINK(tenantId)}`;
    },
    OUTLOOK_GENEREATE_LINK: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.OUTLOOK_GENEREATE_LINK()}`;
    },
    GOOGLE_AUTHORIZE: (tenantID) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GOOGLE_AUTHORIZE(tenantID)}`;
    },
    OUTLOOK_AUTHORIZE: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.OUTLOOK_AUTHORIZE()}`;
    },
    GET_ALL_CALENDARS_WITH_BASIC_INFO: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_ALL_CALENDARS_WITH_BASIC_INFO()}`;
    },
    GET_ALL_CALENDARS: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_ALL_CALENDARS()}`;
    },
    GET_CALENDARS_MINIMAL_DATA: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_CALENDARS_MINIMAL_DATA()}`;
    },
    GET_ALL_CALENDARS_PUBLIC: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.PUBLIC_API_POSIX
      }${Path.GET_ALL_CALENDARS_PUBLIC()}`;
    },
    UPDATE_CALENDAR: (calendarId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.UPDATE_CALENDAR(calendarId)}`;
    },
    DELETE_CALENDAR: (calendarId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.DELETE_CALENDAR(calendarId)}`;
    },
    CREATE_CALENDAR: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.CREATE_CALENDAR()}`;
    },
    GET_ONE_CALENDAR: (calendarId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_ONE_CALENDAR(calendarId)}`;
    },
  },
  ENTITY_SERVICE: {
    ENTITY_CREATE: (tenantID) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.ENTITY_CREATE(tenantID)}`;
    },
    ENTITY_DELETE: (tenantID, entityID) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.ENTITY_DELETE(tenantID, entityID)}`;
    },
    ENTITY_UPDATE: (tenantID, entityID) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.ENTITY_UPDATE(tenantID, entityID)}`;
    },
    GET_ONE_USERS_EVENTS: (userId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_ONE_USERS_EVENTS(userId)}`;
    },
    GET_AVAILABLE_ENTITIES: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_AVAILABLE_ENTITIES()}`;
    },
    GET_AVAILABLE_ENTITIES_PUBLIC: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.PUBLIC_API_POSIX
      }${Path.GET_AVAILABLE_ENTITIES()}`;
    },
    GET_ONE_PUBLIC_ROOM: (roomID) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.PUBLIC_API_POSIX
      }${Path.GET_ONE_PUBLIC_ROOM(roomID)}`;
    },
    GET_ONE_ENTITY: (tenantId, entityID) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_ONE_ENTITY(tenantId, entityID)}`;
    },
    GET_ONE_USERS_OWNED_EVENTS: (userId, tenantId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.GET_ONE_USERS_OWNED_EVENTS(userId, tenantId)}`;
    },
  },
  DEVICE_SERVICE: {
    CREATE: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.BOOKING_API_POSIX}${Path.DEVICE_CREATE}`;
    },
    GET_ALL: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.BOOKING_API_POSIX}${Path.DEVICE_GET_ALL}`;
    },
    GET_ONE_DEVICE: (id) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.DEVICE_GET_ONE(id)}`;
    },
    UPDATE: (id) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.DEVICE_UPDATE(id)}`;
    },
    DELETE_BY_ID: (id) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.DEVICE_DELETE_BY_ID(id)}`;
    },
  },
  DEVICE_CALENDAR_SERVICE: {
    GET: (tenantId, deviceId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.DEVICE_CALENDAR_GET(tenantId, deviceId)}`;
    },
    UPDATE: (tenantId, deviceId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.DEVICE_CALENDAR_UPDATE(tenantId, deviceId)}`;
    },
    GET_EVENTS: (tenantId, deviceId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.DEVICE_CALENDAR_GET_EVENTS(tenantId, deviceId)}`;
    },
    CREATE_EVENT: (tenantId, deviceId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.DEVICE_CALENDAR_CREATE_EVENT(tenantId, deviceId)}`;
    },
    UPDATE_EVENT: (tenantId, deviceId, eventId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.DEVICE_CALENDAR_UPDATE_EVENT(tenantId, deviceId, eventId)}`;
    },
    DELETE_EVENT: (tenantId, deviceId, eventId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.DEVICE_CALENDAR_DELETE_EVENT(tenantId, deviceId, eventId)}`;
    },
  },
  LOCATION_SERVICE: {
    GET_ALL: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.BOOKING_API_POSIX}${Path.GET_ALL_LOCATIONS}`;
    },
    CREATE_A_LOCATION: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.BOOKING_API_POSIX}${Path.CREATE_A_LOCATION}`;
    },
    UPDATE_A_LOCATION: (locationId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.UPDATE_A_LOCATION(locationId)}`;
    },
    DELETE_A_LOCATION: (locationId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.DELETE_A_LOCATION(locationId)}`;
    },
      GET_ALL_LOCATIONS_WITH_ROOMS: () => {
          return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.BOOKING_API_POSIX}${Path.GET_ALL_LOCATIONS_WITH_ROOMS}`;
      },
  },
  LAYOUTS_SERVICE: {
    GET_ALL_LAYOUTS: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.BOOKING_API_POSIX}${Path.GET_ALL_LAYOUTS}`;
    },
  },
  IMAGE_SERVICE: {
    UPLOAD_TEMP_IMAGE: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.IMAGE_API_POSIX}${Path.UPLOAD_TEMP_IMAGE}`;
    },
    UPLOAD_MULTIPLE_TEMP_IMAGE: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.IMAGE_API_POSIX}${Path.UPLOAD_MULTIPLE_TEMP_IMAGE}`;
    },
  },
  PROPERTY_SERVICE: {
    CREATE: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.BOOKING_API_POSIX}${Path.PROPERTY_CREATE}`;
    },
    GET_ALL: () => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${RequestURLs.BOOKING_API_POSIX}${Path.PROPERTY_GET_ALL}`;
    },
    GET_ONE_DEVICE: (propertyId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.PROPERTY_GET_ONE(propertyId)}`;
    },
    UPDATE: (propertyId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.PROPERTY_UPDATE(propertyId)}`;
    },
    DELETE_BY_ID: (propertyId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.BOOKING_API_POSIX
      }${Path.PROPERTY_DELETE_BY_ID(propertyId)}`;
    },
  },
  PAYMENT_SERVICE: {
    CREATE_ORDER: (tenantId) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.USERS_TENANT_POSIX
        }${Path.CREATE_ORDER(tenantId)}`
      );
    },
    CAPTURE_PAYMENT: (tenantId) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.USERS_TENANT_POSIX
        }${Path.CAPTURE_PAYMENT(tenantId)}`
      );
    },
    CREATE_INVOICE: (tenantId) => {
      return String.format(
        `${RequestURLs.BOOKING_SERVER_BASE_URL}${
          RequestURLs.USERS_TENANT_POSIX
        }${Path.CREATE_INVOICE(tenantId)}`
      );
    },
  },
  INVOICE_DATA_SERVICE: {
    GET_INVOICE_DATA: (tenantId) => {
      return `${RequestURLs.BOOKING_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.GET_INVOICE_DATA(tenantId)}`;
    },
  },
  TENANT_SERVICE: {
    GET_TENANT_CONFIG: () => {
      return `${RequestURLs.USERS_SERVER_BASE_URL}${RequestURLs.BOOKING_API_POSIX}${Path.GET_TENANT_CONFIG}`;
    },
    GET_ALL_TENANTS: () => {
      return `${RequestURLs.ADMIN_SERVER_BASE_URL}${RequestURLs.USERS_TENANT_POSIX}`;
    },
    GET_ONE_TENANT: (tenantId) => {
      return `${RequestURLs.ADMIN_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.GET_ONE_TENANT(tenantId)}`;
    },
    CREATE_A_TENANT: () => {
      return `${RequestURLs.ADMIN_SERVER_BASE_URL}${RequestURLs.USERS_TENANT_POSIX}`;
    },
    UPDATE_A_TENANT: (tenantId) => {
      return `${RequestURLs.ADMIN_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.UPDATE_A_TENANT(tenantId)}`;
    },
    DELETE_A_TENANT: (tenantId) => {
      return `${RequestURLs.ADMIN_SERVER_BASE_URL}${
        RequestURLs.USERS_TENANT_POSIX
      }${Path.DELETE_A_TENANT(tenantId)}`;
    },
    GET_ALL_USERS: () => {
      return `${RequestURLs.ADMIN_SERVER_BASE_URL}${RequestURLs.USERS_TENANT_POSIX}${Path.GET_ALL_USERS}`;
    },
  },
};

String.format = function () {
  var s = arguments[0];
  for (var i = 0; i < arguments.length - 1; i++) {
    var reg = new RegExp("\\{" + i + "\\}", "gm");
    s = s.replace(reg, arguments[i + 1]);
  }
  return s;
};
