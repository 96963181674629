import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import { IconButton } from "@mui/material";
import Fade from '@mui/material/Fade';

const UserMailList = (props) => {
  return (
    <div id ="user-mail-list" className="userMailList">
      <List>
        {props.userList.map((element, index) => (
          <ListItem key={`user_${index}`}>
            <ListItemAvatar>
              <Avatar>
                <EmailIcon />
              </Avatar>
            </ListItemAvatar>
            <Fade in={true}>
            <ListItemText
              primary={element.email}
            />
            </Fade>
            {props.deletable ? (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  type="button"
                  onClick={() => props.deleteUser(element.id)}
                  size="large">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        ))}
      </List>
    </div>
  );
};
export default UserMailList;
