import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
export default function Copyright() {
    const { t } = useTranslation();
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {t('Copyright.Copyright')}
            <Link color="inherit" href="https://www.iotiq.de/en/">
                IOTIQ
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
