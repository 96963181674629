import React from "react";
import { makeStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import Slide from "@mui/material/Slide";
import { Card, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import AccountProfileCard from "./AccountProfile";
import { HTTP_REQUESTS } from "../../../backendcomm/services/httpRequestService";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from "../../../theme";
import {useAppSelector} from "../../../store/hooks";
import {selectSessionData, setSession} from "../../../store/features/sessionSlice";
import { useDispatch } from 'react-redux';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  profile: {
    width: 100,
    height: 100,
    position: "inherit",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    margin: "auto",
  },
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(3),
  },
  /*submit: {
    margin: theme.spacing(3, 2, 2, 0),
  },*/
  cancel: {
    backgroundColor: theme.palette.danger.main
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Profile(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const sessionData = useAppSelector(selectSessionData)
  const [nameError, setNameError] = React.useState('');
  const [name, setName] = React.useState(sessionData.name);
  const [fullName, setFullName] = React.useState(name);
  const [phoneNumber, setPhoneNumber] = React.useState(sessionData.phoneNo);
  const [phoneNumberError, setPhoneNumberError] = React.useState('');
  const [avatar, setAvatar] = React.useState(sessionData.profilePic);
  const [image, setImage] = React.useState(null);
  const [email, setEmail] = React.useState(sessionData.email);
  const [emailError, setEmailError] = React.useState('');
  const [psw, setPsw] = React.useState(null);
  const [pswError, setPswError] = React.useState('');
  const [newPsw, setNewPsw] = React.useState(null);
  const [newPswError, setNewPswError] = React.useState('');
  //const [formValid, setFormValid] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const navigate = useNavigate();
  React.useEffect(() => {
  }, [image])

  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
      event.preventDefault();
  };  

  const nameChanged = (event) => {
    setName(event.target.value);
    setNameError(null);
  };

  const phoneNumberChanged = (event) =>{
    event.target.value = checkFloat(event.target.value);
    setPhoneNumber(event.target.value);
    setPhoneNumberError(null);
  }
  const emailChanged = (event) => {
    setEmail(event.target.value);
    setEmailError(null);
  };

  const passwordChanged = (event) => {
    setPsw(event.target.value);
    setPswError(null);
  };

  const newPasswordChanged = (event) => {
    setNewPsw(event.target.value);
    setNewPswError(null);
  };
  const fileUploadHandler = () =>{
    const fd = new FormData();
    if (image) {

      fd.append('image', image, sessionData.id)
      fd.append('name3' , sessionData.id)
      HTTP_REQUESTS.USER_SERVICE.UPDATE_ONE_USER_PHOTO (fd,success => {
        console.log(success)

    },fail=>{
      console.log(fail)
  });}
      else if(avatar === null) {
        let params = {
          id : sessionData.id
        }
        HTTP_REQUESTS.USER_SERVICE.DELETE_ONE_USER_PHOTO (sessionData.id,params,fd,success => {
        console.log(success)

    })}
  }
  const saveClicked = () => {
    let formValid = true
    if (!email || email === "") {
      setEmailError('errorProperties.PROFILE.SAVE_CLICKED.SET_EMAIL_ERROR');
      //setFormValid(false);
      formValid = false
    }
    if (!name || name === "") {
      setNameError('errorProperties.PROFILE.SAVE_CLICKED.SET_NAME_ERROR');
      //setFormValid(false);
      formValid = false
    }
    if (name.length < 2 && name.length > 0) {
      setNameError('errorProperties.PROFILE.SAVE_CLICKED.SET_NAME_ERROR_LENGTH');
      formValid = false
    }
      if (phoneNumber === "" && phoneNumber !== sessionData.phoneNo) {
        setPhoneNumberError('errorProperties.PROFILE.SAVE_CLICKED.SET_PHONE_NUMBER_ERROR');
        formValid = false
      }
    if (!psw || psw === "") {

    } else {
      if (!newPsw || newPsw === "" || newPsw=="null") {
        setNewPswError('errorProperties.PROFILE.SAVE_CLICKED.SET_PSW_ERROR');
        //setFormValid(false);
        formValid = false
      } else if (newPsw.length < 6) {
        setNewPswError('errorProperties.PROFILE.SAVE_CLICKED.SET_PSW_ERROR_LENGTH');
        //setFormValid(false);
        formValid = false
      } else {

      }
    }
    if (formValid) onSaveChanges();
  };

  const onSaveChanges = () => {
    setFullName(name);
      fileUploadHandler()
      if(newPsw==="" || !newPsw || newPsw==="null"){
        let params1 = {
            name: name,
          };
        if(phoneNumber !==''){
          params1.phoneNo= phoneNumber;
        }
          HTTP_REQUESTS.USER_SERVICE.UPDATE_PROFILE(params1,response=>{
            const updatedSessionData = {
              ...sessionData,
              name: params1.name
            };
            dispatch(setSession(updatedSessionData));
            props.enqueueSnackbar(t('Profile.Profile Updated'), { variant: "success" });

        },fail=>{
            props.enqueueSnackbar(t('errorProperties.PROFILE.ON_SAVE_CHANGES.IF_PASSWORD_EMPTY'), { variant: "error" });
        })
      }
      else {
        HTTP_REQUESTS.FIREBASE_SERVICE.LOGIN(email,psw,(data) => {
          if (data?.user?.stsTokenManager?.accessToken) {
            localStorage.setItem('accessToken', data.user.stsTokenManager.accessToken);
            localStorage.setItem('refreshToken', data.user.stsTokenManager.refreshToken);
          }
          HTTP_REQUESTS.FIREBASE_SERVICE.UPDATE_PASSWORD(newPsw,(res) => {
            let params = {
              name: name,
            };
            if (phoneNumber !== '') {
              params.phoneNo = phoneNumber;
            }
            HTTP_REQUESTS.USER_SERVICE.UPDATE_PROFILE(params,response=>{
              const updatedSessionData = {
                ...sessionData,
                name: params.name
              };
              dispatch(setSession(updatedSessionData));
              props.enqueueSnackbar(t('Profile.Profile Updated'), { variant: "success" });
            },error=>{
                props.enqueueSnackbar(t('errorProperties.PROFILE.ON_SAVE_CHANGES.IF_PASSWORD_EMPTY_ELSE'), { variant: "error" });
            })
          },(err) => {
            setPswError('errorProperties.PROFILE.ON_SAVE_CHANGES.SET_PSW_ERROR')
            props.enqueueSnackbar(t('errorProperties.PROFILE.ON_SAVE_CHANGES.SET_PSW_ERROR') ,{ variant: "error" });
          })

        },(err) => {
          setPswError('errorProperties.PROFILE.ON_SAVE_CHANGES.SET_PSW_ERROR');
          props.enqueueSnackbar(t('errorProperties.PROFILE.ON_SAVE_CHANGES.SET_PSW_ERROR') ,{ variant: "error" });
        });
      }


  };

  const checkFloat = (value) => {
    if(isNaN(Number(value))) {
        value = ''
    } else if(value.trim() === '') {
        value = ''
    }
    return value;
}

  return (

    <div>
      <Card>
      <Container component="main" maxWidth="xs">
          <CssBaseline />

          <div className={classes.paper}>

            <form className={classes.form} noValidate >
              <Grid item xs={12}>
                <AccountProfileCard
                  image={image}
                  setImage={setImage}
                  avatar={avatar}
                  setAvatar={setAvatar}
                  name={fullName}
                ></AccountProfileCard>
              </Grid>
              <br></br>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <TextField
                    inputProps={{ autoComplete:'off',form: {autoComplete: 'off'}}}
                    autoComplete="name"
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    value={name}
                    error={nameError != null && nameError.length > 0}
                    helperText={(nameError != null && nameError.length > 0) ? t(nameError):''}
                    onChange={nameChanged}
                    id="name"
                    label={t('Profile.Name')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    type="text"
                    fullWidth
                    required={phoneNumber ==='' && phoneNumber !== sessionData.phoneNo}
                    error={phoneNumberError != null && phoneNumberError.length > 0}
                    helperText={(phoneNumberError != null && phoneNumberError.length > 0) ? t(phoneNumberError):''}
                    onChange={phoneNumberChanged}
                    value={phoneNumber}
                    label={t('Profile.Phone Number')}
                    id='pNumber'
                    name='phoneNumber'
                    inputProps={{ autoComplete:'off',form: {autoComplete: 'off'}}}
                    />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    id="email"
                    onChange={emailChanged}
                    error={emailError != null && emailError.length > 0}
                    helperText={(emailError != null && emailError.length > 0) ? t(emailError):''}
                    value={email}
                    disabled={true}
                    label={t('Profile.Email')}
                    name="email"
                    autoComplete="username"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label={t('Profile.Password')}
                    onChange={passwordChanged}
                    error={pswError != null && pswError.length > 0}
                    helperText={((pswError != null && pswError.length > 0) ? t(newPswError) : '' )||t('errorProperties.PROFILE.RETURN.GRID')}
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    InputProps={{  endAdornment:(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="newPassword"
                    onChange={newPasswordChanged}
                    error={newPswError != null && newPswError.length > 0}
                    helperText={(newPswError != null && newPswError.length > 0) ? t(newPswError) : ''}
                    label={t('Profile.New Password')}
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="newPassword"
                    autoComplete='new-password'
                    InputProps={{  endAdornment:(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )}}
                  />
                </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  style={theme.palette.danger}
                  type="button"
                  fullWidth
                  variant="contained"
                  onClick= {() => {navigate("/dashboard/")}}
                >
                  {t('Profile.Cancel')}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"

                  onClick={saveClicked}
                >
                  {t('Profile.Save')}
                </Button>
              </Grid>

            </Grid>
            </form>
          </div>
        </Container>
      </Card>
    </div>
  );
}
export default withSnackbar(Profile);
