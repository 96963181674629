import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService'
import withRouter from '../../withRouter';
import UserCrudForm from './UserCrudForm'
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../helpers/dataConverter";

export class UserCrudDataHandler extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            user:{},
            userId:"",
            isReady:false,
            tenantId:"",
            isMe: false
        }
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }
    
    componentDidMount(){
        this.getUserId();
        this.setTenantId();
    }
    isMe() {
        if(this.props.sessionData.id === this.state.userId){
            this.setState({isMe:true})
        }
    }
    getUserId(){
        let userId = this.props.params.userId
        this.setState({userId:userId},this.getUserById)
    }

    setTenantId(){
        let tenantId = this.props.sessionData.tenantId;
        if(tenantId)
        {
            this.setState({tenantId:tenantId})
        }
    }
    
    getUserById(){
        this.isMe()
        if(this.state.userId!=='new')
        {
            HTTP_REQUESTS.USER_SERVICE.GET_ONE_USER(this.state.userId,res=>{
                this.setState({user:res.user,isReady:true})
            },error=>{
            })
        }
        else {
            this.setState({isReady:true})
        }

    }

    onSave(user){
        if(this.state.userId!=='new')
        {
            HTTP_REQUESTS.USER_SERVICE.UPDATE_ONE_USER(this.state.userId,user,res=>{
                this.setState({user:res,isReady:true})
                window.history.back();
            },error=>{
            })
           
        }
        else {
            this.setState({isReady:true})
        }
    }

    onDelete(){
        const { t } = this.props;
        HTTP_REQUESTS.USER_SERVICE.DELETE_ONE_USER(this.state.userId,(res)=>{
            this.props.enqueueSnackbar(t('UserCrudDataHandler.User Deleted'), {
                variant: 'success',
            })
            window.location.reload();
        },(error)=>{
            this.props.enqueueSnackbar(t('errorProperties.USERS_FORM.USER_CANNOT_BE_DELETED'), {
                variant: 'error',
            })
       })
    }

    render() {

        return (
            <div>
                {
                this.state.isReady ?
                <UserCrudForm user={this.state.user} onSave={this.onSave} onDelete={this.onDelete} isMe={this.state.isMe} />
                :null
                }
            </div>
        )
    }
}

export default withTranslation()(withSnackbar(withRouter(connect(mapSessionStateToProps)(UserCrudDataHandler))))
