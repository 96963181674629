import {Box} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Search from "@mui/icons-material/Search";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import React from "react";
import DevicesOtherOutlinedIcon from "@mui/icons-material/DevicesOtherOutlined";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import Customize from "@mui/icons-material/CategoryOutlined";
import PaymentsIcon from "@mui/icons-material/Payment";
import SettingsIcon from "@mui/icons-material/Settings";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/styles";
import MuiDrawer from "@mui/material/Drawer";
import {hasFeature} from "../../guards/RoleFeatures";
import EventNoteIcon from "@mui/icons-material/EventNote";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectSessionData} from "../../store/features/sessionSlice";
import {selectSidebarOpen} from "../../store/features/navigationSlice";
import {selectTenantConfig} from "../../store/features/tenantConfigSlice";

const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

export default function Sidebar() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isSidebarOpen = useAppSelector(selectSidebarOpen)
    const tenantConfig = useAppSelector(selectTenantConfig);

    return (
        <div>
            <Drawer
                variant="permanent"
                open={isSidebarOpen}
            >
                <Box
                    sx={{marginTop: '80px'}}
                >
                    {
                        hasFeature('view-dashboard') &&
                        <>
                            <ListItem id='dashboard' button onClick={() => {navigate("/dashboard") }}
                                      sx={ (window.location.href.includes('dashboard')) ? {backgroundColor: "rgba(115, 117, 245, .2)", backdropFilter: 'blur(10px)'} : null }>
                                <ListItemIcon style={{ color: "blue" }}><DashboardOutlinedIcon /> </ListItemIcon>
                                <ListItemText primary={t('Left Menu.Dashboard')} />
                            </ListItem>
                            <Divider />
                        </>
                    }

                    {
                        hasFeature('view-quick-booking') &&
                        <>
                            <ListItem id='quickBooking' button onClick={() => { navigate("/quick-booking"); }}
                                      sx={ (window.location.href.includes('quick-booking')) ? {backgroundColor: "rgba(115, 117, 245, .2)", backdropFilter: 'blur(10px)'} : null }>
                                <ListItemIcon style={{ color: "red" }}><Search /> </ListItemIcon>
                                <ListItemText primary={t('Left Menu.Quick Booking')} />
                            </ListItem>
                            <Divider />
                        </>
                    }

{
                        hasFeature('view-public-booking') && !hasFeature('view-quick-booking') &&
                        <>
                            <ListItem id='publicBooking' button onClick={() => { navigate("/public-booking"); }}
                                      sx={ (window.location.href.includes('public-booking')) ? {backgroundColor: "rgba(115, 117, 245, .2)", backdropFilter: 'blur(10px)'} : null }>
                                <ListItemIcon style={{ color: "red" }}><Search /> </ListItemIcon>
                                <ListItemText primary={t('Left Menu.Public Booking')} />
                            </ListItem>
                            <Divider />
                        </>
                    }

                    {
                        hasFeature('view-rooms') && !hasFeature('view-room-table') &&
                        <>
                            <ListItem id='userAllRooms' button onClick={() => { navigate("/rooms") }}
                                      sx={ (window.location.href.includes('rooms')) ? {backgroundColor: "rgba(115, 117, 245, .2)", backdropFilter: 'blur(10px)'} : null }>
                                <ListItemIcon style={{ color: "orange" }}><HouseOutlinedIcon /> </ListItemIcon>
                                <ListItemText primary={t('Left Menu.Rooms')} />
                            </ListItem>
                            <Divider />
                        </>
                    }

                    {
                        hasFeature('view-room-table') && hasFeature('view-rooms') &&
                        <>
                            <ListItem id='Entities' button onClick={() => { navigate("/entities") }}
                                      sx={ (window.location.href.includes('entities')) ? {backgroundColor: "rgba(115, 117, 245, .2)", backdropFilter: 'blur(10px)'} : null }>
                                <ListItemIcon style={{ color: "orange" }}><HouseOutlinedIcon /> </ListItemIcon>
                                <ListItemText primary={t('Left Menu.Rooms')} />
                            </ListItem>
                            <Divider />
                        </>
                    }

                    {
                        hasFeature('view-location-table') &&
                        <>
                            <ListItem id='Locations' button onClick={() => { navigate("/locations"); }}
                                      sx={ (window.location.href.includes('locations')) ? {backgroundColor: "rgba(115, 117, 245, .2)", backdropFilter: 'blur(10px)'} : null }>
                                <ListItemIcon style={{ color: "green" }}><LocationOnIcon /> </ListItemIcon>
                                <ListItemText primary={t('Left Menu.Locations')} />
                            </ListItem>
                            <Divider />
                        </>
                    }

                    {
                        hasFeature('view-user-table') &&
                        <>
                            <ListItem id='Users' button onClick={() => { navigate("/users"); }}
                                      sx={ (window.location.href.includes('users')) ? {backgroundColor: "rgba(115, 117, 245, .2)", backdropFilter: 'blur(10px)'} : null }>
                                <ListItemIcon style={{ color: "black" }}><PeopleOutlineOutlinedIcon /> </ListItemIcon>
                                <ListItemText id='users' primary={t('Left Menu.Users')} />
                            </ListItem>
                            <Divider />
                        </>

                    }


                    {
                        tenantConfig.features.device && hasFeature('view-device-table') &&
                        <>
                            <ListItem id='devices' button onClick={() => { navigate("/devices"); }}
                                      sx={ (window.location.href.includes('devices')) ? {backgroundColor: "rgba(115, 117, 245, .2)", backdropFilter: 'blur(10px)'} : null  }>
                                <ListItemIcon style={{ color: "magenta" }}><DevicesOtherOutlinedIcon /> </ListItemIcon>
                                <ListItemText primary={t('Left Menu.Devices')} />
                            </ListItem>
                            <Divider />
                        </>
                    }

                    {
                        tenantConfig.features.hasLobby && hasFeature('view-lobby') &&
                        <>
                            <ListItem id='lobby' button onClick={() => { navigate("/lobby" ); }}>
                                <ListItemIcon style={{ color: "aqua" }}><DynamicFeedIcon /> </ListItemIcon>
                                <ListItemText primary={t('Left Menu.Lobby')} />
                            </ListItem>
                            <Divider />
                        </>
                    }

                    {
                        tenantConfig.features.customization && hasFeature('view-customize-table') &&
                            <>
                                <ListItem id='customize' button onClick={() => { navigate("/customize" ); }}
                                          sx={ (window.location.href.includes('customize')) ? {backgroundColor: "rgba(115, 117, 245, .2)", backdropFilter: 'blur(10px)'} : null}>
                                    <ListItemIcon style={{ color: "" }}><Customize /> </ListItemIcon>
                                    <ListItemText primary={t('Left Menu.Customize')} />
                                </ListItem>
                                <Divider />
                            </>
                    }

                    {
                        tenantConfig.features.payment && hasFeature('view-payments-table') &&
                            <>
                                <ListItem id='payments' button onClick={() => { navigate("/payments") }}>
                                    <ListItemIcon style={{ color: "green" }}><PaymentsIcon /> </ListItemIcon>
                                    <ListItemText primary={t('Left Menu.Payments')} />
                                </ListItem>
                                <Divider />
                            </>
                    }

                    {
                        tenantConfig.features.payment && hasFeature('view-invoice-settings') &&
                            <>
                            <ListItem id='invoiceSettings' button onClick={() => { navigate("/invoice-settings") }}>
                                <ListItemIcon style={{ color: "#eb4d4b" }}><SettingsIcon/> </ListItemIcon>
                                <ListItemText primary={t('Left Menu.Invoice Settings')} />
                            </ListItem>
                            <Divider />
                        </>
                    }

                    {
                        hasFeature('view-my-bookings') &&
                        <>
                            <ListItem id='publicUserEvents' button onClick={() => { navigate("/myBookings")}}
                                      sx={ (window.location.href.includes('myBookings')) ? {backgroundColor: "rgba(115, 117, 245, .2)", backdropFilter: 'blur(10px)'} : null }>
                                <ListItemIcon style={{ color: "orange" }}><EventNoteIcon /> </ListItemIcon>
                                <ListItemText primary={'My Bookings'} />
                            </ListItem>
                            <Divider />
                        </>
                    }
                </Box>
            </Drawer>
        </div>
    )
}
