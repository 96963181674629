import { isMoment } from "moment";

export default function roundingTime (newStartDate) {
    if(isMoment(newStartDate)) {
        newStartDate = newStartDate.toDate()
    }
    if(newStartDate.getMinutes() === 0){
      newStartDate.setHours(newStartDate.getHours());
      newStartDate.setMinutes(0);
    }
    else if (newStartDate.getMinutes() > 0 && newStartDate.getMinutes() <= 15){
      newStartDate.setHours(newStartDate.getHours());
      newStartDate.setMinutes(15);
    } 
    else if (newStartDate.getMinutes() > 15 && newStartDate.getMinutes() <= 30){
      newStartDate.setHours(newStartDate.getHours());
      newStartDate.setMinutes(30);
    }else if (newStartDate.getMinutes() > 30 && newStartDate.getMinutes() <= 45){
      newStartDate.setHours(newStartDate.getHours());
      newStartDate.setMinutes(45);
    } else{
      newStartDate.setHours(newStartDate.getHours()+1);
      newStartDate.setMinutes(0);
    }
    newStartDate.setSeconds(0)
    newStartDate.setMilliseconds(0)
    return newStartDate;
};