import React, {useEffect, useRef} from "react";
import {withSnackbar} from "notistack";
import "../../MultiLabelComponent/multi-label.scss";
import PriceInfoCard from "./PriceInfoCard";
import {useNavigate, useParams} from "react-router-dom";
import {HTTP_REQUESTS} from "../../../backendcomm/services/httpRequestService";
import moment from "moment";
import {CardMedia, Grid, Stack, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import EventSeatIcon from '@mui/icons-material/EventSeat';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CustomSpinner from '../../CustomSpinner';
import Box from "@mui/material/Box";
import TableLayoutIcon from "@mui/icons-material/PivotTableChart";
import CateringsIcon from "@mui/icons-material/EmojiFoodBeverage";
import ModerationEquipmentIcon from "@mui/icons-material/CoPresent";
import NotesIcon from "@mui/icons-material/Notes";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import dateFormatter from "../../../helpers/DateFormatter";
import Button from "@mui/material/Button";
const useStyles = makeStyles(() => ({
    root: {
        "& .MuiTypography-h6": {
            fontSize: '24px',
            fontWeight: '600'
        },
        "& .MuiTypography-h4": {
            fontSize: '32px',
            fontWeight: '700'
        },
        "& .MuiTypography-subtitle2": {
            fontSize: '18px',
            fontWeight: '600'
        },
    }
}));
const roomImagePlaceHolder = {
    MeetingRoom0: "../../images/meetingRoom.png",
}
const CreateEventForm = () => {
    const params = useParams()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const classes = useStyles()
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [myEvent,setMyEvent] = React.useState({})
    const [room,setRoom] = React.useState({})
    const [priceObj,setPriceObj] = React.useState({})
    let roomImages = []

    const [dividerLength, setDividerLength] = React.useState(0);
    const [dividerMargin, setDividerMargin] = React.useState(0);
    const width = useRef(null)
    const height = useRef(null)
    useEffect(() => {
        if(isLoaded){
            if(width.current){
                setDividerMargin(width.current.clientWidth);
            }
            if(height.current){
                setDividerLength(height.current.clientHeight);
            }
        }
    }, [isLoaded]);


    useEffect(() => {
        function handleWindowResize() {
            setDividerMargin(width.current.clientWidth);
            setDividerLength(height.current.clientHeight);
        }
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    React.useEffect(() => {
        if(!isLoaded){
            getEvent()
        }
    }, [])
    const getEvent = () => {
        HTTP_REQUESTS.BOOKING_SERVICE.GET_A_EVENT(params.eventId, (res) => {
            setMyEvent(res.eventObj.createdEventSnapshot)
            setRoom(res.eventObj.room)
            roomImages = JSON.parse(res.eventObj.room.images)
            setPriceObj(res.eventObj.createdEventSnapshot.priceObj)
            setIsLoaded(true)
        }, (err) => {
            console.error(err);
    })}

    const checkoutForm = () => { return (
        <Grid xs={12} item container spacing={4} className={classes.root} ref={height}>
            <Grid xs={12} item container sx={{position:'relative'}}>
                {/*<Divider color='#FC2190' orientation="vertical" role="presentation" sx={{width:'8px', borderRadius:'5px',position:'absolute',height:dividerLength-32,marginLeft:`${dividerMargin+12}px`}}/>*/}
                <Grid xs item container>
                    <Grid item xs={4} ref={width}>
                        <CardMedia
                            component="img"
                            style={{height:'213px',width:'100%',borderRadius:'16px'}}
                            image = {roomImages[0] ? roomImages[0] : roomImagePlaceHolder['MeetingRoom0']}/>
                    </Grid>
                    <Grid item container direction="column" justifyContent="flex-start" xs={8} spacing={1}>
                        <Grid item>
                            <Box  sx={{ display: 'flex', alignItems: 'center', height:'fit-content'}} style={{marginLeft:'32px', width:'100%'}}>
                                {/* icon and calendar input */}
                                <Typography variant={"h4"} style={{color:'#003EB0'}}>{dateFormatter.getLocalizedCalendarFormat(myEvent.startDate)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box  sx={{ display: 'flex', alignItems: 'center', height:'fit-content'}} style={{marginLeft:'32px'}}>
                                {/* icon and calendar input */}
                                <Typography variant={"h4"} style={{color:'#003EB0'}} >
                                    {moment(myEvent.startDate).format('HH:mm')} - {moment(myEvent.endDate).format('HH:mm')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box  sx={{ display: 'flex', alignItems: 'center', height:'fit-content' }} style={{paddingLeft:'32px'}}>
                                <Typography variant={"h6"}>{myEvent.title}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} item container>
                    <Grid item container xs={4} justifyContent={"flex-end"}>
                        <Typography variant={"h6"} > {room.name}</Typography>
                    </Grid>
                    <Grid item container xs={8} style={{paddingLeft:'32px'}} >
                        <Stack direction={'row'} alignItems='center' gap={1} >
                            <EventSeatIcon/>
                            <Typography variant={'subtitle2'} > {myEvent.participantCount} </Typography>
                            <Typography variant={'subtitle2'} > {t('Booking.InfoCard.peopleAttending')} </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            <Grid xs={12} item container spacing={1}>
                <Grid xs={12} item container id={'tableLayout'}>
                    <Grid item container alignItems={"center"}  justifyContent={"flex-end"} style={{textAlign:"end"}} xs={4}>
                        <Stack direction={'row'} alignItems='center' gap={1}>
                            <TableLayoutIcon/>
                            <Typography variant={"h6"}> {t('Booking.InfoCard.Table')}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item container xs={8} alignItems={"center"} justifyContent={"flex-start"} style={{paddingLeft:'32px'}} >
                        <Stack direction={'row'} alignItems='center' gap={1}>
                            <Typography variant={"h6"} >{myEvent.tableLayout.name}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid xs={12} item container id={'catering'}>
                    <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs={4} style={{textAlign:"end"}}>
                        <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs>
                            <Stack direction={'row'} alignItems='center' gap={1}>
                                <CateringsIcon/>
                                <Typography variant={"h6"}> {t('Booking.InfoCard.Caterings')}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item container xs={8} style={{paddingLeft:'32px'}}>
                        <Stack direction={'row'} alignItems='center'>
                            <Typography variant={"h6"} >{myEvent.caterings.map((catering,index) => {
                                return (index ? ', ' : '') + catering
                            })}</Typography >
                        </Stack>
                    </Grid>

                </Grid>
                <Grid xs={12} item container id={'moderationEquipment'}>
                    <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs={4} style={{textAlign:"end"}}>
                        <Grid item container alignItems={"center"} justifyContent={"flex-end"} xs style={{textAlign:"end"}}>
                            <Stack direction={'row'} alignItems='center' gap={1} style={{textAlign:"end"}}>
                                <ModerationEquipmentIcon/>
                                <Typography variant={"h6"}> {t('Booking.InfoCard.Moderation')}</Typography>
                            </Stack>

                        </Grid>
                    </Grid>
                    <Grid item container xs={8} style={{paddingLeft:'32px'}} >
                        <Stack direction={'row'} alignItems='start'>
                            <Typography variant={"h6"}>{myEvent.moderationEquipment.map((moderationEquipment,index) => {
                                return (index ? ', ' : '') + moderationEquipment
                            })}</Typography >
                        </Stack>
                    </Grid>

                </Grid>
                <Grid xs={12} item container id={'note'}>
                    <Grid item container alignItems={"start"} justifyContent={"flex-end"} xs={4} style={{textAlign:"end"}}>
                        <Grid item container alignItems={"center"} justifyContent={"flex-end"} xs style={{textAlign:"end"}}>
                            <Stack direction={'row'} alignItems='center' gap={1} style={{textAlign:"end"}}>
                                <NotesIcon/>
                                <Typography variant={"h6"}> {t('Booking.InfoCard.Notes')}</Typography>
                            </Stack>

                        </Grid>
                    </Grid>
                    <Grid item container xs={8} style={{paddingLeft:'32px'}} >
                        <Stack direction={'row'} alignItems='start'>
                            <Stack direction={'row'} alignItems='center'>
                                <Typography variant={"h6"}>{myEvent.note}</Typography>
                            </Stack>
                        </Stack>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    )}




    return isLoaded ? <Grid container style={{width:'80%', margin:'0 auto'}} spacing={4}>
        <Grid item xs={12}>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <CheckCircleOutlineIcon sx={{color:'green',fontSize:'64px'}}/>
                <Typography  variant={'h4'}>{t('Booking.InfoCard.createEventSuccess')}</Typography>
            </Stack>

        </Grid>
        <Grid item container md={8} xs={12}>{checkoutForm()}</Grid>
        <Grid item container md={4} xs={12} style={{height:'fit-content'}}>
            <PriceInfoCard priceObj={priceObj}/>
            <Button  variant={"contained"} fullWidth style={{backgroundColor:'#2D77FC',color:'#fff',borderRadius:'8px',marginTop:16}} onClick={() => {navigate('/')}}>
                {t('Error Page.Go Dashboard')}
            </Button>

        </Grid>

    </Grid> : <CustomSpinner/>
}
export default withSnackbar(CreateEventForm);
