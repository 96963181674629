import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import { withSnackbar } from "notistack";
import InvitationForm from "./InvitationForm";
import SignUpSuccess from "../Login/SignUpSuccess";
import { useTranslation } from 'react-i18next';
import { Card } from "@mui/material";
import {useNavigate} from "react-router-dom";
import { useEffect } from 'react';
var user = {};

function Invitation(props) {
  const [name, setName] = React.useState(null);
  const [nameError, setNameError] = React.useState(null);
  const [psw, setPsw] = React.useState(null);
  const [psw_2, setPsw_2] = React.useState(null);
  const [pswError, setPswError] = React.useState(null);
  const [pswError_2, setPswError_2] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [opInProgress, setOpInProgress] = React.useState(null);
  const [opSuccess, setOpSuccess] = React.useState(null);
  const [userId, setUserId] = React.useState(null);
  const [tenantId, setTenantId] = React.useState(null);
  const [tenantName, setTenantName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [flag,setFlag] = React.useState(false);
  const [renderOrNot, setRenderOrNot] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    
    useEffect(() => {
        if (params.get("code") == null) {
            props.enqueueSnackbar(t('errorProperties.INVITATION.VALIDATE_TOKEN.INVALID_LINK'), {
                variant: 'error'
            });
            navigate("/login");
        } else {
            validateToken();
        }
    }, []);

  const validateToken = () => {
    let token = decodeURIComponent(params.get("code"));
    let tokenParams = { inviteToken: token };

    HTTP_REQUESTS.USER_SERVICE.VALIDATE_INVITED_USER(
      tokenParams,
      (success) => {
        setEmail(success.email);
        setUserId(success.accountId);
        setTenantId(success.tenantId);
        setTenantName(success.tenantName);
        if (success.firebaseUserId) {
          navigate("/login");
        }
        setRenderOrNot(true);
      },
      (fail) => {
        if ( fail == "Error: Request failed with status code 401" ) {
          props.enqueueSnackbar(t('errorProperties.INVITATION.VALIDATE_TOKEN.INVALID_LINK'), {
            variant: 'error'
          });
          navigate("/login")
        }
        else {
          props.enqueueSnackbar(t('errorProperties.INVITATION.VALIDATE_TOKEN.UNKNOWN_ERROR'), {
            variant: 'error'
          });
          navigate("/login")
        }
        setTimeout(() =>  navigate("/dashboard") , 1000);
      }
    );
  };

  const flagChanged = event => {
    setFlag(event.target.checked);
  }
  const nameChanged = (event) => {
    setName(event.target.value);
    setNameError(null);
  };


  const passwordChanged = (event) => {
    setPsw(event.target.value);
    setPswError(null);
  };

  const passwordChanged_2 = (event) => {
    setPsw_2(event.target.value);
    setPswError_2(null);
  };

  const emailChanged = (event) => {
    setEmail(event.target.value);
    setEmailError(null);
  };

  const signUpCliked = () => {
    let formValid = true;

    if (!name || name === "") {
      setNameError('errorProperties.INVITATION.SIGN_UP_CLICKED.SET_NAME_ERROR');
      formValid = false;
    }
    if (name.length < 2 && name.length > 0) {
      setNameError('errorProperties.INVITATION.SIGN_UP_CLICKED.SET_NAME_ERROR_LENGTH');
      formValid = false
    }

    if (!psw || psw === "") {
      setPswError('errorProperties.INVITATION.SIGN_UP_CLICKED.SET_PSW_ERROR');
      formValid = false;
    } else if (psw.length < 6) {
      setPswError('errorProperties.INVITATION.SIGN_UP_CLICKED.SET_PSW_ERROR_LENGTH');
      formValid = false;
    }

    if (!psw_2 || psw_2 === "") {
      setPswError_2('errorProperties.INVITATION.SIGN_UP_CLICKED.SET_PSW_ERROR_2');
      formValid = false;
    } else if (psw !== psw_2) {
      setPswError_2('errorProperties.INVITATION.SIGN_UP_CLICKED.SET_PSW_ERROR_NOT_SAME');
      formValid = false;
    }

    if (formValid) {
      update(); //SEND REQUEST FUNCTIONS

      //window.open('/login','_self');
    }
  };
  const update = async () => {
    let params = { name: name, status: "active", isSubscribed: flag,userId: userId};
    //let statusParams = { userId: userId, status: "active" ,updateObj:params, };
    HTTP_REQUESTS.FIREBASE_SERVICE.REGISTER(email, psw, (userCredential) => {
      localStorage.setItem('accessToken', userCredential?.user?.accessToken);
      params.firebaseUserId = userCredential?.user?.uid;
      HTTP_REQUESTS.USER_SERVICE.ACTIVATE_INVITATION(
          params,
          (success) => {
            setOpInProgress(false);
            setOpSuccess(true);

            HTTP_REQUESTS.USER_SERVICE.LOGOUT(
              data => {
                window.open("/login",'_self');
              },
              err => {
                console.log("Error: " + err);
              }
            );

          },
          (fail) => {
            // TODO handle delete user from firebase
            props.enqueueSnackbar(t(`errorProperties.SIGN_UP.PROCESS_SIGN_UP`), {variant: 'error'});
              HTTP_REQUESTS.FIREBASE_SERVICE.DELETE_USER(userCredential?.user?.auth?.currentUser, () => {
              console.log('Successfully deleted user');
            },(err) => {
              console.log('Error deleting user:', err);
            })
            setOpInProgress(false);
          }
      );
    },(err) => {
      props.enqueueSnackbar(t(`errorProperties.SIGN_UP.${err.code}`), {variant: 'error'});
      setOpInProgress(false);
    })



    //window.open('/login','_self');
  };

  return ( renderOrNot ?
    <Card>
      <Container component="main" maxWidth="xs" enterDelay={1000} style={{marginBottom: "24px"}}>
        <CssBaseline />
        {opSuccess ? (
          <SignUpSuccess />
        ) : (
            <InvitationForm
              nameChanged={nameChanged}
              passwordChanged={passwordChanged}
              passwordChanged_2={passwordChanged_2}
              emailChanged={emailChanged}
              signUpCliked={signUpCliked}
              flagChanged={flagChanged}
              nameError={nameError}
              pswError={pswError}
              pswError_2={pswError_2}
              email={email}
              tenantName={tenantName}
              opInProgress={opInProgress}
            />
        )}
      </Container>
    </Card>
    : null
  );
};

export default withSnackbar(Invitation);
