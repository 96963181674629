import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {getCateringIcon} from '../../../helpers/IconSelecter';
import {Checkbox, Stack, Typography} from "@mui/material";
import DropDownInput from "./StyledInputBase";
import AddIcon from "@mui/icons-material/Add";
import {KeyboardArrowDown} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
        maxWidth: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 'fit-content',
        },
    },
};


const ModerationEquipmentDropdown = props => {
    const classes = useStyles();
    const { t } = useTranslation()
    const [moderationEquipmentsData, setModerationEquipmentsData] = React.useState(props.moderationEquipmentsData);
    const [moderationEquipmentsDataRaw, setCateringsDataRaw] = React.useState(props.moderationEquipmentsDataRaw);
    const [moderationEquipmentsEmpty, setModerationEquipmentsEmpty] = React.useState(false);


    const handleModerationEquipmentChange = (event) => {
        props.onChange(event)
    };

    React.useEffect(() => {
        filterModerationEquipmentsData()
    },[props]);

    const filterModerationEquipmentsData = () =>{
        // datayı maple , eğer value arayınde filtrele varsa rawda filtrele deletedAt ine bak , yoksa datayı rawda filtrele deletedAt ine bak
        let snapshotValue = ""
        let newData = ""
        let newDataArray = [];
        for(const oneData of props.moderationEquipmentsData){
            snapshotValue = props.value.filter((item)=>item === oneData)[0]
            if(snapshotValue){
                newData = moderationEquipmentsDataRaw.filter((item)=>item.name === snapshotValue)[0]
                if(newData){
                    newDataArray.push(newData.name)
                }
            }else{
                newData = moderationEquipmentsDataRaw.filter((item)=>item.name === oneData && !item.deletedAt)[0]
                if(newData){
                    newDataArray.push(newData.name)}
            }
        }
        setModerationEquipmentsData(newDataArray)
    }
    const iconComponent = (props) => {
        return (
            <KeyboardArrowDown className={props.className} style={{marginRight: '4px',display: 'none'}}/>
        )};

    const isNotAvailable = (value) =>{
        let notAvailable = false
        for(const moderationEquipment of moderationEquipmentsDataRaw){
            if(value === moderationEquipment.name && moderationEquipment.deletedAt){
                notAvailable = true
            }
        }
        return notAvailable
    }
    React.useEffect(() => {
        let res = true;
        moderationEquipmentsDataRaw.forEach((item) => {
            if(item.deletedAt === null){
                res = false;
            }
        })

        setModerationEquipmentsEmpty(res)

    }, [moderationEquipmentsDataRaw]);

    return (<div>
        <FormControl className={classes.formControl}>
        <Select
                input={<DropDownInput/>}
                IconComponent={iconComponent}
                autoWidth
                id="entity_moderationequipment"
                displayEmpty={true}
                multiple
                name={props.name}
                value={props.value}
                onChange={handleModerationEquipmentChange}
                sx={{'& .MuiSelect-select': {paddingRight:'16px'}}}
                disabled={moderationEquipmentsEmpty}
                style={{ width:'max-content'}} /* added to fix icon size.  */
                renderValue={() => (
                    <Stack direction="row" alignItems="center">
                        {
                            !moderationEquipmentsEmpty && 
                                <AddIcon fontSize={'small'}/> 
                        }

                        <Typography sx={{fontWeight:'bold'}}> {t(!moderationEquipmentsEmpty ? 'Booking.InfoCard.add' : 'No Equipment Available' )} </Typography>
                    </Stack>
                )}
                MenuProps={MenuProps}>
                {moderationEquipmentsData.map((feature, index) => (
                    <MenuItem key={`${feature}${index}`} value={feature} disabled={isNotAvailable(feature)}>
                        <Checkbox checked={props.value.indexOf(feature) > -1}/>
                        {getCateringIcon(feature, moderationEquipmentsDataRaw)}
                        <ListItemText primary={feature} sx={{marginLeft: 1}}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        </div>
    )
}

export default ModerationEquipmentDropdown;
