import React from "react";
import "../styles/loaders.scss"

export default function CustomSpinner() {
    return (
        <div className="spinner-container">
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}
