import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from 'react-i18next';
import theme from "../../theme";
import {useAppDispatch} from "../../store/hooks";
import {restartSession} from "../../store/features/sessionSlice";
import {useNavigate} from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LogoutConfirmDialog(props) {
    const { t } = useTranslation();
    const { onClose, open } = props;

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleBack = () => {
        onClose(false);
    };


    const handleClose = () => {
        let language = localStorage.getItem('language');
        localStorage.clear();
        localStorage.setItem('language' , language);
        dispatch(restartSession())
        navigate('/login')
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleBack}
                aria-labelledby="logout-title"
                aria-describedby="logout-description"
            >
                <DialogTitle id="logout-title">{t('LogoutConfirmDialog.Confirm')}</DialogTitle>
                <DialogContent>
                    {t('LogoutConfirmDialog.Logout System')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleBack} style={{color: theme.palette.danger.backgroundColor}}>
                        {t('LogoutConfirmDialog.Cancel')}
                    </Button>
                    <Button id={'logout'} variant="contained" onClick={handleClose} color="primary">
                        {t('LogoutConfirmDialog.Logout')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
