export default function checkFloat (value) {
    switch(true) {
        case (isNaN(Number(value))) : 
            value = '';
            break;
        case (value.trim() === '') :
            value = '';
            break;
        case (value < 0) :
            value = '';
            break;
        default :
            break;
    }
    return value;
};