import BookingTable from "../components/Booking/BookingTable";
import {useEffect} from "react";
import {HTTP_REQUESTS} from "../backendcomm/services/httpRequestService";
import React from "react";
import {useAppSelector} from "../store/hooks";
import {selectSessionData} from "../store/features/sessionSlice";
import EventEdit from "../components/Dashboard/EventEdit";
import { Grid } from "@mui/material";

const UserBookingsPage = (value) => {

    const sessionData = useAppSelector(selectSessionData)

    const [bookingData, setBookingData] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);
    const [selectedEvent, setSelectedEvent] = React.useState({id: ''})

    useEffect(() => {
        fetchBookings();
    }, [])

    const fetchBookings = () => {
        HTTP_REQUESTS.BOOKING_SERVICE.GET_ALL_BY_ACCOUNT_ID(sessionData.id, (data) => {
            setBookingData(data.allEvents);
            setLoading(false);
        }, (error) => {
            console.log('Error: UserBookingPage.js fetchBookings: ' ,error)
        })
    }

    return (
        <Grid container>
            <Grid item lg={selectedEvent.id === '' ? 12:9} xs={12} md={12}>
                <BookingTable data={bookingData} isLoading={isLoading} fetchBookings={fetchBookings}
                    setSelectedEvent={setSelectedEvent}
                />
            </Grid>
            {
                selectedEvent.id !== '' &&
                 <Grid lg={3} xs={12} md={12}>
                    <EventEdit
                    selectedEvent={selectedEvent}
                 />
                 </Grid>
            }
        </Grid>
    )

}

export default UserBookingsPage;
