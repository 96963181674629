import React from 'react'

const SuperAdminErrorPage = () => {
    return (
        <div>
            <h1>SuperAdminErrorPage</h1>

            // TODO: add error page
            // TODO: put a button to navigate to the login page
            
        </div>
    );
}

export default SuperAdminErrorPage;