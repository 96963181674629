import React from "react";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IconButton } from "@mui/material";
import Popover from "@mui/material/Popover";
import { withTranslation } from 'react-i18next';
import Tooltip from "@mui/material/Tooltip";
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../helpers/dataConverter";

class StatusPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = { is_open: false, anchorEl: null, id: "" };
  }
  statusIcons = {
    active: "#43A047",
    invited: "#1E88E5",
    deleted: "#E53935",
  };
  open = () => {
    this.setState({
      is_open: true,
    });
    this.setState({
      id: this.state.is_open ? "simple-popover" : undefined,
    });
    
  };
  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  close = () => {
    this.setState({
      is_open: false,
    });
  };

  toggle = (event) => {
    this.setState((prevState) => ({
      is_open: !prevState.is_open,
    }));
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  getDeleteInformation = (rowData) => {
    const { t } = this.props;

    if (rowData.deletedBy === this.props.sessionData.id) {
      return (
        <div>
          {t('StatusPopover.Deleted You')}
          <br />
          {t('StatusPopover.Deleted Time')}
          {moment(rowData.deletedTime).format("DD-MM-YYYY HH:mm a")} <br />
          {t('StatusPopover.Reason')} {rowData.reasonForDelete}
        </div>
      );
    } else {
      this.props.users.forEach((user) => {
        if (rowData.deletedBy === user.userId) {
          return (
            <div>
              {t('StatusPopover.Deleted')} {user.username}
              <br />
              {t('StatusPopover.Deleted Time')}
              {moment(rowData.deletedTime).format("DD-MM-YYYY HH:mm a")} <br />
              {t('StatusPopover.Reason')} {rowData.reasonForDelete}
            </div>
          );
        }
      });
      return (
          <div>
            No delete information found.
          </div>
      );
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Tooltip title={t(`Users.Users Edit Table.StatusTooltip.${this.props.data.status}`)}>
          <IconButton onClick={this.toggle} size="large">
            <FiberManualRecordIcon
                style={{
                  color: this.statusIcons[this.props.data.status],
                  textAlign: "right",
                }}
            />
          </IconButton>
        </Tooltip>
        <Popover
          id={this.state.id}
          open={this.state.is_open}
          anchorEl={this.state.anchorEl}
          onClose={this.close}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div style={ {padding:5,backgroundColor: "grey",color:"white"}}>
            {this.state.is_open && this.props.data.status === "deleted"
              ? this.getDeleteInformation(this.props.data)
              : this.props.data.status}
          </div>
        </Popover>
      </div>
    );
  }
}

export default withTranslation()(connect(mapSessionStateToProps)(StatusPopover));
