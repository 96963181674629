import { Grid, Tooltip,Button, FormLabel,Select,MenuItem,InputLabel } from '@mui/material';
import { withStyles } from '@mui/styles'
import { withSnackbar } from 'notistack';
import React from 'react';
import { withTranslation } from 'react-i18next';
import PaymentDoneIcon from "@mui/icons-material/DoneSharp";
import CancelledIcon from "@mui/icons-material/EventBusySharp";
import EventAvailable from "@mui/icons-material/EventAvailableSharp";
import { getAllEvents } from '../../data/getAllEvents';
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import {PAYMENT_STATUS} from "../../utils/Constants"
import withRouter from '../../withRouter'
import NotPaidIcon from "@mui/icons-material/CloseSharp";
import { getUsersForSelectionWithIdValues } from "../../data/getUsers";
import { CustomConfirmDialog } from '../CustomConfirmDialog';
import PaypalUpdateOrderObj from '../../models/PaypalUpdateOrderObj'
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCard from "@mui/icons-material/CreditCard";
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../helpers/dataConverter";

const styles =  theme  => ({
    flexWrap: {
      flexWrap: "wrap",
    },
    toolbarLeft: {
        flex:1,
    },
    toolbarRight: {
        display: 'table',
        marginLeft: 'auto',
        marginRight: '15px',
    },
    button:{
        border:'1px solid rgba(255,255,255,.5);'
    },
    buttonActive:{
        border:'1px red solid'
    },
    iconSize:{
        fontSize:"25px"
    },
    formControl: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    userDropdwon:{
        width:"200px"
    }
})
class PaymentsPaidTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            role : this.props.sessionData.role,
            tenantId : this.props.sessionData.tenantId,
            selectedUser: "",
            allUsers:[],
            openConfirmModal:false,
            isMultiple:false,
            oldData:"",
            newData:""
        }
    }
    componentDidMount() {
        this.loadEvents();
        if(this.state.role === "admin"){
            this.loadAllUsers()
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedUser !== this.state.selectedUser) {
            this.loadEvents()
        }
    }

    loadEvents() {
        const callCbFuncIfAssigned = (successCB) => {
            if (!successCB) {
                return;
            }
            try {
                successCB();
            }
            catch (error) {
                console.log(error);
            }
        };
        const getEvents__ = (newEvents) => {
            const tempEnewEvents = newEvents.filter((item) => (!item.isBuffer))
            this.setState({ data: tempEnewEvents },  callCbFuncIfAssigned);
            // removeLoading();
        };
        const getUsersAllEvents = (accountId) => {
            let tenantId = this.props.sessionData.tenantId;
            HTTP_REQUESTS.ENTITY_SERVICE.GET_ONE_USERS_OWNED_EVENTS(
              accountId,
              tenantId,
              (success) => {
                this.setState({ data: success },  callCbFuncIfAssigned);
              },
              (fail) => {
                errorCallback(fail)
              },
              {paymentStatus:PAYMENT_STATUS.PAID}
            );
          }
        const getEvents_ = () => {
            /* TODO: Add filters here */
            if (this.state.role === "admin") {
                if(!this.state.selectedUser){
                    getAllEvents(this.state.selectedUser,  getEvents__, errorCallback, {paymentStatus:PAYMENT_STATUS.PAID});
                }else{
                    getUsersAllEvents(this.state.selectedUser)
                }
            } else {
                getUsersAllEvents(this.props.sessionData.id)
            }

        };
        const errorCallback = (error) =>{
            setTimeout(() => {
                this.setState({loading:false});
              }, 2000);
        }
        getEvents_();
    }

    loadAllUsers(){
        const { t } = this.props;
        const getUsers__ = (newUsers) => {
            let allUsersArray = []
            Object.keys(newUsers).map(key => {
                allUsersArray.push(newUsers[key])
            })
            this.setState({allUsers:allUsersArray});
        };
        const getUsers_ = () => {
            getUsersForSelectionWithIdValues(getUsers__,); //todo need to add errorCallback parameter 
        };
        getUsers_();
    }
    generateColumns = () =>{
        const { t } = this.props;
        if(this.state.role === "admin"){
            return this.generateAdminColumns()
        }else{
            return[
                { 
                    title: t('Payments.PaymentsTable.Name'), field: 'title', 
                    //validate: rowData => Boolean(rowData.title),
                    editable:false
                },
                {
                    title: t('Payments.PaymentsTable.Payment Status'),
                    field: "eventObj.paymentStatus",
                    editable: "onUpdate",
                    filtering: false,
                    lookup: { PAID: this.renderPaymentDoneIcon(), NOT_PAID: this.renderPaymentNotDoneIcon() },
                    render: (rowData) => rowData.eventObj.paymentStatus === PAYMENT_STATUS.PAID ? this.renderPaymentDoneIcon() : null
                },
                { 
                    title: t('Payments.PaymentsTable.Event Status'), 
                    field: 'eventObj.state',
                    editable: false, 
                    //validate: rowData => Boolean(rowData.eventObj.state),
                    render: (rowData) => rowData.eventObj.state === "cancelled" ? this.renderCancelledIcon() : this.renderEventAvailableIcon()
                },
                {
                    title: t('Payments.PaymentsTable.Total Price'),
                    field: "totalprice",
                    editable: false,
                    filtering: false,
                    render: (rowData) => rowData.eventObj.totalprice ? `${rowData.eventObj.totalprice} €` : null
                },
                {
                    title: t('Payments.PaymentsTable.Payment Method'),
                    field: "paymentMethod",
                    editable: false,
                    filtering: false,
                    render: (rowData) => this.decidePaymentMethod(rowData.eventObj)
                },
            ]
        }

    }

    generatePaymentLogDescription(eventObj){
        const logObject = JSON.parse(eventObj.paymentLog)
        const { t } = this.props;
        let descriptionText = ""
        if(logObject){
            if(logObject.operationMethod === "createOrder"){
                descriptionText = t('Payments.LogText.createOrder',{ownerName:logObject.ownerName});
            }
            else if(logObject.operationMethod === "capturePayment"){
                descriptionText = t('Payments.LogText.capturePayment',{ownerName:logObject.ownerName});
            }
            else if(logObject.operationMethod === "updateStatus"){
                if(logObject.updatedStatus === PAYMENT_STATUS.PAID){
                    descriptionText = t('Payments.LogText.updateStatus.PAID',{ownerName:logObject.ownerName});
                }
                else if (logObject.updatedStatus === PAYMENT_STATUS.NOT_PAID){
                    descriptionText = t('Payments.LogText.updateStatus.NOT_PAID',{ownerName:logObject.ownerName});
                }
                
            }
        }
        return descriptionText

    }

    decidePaymentMethod(eventObj){
        const { t,classes } = this.props;
        const logObj = JSON.parse(eventObj.paymentLog)
        if(logObj && logObj.paymentMethod){
            if(logObj.paymentMethod === "cash"){
                return(
                    <Tooltip title={t('Payments.PaymentMethod.Cash')}>
                        <AccountBalanceWalletIcon className={classes.iconSize}/>
                    </Tooltip>
                )
            }
            else if(logObj.paymentMethod === "paypal"){
                return(
                    <Tooltip title={t('Payments.PaymentMethod.Paypal')}>
                        <CreditCard className={classes.iconSize}/>
                    </Tooltip>
                )
            }
            else{
                return null
            }
        }else{
            return null
        }
    }

    renderPaymentDoneIcon = () => {
        const { t,classes } = this.props;
        return(
            <Tooltip title={t('Payments.PaymentsTable.Paid')}>
                <PaymentDoneIcon id='paymentDone' className={classes.iconSize}/>
            </Tooltip>
        )
    }

    renderPaymentNotDoneIcon = () => {
        const { t,classes } = this.props;
        return(
            <Tooltip title={t('Payments.PaymentsTable.Not Paid')}>
                <NotPaidIcon id='paymentNotDone' className={classes.iconSize}/>
            </Tooltip>
        )
    }

    renderEventAvailableIcon = () =>{
        const { t,classes } = this.props;
        return(
            <Tooltip title={t('Payments.PaymentsTable.Available')}>
                <EventAvailable className={classes.iconSize}/>
            </Tooltip>
        )
    }

    renderCancelledIcon = () => {
        const { t,classes } = this.props;
        return(
            <Tooltip title={t('Payments.PaymentsTable.Cancelled')}>
                <CancelledIcon  className={classes.iconSize}/>
            </Tooltip>
        )
    }
    renderFilterByPaymentStatusButtons(){
        const { t,classes, navigate } = this.props;

        const tenantId = this.props.sessionData.tenantId;

        return (
            <div style={{justifyItems:"center",display:"grid"}}>
               <FormLabel style={{marginBottom:"5px"}}>{ t('Payments.PaymentsTable.Show Events')}</FormLabel>
                <Grid>
                    <Button id='payOnline' size="small" className={classes.button} onClick={()=>{navigate("/payments/pay/" + tenantId)}}>{ t('Payments.PaymentsTable.Pay')}</Button>
                    <Button id='paidEvents' size="small" className={classes.buttonActive} onClick={()=>{navigate("/payments/paid/" + tenantId)}}>{ t('Payments.PaymentsTable.Paid')}</Button>
                    <Button id='unpaidEvents' size="small" className={classes.button} onClick={()=>{navigate("/payments/" + tenantId)}}>{ t('Payments.PaymentsTable.Not Paid')}</Button>
                </Grid>
            </div>
        )
    }

    prepareTableEditable = () => {
        if(this.state.role === "admin"){
            return {
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        {
                            if(newData.eventObj.paymentStatus !== oldData.eventObj.paymentStatus){
                                this.checkIsOrderMultiple(newData,oldData)
                                resolve()
                            }else{
                                resolve()
                            }
                        }
        
                    }),
                }
        }else{
            return null
        }
    };

    checkIsOrderMultiple = (newData,oldData) => {
        const { t } = this.props;
        new Promise((resolve,reject)=>{
            HTTP_REQUESTS.PAYMENT_SERVICE.IS_ORDER_MULTIPLE(this.state.tenantId,oldData.id,res =>{
                if(res.isMultiple){
                    this.setState({
                        isMultiple:res.isMultiple,
                        oldData:oldData,
                        newData:newData
                    },()=>{
                        this.setState({openConfirmModal:true,})
                        resolve()
                    })
                }else{
                    this.setState({
                        isMultiple:res.isMultiple,
                        oldData:oldData,
                        newData:newData
                    },()=>{
                        this.updatePaymentRequest()
                        resolve()
                    })
                }
            },err => {
                if(err.data && err.data.error.code){
                    const errorCode = err.data.error.code
                    this.props.enqueueSnackbar(t(`Payments.PaidTable.Errors.Code.${errorCode}`), {
                        variant: 'error'
                    });                
                }else{
                    this.props.enqueueSnackbar(t('Payments.PaidTable.Errors.Default'), {
                        variant: 'error'
                    });
                }
                reject()
            })
        })
    }

    updatePaymentRequest = () => {
        const newStatus = new PaypalUpdateOrderObj(this.state.newData.eventObj.paymentStatus,this.state.isMultiple)
        const { t } = this.props;
        HTTP_REQUESTS.BOOKING_SERVICE.UPDATE_A_BOOKING_PAYMENT_STATUS(this.state.tenantId,this.state.oldData.id,newStatus, res => {
            let array = this.state.data.filter((x,i)=> x.id !== this.state.oldData.id)
            array.push(this.state.newData);
            this.setState({data: array,openConfirmModal:false},()=>{
                this.loadEvents();
            })
            this.props.enqueueSnackbar(t('Payments.PaidTable.Successful'), {
                variant: 'success'
            });
        },err => {
            if(err.data && err.data.error.code){
                const errorCode = err.data.error.code
                this.props.enqueueSnackbar(t(`Payments.PaidTable.Errors.Code.${errorCode}`), {
                    variant: 'error'
                });                
            }else{
                this.props.enqueueSnackbar(t('Payments.PaidTable.Errors.Default'), {
                    variant: 'error'
                });
            }
            this.setState({openConfirmModal:false})
        })
    }

    renderUserFilterDropdown(){
        const { t,classes } = this.props;
        return this.state.role === "admin" ? (
            <Grid className={classes.formControl}>
                <InputLabel id="user-filter-holder-label">{t('Payments.PaymentsTable.UserFilter.Label')}</InputLabel>
                <Select
                className={classes.userDropdwon}
                fullWidth
                labelId="user-filter-holder-label"
                id="userFilter"
                name="userFilter"
                value={this.state.selectedUser}
                onChange={(event)=>{this.setState({selectedUser:event.target.value})}}
                >
                    <MenuItem value="">
                        {t('Payments.PaymentsTable.UserFilter.All Users')}
                    </MenuItem>
                    {this.state.allUsers.map((oneUser) => (
                        <MenuItem value={oneUser.id}>
                            {
                                oneUser.mail 
                            }
                        </MenuItem>                    
                    ))}
                </Select>
            </Grid>            
        ):null
    }

    returnTableOptions(){
        return {
            headerStyle:{fontWeight:"bold"},
            rowStyle:{
                borderBottom: "2px solid #e7e7e7",
                borderTop:"2px solid #e7e7e7",
            }
        }
    }

    onCloseConfirmModal = () => {
        this.setState({openConfirmModal:false,newData:"",oldData:""})
    }

    generateAdminColumns(){
        const { t } = this.props;
        return [
            { 
                title: t('Payments.PaymentsTable.Name'), field: 'title', 
                //validate: rowData => Boolean(rowData.title),
                editable:false
            },
            {
                title: t('Payments.PaymentsTable.Payment Status'),
                field: "eventObj.paymentStatus",
                editable: "onUpdate",
                filtering: false,
                lookup: { PAID: this.renderPaymentDoneIcon(), NOT_PAID: this.renderPaymentNotDoneIcon() },
                render: (rowData) => rowData.eventObj.paymentStatus === PAYMENT_STATUS.PAID ? this.renderPaymentDoneIcon() : null
            },
            { 
                title: t('Payments.PaymentsTable.Event Status'), 
                field: 'eventObj.state',
                editable: false, 
                //validate: rowData => Boolean(rowData.eventObj.state),
                render: (rowData) => rowData.eventObj.state === "cancelled" ? this.renderCancelledIcon() : this.renderEventAvailableIcon()
            },
            {
                title: t('Payments.PaymentsTable.Total Price'),
                field: "totalprice",
                editable: false,
                filtering: false,
                render: (rowData) => rowData.eventObj.totalprice ? `${rowData.eventObj.totalprice} €` : null
            },
            {
                title: t('Payments.PaymentsTable.Payment Method'),
                field: "paymentMethod",
                editable: false,
                filtering: false,
                render: (rowData) =>  this.decidePaymentMethod(rowData.eventObj)
            },
            {
                title: t('Payments.PaymentsTable.LogText'),
                field: "paymentLog",
                editable: false,
                filtering: false,
                render: (rowData) => this.generatePaymentLogDescription(rowData.eventObj) ? this.generatePaymentLogDescription(rowData.eventObj) : null
            }
        ]
    }

    returnLocalization = () => {    
        const { t } = this.props;
        const localization = {
            header: {
                actions: t('Locations.LocationsEditTable.Actions')
            },
            toolbar: {
              searchPlaceholder: t('Locations.LocationsEditTable.Search'),
              searchTooltip: t('Locations.LocationsEditTable.Search')
            },
            pagination: {
              labelRowsSelect: t('Locations.LocationsEditTable.Rows'),
              labelDisplayedRows: t('Locations.LocationsEditTable.Of'),
              firstTooltip: t('Locations.LocationsEditTable.First Page'),
              previousTooltip: t('Locations.LocationsEditTable.Prev Page'),
              nextTooltip: t('Locations.LocationsEditTable.Next Page'),
              lastTooltip: t('Locations.LocationsEditTable.Last Page')
            },
            body: {
              emptyDataSourceMessage: t('Locations.LocationsEditTable.No Records'),
              filterRow: {
                filterTooltip: t('Locations.LocationsEditTable.Filter')
              }
            }
        }
        return localization;
    }
    render() {
        const { t, classes } = this.props;
        return (
            <div>
        {/*     <MaterialTable*/}
        {/*    title={t('Payments.Payments')}*/}
        {/*    columns={this.generateColumns()}*/}
        {/*    editable={this.prepareTableEditable()}*/}
        {/*    icons={{*/}
        {/*        Add: props => <AddBoxIcon */}
        {/*        color='primary'*/}
        {/*        style={{ width: 40, height: 40 }}*/}
        {/*        />*/}
        {/*    }}*/}
        {/*    data={this.state.data}*/}
        {/*    options={this.returnTableOptions()}*/}
        {/*    components={{*/}
        {/*        Toolbar: props => (*/}
        {/*        <div*/}
        {/*            className={classes.toolbar}>*/}
        {/*            <MTableToolbar classes={{root:classes.toolbarLeft}} {...props} />*/}
        {/*            <div className={classes.toolbarRight} style={{marginBottom:"3px"}}>*/}
        {/*                <Grid container className={classes.formControl}>*/}
        {/*                    <Grid item >*/}
        {/*                        {this.renderUserFilterDropdown()}*/}
        {/*                    </Grid>*/}
        {/*                    <Grid item>*/}
        {/*                        {this.renderFilterByPaymentStatusButtons()}*/}
        {/*                    </Grid>*/}
        {/*                </Grid>*/}
        {/*            </div>                */}
        {/*        </div>*/}
        {/*        ),*/}
        {/*    }}*/}
        {/*    localization = {this.returnLocalization()}*/}
        {/*/>*/}
        {this.state.openConfirmModal? 
          <CustomConfirmDialog
            forDelete={true}
            cancel={t('Payments.PaidTable.Cancel')} 
            open={this.state.openConfirmModal} 
            confirm={t('Payments.PaidTable.Confirm')} 
            confirmCB={this.updatePaymentRequest} 
            cancelCB={this.onCloseConfirmModal} 
            header={t('Payments.PaidTable.Header')} 
            text={t('Payments.PaidTable.Text')}>
            </CustomConfirmDialog> 
            :null }              
            </div>

    )
    }
}
export default withTranslation()(connect(mapSessionStateToProps)(withSnackbar(withStyles(styles)(withRouter(PaymentsPaidTable)))));
