import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService'
import withRouter from '../../withRouter';
import CustomizeCrudForm from './CustomizeCrudForm'
import Property from '../../models/Property';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import CustomSpinner from '../CustomSpinner';


export class CustomizeCrudDataHandler extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            data:{},
            isReady:false,
            propertyId:'',
            loading:false,
            isNew:false,
            Events: [],
        }
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }
    
    componentDidMount(){
        this.setPropertyId();
    }


    setPropertyId(){
        let property = this.props['params']['propertyId'];
        queryString.stringify(property)
        if(property)
        {
            this.setState({propertyId:property},()=>{
                this.setData()
            })
        }
    }
    setData(){
        const { t } = this.props;
        if( this.props['params']['propertyId'] !== 'new' ){
            HTTP_REQUESTS.PROPERTY_SERVICE.GET_ONE(this.state.propertyId, data=>{
                        this.setState({data:data},()=>{
                            this.setState({isReady:true})
                        })
                    }, (err) => {
                        this.props.enqueueSnackbar(t('errorProperties.CUSTOMIZE_CRUD_DATA_HANDLER.GET_ONE'), {
                            variant: 'error'})
                        })

        } else {
            let oneProperty = new Property("","","","","","",false)
            this.setState({data:oneProperty},()=>{
                this.setState({isReady:true})

            })
        }
    }

    createProperty = (data) => {
        const { t } = this.props;
        const { navigate } = this.props;
        let newProperty = new Property()
        const requestData = newProperty.toAPIJSON(data)
        HTTP_REQUESTS.PROPERTY_SERVICE.CREATE(requestData, (property) => {
            navigate(`/customize`)
            this.props.enqueueSnackbar(t('Customize.CUSTOMIZE_CRUD_DATA_HANDLER.PropertyCreated'), {
                variant: 'success'
            });
        }, (err) => {
            let errorMessage = t('errorProperties.CUSTOMIZE_CRUD_DATA_HANDLER.CREATE')
            this.props.enqueueSnackbar(errorMessage, {
                variant: 'error'
            });
            this.setState({loading:false})
        });
    };

    updateProperty = (data) => {
        const { t } = this.props;
        const { navigate } = this.props;
        let newProperty = new Property()
        const requestData = newProperty.toAPIJSON(data)
        HTTP_REQUESTS.PROPERTY_SERVICE.UPDATE(this.state.propertyId, requestData, (property) => {
            this.setState({loading:false})
            navigate(`/customize`)
            this.props.enqueueSnackbar(t('Customize.CUSTOMIZE_CRUD_DATA_HANDLER.PropertyUpdated'), {
                variant: 'success'
            });
        }, (err) => {
            let errorMessage = t('errorProperties.CUSTOMIZE_CRUD_DATA_HANDLER.UPDATE_PROPERTY')
            this.props.enqueueSnackbar(errorMessage, {
                variant: 'error'
            });
            this.setState({loading:false})
        });
    };

    onSave(obj){
        this.setState({loading:true})
        let oneProperty = new Property(
            obj.id,
            obj.name,
            obj.type,
            obj.price,
            obj.priceType,
            obj.icon,
            obj.isVisible
        );
        if(obj.id === ''){
            this.createProperty(oneProperty)
        } else{
            this.updateProperty(oneProperty)
        }
    }
    
    onDelete(){
        this.setState({loading:true})
        const { t } = this.props;
        const { navigate } = this.props;
        HTTP_REQUESTS.PROPERTY_SERVICE.DELETE_BY_ID(
            this.state.propertyId,
            () => {
                this.setState({loading:false})
                navigate(`/customize`)
                this.props.enqueueSnackbar(t('Customize.CUSTOMIZE_CRUD_DATA_HANDLER.PropertyDeleted'), {
                    variant: 'success'
                });
                
            }, (err) => {
                this.setState({loading:false})
                this.props.enqueueSnackbar(t('errorProperties.CUSTOMIZE_CRUD_DATA_HANDLER.DELETE_PROPERTY'), {
                    variant: 'error'
                });
            });
        
    }

    render() {
        if(this.state.loading === true){
            return <CustomSpinner />
        } else {
        return (
            <div>
                {
                this.state.isReady ?
                <CustomizeCrudForm
                 data={this.state.data}
                 onSave={this.onSave}
                 onDelete={this.onDelete}
                 />
                :null
                }
            </div>
        )
    }
}
}
export default withTranslation()(withSnackbar(withRouter(CustomizeCrudDataHandler)))

