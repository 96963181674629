import CustomTable from "../Core/CustomTable/CustomTable";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    ActionButton,
    ActionButtonTypes,
    ActionButtonWrapper,
    ActionTypes
} from "../Core/CustomTable/TableActionButtons";
import {ColumnType} from "../Core/CustomTable/TableConfig";
import ConfirmationDialog from "../Core/Dialog/ConfirmationDialog/ConfirmationDialog";
import React from "react";
import moment from 'moment';
import {convertUuidToDisplay} from "../../utils/Converters";
import CreateBookingPopover from "./CreateBookingPopover";
import {HTTP_REQUESTS} from "../../backendcomm/services/httpRequestService";
import { useSnackbar } from 'notistack'
import {dataToEventObj} from "../../helpers/dataConverter";
import {useAppSelector} from "../../store/hooks";
import {selectSessionData} from "../../store/features/sessionSlice";

const BookingTable = (props) => {

    const { t } = useTranslation();
    const sessionData = useAppSelector(selectSessionData)

    const [bookingPopover, setBookingPopover] = React.useState({
        open: false,
        loading: false,
        selectedEntity: '',
        slotData: false,
        data: [],
        personId: ''
    });
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogAction, setDialogAction] = React.useState(() => {});
    const { enqueueSnackbar } = useSnackbar()

    const generateColumns = () => {
        return [
            {
                id: 'actions',
                title: t('Table.Actions'),
                cell: ({row}) => {
                    return (
                        ActionButtonWrapper([
                            ActionButton(
                                t('BookingTable.edit'),
                                {actionType: ActionTypes.custom, action: () => {
                                    props.setSelectedEvent(row.original)
                                    }},
                                ActionButtonTypes.edit
                            )
                        ]))
                },
                type: ColumnType.display,
                size: 100
            },
            {
                id: 'id',
                title: t('BookingTable.roomId'),
                type: ColumnType.accessor,
                render: row => convertUuidToDisplay(row)
            },
            {
                id: 'title',
                title: t('BookingTable.bookingTitle'),
                type: ColumnType.accessor
            },
            {
                id: 'status',
                title: t('BookingTable.status'),
                type: ColumnType.accessor
            },
            {
                id: 'start',
                title: t('BookingTable.startDate'),
                type: ColumnType.accessor,
                filterType: "date",
                render: row => moment(row).format('DD-MM-YYYY')
            },
            {
                id: 'eventObj.room.location.name',
                title: t('BookingTable.location'),
                type: ColumnType.accessor
            }
        ]
    }

    const openBookingPopover = (row) => {
        const rowInfo = row.original;
        console.log(rowInfo)
        setBookingPopover({
            open: true,
            loading: false,
            selectedEntity: rowInfo.eventObj.roomId,
            data: {...rowInfo, room: rowInfo.eventObj.roomId},
            personId: rowInfo.eventObj.ownerAccountId
        })
    }

    const handleDialogClose = (confirmation) => {
        setDialogOpen(false)
        if(confirmation){
            dialogAction();
        }
    }

    const handleBookingPopoverClose = () => {
        setBookingPopover({
            ...bookingPopover,
            open: false
        });
    }

    const handleDeleteBooking = (bookingId,entityID,deleteAll,isCancel) => {
        let deleteObj = {deleteAll:deleteAll,isCancel:isCancel};
        setBookingPopover({...bookingPopover, loading: true});
        HTTP_REQUESTS.BOOKING_SERVICE.DELETE_A_BOOKING(
            entityID,
            bookingId,
            deleteObj,
            (res) => {
                props.fetchBookings();
                setBookingPopover({...bookingPopover, loading: false, open: false});
                if(isCancel){
                    enqueueSnackbar(t('Entities.RCalendar.Event Cancelled'), {
                        variant: 'success'
                    });
                }else{
                    enqueueSnackbar(t('Entities.RCalendar.Event Deleted'), {
                        variant: 'success'
                    });
                }

            }, (err) => {
                setBookingPopover({...bookingPopover, loading: false});
                if(isCancel){
                    enqueueSnackbar(t('Entities.RCalendar.Event Not Cancelled'), {
                        variant: 'error'
                    });
                }else{
                    enqueueSnackbar(t('Entities.RCalendar.Event Not Deleted'), {
                        variant: 'error'
                    });
                }
            }
        )
    }

    const handleSaveBooking = (data) => {
        setBookingPopover({...bookingPopover, loading: true});
        const tenantId = sessionData.tenantId;
        let eventObj = {};
        eventObj = dataToEventObj(data)
        HTTP_REQUESTS.BOOKING_SERVICE.CREATE_A_BOOKING(false, eventObj, (res) => {
            enqueueSnackbar(t('Entities.RCalendar.Event Created'), {
                variant: 'success'
            });
            setBookingPopover({...bookingPopover, loading: false, open: false});
        }, (err) => {
            props.fetchBookings();
            setBookingPopover({...bookingPopover, loading: false});
            if(err.status === 403){
                let errorDetails = err.data.error.details
                enqueueSnackbar(t(`errorProperties.RCALENDAR.CREATE_EVENT.${errorDetails.code}`), {
                    variant: 'error'
                });
            }else{
                showBookingRequestError(err)
            }
            console.log(err);
        });
    }

    const showBookingRequestError = (err) => {
        if(err.data.error && (err.data.error.code === 2001 || err.data.error.code === 2002 )){
            let errorDetails = err.data.error.details
            if( errorDetails.code === 100){
                let errorWord = t(`errorProperties.RCALENDAR.CREATE_EVENT.FIELDS.${errorDetails.key}`)
                enqueueSnackbar(t(`errorProperties.RCALENDAR.CREATE_EVENT.${errorDetails.code}.${errorDetails.type}`,{fieldName:errorWord}), {
                    variant: 'error'
                });
            }else if (errorDetails.code === 0){
                if(err.data.error.code === 2001){
                    enqueueSnackbar(t('errorProperties.RCALENDAR.CREATE_EVENT.109'), {
                        variant: 'error'
                    });
                }else{
                    enqueueSnackbar(t('errorProperties.RCALENDAR.CREATE_EVENT.112'), {
                        variant: 'error'
                    });
                }
            }else{
                enqueueSnackbar(t(`errorProperties.RCALENDAR.CREATE_EVENT.${errorDetails.code}`), {
                    variant: 'error'
                });
            }
        }else{
            enqueueSnackbar(t('errorProperties.RCALENDAR.CREATE_EVENT.109'), {
                variant: 'error'
            });
        }
    }

    return(
        <div>
            <CustomTable
                columns={generateColumns()}
                data={props.data}
                title={t('BookingTable.title')}
                loading={props.isLoading}
            />
            <ConfirmationDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                message={t('BookingTable.delete.message')}
                title={t('BookingTable.delete.title')}
            />
            <CreateBookingPopover
                isLoading = {bookingPopover.loading}
                selectedEntity={bookingPopover.selectedEntity}
                data={bookingPopover.slotData}
                open={bookingPopover.open}
                handleClose={handleBookingPopoverClose}
                bookingData={bookingPopover.data}
                method="update"
                handleCancelBooking={handleDeleteBooking}
                handleSave={handleSaveBooking}
                personId={bookingPopover.personId} />
        </div>

    )
}

export default BookingTable;
