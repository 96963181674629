import React  from 'react';
import Popover from '@mui/material/Popover';
import BookingInfoCard from './BookingInfoCard';
import { useTranslation } from 'react-i18next';
import {useAppSelector} from "../../store/hooks";
import {selectSessionData} from "../../store/features/sessionSlice";

const CreateBookingPopover = (props) => {

  const { t } = useTranslation();
  const sessionData = useAppSelector(selectSessionData)

  const id = props.open ? 'simple-popover' : undefined;
  const calculateWidth = () => {
    let width = '65%'
    if(sessionData.isMobile){
      width = '100%'
    }
    return width
  }
  let top,left = 0;

  try {

    if(props.data)
      if(props.data.bounds){
        top = props.data.bounds.top;
        left = props.data.bounds.left;
      }else{
        top = props.data.box.y;
        left = props.data.box.x;
      }

  } catch (error) {
     console.error(t('errorProperties.CREATE_BOOKING_POPOVER') ,props.data.bounds);
  }

  


  return (
   
    <div>

      <Popover
        id={id}
        open={props.open?props.open:false}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 0, left: 0 }}
        onClose={props.handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={
             {style: {width: calculateWidth(),height:"100%"}}
          }
      >
       <BookingInfoCard
           isLoading={props.isLoading}
          // titleOnChange={props.titleOnChange}
          handleClose={props.handleClose} 
          handleSave={props.handleSave}
          data={props.data}
          bookingData={props.bookingData}
          method = {props.method}
          handleCancelBooking={props.handleCancelBooking}
          selectedEntity={props.selectedEntity} 
          isUserDashboard={props.dateDisable}
          personId = {props.personId}
          />
        
          
      </Popover> 
    </div>
  );
}

export default CreateBookingPopover;
