import React from 'react';
import {getCateringIcon} from "../../../helpers/IconSelecter";
import TimesIcon from '@mui/icons-material/Clear';
import ItemCounter from "./ItemCounter";
import {ReactComponent as Equals} from "../../MultiLabelComponent/icons/equals.svg";
import {Stack, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import StyledChip from "./StyledChip";
const useStyles = makeStyles(() => ({
    chipLabel: {
        "& > *": {
            color:'white !important',
            fill:'white',
            textAlign:'center',
        },
    },
    aligner:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width:'fit-content',
    },
}));
export default function CateringCountChip(props) {
    const classes = useStyles();
    const [totalPrice, setTotalPrice] = React.useState(0);
    React.useEffect(() => {
        let itemPrice = 0
        props.priceObj.additionalOptions.items.caterings.items.forEach(catering => {
                if(catering.name === props.catering){
                    itemPrice=catering.total
                }
            }
        )
        setTotalPrice(Number(itemPrice).toFixed(2))
    }, [props.priceObj])
    const handleDelete = () => {
        let newCaterings = props.cateringsArray.filter((catering) => catering !== props.catering)
        let event = {
            target: {
                name:'caterings',
                value: newCaterings,
            }
        }
        props.handleCateringChange(event)
    }
    const chipLabel = () => {
        return (
            <Stack direction={'row'} alignItems={'center'} gap={1} className={classes.chipLabel}>
                <Typography >{props.catering}</Typography>
                <TimesIcon/>
                <ItemCounter catering={props.catering} cateringsCounts={props.cateringsCounts} onChange={props.handleCateringChange} handleDelete={handleDelete} />
                <Equals/>
                <Typography>€{totalPrice}</Typography>
            </Stack>
        )
    }
    return (
        <StyledChip icon={getCateringIcon(props.catering, props.cateringsDataRaw)} label={chipLabel()} onDelete={handleDelete}  />
    );
}
