import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import withRouter from "../../withRouter";
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import InvoiceSettings from "../../models/InvoiceSettings";
import CustomSpinner from '../CustomSpinner';
import InvoiceSettingsCrudForm from "./InvoiceSettingsCrudForm";
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../helpers/dataConverter";
export class InvoiceSettingsCrudDataHandler extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: new InvoiceSettings(),
            isReady: false,
            tenantId: this.props.sessionData.tenantId,
            loading:false,
            method: 'POST'
        }
        this.onSave = this.onSave.bind(this);
    }
    componentDidMount(){
        this.setData();
    }
    setData(){
        const { t } = this.props;
        HTTP_REQUESTS.INVOICE_DATA_SERVICE.GET_INVOICE_DATA(this.state.tenantId, data=>{
            if(data.invoicingData) {
                this.setState({
                    data: data.invoicingData, 
                    isReady:true,
                    method: 'PUT'
                })
                console.log('data.invoiceData',this.state);
            } else {
                this.setState({isReady:true})
            }
         }, (err) => {
             this.props.enqueueSnackbar(t('errorProperties.SettingsCrudDataHandler.SETTINGS_CAN_NOT_BE_LOADED'), {
                variant: 'error'})
        })
    }
    onSave(obj) {
        const { t } = this.props;
        console.log("onSave", obj);
        HTTP_REQUESTS.INVOICE_DATA_SERVICE.CREATE_INVOICE_DATA(
            this.state.tenantId,
            obj,
            this.state.method,
            () => {
                this.props.enqueueSnackbar(t('Invoice Settings.InvoiceSettingsCrudDataHandler.Saved'), {
                    variant: 'success'
                });
                this.setState({loading:false, method: "PUT"})
            }, (err) => {
                let tempError = 'errorProperties.SettingsCrudDataHandler.SETTINGS_CAN_NOT_BE_SAVED';
                this.props.enqueueSnackbar((tempError != null && tempError.length > 0) ? t(tempError):'' , {
                    variant: "error"
                });
                this.setState({loading:false})
            });
    }
    
    render() {
        return (this.state.loading) ? <CustomSpinner /> :
        <div>
            {
                (this.state.isReady) ?  <InvoiceSettingsCrudForm
                data={this.state.data}
                onSave={this.onSave} 
                /> : null
            }
        </div>
    }
}
export default withTranslation()(connect(mapSessionStateToProps)(withSnackbar(withRouter(InvoiceSettingsCrudDataHandler))))
