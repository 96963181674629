import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import VariantAvatars from './CompanyAvatar';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import Copyright from './Copyright';
import { withSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {useAppSelector} from "../../store/hooks";
import {selectTenantConfig} from "../../store/features/tenantConfigSlice";


const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(0),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ChangePassword(props) {
    const {t} = props;
    let url = new URL(window.location);
    let params = new URLSearchParams(url.search);

    const tenantConfig = useAppSelector(selectTenantConfig);

    const classes = useStyles();
    const [password, setPassword] = React.useState(null);
    const [rePassword, setRePassword] = React.useState(null);
    const [rePasswordError, setRePasswordError] = React.useState(null);
    const [passwordError, setPasswordError] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [isRender , setIsRender] = React.useState(false);
    const [firstLoad , setFirstLoad] = React.useState();
    const [token, setToken] = React.useState("")

    const navigate = useNavigate();

    useEffect(() => {
            validateToken(); 
    },[props.t]);
    
    const validateToken = () => {
        if(!firstLoad && params.get("code") && 
        props.t('errorProperties.INVITATION.VALIDATE_TOKEN.INVALID_LINK') !== 'errorProperties.INVITATION.VALIDATE_TOKEN.INVALID_LINK') {
            setFirstLoad(true);
            let token = decodeURIComponent(params.get("code"));
            let tokenParams = { passwordToken: token };
            HTTP_REQUESTS.USER_SERVICE.VALIDATE_PASSWORD(
              tokenParams,
              (success) => {
                setEmail(success.email);
                setIsRender(true)
                setToken(params.get("code"))
              },
              (fail) => {
                props.enqueueSnackbar(props.t('errorProperties.INVITATION.VALIDATE_TOKEN.INVALID_LINK'), {
                    variant: 'error'
                });
                navigate("/login");
              }
            );
        } else if(!firstLoad && !params.get("code")) {
            navigate("/login");
        }
      };

      const loginClicked = () =>{
        navigate("/login")
    }

    const passwordChange = event => {
        setPassword(event.target.value);
        setPasswordError(null);
    }

    const rePasswordChange = event => {
        setRePassword(event.target.value);
        setRePasswordError(null);
    }

    const keyPress = event => {
        if(event.key=="Enter")
        {
            event.preventDefault();
            changePasswordClicked();
        }
    }

    const changePasswordClicked = () => {
        if (!password || password === "" ){
            setPasswordError('errorProperties.CHANGE_PASSWORD.CHANGE_PASSWORD_CLICKED.SET_PASSWORD_ERROR');
        } else if(password.length < 6){
            setPasswordError('errorProperties.CHANGE_PASSWORD.CHANGE_PASSWORD_CLICKED.SET_PSW_ERROR_LENGTH');
        }else if(!rePassword || rePassword === "" ){
            setRePasswordError('errorProperties.CHANGE_PASSWORD.CHANGE_PASSWORD_CLICKED.SET_PASSWORD_ERROR');
        }else if(password != rePassword){
            props.enqueueSnackbar(t('errorProperties.CHANGE_PASSWORD.CHANGE_PASSWORD_CLICKED.SET_PASSWORD_NOT_MATCH_ERROR'), {
                variant: 'error'
            });
        }
        else{
            continueChangePasswordProcess();
        }
    }

    
    const continueChangePasswordProcess = () => {
        let passwordObject = {
            password : password,
            email : email,
            token:token
        }
        HTTP_REQUESTS.USER_SERVICE.CHANGE_PASSWORD(passwordObject,(success)=>{
            props.enqueueSnackbar(t('Password.ChangePassword.Password Changed'), {
                variant: 'success'
            });
            navigate("/login")
        },(err) => {
            props.enqueueSnackbar(t('errorProperties.CHANGE_PASSWORD.CONTINUE_CHANGE_PASSWORD_PROCCES'), {
                variant: 'error'
            });
         });
    }


    return ( isRender ? (
        <Grid container component="main" className="signInRoot">
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} sx={{backgroundImage: `url(${tenantConfig.loginLeftImage})`}}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className="signInPaper">
                    <VariantAvatars />
                    <Avatar className="avatar">
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('ChangePassword.Change Pass')}
                    </Typography>
                    <form className="form" noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            type="password"
                            fullWidth
                            id="password"
                            label={t('ChangePassword.New Pass')}
                            name="password"
                            autoComplete="password"
                            autoFocus
                            onChange={passwordChange}
                            value={password}
                            helperText={(passwordError != null && passwordError.length > 0) ? t(passwordError) : ''}
                            error={passwordError != null && passwordError.length > 0}
                            onKeyPress={keyPress}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            type="password"
                            fullWidth
                            id="password"
                            label={t('ChangePassword.Confirm New')}
                            name="password"
                            autoComplete="password"
                            onChange={rePasswordChange}
                            value={rePassword}
                            helperText={(rePasswordError != null && rePasswordError.length > 0) ? t(rePasswordError) : ''}
                            error={rePasswordError != null && rePasswordError.length > 0}
                            onKeyPress={keyPress}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={changePasswordClicked}                           
                        >
                            {t('ChangePassword.Change Pass')}
                        </Button>
                       <Grid container>
                            <Grid item xs={12} s>
                                <Link style={{ float: "right" }} className="cursor-ptr" onClick={loginClicked} variant="body2">
                                    {t('ChangePassword.Login')}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5} className="box">
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    ): null);
}

export default withTranslation()(withSnackbar(ChangePassword));
