import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import VariantAvatars from './CompanyAvatar';
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import Copyright from './Copyright';
import { withSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import emailStrIsValid from "../../helpers/Validation";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: "100%",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#f50057',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(0),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    selector:{
        color:'white',
        fontSize:'medium',
        "& .MuiSvgIcon-root": { color: "black" }
    },
    box: {
        marginTop: "20px"
    }
}));

function ForgotPassword(props) {
    const classes = useStyles();
    const navigate = useNavigate();

    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const { t } = useTranslation();
    let environment = window.location.origin;

    const emailChanged = event => {

        setEmail(event.target.value);
        setEmailError(null);
    }

    const keyPress = event => {
        if(event.key=="Enter")
        {
            event.preventDefault();
            forgotPasswordClicked();
        }
    }

    const forgotPasswordClicked = () => {
        let formValid = true;

        if (!email || email === "") {
            setEmailError('errorProperties.FORGOT_PASSWORD.FORGOT_PASSWORD_CLICKED.SET_EMAIL_ERROR');
            formValid = false;
        }
        if (email){
            if(!emailStrIsValid(email)){
                props.enqueueSnackbar(t('errorProperties.FORGOT_PASSWORD.FORGOT_PASSWORD_CLICKED.MAIL_NOT_VALID'), {
                    variant: 'error'
                });
            }else{
                if (formValid) continueForgotPasswordProcess();
            }   
        }
    }   

    const continueForgotPasswordProcess = () => {

        HTTP_REQUESTS.USER_SERVICE.SEND_PASSWORD_TO_EMAIL(email,() => {
            props.enqueueSnackbar(t('Password.ForgotPassword.Password Mail'), {
                variant: 'success'
            });
            navigate("/login")
        },(err) => {
            props.enqueueSnackbar(t('errorProperties.FORGOT_PASSWORD.CONTINUE_FORGOT_PASSWORD_PROCCES'), {
                variant: 'error'
            });
        })
    }


    return (
        <>
            <div className={classes.paper}>
                <VariantAvatars />
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('ForgotPassword.Forgot')}
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t('ForgotPassword.Email')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={emailChanged}
                        value={email}
                        helperText={(emailError != null && emailError.length > 0) ? t(emailError) : ''}
                        error={emailError != null && emailError.length > 0}
                        onKeyPress={keyPress}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={forgotPasswordClicked}
                    >
                        {t('ForgotPassword.Change Pass')}
                    </Button>
                    <Grid container>
                        <Grid item xs={12}>
                            <Link style={{ float: "right" }} className="cursor-ptr" onClick={props.onSignInClicked} variant="body2">
                                {t('ForgotPassword.Login')}
                            </Link>
                        </Grid>
                    </Grid>
                    <Box mt={5} className={classes.box}>
                        <Copyright />
                    </Box>
                </form>
            </div>
        </>
    );
}

export default withSnackbar(ForgotPassword);
