import { Grid,FormLabel, Button,Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles'
import { withSnackbar } from 'notistack';
import React from 'react';
import { withTranslation } from 'react-i18next';
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCard from "@mui/icons-material/CreditCard";
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import PaypalCheckoutButton from '../PaypalCheckoutButton/PaypalCheckoutButton';
import {PAYMENT_STATUS} from "../../utils/Constants"
import withRouter from '../../withRouter'
import PaypalCreateOrderObj from '../../models/PaypalCreateOrderObj';
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../helpers/dataConverter";
const styles =  theme  => ({
    flexWrap: {
      flexWrap: "wrap",
    },
    toolbarLeft: {
        flex:1,
    },
    toolbarRight: {
        display: 'table',
        marginLeft: 'auto',
        marginRight: '15px',
    },
    iconSize:{
        fontSize:"25px"
    },
    button:{
        border:'1px solid rgba(255,255,255,.5);'
    },
    buttonActive:{
        border:'1px red solid'
    }
})
class PaymentsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedEvents: [],
            selectedEventIds : [],
            data: [],
            role : this.props.sessionData.role,
            paypalDatas : {
                description: "",
                currency: "EUR"
            },
        }
    }
    componentDidMount() {
        this.loadEvents();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedEvents !== this.state.selectedEvents) {
            this.handleMultiSelectEvents()
        }
    }

    handleApprove = (createdOrderId) =>{
        const {t} = this.props;
        HTTP_REQUESTS.PAYMENT_SERVICE.CAPTURE_PAYMENT(this.props.sessionData.tenantId,createdOrderId,(res) =>{
            this.props.enqueueSnackbar(t('Payments.PayEvents.handleApprove Success'), {
                variant: 'success'
            });
            this.createInvoice(res.orderId)
            this.loadEvents()
        }, (error)=>{
            if(error.data && error.data.error.code){
                const errorCode = error.data.error.code
                this.props.enqueueSnackbar(t(`Payments.PayEvents.Errors.Code.${errorCode}`), {
                    variant: 'error'
                });                
            }else{
                this.props.enqueueSnackbar(t('Payments.PayEvents.Errors.Default'), {
                    variant: 'error'
                });
            }
            this.loadEvents()
        })

    }

    handleMultiSelectEvents(){
        const { t } = this.props;
        let selectedIdArray = []
        let description = this.generateOrderDescription()
        if(this.state.selectedEvents.length === 1){
            selectedIdArray.push(this.state.selectedEvents[0].id)
        }else{
            this.state.selectedEvents.map((oneEvent,index) => {
                selectedIdArray.push(oneEvent.id)
            })
        }
        this.setState(prevState => ({
            paypalDatas: {
                ...prevState.paypalDatas,
                description: description
            },
            selectedEventIds : selectedIdArray
        }));
    }
    loadEvents() {
        const callCbFuncIfAssigned = (successCB) => {
            if (!successCB) {
                return;
            }
            try {
                successCB();
            }
            catch (error) {
                console.log(error);
            }
        };
        const getUsersAllEvents = () => {
            const tenantId = this.props.sessionData.tenantId;
            HTTP_REQUESTS.ENTITY_SERVICE.GET_ONE_USERS_OWNED_EVENTS(
              this.props.sessionData.id,
              tenantId,
              (success) => {
                this.setState({ data: [] });
                setTimeout(() => {
                    this.setState({ data: success,selectedEvents:[],selectedEventIds:[] },callCbFuncIfAssigned);
                  },5);
              },
              (fail) => {
                errorCallback(fail)
              },
              {cancelled:false,paymentStatus:PAYMENT_STATUS.NOT_PAID}
            );
          }
        const errorCallback = (error) =>{
            setTimeout(() => {
                this.setState({loading:false});
              }, 2000);
        }
        getUsersAllEvents();
    }

    renderMultiPaymentButton(){
        const { t } = this.props;
        let newOrderObj = new PaypalCreateOrderObj(
            this.state.paypalDatas,
            this.state.selectedEventIds
        )
        return this.state.selectedEvents.length > 1 ?(
            <div style={{justifyItems:"center",display:"grid"}}>
                <FormLabel style={{marginBottom:"5px"}}>{ t('Payments.PaymentsTable.Pay All')}</FormLabel>
                <PaypalCheckoutButton 
                    newOrderObj={newOrderObj} 
                    handleApprove={this.handleApprove}
                />
            </div>
        )
        :null
    }
    generatePaymentObject(rowData){
        if (rowData !== undefined) {
            let newOrderObj = new PaypalCreateOrderObj(
                {   description : this.generateOrderDescription(),
                    currency : "EUR"
                },
                [rowData.eventObj.id]
            )
            return newOrderObj
        } else {
            return null
        }
    }
    renderPaymentButton(rowData) {
        let selectedEventId = [rowData.eventObj.id]
        const paypalDatas = this.generatePaymentObject(rowData);
        return (rowData !== undefined && paypalDatas) ? 
        <Grid style={{display:"flex"}}>
            <PaypalCheckoutButton 
                newOrderObj={paypalDatas} 
                handleApprove={this.handleApprove}
            />
        </Grid>
        : null;
    }

    renderFilterByPaymentStatusButtons(){
        const { t,classes, navigate } = this.props;

        const tenantId = this.props.sessionData.tenantId;

        return (
            <div style={{justifyItems:"center",display:"grid"}}>
               <FormLabel style={{marginBottom:"5px"}}>{ t('Payments.PaymentsTable.Show Events')}</FormLabel>
                <Grid>
                    <Button size="small" className={classes.buttonActive} onClick={()=>{navigate("/payments/pay/" + tenantId)}}>{ t('Payments.PaymentsTable.Pay')}</Button>
                    <Button size="small" className={classes.button} onClick={()=>{navigate("/payments/paid/" + tenantId)}}>{ t('Payments.PaymentsTable.Paid')}</Button>
                    <Button size="small" className={classes.button} onClick={()=>{navigate("/payments/" + tenantId)}}>{ t('Payments.PaymentsTable.Not Paid')}</Button>
                </Grid>
            </div>
        )
    }

    checkIsEventOwner = (event)=> {
        let eventArray = []
        let isOwner = true
        if(!event.length){
            eventArray.push(event)
        }else{
            eventArray = event
        }
        eventArray.map((oneEvent)=>{
            if(oneEvent.ownerAccountId !== this.state.payerAccountId){
                isOwner = false
            }
        })
        return isOwner
    }

    generateColumns = () =>{
        const { t } = this.props;
        if(this.state.role === "admin"){
            return this.generateAdminColumns()
        }else{
            return [
                { title: t('Payments.PaymentsTable.Name'), field: 'title', validate: rowData => Boolean(rowData.title)},
                {
                    title: t('Payments.PaymentsTable.Pay Now'),
                    field: "pay",
                    editable: false,
                    filtering: false,
                    render: (rowData) => rowData.eventObj.paymentStatus === PAYMENT_STATUS.NOT_PAID ? this.renderPaymentButton(rowData) : null
                },
                {
                    title: t('Payments.PaymentsTable.Total Price'),
                    field: "totalprice",
                    editable: false,
                    filtering: false,
                    render: (rowData) => rowData.eventObj.totalprice ? `${rowData.eventObj.totalprice} €` : null
                },
            ]
        }
    }

    generatePaymentLogDescription(eventObj){
        const logObject = JSON.parse(eventObj.paymentLog)
        const { t } = this.props;
        let descriptionText = ""
        if(logObject){
            if(logObject.operationMethod === "createOrder"){
                descriptionText = t('Payments.LogText.createOrder',{ownerName:logObject.ownerName});
            }
            else if(logObject.operationMethod === "capturePayment"){
                descriptionText = t('Payments.LogText.capturePayment',{ownerName:logObject.ownerName});
            }
            else if(logObject.operationMethod === "updateStatus"){
                if(logObject.updatedStatus === PAYMENT_STATUS.PAID){
                    descriptionText = t('Payments.LogText.updateStatus.PAID',{ownerName:logObject.ownerName});
                }
                else if (logObject.updatedStatus === PAYMENT_STATUS.NOT_PAID){
                    descriptionText = t('Payments.LogText.updateStatus.NOT_PAID',{ownerName:logObject.ownerName});
                }
                
            }
        }
        return descriptionText

    }

    decidePaymentMethod(eventObj){
        const { t,classes } = this.props;
        const logObj = JSON.parse(eventObj.paymentLog)
        if(logObj && logObj.paymentMethod){
            if(logObj.paymentMethod === "cash"){
                return(
                    <Tooltip title={t('Payments.PaymentMethod.Cash')}>
                        <AccountBalanceWalletIcon className={classes.iconSize}/>
                    </Tooltip>
                )
            }
            else if(logObj.paymentMethod === "paypal"){
                return(
                    <Tooltip title={t('Payments.PaymentMethod.Paypal')}>
                        <CreditCard className={classes.iconSize}/>
                    </Tooltip>
                )
            }
            else{
                return null
            }
        }else{
            return null
        }
    }

    returnTableOptions(){
        return {
            headerStyle:{fontWeight:"bold"},
            rowStyle:{
                borderBottom: "2px solid #e7e7e7",
                borderTop:"2px solid #e7e7e7",
            },
            selection: true
        }
    }

    generateOrderDescription(){
        const { t } = this.props;
        return t('Payments.PaymentsTable.Description',{tenantName:this.props.sessionData.tenantName})
    }

    generateAdminColumns(){
        const { t } = this.props;
        return [
            { title: t('Payments.PaymentsTable.Name'), field: 'title', validate: rowData => Boolean(rowData.title)},
            {
                title: t('Payments.PaymentsTable.Pay Now'),
                field: "pay",
                editable: false,
                filtering: false,
                render: (rowData) => rowData.eventObj.paymentStatus === PAYMENT_STATUS.NOT_PAID ? this.renderPaymentButton(rowData) : null
            },
            {
                title: t('Payments.PaymentsTable.Total Price'),
                field: "totalprice",
                editable: false,
                filtering: false,
                render: (rowData) => rowData.eventObj.totalprice ? `${rowData.eventObj.totalprice} €` : null
            },
            {
                title: t('Payments.PaymentsTable.LogText'),
                field: "paymentLog",
                editable: false,
                filtering: false,
                render: (rowData) => this.generatePaymentLogDescription(rowData.eventObj) ? this.generatePaymentLogDescription(rowData.eventObj) : null
            }
        ]
    }

    createInvoice = (paidOrderId) =>{
        const {t} = this.props;
        HTTP_REQUESTS.PAYMENT_SERVICE.CREATE_INVOICE(this.props.sessionData.tenantId,paidOrderId,(res) =>{
            this.props.enqueueSnackbar(t('Payments.PayEvents.createInvoice Succes'), {
                variant: 'success'
            });
        }, (error)=>{
            if(error.data && error.data.error.code){
                const errorCode = error.data.error.code
                this.props.enqueueSnackbar(t(`Payments.PayEvents.Errors.Code.${errorCode}`), {
                    variant: 'error'
                });                
            }else{
                this.props.enqueueSnackbar(t('Payments.PayEvents.Errors.Invoice Default'), {
                    variant: 'error'
                });
            }
        })

    }

    returnLocalization = () => {
        const { t } = this.props;
        const localization = {
            header: {
                actions: t('Locations.LocationsEditTable.Actions')
            },
            toolbar: {
              searchPlaceholder: t('Locations.LocationsEditTable.Search'),
              searchTooltip: t('Locations.LocationsEditTable.Search')
            },
            pagination: {
              labelRowsSelect: t('Locations.LocationsEditTable.Rows'),
              labelDisplayedRows: t('Locations.LocationsEditTable.Of'),
              firstTooltip: t('Locations.LocationsEditTable.First Page'),
              previousTooltip: t('Locations.LocationsEditTable.Prev Page'),
              nextTooltip: t('Locations.LocationsEditTable.Next Page'),
              lastTooltip: t('Locations.LocationsEditTable.Last Page')
            },
            body: {
              emptyDataSourceMessage: t('Locations.LocationsEditTable.No Records'),
              filterRow: {
                filterTooltip: t('Locations.LocationsEditTable.Filter')
              }
            }
        }
        return localization;
    }
    render() {
        const { t, classes } = this.props;
        return (
            <div>
                {/*<MaterialTable*/}
                {/*    title={t('Payments.Payments')}*/}
                {/*    columns={this.generateColumns()}*/}
                {/*    icons={{*/}
                {/*        Add: props => <AddBoxIcon */}
                {/*        color='primary'*/}
                {/*        style={{ width: 40, height: 40 }}*/}
                {/*        />*/}
                {/*    }}*/}
                {/*    options={this.returnTableOptions()}*/}
                {/*    data={this.state.data}*/}
                {/*    onSelectionChange = { (data) => {*/}
                {/*        this.setState({selectedEvents:data.map( item => {*/}
                {/*            return item.eventObj;*/}
                {/*        })})*/}
                {/*        console.log('data', data);*/}
                {/*    }}*/}
                {/*    components={{*/}
                {/*        Toolbar: props => (*/}
                {/*        <div className={classes.toolbar}>*/}
                {/*            <MTableToolbar classes={{root:classes.toolbarLeft}} {...props} />*/}
                {/*            <div className={classes.toolbarRight} style={{marginBottom:"3px"}}>*/}
                {/*                <Grid container>*/}
                {/*                    <Grid item>*/}
                {/*                        {this.renderFilterByPaymentStatusButtons()}*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </div>*/}
                {/*            <div className={classes.toolbarRight}>*/}
                {/*            {*/}
                {/*            this.renderMultiPaymentButton()*/}
                {/*            }*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        ),*/}
                {/*    }}*/}
                {/*    localization = {this.returnLocalization()}*/}
                {/*/>                */}
            </div>
        )
    }
}
export default withTranslation()(connect(mapSessionStateToProps)(withSnackbar(withStyles(styles)(withRouter(PaymentsTable)))));
