import moment from 'moment';
import dateFormatter from "../../../helpers/DateFormatter";


class EventDates {
    constructor(selectedDate, startTime, endTime) {
        selectedDate.setTime(startTime)
        this.selectedDate = moment(selectedDate);
        this.startTime = moment(startTime);
        this.endTime = moment(endTime);
    }
    getSelectedDate() {
        return this.selectedDate;
    }
    getStartTime() {
        return this.startTime;
    }
    getEndTime() {
        return this.endTime;
    }
    getFormattedDate() {
        return dateFormatter.getLocalizedCalendarFormat(this.selectedDate)
    }
    getFormattedStartTime() {
        return this.startTime.format('HH:mm');
    }
    getFormattedEndTime() {
        return this.endTime.format('HH:mm');
    }
}
export default EventDates;