export class CreateDeviceRequest {
    constructor(
        name,
        email,
        password,
        roomId,
    ) {
        this.name = name;
        this.email = email;
        this.password = password;
        this.roomId = roomId;
    }
}