import React, { useState,useRef } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import BookingActionButtonGroup from './BookingActionButtonGroup';
import {
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Box,
    Autocomplete,
    Stack,
    IconButton,
    Alert
} from '@mui/material';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import '@mui/lab';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import SettingsIcon from '@mui/icons-material/Settings';
import RepeatingBooking from './RepeatingBooking';
import NotesTextField from '../../helpers/NotesTextField';
import TableLayoutDropdown from '../../helpers/TableLayoutDropdown';
import CateringDropdown from '../../helpers/CateringDropdown';
import ModerationEquipmentDropdown from '../../helpers/ModerationEquipmentDropdown';
import SeatsTextField from '../../helpers/SeatsTextField';
import emailStrIsValid from "../../helpers/Validation";
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import { getUsersForSelectionWithIdValues } from "../../data/getUsers";
import { useTranslation } from 'react-i18next';
import CustomErrorBox from '../CustomErrorBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import theme from '../../theme';
import { CustomConfirmDialog } from '../CustomConfirmDialog';
import { dataToEventObj } from '../../helpers/dataConverter';
import PriceInformations from '../Entity/PriceInformations';
import roundingTime from '../../helpers/roundingTime';
import { calendarLanguagesData } from '../../data/languages';
import {PAYMENT_STATUS} from "../../utils/Constants";
import CustomSpinner from "../CustomSpinner";
import {useAppSelector} from "../../store/hooks";
import {selectSessionData} from "../../store/features/sessionSlice";
import {selectTenantConfig} from "../../store/features/tenantConfigSlice";
import CloseIcon from '@mui/icons-material/Close';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import DeleteIcon from '@mui/icons-material/Delete';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import SaveIcon from '@mui/icons-material/Save';
import Slide from '@mui/material/Slide';

const calendarLanguages = calendarLanguagesData
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles(theme => ({
    spinner:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    typography: {
        padding: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    mr2: {
        marginRight:theme.spacing(2)
    },
    center: {
        display: 'flex',
        alignItems: 'center'
    },
    cancelBookingBtn : {
        marginLeft : theme.spacing(1),
        marginRight : theme.spacing(1)
    }

}));

const BookingInfoCard = props => {
    const sessionData = useAppSelector(selectSessionData)
    const tenantConfig = useAppSelector(selectTenantConfig);

    let tempData = props.data;
    const [isMultiple, setIsMultiple] = React.useState((props.method !=='update') && tenantConfig.features.multipleRoomBooking && !props.selectedEntity);
    const [isLoading, setIsLoading] = React.useState(true);

    let tempRoom;
    let paymentStatus;
    let paymentIsActive = tenantConfig.features.payment;
    // Booking Data is using as a flag for detect is it create or edit to booking.
    if(props.method==='update') { 
        tempData = props.bookingData;
        tempRoom = (props.selectedEntity === null) ? tempData.room : props.selectedEntity;
    } else {
        /* TODO add check with config */
        tempRoom = (isMultiple) ? [] : props.selectedEntity;
        tempData.start = roundingTime(tempData.start)
        tempData.end = roundingTime(tempData.end)
        tempData.end = (tempData.start.getTime() == tempData.end.getTime()) ? moment(tempData.start).add(30, 'minutes').toDate() : tempData.end;
    }
    tempData.eventObj = (tempData.eventObj) ? tempData.eventObj : {};
    let tempEventSnapShot = tempData.eventObj.createdEventSnapshot;
    const [newEvent, setnewEvent] = React.useState({
        id:tempData.eventObj.id,
        bookingStatus: tempData.eventObj.status ? tempData.eventObj.status : paymentIsActive ? 'reserved' : 'booked',
        selectedDate:tempData.start,
        selectedStart:tempData.start,
        selectedEnd:tempData.end,
        room:tempRoom,
        bookingHolder:(tempData.eventObj.ownerAccountId) ? tempData.eventObj.ownerAccountId: sessionData.id,
        billingAddress:'',
        contactCustomer:[],
        title:tempData.title, 
        sendConfirmationTo:'',
        isReccured:false,
        repeatingBookingData:{
            reccuredType:'Daily',
            reccuredCount:'',
            reccuredPeriod:'',
            reccuredWeekDay :''
        },
        additionalFeatures:{
            notes:'',
            tableLayout:'',
            caterings:'',
            moderationEquipment:'',
            seats:''
        },
        customerName:'',
        createdEventSnapshot: tempEventSnapShot,
        welcomeMessage:tempEventSnapShot ? tempEventSnapShot.welcomeMessage : "",
        deviceId : tempData.eventObj.deviceId ? tempData.eventObj.deviceId : "",
        participantCount : (tempEventSnapShot) ? tempEventSnapShot.participantCount: "",
        state : (tempData.eventObj.state) ? tempData.eventObj.state : ""
    });
    const [selectedEntityData,setSelectedEntityData] = React.useState([]);
    const [entities, setEntities] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [formValid, setformValid] = React.useState(false);
    const [openAddOptions, setopenAddOptions] = React.useState(false);
    const [openRepeatingBooking, setopenRepeatingBooking] = React.useState(false);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [roomLoaded, setRoomLoaded] = React.useState(false);
    const [disableForPaymentStatus, setDisableForPaymentStatus] = React.useState(false);
    const [usersAutoComplete, setUsersAutoComplete] = React.useState([]);
    const [contactCustomerView, setcontactCustomerView] = React.useState([]);
    const [additionalFeatures, setadditionalFeatures] = React.useState(
        {
            notes:'',
            tableLayout:{},
            caterings:[],
            moderationEquipment:[],
            seats:0,
            cateringsCount:{}
        }
    );
    const [holderDisable, setHolderDisable] = React.useState(false)
    // const [durationPrice, setDurationPrice] = React.useState(0);
    // const [additonalPrice, setAdditionalPrice] = React.useState(0);
    // const [totalPrice, setTotalPrice] = React.useState(0);
    // const [personnelPrice, setPersonnelPrice] = React.useState(0);
    const [additionalPriceItems , setAdditionalPriceItems] = React.useState([])
    const [priceObj, setPriceObj] = React.useState([{}])
    const [openConfirmModal,setOpenConfirmModal] = useState(false)
    
    const handleOpenConfirmModal = () => {
        setOpenConfirmModal(true)
      }
    
      const onCloseConfirmModal = () => {
        setOpenConfirmModal(false)
      }
    

    const { t } = useTranslation();

    const [method, setMethod] = React.useState(props.method);
    const [selectTimeError, setselectTimeError] = React.useState(false);
    const [tableLayouts, setTableLayouts] = React.useState([]);
    const [tableLayoutsOfRoom, settTableLayoutsOfRoom] = React.useState([]);
    // Properties
    const [propertiesData, setPropertiesData] = React.useState([]);
    // Caterings
    const [cateringsOfRoomData, setCateringsOfRoomData] = React.useState([]);
    const [cateringsOfRoomDataRaw, setCateringsOfRoomDataRaw] = React.useState([]);
    // Moderation Equipments
    const [moderationEquipmentsOfRoomData, setModerationEquipmentsOfRoomData] = React.useState([]);
    const [moderationEquipmentsOfRoomDataRaw, setModerationEquipmentsOfRoomDataRaw] = React.useState([]);

    //Restricted Percantage
    const [isRestricted, setIsRestricted] = React.useState(false);

    const [allowOpenAddOptions, setAllowOpenAddOptions] = React.useState(false);
    const [disableForViewer, setDisableForViewer] = React.useState(false);
    const [disableForViewerPastEvent, setDisableForViewerPastEvent] = React.useState(false);
    const [showDeleteBtn, setShowDeleteBtn] = React.useState(true);
    const [showCancelBtn, setShowCancelBtn] = React.useState(true);
    const [personId, setPersonId] = React.useState({});
    
    const [displayPersonnelPrice, setDisplayPersonnelPrice] = React.useState(false);
    const [isRepeatBookingValidated , setIsRepeatBookingValidated] = React.useState(true)
    const [isDateValid,setIsDateValid] = React.useState(true)
    React.useEffect(() => {
        if (props.personId) {
            setPersonId(props.personId);
        }
        if(method === 'update') {
            handleDisableForViewer();
        }
        
    });

    React.useEffect(() => {
        if(isLoading){
            setIsLoading(false)
        }
    },[priceObj]);
    let requiredFields = [
        'room',
        'bookingHolder',
        'title',
        'customerName',
    ]
    let mailFields = [
        'sendConfirmationTo'
    ]

    const classes = useStyles();
    const [isErrorGetAnEvent, setIsErrorGetAnEvent] = React.useState(false);
    const handleRole = () => {
        if(sessionData.role === 'user'){
            setHolderDisable(true);
        }
    }
    const checkIsDateObjectValid = (date) =>{
        let isValid = true
        if (Object.prototype.toString.call(date) === "[object Date]") {
            // it is a date
            if (isNaN(date)) { // d.getTime() or d.valueOf() will also work
                setIsDateValid(false)
                isValid = false
              // date object is not valid
            } else {
                setIsDateValid(true)
                isValid = true
              // date object is valid
            }
          } else {
            setIsDateValid(false)
            isValid = false
            // not a date object
          }
        return isValid
    }

    React.useEffect(()=>{
        checkFormValid()
    },[isDateValid])

    const setselectedDateHandle = (date) => {
        //date format has to be in ISO date format 
        if(checkIsDateObjectValid(date)){
            if(prevEvent.selectedDate !== date){
                const newSelectedStart = moment(newEvent.selectedStart).set({
                        "date":moment(date).get("date"),
                        "year":moment(date).get("year"),
                        "month":moment(date).get("month")
                    }).toISOString();
                const newSelectedEnd = moment(newEvent.selectedEnd).set({
                    "date":moment(date).get("date"),
                    "year":moment(date).get("year"),
                    "month":moment(date).get("month")
                }).toISOString();
                if(checkDate(date)) {
                    if(checkIsDateObjectValid(date)){
                        setnewEvent((prevState) => ({
                            ...prevState,
                            selectedDate: date.toISOString(),
                            selectedStart:newSelectedStart,
                            selectedEnd:newSelectedEnd
                        }))
                    }

                }
            }else{
                if(checkDate(date)) {
                    setnewEvent((prevState) => ({
                        ...prevState,
                        selectedDate: date.toISOString(),
                    }))
                }
            }
        }
    }

    const handlePrice = () => {
        //This function recalculates the cost of the event based on changes in the event time 
        const newPrice = {
            startDate : newEvent.selectedStart,
            endDate: newEvent.selectedEnd,
            caterings: newEvent.createdEventSnapshot.caterings,
            moderationEquipment: newEvent.createdEventSnapshot.moderationEquipment,
            cateringsCount: newEvent.createdEventSnapshot.cateringsCount,
        }
        HTTP_REQUESTS.BOOKING_SERVICE.CALCULATE_PRICE_OF_BOOKING_PUBLIC(tempData.eventObj.roomId, newPrice, (res)=>{
            
            setnewEvent((prevState) => ({
                ...prevState,
                createdEventSnapshot: {
                    priceObj: res,
                }
            }))
            
        },(err)=>{})
    }

    React.useEffect(()=>{
        handlePrice();
    },[newEvent.selectedStart, newEvent.selectedEnd])

    const setselectedStartHandle = (date) => {
        //date format has to be in ISO date format 
        let startDate =  date.toISOString();
        let minBookingTime = decideMinBookingTime();
        let newEndDate = moment(startDate).add(minBookingTime,"m").toISOString();
        if(moment(date).isSameOrAfter(newEvent.selectedEnd) || minBookingTime > moment(newEvent.selectedEnd).diff(startDate,"minutes")){
            if(checkTimes(date,newEndDate)) {
                setnewEvent((prevState) => ({
                    ...prevState,
                    selectedStart: startDate,
                    selectedDate: startDate,
                    selectedEnd: newEndDate,
                }))
            } 
        }else{
            if(checkTimes(date,newEndDate)) {
                setnewEvent((prevState) => ({
                    ...prevState,
                    selectedStart: startDate,
                    selectedDate: startDate,
                }))
            } 
        }

    }

    const decideMinBookingTime = () =>{
        let minBookingTime = 30;
        let minBookingTimeArray = []
        if(selectedEntityData.length > 0 ){
            selectedEntityData.map((oneRoom)=>{
                if(oneRoom.minBookingTime){
                    minBookingTimeArray.push(oneRoom.minBookingTime)
                }
            })
        }
        if(minBookingTimeArray.length > 0){
            minBookingTime = Math.max(...minBookingTimeArray)
        }
        return minBookingTime;
    }

    const setselectedEndHandle = (date) => {
        //date format has to be in ISO date format 
        let endDate =  date.toISOString();
        let minBookingTime = decideMinBookingTime();
        let newStartDate = moment(endDate).subtract(minBookingTime,"m").toDate().toISOString();
        if(moment(date).isSameOrBefore(newEvent.selectedStart) || minBookingTime > moment(endDate).diff(newEvent.selectedStart,"minutes")){
            if(checkTimes(newStartDate,date)) {
                setnewEvent((prevState) => ({
                    ...prevState,
                    selectedEnd: endDate,
                    selectedStart: newStartDate,
                    selectedDate: newStartDate,
                })) 
            }
        }else{
            if(checkTimes(newStartDate,date)) {
                setnewEvent((prevState) => ({
                    ...prevState,
                    selectedEnd: endDate,
                    selectedDate: newStartDate,
                })) 
            }
        }

    }
    const checkDate = (date) => {
        if(date < new Date()){
            props.enqueueSnackbar(t('errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.PASTE_DATE_EVENT'), {
                variant: 'error'
            });
            setselectTimeError('errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.PASTE_DATE_EVENT');
            setIsDateValid(false)
            return false;
        } else {
            setselectTimeError(false);
            setIsDateValid(true)
            return true;
        }
    }

    const checkMinBookingTime = (start,end) => {
        const maxHour =  new Date(start);
        maxHour.setHours(24,0,0);  moment(maxHour).format("HH:mm")
        const minHour = new Date(end);
        minHour.setHours(0,0,0);  moment(minHour).format("HH:mm")
        let isValid = true;
        let successArray = [];
        let minBookingTime = decideMinBookingTime()
        if(selectedEntityData.length>0){
            selectedEntityData.map((oneRoom)=>{
                if(moment(start).isSameOrAfter(moment(maxHour).subtract(minBookingTime,'m'))){ //
                    isValid = false
                }else if(moment(end).isSameOrBefore(moment(minHour).add(minBookingTime,'m'))){
                    isValid = false
                }else if (minBookingTime > (moment(end).diff(moment(start),'minutes'))){  // ++++
                    isValid = false
                }
                if(isValid){
                    successArray.push(isValid)
                }
            })
            if(successArray.length > 0){
                return true
            }else{
                return false
            }
        }
        return isValid
    }

    const checkTimes = (start,end) => {
        const early = new Date();
        const late = new Date();
        const currentDate = new Date();
        early.setHours(8,0,0);  moment(early).format("HH:mm")
        late.setHours(18,0,0);  moment(late).format("HH:mm")
        let startDate = new Date(start);
        let endDate = new Date(end);
        let currentSelectTimeError = selectTimeError;
        let isMinBookingTimeValid = checkMinBookingTime(start,end)
        let roleOfOccupant = sessionData.role
        if(startDate < currentDate && roleOfOccupant !== "admin") { // Check past time
            // TODO Add case for admin role and change the error message
            setselectTimeError('errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.PASTE_DATE_EVENT');
            return false;
        }
        if(!isMinBookingTimeValid){
            setselectTimeError('errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.MIN_BOOKING_TIME');
            return false
        } 
        if(moment(end).format("HH:mm") <= moment(start).format("HH:mm")){
            setselectTimeError('errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.WRONG_TIME_RANGE');
            if(currentSelectTimeError === 'errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.START_DATE_LATE' ) {
                setselectTimeError('errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.START_DATE_LATE');
            } else {
                setselectTimeError(false);
            }
            return false;
        }
        else if(moment(late).format("HH:mm") <= moment(startDate).format("HH:mm")){
            setselectTimeError('errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.START_DATE_LATE');
            return true;
        } else if((moment(startDate).format("HH:mm") < moment(early).format("HH:mm")) || (moment(late).format("HH:mm") < moment(endDate).format("HH:mm"))){
            setselectTimeError('errorProperties.RCALENDAR.SHOW_CREATE_EVENT_MODAL.START_DATE_EARLY');
            return true;
        } else {
            setselectTimeError(false);
            return true;
        }
    }
    const checkFormValid = () => {
        let tempValid = true;
        requiredFields.map((key) => 
            {
                if(tempValid) {
                    if(!newEvent[key] || newEvent[key] === '') {
                        tempValid = false;
                    }
                }
            }
        )
        mailFields.forEach((key) => {
        if(newEvent[key] && !emailStrIsValid(newEvent[key])) {
            tempValid = false;
        }
        })
        if(newEvent.deviceId){
            tempValid = false;
        }
        if(isMultiple && newEvent.room.length === 0 ){
            tempValid = false;
        }
        if(!isMultiple && !newEvent.room ){
            tempValid = false;
        }
        if(!isRepeatBookingValidated && openRepeatingBooking){
            tempValid = false;
        }
        if(!isDateValid){
            tempValid = false;
        }
        setformValid(tempValid);
    }
    React.useEffect(() => {
        if(props.method === 'update') {
                getAEvent(newEvent.id);
                if(tempRoom)
                    setnewEvent((prevState) => ({
                        ...prevState,
                        room: tempRoom,
                    }))
        }
        if(props.selectedEntity && props.method === 'create') { // For creating method at room calendar
            layoutsOfRoom(props.selectedEntity);
        }
    },[]);
    React.useEffect(() => {
        let tempCateringsOfRoomArray = []; let tempCateringsOfRoomArrayRaw = [];
        let tempModerationEquipmentsOfRoomArray = []; let tempModerationEquipmentsOfRoomRaw = [];
        propertiesData.forEach((item) => {
            if(item.Properties){
                for(const oneProperty of item.Properties){
                    switch (oneProperty.type) {
                        case "Catering":
                            tempCateringsOfRoomArray.push(oneProperty.name);
                            tempCateringsOfRoomArrayRaw.push(oneProperty);
                            break;
                        case "ModerationEquipment":
                        if(oneProperty.name !== 'Personalaufwand') {
                            /* TODO add db field as isVisible (default true) */
                            tempModerationEquipmentsOfRoomArray.push(oneProperty.name)
                            tempModerationEquipmentsOfRoomRaw.push(oneProperty)
                        }
                            break;
                        default:
                            break;
                    }
                }
            }else{
                switch (item.type) {
                    case "Catering":
                        tempCateringsOfRoomArray.push(item.name);
                        tempCateringsOfRoomArrayRaw.push(item);
                        break;
                    case "ModerationEquipment":
                    if(item.name !== 'Personalaufwand') {
                        /* TODO add db field as isVisible (default true) */
                        tempModerationEquipmentsOfRoomArray.push(item.name)
                        tempModerationEquipmentsOfRoomRaw.push(item)
                    }
                        break;
                    default:
                        break;
                }
            }
        })
        
        if(props.selectedEntity && props.method === 'create') { // For creating method at room calendar
            cateringsOfRoom(props.selectedEntity);
            moderationEquipmentsOfRoom(props.selectedEntity);
        } else {
            setCateringsOfRoomData(tempCateringsOfRoomArray);
            setCateringsOfRoomDataRaw(tempCateringsOfRoomArrayRaw);
            setModerationEquipmentsOfRoomData(tempModerationEquipmentsOfRoomArray);
            setModerationEquipmentsOfRoomDataRaw(tempModerationEquipmentsOfRoomRaw);
        }
    }, [propertiesData])

    function inputGroupChangeHandler(event){
        if (event.constructor.name === 'SyntheticEvent') {
            event.persist();
        }
            setnewEvent((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
            }));
        if(event.target.name==='room'){
                /* TODO add config check */
                if(tenantConfig.features.additionalOptions){
                    layoutsOfRoom(event);
                    cateringsOfRoom(event);
                    moderationEquipmentsOfRoom(event);
                }
                generateShowPriceInformations();
            }
        // })
    }

    const layoutsOfRoom = (event) => {
        let selectedRoom;
        selectedRoom = (event.target) ? event.target.value : event;
        let tempTableLayoutsOfRoom;
        if(isMultiple){
            tempTableLayoutsOfRoom = tableLayouts.filter(tableLayout => tableLayout.roomId === selectedRoom[0])[0];
        }else{
            tempTableLayoutsOfRoom = tableLayouts.filter(tableLayout => tableLayout.roomId === selectedRoom)[0];         
        }
        let tempTableLayoutsOfRoomArray = [];
        if(tempTableLayoutsOfRoom) {
            tempTableLayoutsOfRoom.RoomLayouts.forEach((item) => {
                if(props.method !== 'update') {
                    if (item.layout.name === 'Parlament') { /* TODO (Emre) Parlement should be variable*/
                        let tempDefaultItem = {id:item.layoutId, name:item.layout.name}
                        setadditionalFeatures((prevState) => ({
                            ...prevState,
                            'tableLayout': tempDefaultItem,
                            "seats" :item.layoutSeat
                        }));
                    }
                }
                tempTableLayoutsOfRoomArray.push({id:item.layoutId, name:item.layout.name})
            })
            settTableLayoutsOfRoom(tempTableLayoutsOfRoomArray);
            if(tenantConfig.features.additionalOptions){

                setAllowOpenAddOptions(true);
            }
        }else{
            if(tenantConfig.features.additionalOptions){
                setAllowOpenAddOptions(false);
            }
        }
    }
    // Caterings
    const cateringsOfRoom = (event) => {
        let selectedRoom; 
        selectedRoom = (event.target) ? event.target.value : event;
        let tempPropertiesOfRoom;
        if(isMultiple){
            tempPropertiesOfRoom = propertiesData.filter(item => item.roomId === selectedRoom[0])[0];
        }else{
            tempPropertiesOfRoom = propertiesData.filter(item => item.roomId === selectedRoom)[0];
        }
        let tempCateringsOfRoomArray = [];
        let tempCateringsOfRoomArrayRaw = [];
        if(tempPropertiesOfRoom) {
            tempPropertiesOfRoom.Properties.forEach((item) => {
                // if(props.method !== 'update') {
                    if (item.type === 'Catering') {
                        tempCateringsOfRoomArray.push(item.name)
                        tempCateringsOfRoomArrayRaw.push(item)
                    }
                // }
            })
            setCateringsOfRoomData(tempCateringsOfRoomArray);
            setCateringsOfRoomDataRaw(tempCateringsOfRoomArrayRaw);
        }
    }
    // Moderation Eqipments
    const moderationEquipmentsOfRoom = (event) => {
        let selectedRoom; 
        selectedRoom = (event.target) ? event.target.value : event;
        let tempPropertiesOfRoom;
        if(isMultiple){
            tempPropertiesOfRoom = propertiesData.filter(item => item.roomId === selectedRoom[0])[0];
        }else{
            tempPropertiesOfRoom = propertiesData.filter(item => item.roomId === selectedRoom)[0];
        }
        let tempModerationEqipmentsOfRoomArray = [];
        let tempModerationEqipmentsOfRoomArrayRaw = [];
        if(tempPropertiesOfRoom) {
            tempPropertiesOfRoom.Properties.forEach((item) => {
                // if(props.method !== 'update') {
                    if (item.type === 'ModerationEquipment') {
                        if(item.name !== 'Personalaufwand') {
                            /* TODO add db field as isVisible (default true) */
                            tempModerationEqipmentsOfRoomArray.push(item.name)
                            tempModerationEqipmentsOfRoomArrayRaw.push(item)
                        }
                    }
                // }
            })
            setModerationEquipmentsOfRoomData(tempModerationEqipmentsOfRoomArray);
            setModerationEquipmentsOfRoomDataRaw(tempModerationEqipmentsOfRoomArrayRaw);
        }
    }
    const getRoomProperties = () => {
        if(props.method === 'update') {
            paymentStatus = tempData.eventObj.paymentStatus;
            if(paymentIsActive){
                if(paymentStatus===PAYMENT_STATUS.PAID)
                    setDisableForPaymentStatus(true)
                if (paymentStatus === PAYMENT_STATUS.NOT_PAID)
                    setDisableForPaymentStatus(false)
            }
            HTTP_REQUESTS.CALENDAR_SERVICE.GET_ONE_CALENDAR(tempData.eventObj.room.id, data=>{
                setPropertiesData(data.room.Properties)
            }, (err) => {
                this.props.enqueueSnackbar(t('errorProperties.ENTITIES_EDIT_TABLE.RENDER.ON_ROW_UPDATE.ERROR_OCCURED'), {
                variant: 'error'})
            })
        }
    }
    const inputAutocompleteChangeHandler = (event,newValue) => {
        if (event.constructor.name === 'SyntheticEvent') {
            event.persist();
        }
        setcontactCustomerView(newValue);
        setnewEvent((prevState) => ({
        ...prevState,
        'contactCustomer': newValue,
        }));
    }
    const inputGroupChangeHandlerAddtionalFeatures = (event) => {
        if (event.constructor.name === 'SyntheticEvent') {
            event.persist();
        }
            let dataType = (event.target.dataset) ? event.target.dataset.type || '' : '';
            if(dataType) {
                setadditionalFeatures((prevState) => ({
                    ...prevState,
                    [dataType] : {
                        ...prevState[dataType],
                        [event.target.name]: event.target.value
                    }
                }));
            } else {
                if(event.target.name === 'tableLayout') {
                    let tempRoomLayoutData;
                    if(isMultiple){
                        tempRoomLayoutData = tableLayouts.filter((item)=> item.roomId === newEvent.room[0])[0]
                    }else{
                        tempRoomLayoutData = tableLayouts.filter((item)=> item.roomId === newEvent.room)[0];
                    }
                    let tempSeat = tempRoomLayoutData.RoomLayouts.filter((item) => item.layoutId === event.target.value.id)[0];
                    setadditionalFeatures((prevState) => ({
                    ...prevState,
                    'seats': tempSeat.layoutSeat,
                    [event.target.name]: event.target.value
                    }));
                } else {
                    setadditionalFeatures((prevState) => ({
                    ...prevState,
                    [event.target.name]: event.target.value
                    }));
                }  
            }
    }


    const checkBoxChangeHandler = (event) => {
        if (event.constructor.name === 'SyntheticEvent') {
            event.persist();
        }
        let tempChecked = event.target.checked; /* TODO : Improve it */
        if(event.target.name === 'isReccured' ) {
            setopenRepeatingBooking(!openRepeatingBooking);
        }

        setnewEvent((prevState) => ({
        ...prevState,
        [event.target.name]: tempChecked
        }));
    }
    const calculateMainColumn = () => {
        return (openAddOptions || openRepeatingBooking) ? 12 : 12;
    }
    React.useEffect(() => {
        /* TODO: Research solution without using firstload */
        if(firstLoad) {
            setFirstLoad(false);
            handleRole()
            let currentTenantId = sessionData.tenantId;
            fetchEntities(currentTenantId,method);
            const getUsers__ = (newUsers) => {
                var letTempUsers = [];
                for (const element of Object.keys(newUsers)) {
                    letTempUsers.push(newUsers[element])
                }
                setUsers(newUsers);
                setUsersAutoComplete(letTempUsers)
            };
            const getUsers_ = () => {
                getUsersForSelectionWithIdValues(getUsers__,); //todo need to add errorCallback parameter 
            };
            getUsers_();
            getRoomProperties();
        }
    })

    function usePrevious(value) {
        const ref = useRef();
        React.useEffect(() => {
          ref.current = value;
        });
        return ref.current;
      }

    const prevEvent = usePrevious(newEvent);
    
    React.useEffect(() => {
        checkFormValid();
        if(prevEvent){
            setopenRepeatingBooking(newEvent.isReccured);
            if(prevEvent.selectedStart != newEvent.selectedStart 
                || prevEvent.selectedEnd != newEvent.selectedEnd
                    || prevEvent.selectedDate != newEvent.selectedDate 
                        //|| prevEvent.room != newEvent.room
                            || JSON.stringify(prevEvent.additionalFeatures.caterings) !== JSON.stringify(newEvent.additionalFeatures.caterings)
                                || JSON.stringify(prevEvent.additionalFeatures.moderationEquipment) !== JSON.stringify(newEvent.additionalFeatures.moderationEquipment)
                                    || JSON.stringify(prevEvent.additionalFeatures.cateringsCount) !== JSON.stringify(newEvent.additionalFeatures.cateringsCount))
                                {
                                if(newEvent.room) {
                                    calculatePrice();
                                }
                                if(prevEvent.selectedStart != newEvent.selectedStart 
                                    || prevEvent.selectedEnd != newEvent.selectedEnd  
                                        || prevEvent.selectedDate != newEvent.selectedDate){
                                            let currentTenantId = sessionData.tenantId;
                                            fetchEntities(currentTenantId,method);
                                }
            }
        } else {
            setopenRepeatingBooking(newEvent.isReccured); // TODO-recurrent
        }
    }, [newEvent,isRepeatBookingValidated])
    const calculatePrice = () => {
        const eventData = dataToEventObj(newEvent)
        HTTP_REQUESTS.BOOKING_SERVICE.CALCULATE_PRICE_OF_BOOKING(eventData , res => {
            //TODO : Set price field here 
            Object.keys(res).forEach((item) => {
                if(!res[item] || typeof res[item] === 'undefined') {
                    res[item] = 0;
                }
            })
            let personnelPriceCount = 0
            res.forEach(element => {
                if (element.personnelPrice > 0) {
                    personnelPriceCount += 1
                }
            });
            if(personnelPriceCount !== 0){
                setDisplayPersonnelPrice(true)
            }else{
               setDisplayPersonnelPrice(false)
            }
            if(!isMultiple) { // !isArray(res)
            setPriceObj([res]) } else {
                let tempRes = [];
                res.map(resItem=>{ 
                    tempRes.push(resItem)
                })
                setPriceObj(tempRes);
            }
           /*  setDurationPrice(res.Duration)
            setAdditionalPrice(res.AdditionalOptions)
            setTotalPrice(res.Total) */
            // setPersonnelPrice(res.personnelPrice)
            setAdditionalPriceItems(res.additionalPriceItems)
        }, err=> {

        })
    }
    React.useEffect(() => {
        let selectedEntity;
        if(entities.length !== 0) {
            selectedEntity = entities.filter(entity => entity.id === newEvent.room)[0]
        }else{
            selectedEntity = newEvent.room
        }
        if(selectedEntity)
        {
            let tempSelectedEntity =  [selectedEntity]
            setSelectedEntityData(tempSelectedEntity)
            if(selectedEntity.restrictedPercantage > 0){
                setIsRestricted(true)
            }
        }

    }, [entities])

    React.useEffect(() => {
        if(selectedEntityData && selectedEntityData.length !== 0 && selectedEntityData[0] && selectedEntityData[0].length !== 0){
            let minBookingTime = decideMinBookingTime();
            let newEndDate = moment(newEvent.selectedStart).add(minBookingTime,"m").toDate()
            if(moment(newEvent.selectedEnd).isSameOrBefore(newEvent.selectedStart) || minBookingTime > moment(newEvent.selectedEnd).diff(newEvent.selectedStart,"minutes")){
                if(checkTimes(newEvent.selectedStart,newEndDate)) {
                    setnewEvent((prevState) => ({
                        ...prevState,
                        selectedEnd: newEndDate,
                    })) 
                };
            }

        }
    }, [selectedEntityData])

    const selectedEntityDataHandler = () => {
        let tempSelectedEntity = []
        let selectedEntity;
        if(entities.length !== 0) {
            if(isMultiple) {
                for(const roomItem of newEvent.room) {
                    selectedEntity = entities.filter(entity => entity.id === roomItem)[0];
                    tempSelectedEntity.push(selectedEntity);
                }
            } else {
                selectedEntity = entities.filter(entity => entity.id === newEvent.room)[0]
                tempSelectedEntity.push(selectedEntity);
            }
            if(newEvent.room){
                setSelectedEntityData(tempSelectedEntity)
            }
        }
        else{
            selectedEntity = newEvent.room
            tempSelectedEntity.push(selectedEntity);
            if(newEvent.room){
                setSelectedEntityData(tempSelectedEntity)
            }
        }

    }
    React.useEffect(() => {
        setIsLoading(true)
        if(newEvent.room){
            calculatePrice();
        }
        selectedEntityDataHandler();
    }, [newEvent.room])

    React.useEffect(() => {
        setnewEvent((prevState) => ({
            ...prevState,
            additionalFeatures: additionalFeatures
            }));
    }, [additionalFeatures])
    const fetchEntities= (tenantId,method) =>{
        HTTP_REQUESTS.CALENDAR_SERVICE.GET_ALL_CALENDARS(
            (data) => {
                let entitiesArray = [];
                let layoutsArray = [];
                let propertiesArray = [];
                data.returnArray.forEach(function (calendar, index) {
                   if (!calendar.primary) {
                        let oneCalendar = calendar.room
                        entitiesArray.push(oneCalendar);
                        calendar.room.RoomLayouts = calendar.room.RoomLayouts.map(layout=>{
                            layout.layoutSeat = calculateRestrictedSeats(layout.layoutSeat,calendar.room.restrictedPercantage)
                            return layout
                        })
                        layoutsArray.push({roomId:calendar.room.id, RoomLayouts:calendar.room.RoomLayouts});
                        propertiesArray.push({roomId:calendar.room.id, Properties:calendar.room.Properties});
                   }
                });
                setEntities(entitiesArray);
                setRoomLoaded(true)
                setTableLayouts(layoutsArray);
                clearSelectedRoom()
                if(props.method !== 'update') { // To initial all properties at create
                    setPropertiesData(propertiesArray);
                }
            }, (err) => {
                props.enqueueSnackbar(t('errorProperties.ENTITIES_EDIT_TABLE.COMPONENT_DID_MOUNT'), {
                    variant: 'error'
                });
                // navigate("/registerentity");
            },method === "create" ? {availableObj:{checkAvailable:true,start:newEvent.selectedStart,end:newEvent.selectedEnd,method:"create"}}:
                                    {availableObj:{checkAvailable:true,start:newEvent.selectedStart,end:newEvent.selectedEnd,
                                        method:"update",selectedRoom:tempRoom,selectedEvent:newEvent.id}});

    } 

    const calculateRestrictedSeats = (seat,percantage) => {
        let result = Math.floor(((100-percantage)* seat)/100);
        return result
    }

    const getAEvent = (eventId) => {
                 let tempParticipiants = tempData.eventObj.participants;
                 let tempParticipiantsView = tempParticipiants.map(participant => {
                     return {id: participant.account.id, mail: participant.account.email}
                 })
                 let eventNote ="";
                 let customerName=""
                 setnewEvent((prevState) => ({
                     ...prevState,
                     bookingStatus:tempData.eventObj.status,
                     bookingHolder:tempData.eventObj.ownerAccountId,  
                     billingAddress: tempData.eventObj.billingAddress,
                     sendConfirmationTo:tempData.eventObj.createdEventSnapshot.confirmMail,
                     customerName:tempData.eventObj.customerName,
                     contactCustomer:tempParticipiantsView,
                     isReccured:tempData.eventObj.isReccured, // TODO-recurrent
                     //Recurring data 
                     createdEventSnapshot : tempData.eventObj.createdEventSnapshot,  
                     repeatingBookingData: {
                        reccuredType: tempData.eventObj.reccuredType,
                        reccuredCount:  tempData.eventObj.reccuredCount,
                        reccuredPeriod: tempData.eventObj.reccuredPeriod,
                        reccuredWeekDay :tempData.eventObj.reccuredWeekDay,
                    }
                    }));
                    let createdEventSnapshot,tempTableLayout, tempcateringDropdown, tempModerationEquipment, tempSeats,tempcateringsCount;
                    if(tempData.eventObj) {
                        eventNote = tempData.eventObj.note
                        createdEventSnapshot = tempData.eventObj.createdEventSnapshot;
                        if(createdEventSnapshot) {
                            tempTableLayout = createdEventSnapshot.tableLayout;
                            tempcateringDropdown = createdEventSnapshot.caterings;
                            tempModerationEquipment = createdEventSnapshot.moderationEquipment;
                            tempSeats = createdEventSnapshot.seat;
                            tempcateringsCount = createdEventSnapshot.cateringsCount
                        }
                    }
                   
                    // TODO Add set values to form 
                  setadditionalFeatures((prevState) => ({
                    ...prevState,
                    tableLayout: tempTableLayout,
                    caterings:tempcateringDropdown,
                    moderationEquipment:tempModerationEquipment,
                    notes:eventNote,
                    seats:tempSeats,
                    cateringsCount:tempcateringsCount
                  }))
                  setPriceObj(tempData.eventObj.createdEventSnapshot.priceObj[0] ? tempData.eventObj.createdEventSnapshot.priceObj : [tempData.eventObj.createdEventSnapshot.priceObj])
                  setcontactCustomerView(tempParticipiantsView);
                  layoutsOfRoom(tempData.eventObj.room.id);
                  // cateringsOfRoom(data.eventObj.Room.id);
                  // moderationEquipmentsOfRoom(data.eventObj.Room.id);
                  /*if(tenantConfig.features.additionalOptions){
                    setAllowOpenAddOptions(true);
                    }*/
                  checkTimes(tempData.eventObj.startDate,tempData.eventObj.endDate)
                  if(tenantConfig.features.additionalOptions){
                    if (newEvent.bookingHolder !== sessionData.id && sessionData.role !== 'admin') {
                        setopenAddOptions(true);
                    }
                  }
            
    }

    const handleSave = () => {
        
        const event= dataToEventObj(newEvent);
        HTTP_REQUESTS.BOOKING_SERVICE.UPDATE_A_BOOKING(newEvent.id, event,  (res) => {
            props.enqueueSnackbar(t('Booking.InfoCard.updateEventSuccess'), {
                variant: 'success'
            });
            props.eventEditCloser();
        }, (err) => {
            props.enqueueSnackbar(t('Booking.InfoCard.updateEventFail'), {
                variant: 'error'
            });
        })

        // let obj = newEvent
        // obj.priceObj = priceObj
        // if(tenantConfig.features.customization){
        //     obj.additionalFeatures.caterings = filterCaterings(obj.additionalFeatures.caterings)
        //     obj.additionalFeatures.moderationEquipment = filterModerationEquipment(obj.additionalFeatures.moderationEquipment)
        // }
        // props.handleSave(obj,method);
    }

    const filterCaterings = (nameArray) =>{
        let filteredCaterings = []
        let foundCateringName = ""
        for(const oneName of nameArray){
            foundCateringName = cateringsOfRoomDataRaw.filter((item)=> item.name === oneName && !item.deletedAt)[0]
            if(foundCateringName){
                filteredCaterings.push(foundCateringName.name)
            }
        }
        return filteredCaterings
    }

    const filterModerationEquipment = (nameArray) =>{
        let filteredCaterings = []
        let foundCateringName = ""
        for(const oneName of nameArray){
            foundCateringName = moderationEquipmentsOfRoomDataRaw.filter((item)=> item.name === oneName && !item.deletedAt)[0]
            if(foundCateringName){
                filteredCaterings.push(foundCateringName.name)
            }
        }
        return filteredCaterings
    }

    const openAddOptionsHandle= () => {
        setopenAddOptions(!openAddOptions);
    }
    const repeatingBookingHandle = (event) => {

        setnewEvent((prevState) => ({
            ...prevState,
            repeatingBookingData: {...prevState.repeatingBookingData, [event.target.name]: event.target.value}
        }))
    }
    const generateEntitiesSettings = () => {
        /**
         * TODO (Emre) Finish all inputs to bind newEvent
         */
        return  <Grid container item xs={12} spacing={1} >

                    <Grid item container xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{t('Booking.InfoCard.Notes')}</Typography>
                            <NotesTextField name="notes" disabled={disableForViewer} value={additionalFeatures.notes || ''} onChange={inputGroupChangeHandlerAddtionalFeatures}></NotesTextField>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}  className={classes.center}>
                        <Grid item xs={12}  >
                            <Typography variant="h6">{t('Booking.InfoCard.Table')}</Typography>
                        </Grid>
                        <Grid item xs={12}  >
                            <TableLayoutDropdown name="tableLayout" disabled={disableForPaymentStatus || disableForViewer} layoutsData={tableLayoutsOfRoom} value={additionalFeatures.tableLayout || ''} onChange={inputGroupChangeHandlerAddtionalFeatures}></TableLayoutDropdown>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}  className={classes.center}>
                        <Grid item xs={12}  >
                            <Typography variant="h6">{t('Booking.InfoCard.Caterings')}</Typography>
                        </Grid>
                        <Grid item xs={12}  >
                            <CateringDropdown name="caterings" disabled={disableForPaymentStatus || disableForViewer} cateringsDataRaw={cateringsOfRoomDataRaw} cateringsData={cateringsOfRoomData} value={additionalFeatures.caterings || []} cateringsCounts={additionalFeatures.cateringsCount || {}} onChange={inputGroupChangeHandlerAddtionalFeatures} onChangeCount={inputGroupChangeHandlerAddtionalFeatures}></CateringDropdown>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Typography variant='caption'>{"*"+t('Booking.InfoCard.Water Coffee')}</Typography>
                    </Grid>
                    <Grid item container xs={12}  className={classes.center}>
                            <Grid item xs={12}  >
                            <Typography variant="h6">{t('Booking.InfoCard.Moderation')}</Typography>
                        </Grid>
                        <Grid item xs={12}  >
                            <ModerationEquipmentDropdown disabled={disableForPaymentStatus || disableForViewer} name="moderationEquipment" moderationEquipmentsDataRaw={moderationEquipmentsOfRoomDataRaw} moderationEquipmentsData={moderationEquipmentsOfRoomData} value={additionalFeatures.moderationEquipment || []} onChange={inputGroupChangeHandlerAddtionalFeatures}></ModerationEquipmentDropdown>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}  className={classes.center}>
                        <Grid item xs={12}  >
                            <Typography variant="h6">{t('Booking.InfoCard.Seats')}</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <SeatsTextField name="seats" disabled={disableForViewer} value={additionalFeatures.seats || 0} isRestricted={isRestricted} onChange={inputGroupChangeHandlerAddtionalFeatures}></SeatsTextField>
                        </Grid>
                    </Grid>
                </Grid>
    }

    const generateNewPrice = () => {
        if(newEvent.createdEventSnapshot && newEvent.createdEventSnapshot.priceObj){
            for(const oneRoomPriceObj of priceObj){
                if(oneRoomPriceObj.roomId === newEvent.createdEventSnapshot.priceObj.roomId){
                    const stringPriceObj = JSON.stringify(oneRoomPriceObj)
                    const stringCreatedEventSnapshot= JSON.stringify(newEvent.createdEventSnapshot.priceObj)
                    if(stringPriceObj !== stringCreatedEventSnapshot){
                        return <Grid item xs={12} lg={12} >
                        <Card style={{'padding': '16px'}}>
                            <Grid item xs={12} align='left' style={{'marginBottom': '16px'}}>
                                <Typography variant="h5">{t('Booking.InfoCard.newprice' )}</Typography>
                            </Grid>
                            <Grid item container xs={12} >
                                <Grid item xs={6}>
                                    <Typography variant="p">{t('Booking.InfoCard.duration' )}</Typography>
                                </Grid>
                                <Grid item xs={6} align='end'>
                                    <Typography variant="p"> {newEvent.createdEventSnapshot.priceObj.duration.toFixed(2).replace('.',',')} € </Typography>
                                </Grid>
                            </Grid>
                            {tenantConfig.features.additionalOptionPrice ?
                                <Grid item container xs={12} >
                                    <Grid item xs={6}>
                                        <Typography variant="p">{t('Booking.InfoCard.additional' )}</Typography>
                                    </Grid>
                                    <Grid item xs={6} align='end'>
                                        <Typography variant="p">{newEvent.createdEventSnapshot.priceObj.additionalOptions.totalPrice.toFixed(2).replace('.',',')} €</Typography>
                                    </Grid>
                                </Grid>:null
                            }
                            {tenantConfig.features.additionalOptionPrice ?
                                <Grid item container xs={12} >
                                    <>
                                        {newEvent.createdEventSnapshot && newEvent.createdEventSnapshot.priceObj.additionalOptions.items.caterings.items.map((item, index) =>
                                            <Grid item xs={12} key={index}>
                                                <Typography variant="p" style={{ padding: 15 }}>{item.name}, {item.price}€  {(item.amount) ? "x"+item.amount : null}</Typography>
                                            </Grid>
                                        )}
                                        {newEvent.createdEventSnapshot && newEvent.createdEventSnapshot.priceObj.additionalOptions.items.moderationEquipments.items.map((item, index) =>
                                            <Grid item xs={12} key={index}>
                                                <Typography variant="p" style={{ padding: 15 }}>{item.name}, {item.price}€  {(item.amount) ? "x"+item.amount : null}</Typography>
                                            </Grid>
                                        )}
                                    </>
                                </Grid>:null                                
                            }

                            <Grid item container xs={12} >
                                <Grid item xs={6}>
                                    <Typography variant="p">{t('Booking.InfoCard.total' )}</Typography>                                   
                                </Grid>
                                <Grid item xs={6} align='end'>
                                    <Typography variant="p">{newEvent.createdEventSnapshot && newEvent.createdEventSnapshot.priceObj.total.toFixed(2).replace('.',',')} €</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    }
                }
            }
        }
    }

    const generatePrice = () => {
        let oldPriceHeader; //this variable is used in price display to show old price
        if(newEvent.createdEventSnapshot && newEvent.createdEventSnapshot.priceObj){
            for(const oneRoomPriceObj of priceObj){
                if(oneRoomPriceObj.roomId === newEvent.createdEventSnapshot.priceObj.roomId){
                    const stringPriceObj = JSON.stringify(oneRoomPriceObj)
                    const stringCreatedEventSnapshot= JSON.stringify(newEvent.createdEventSnapshot.priceObj)
                    if(stringPriceObj !== stringCreatedEventSnapshot){
                        oldPriceHeader = t('Booking.InfoCard.old');
                    }else{
                        oldPriceHeader = null;
                    }
                }
            }
        }
        return priceObj.map((item, index) =>
            <Grid item xs={12} lg={12} key={index}>
                    <Card style={{'padding': '16px'}}>
                        <Grid item xs={12} align='left' style={{'marginBottom': '16px'}}>
                            <Typography variant="h5">{oldPriceHeader}{t('Booking.InfoCard.price' )}</Typography>
                        </Grid>
                        <Grid item container xs={12} >
                            <Grid item xs={6}>
                                <Typography variant="p">{t('Booking.InfoCard.duration' )}</Typography>                                   
                            </Grid>
                            <Grid item xs={6} align='end'>
                                <Typography variant="p"> {item.duration && item.duration.toFixed(2).replace('.',',')} € </Typography>
                            </Grid>
                        </Grid>
                        {tenantConfig.features.additionalOptionPrice ?
                            <Grid item container xs={12} >
                                <Grid item xs={6}>
                                    <Typography variant="p">{t('Booking.InfoCard.additional' )}</Typography>                                   
                                </Grid>
                                <Grid item xs={6} align='end'>
                                    <Typography variant="p">{item.additionalOptions && item.additionalOptions.totalPrice.toFixed(2).replace('.',',')} €</Typography>
                                </Grid>
                            </Grid>
                            : null
                        }
                        {tenantConfig.features.additionalOptionPrice ?
                            <Grid item container xs={12} >
                                <>
                                    {newEvent.createdEventSnapshot && newEvent.createdEventSnapshot.priceObj.additionalOptions.items.caterings.items.map((item, index) =>
                                        <Grid item xs={12} key={index}>
                                            <Typography variant="p" style={{ padding: 15 }}>{item.name}, {item.price}€  {(item.amount) ? "x"+item.amount : null}</Typography>
                                        </Grid>
                                    )}
                                    {newEvent.createdEventSnapshot && newEvent.createdEventSnapshot.priceObj.additionalOptions.items.moderationEquipments.items.map((item, index) =>
                                        <Grid item xs={12} key={index}>
                                            <Typography variant="p" style={{ padding: 15 }}>{item.name}, {item.price}€  {(item.amount) ? "x"+item.amount : null}</Typography>
                                        </Grid>
                                    )}
                                </>
                            </Grid>
                            :null
                        }
                        {(displayPersonnelPrice) 
                        ?
                        <Grid item container xs={12} >
                            <Grid item xs={6}>
                                <Typography variant="p">{t('Booking.InfoCard.Personnel' )}</Typography>                                   
                            </Grid>
                            <Grid item xs={6} align='end'>
                                <Typography variant="p">{item.personnelPrice && item.personnelPrice.toFixed(2).replace('.',',')} €</Typography>
                            </Grid>
                        </Grid>
                        : null}
                        { tenantConfig.features.showOutsideOfWorkHoursPrice
                        ?
                        <Grid item container xs={12} >
                            <Grid item xs={6}>
                                <Typography variant="p">{t('Booking.InfoCard.outsideHoursPrice')}</Typography>
                            </Grid>
                            <Grid item xs={6} align='end'>
                                <Typography variant="p">{item.outsideHoursPrice && item.outsideHoursPrice.toFixed(2).replace('.',',')} €</Typography>
                            </Grid>
                        </Grid>
                        : null}
                        <Grid item container xs={12} >
                            <Grid item xs={6}>
                                <Typography variant="p">{t('Booking.InfoCard.total' )}</Typography>                                   
                            </Grid>
                            <Grid item xs={6} align='end'>
                                <Typography variant="p">{item.total && item.total.toFixed(2).replace('.',',')} €</Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>)
    }
    const generateShowPriceInformations = () => {
        return selectedEntityData.map((item, index)=>
        item && item.id ?
        <Grid item xs={12} lg={12} key={index}>
            <Card style={{'padding': '16px', "height": "100%"}}>
                <Grid item xs={12} align='left' style={{'marginBottom': '16px'}}>
                    <Typography variant="h5">{item.name} {t('Entities.RCalendar.Price Information' )}</Typography>
                </Grid>
                    <PriceInformations allDayRoomPrice={item.allDayRoomPrice} additionalPrice={item.additionalPrice} halfDayRoomPrice={item.halfDayRoomPrice} description={item.description} />
            </Card>
        </Grid>
        :null      
        ) 
    }

    const calculateLabels = () => {
        if(method === 'update') { 
            return { title:t('Booking.InfoCard.Update Booking')} 
        } else if(method === 'create') {
            return {title:t('Booking.InfoCard.Create Booking')} 
        }
    }
    const isEditing = () => {
        return (method === 'update') ? true : false;
    }
    const canBeSaved = () => {
        if(isEditing()) {
            if(tempData.editable) {
                return true;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
    const handleDeleteBooking = (data) => {
        onCloseConfirmModal();
        let deleteAll = (data === true) ? true : false;
        let isCancel = false
        props.handleCancelBooking(newEvent.id, newEvent.room,deleteAll,isCancel);
    }

    const handleCloneBooking = () => {
        setMethod('create');
        setDisableForPaymentStatus(false);
        if(paymentIsActive){
            setnewEvent((prevState) => ({
                ...prevState,
                bookingStatus:'reserved'
            }));
        }
    }

    const handleDisableForViewer = () => {
        handleDisablePastEventForViewer();
        let roleOfOccupant = sessionData.role
        let currentId = sessionData.id
        if(newEvent.state === "cancelled"){
            setDisableForViewer(true)
        }else if (roleOfOccupant === "admin") {
            setDisableForViewer(false)
            return
        }else {
            if (newEvent.bookingHolder === currentId) {
                if(!disableForViewerPastEvent) {
                    setDisableForViewer(false)
                } else {
                    setDisableForViewer(true)
                }
                return
            } else {
                setDisableForViewer(true);
                setShowDeleteBtn(false)
            }
        }
    }
    
    const handleDisablePastEventForViewer = () => {
        let roleOfOccupant = sessionData.role
        let currentId = sessionData.id
        if (roleOfOccupant === "admin") {
            setDisableForViewerPastEvent(false)
            /*if(props.bookingData && props.bookingData.eventObj.state == "cancelled"){
                setShowDeleteBtn(false)
            }*/
            return
        } else {
            if (personId === currentId) {
                if(moment(newEvent.selectedDate).isBefore(moment(new Date()))) {
                    setDisableForViewerPastEvent(true)
                    setShowDeleteBtn(false)
                    return
                }
            }
        }
    }

    const checkDashboardOrRoomsCalendar = () =>{
        if(props.selectedEntity){
            if(entities.filter(item => item.id === props.selectedEntity).length > 0){
                if(!newEvent.room){
                    setnewEvent((prevState) => ({
                        ...prevState,
                        room:props.selectedEntity
                    }));
                }
                return true
            }else{
                return false
            }
        }else{
            if(entities.length > 0 ){
                return true
            }else{
                return false
            }
        }
    }

    const handleIsRepeatBookingValidated = (value) =>{
        setIsRepeatBookingValidated(value)
    }

    const clearSelectedRoom = () => {
        if(isMultiple){
            if(entities.some(item => newEvent.room.includes(item.id))){
                setnewEvent((prevState) => ({
                    ...prevState,
                    room:(isMultiple) ? [] : props.selectedEntity
                }));
            }
        }else{
            if(entities.some(item => item.id === newEvent.room)){
                setnewEvent((prevState) => ({
                    ...prevState,
                    room:(isMultiple) ? [] : ""
                }));
            }
        }
    }


        if(props.isLoading && isLoading){
            return(
                <div className={classes.spinner}>
                    <CustomSpinner/>
                </div>
            )
        }
    return (
        <Stack spacing={0} direction="column" sx={{paddingTop:"0px", paddingBottom:"8px", paddingLeft:"8px", paddingRight:"8px"}}>
            <Grid container item spacing={0} xs={12}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{mr:"40px", background:"white", position:"absolute", zIndex:"2", height:"50px", width:"-webkit-fill-available", paddingBottom:"10px"}}>
                        <Typography variant='h5'>{calculateLabels().title}</Typography>


                        {/* Make the button work */}
                        <IconButton sx={{color:"primary", background:"#efefef"}} display="flex-end" onClick={props.eventEditCloser} >
                            <CloseIcon fontSize="medium"   />
                        </IconButton>
                        {/* /////////////////////////// */}


                    </Stack>

                    <Stack direction="column" sx={{mt:"50px"}}>
                        <FormLabel component="legend">{t('Booking.InfoCard.Status')}</FormLabel>
                        <RadioGroup row name="bookingStatus" id="bookingStatus" value={newEvent.bookingStatus} onChange={inputGroupChangeHandler}>
                            <FormControlLabel disabled={paymentIsActive || disableForViewer} value="booked" control={<Radio id="bookingStatus" />} label={t('Booking.InfoCard.Booked')} />
                            <FormControlLabel disabled={paymentIsActive || disableForViewer} value="reserved" control={<Radio id="bookingStatus" />} label={t('Booking.InfoCard.Reserved')} />
                        </RadioGroup>
                        <FormLabel component="legend">{t('Booking.InfoCard.Repeating')}
                            <Checkbox name="isReccured" disabled={disableForViewer || (props.method === 'update')}  id="isReccured" onChange={checkBoxChangeHandler} checked={newEvent.isReccured} />
                        </FormLabel>
                        {(newEvent.isReccured === false && props.method === 'update' && !disableForViewer) ? <Alert severity="warning" sx={{borderRadius:"16px"}}>{t('Booking.InfoCard.WarningMessageRepeatingBooking')}</Alert> : null}
                    </Stack>
                    <Stack direction="column" spacing={2}>
                        <FormLabel sx={{mb: '10px', mt:'10px'}} component="legend">{t('Booking.InfoCard.Date Time')}</FormLabel>
                        {(selectTimeError && !disableForViewer) && <CustomErrorBox content={t(selectTimeError)} />}
                        <LocalizationProvider adapterLocale={calendarLanguages[t('Calendar.MiniCalendar.Language')]} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                inputFormat="dd-MM-yyyy"
                                margin="normal"
                                label={t('Booking.InfoCard.Date')}
                                value={newEvent.selectedDate}
                                onChange={setselectedDateHandle}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                disabled = {disableForPaymentStatus || props.isUserDashboard || disableForViewer}
                                renderInput={(params) => <TextField {...params} />}
                                />
                                <Stack direction="row" spacing={2}>
                                    <TimePicker
                                        ampm={false}
                                        variant="inline"
                                        margin="normal"
                                        label={t('Booking.InfoCard.From')}
                                        value={newEvent.selectedStart}
                                        minutesStep={1}
                                        onChange={setselectedStartHandle}
                                        InputProps={{ readOnly: true }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change from',
                                        }}
                                        disabled = {disableForPaymentStatus || props.isUserDashboard || disableForViewer}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    <TimePicker
                                        ampm={false}
                                        variant="inline"
                                        margin="normal"
                                        label={t('Booking.InfoCard.To')}
                                        minutesStep={1}
                                        value={newEvent.selectedEnd}
                                        onChange={setselectedEndHandle}
                                        InputProps={{ readOnly: true }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change to',
                                        }}
                                        disabled = {disableForPaymentStatus || props.isUserDashboard || disableForViewer}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                        </LocalizationProvider>
                    </Stack>
                    <Grid className={classes.formControl}>
                        <TextField
                            margin="dense"
                            id="name"
                            label={t('Booking.InfoCard.Enter Reserv')}
                            type="text"
                            name="title"
                            defaultValue={newEvent.title}
                            onChange={inputGroupChangeHandler}
                            fullWidth
                            disabled = {disableForViewer}
                        />
                    </Grid>
                    {roomLoaded ?
                        (checkDashboardOrRoomsCalendar() ? 
                            <Grid className={classes.formControl}>
                                {
                                    entities.length > 0 &&
                                    <TextField
                                        fullWidth
                                        select
                                        label={t('Booking.InfoCard.Room')}
                                        id="room"
                                        name="room"
                                        disabled = {disableForPaymentStatus || (props.selectedEntity !== "" && props.selectedEntity !== [] && props.selectedEntity !== null) || method === 'update'}
                                        SelectProps={{
                                            multiple: isMultiple,
                                            value: newEvent.room === '' || newEvent.room === [] ? [] : newEvent.room,
                                            onChange: inputGroupChangeHandler
                                        }}
                                    >
                                        {entities.map((entity,index)=>(
                                            <MenuItem value={entity.id} key={index}>{entity.name}</MenuItem>
                                        ))}

                                    </TextField>
                                }

                            </Grid> :
                            <Grid className={classes.formControl}>
                                <FormLabel id="room-label">{t('Booking.InfoCard.Room')}</FormLabel> 
                                <CustomErrorBox content={t('Booking.InfoCard.NoAvailableRooms')} />
                            </Grid>
                        )
                        :null
                    }
                    <Grid className={classes.formControl}>
                        {
                            Object.keys(users).length > 0 &&
                            <TextField
                                fullWidth
                                select
                                label={t('Booking.InfoCard.Booking Holder')}
                                id="bookingHolder"
                                name="bookingHolder"
                                value={newEvent.bookingHolder}
                                onChange={inputGroupChangeHandler}
                                disabled= {disableForPaymentStatus || holderDisable || disableForViewer}>
                                {Object.keys(users).map((key) => (
                                    <MenuItem value={users[key].id} key={users[key].id}>{users[key].mail}</MenuItem>
                                ))}
                            </TextField>
                        }

                    </Grid>
                    <Grid className={classes.formControl}>
                        <TextField
                            margin="dense"
                            id="customerName"
                            label={t('Booking.InfoCard.Contact')}
                            type="text"
                            name="customerName"
                            value={newEvent.customerName || ''}
                            disabled={disableForViewer}
                            onChange={inputGroupChangeHandler}
                            fullWidth
                        />
                    </Grid>
                    {tenantConfig.features.participantCount ?
                        <Grid className={classes.formControl}> 
                            <TextField
                                margin="dense"
                                id="participantCount"
                                name="participantCount"
                                label={t('Booking.InfoCard.Participants')}
                                type="number"
                                onChange={inputGroupChangeHandler}
                                value={newEvent.participantCount || ''}
                                disabled={disableForViewer}
                                fullWidth
                            />
                        </Grid>:
                        <Grid className={classes.formControl}>                       
                        <Autocomplete
                        multiple
                        id="contactCustomer"
                        name="contactCustomer"
                        options={usersAutoComplete}
                        onChange={inputAutocompleteChangeHandler}
                        value={contactCustomerView || []}
                        disableCloseOnSelect
                        disabled = {disableForViewer}
                        getOptionLabel={(option) => option.mail}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props,option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.mail}
                            </li>
                        )}  

                        fullWidth
                        renderInput={(params) => (
                            <TextField {...params} label={t('Booking.InfoCard.Participants')}/>
                        )}
                        />
                        </Grid>                    

                    }

                    {disableForViewer 
                    ? null 
                    : <Grid className={classes.formControl}>
                        <TextField
                            margin="dense"
                            id="billingAddress"
                            name="billingAddress"
                            label={t('Booking.InfoCard.Billing')}
                            type="text"
                            onChange={inputGroupChangeHandler}
                            value={newEvent.billingAddress || ''}
                            fullWidth
                        />
                    </Grid>}
                    
                    {disableForViewer 
                    ? null 
                    : <Grid className={classes.formControl}>
                        <TextField
                                value={newEvent.sendConfirmationTo || ''}
                                margin="dense"
                                id="sendConfirmationTo"
                                label={t('Booking.InfoCard.Send Confirmation')}
                                type="email"
                                name="sendConfirmationTo"
                                onChange={inputGroupChangeHandler}
                                fullWidth
                            />
                    </Grid>
                    }
                    {tenantConfig.features.device ?
                    disableForViewer
                    ? null
                    : <Grid item xs={12}>
                        <Typography variant="h6">{t('Booking.InfoCard.WelcomeMessage')}</Typography>
                        <NotesTextField
                        name="welcomeMessage"
                        value={newEvent.welcomeMessage || []}
                        onChange={inputGroupChangeHandler}></NotesTextField>
                    </Grid>
                    :null                   
                    }
                    {
                        disableForViewer || (newEvent.deviceId && method === 'update') ? null :
                            allowOpenAddOptions === true &&
                        <Grid className={classes.formControl} container alignItems="center">
                            <Stack direction="row" alignItems="center">
                            <Typography className={classes.mr2}>{t('Booking.InfoCard.Booking Options')}</Typography>
                            <Button onClick={openAddOptionsHandle}>
                                <SettingsIcon color="primary" fontSize="medium" />
                            </Button>
                            </Stack>

                        </Grid>
                    }
                </Grid>

                {openRepeatingBooking === true && method ==="create" &&
                    <Grid item xs={12}>
                        <RepeatingBooking selectedDate={newEvent.selectedDate} repeatingBookingData={newEvent.repeatingBookingData} repeatingBookingChanged={repeatingBookingHandle} handleIsRepeatBookingValidated={handleIsRepeatBookingValidated}></RepeatingBooking>
                    </Grid>
                }

                <Slide direction="up" in={openAddOptions === true} mountOnEnter unmountOnExit>
                    <Grid item xs={12}>
                        {generateEntitiesSettings()}
                    </Grid>
                </Slide>



            </Grid>
            {disableForViewer 
            ? null 
            : <Grid item container xs={12} sx={{mb:"10px"}}>
                {generateShowPriceInformations() }
                {generatePrice()}
                {generateNewPrice()}
                {/* <Grid item xs={12}>
                    <BookingActionButtonGroup canBeSaved={canBeSaved()} formValid={formValid} handleSave={handleSave} handleClose={props.handleClose}/>
                </Grid>     */}
            </Grid>    
            }
            { // An event is a past event and it is not owned by the viewer
            disableForViewerPastEvent && disableForViewer
            ? <Grid item container xs={12} sx={{mb:"10px"}}>
            {generateShowPriceInformations() }
            {generatePrice()}
            {generateNewPrice()}  
            </Grid>
            : null 
            }


            {/* Make the buttons work */}
            {disableForViewer
                ? null
                //this function may be enabled in the future
                // <Grid item container display="flex" justifyContent="flex-end">
                //     <div>
                //         {(!isErrorGetAnEvent) && (showDeleteBtn ? <Button onClick={handleOpenConfirmModal} style={theme.palette.danger} endIcon={<DeleteIcon/>}>{t('Booking.InfoCard.Cancel Booking')}</Button> :null)}
                //     </div>
                // </Grid>
                : <Grid item container display="flex" justifyContent="flex-end"  >
                    {(isEditing()) &&
                            <Stack direction="row" spacing={2} sx={{mt:"20px"}}>
                                <Button onClick={handleOpenConfirmModal} style={theme.palette.danger} endIcon={<EventBusyIcon/>}>{t('Booking.InfoCard.Cancel Booking')}</Button>
                                {(showDeleteBtn ? <Button  className={classes.cancelBookingBtn} onClick={handleOpenConfirmModal} style={theme.palette.danger} endIcon={<DeleteIcon/>}>{t('Booking.InfoCard.Delete Booking')}</Button> :null)}
                                <Button id='save' background="primary" onClick={handleSave} style={theme.palette.primary} endIcon={<SaveIcon/>}>{t('Booking.ButtonGroup.Save')}</Button>
                            </Stack>
                    }
                </Grid>
            }



            {openConfirmModal ?
                <CustomConfirmDialog
                    forDelete={true}
                    cancel={t('CONFIRM_DIALOG.Cancel')} 
                    open={openConfirmModal} 
                    confirm={t('CONFIRM_DIALOG.Delete')} 
                    confirmCB={() => props.cancelEvent()} 
                    cancelCB={onCloseConfirmModal}
                    header={t('CONFIRM_DIALOG.Header')} 
                    text={t('CONFIRM_DIALOG.Information')}
                    isRecurred={newEvent.isReccured} 
                    confirmRecurring={t('CONFIRM_DIALOG.Delete Recurring')}
                    confirmCBAll={() => handleDeleteBooking(true)} 
                    confirmAll={t('CONFIRM_DIALOG.Delete All')} 
                    textAll={t('CONFIRM_DIALOG.Information All')}
                    >
                </CustomConfirmDialog> 
            :null}
        </Stack>
    );

}
export default withSnackbar(BookingInfoCard);
