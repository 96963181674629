import HTTPClient from "../http_client/HttpClient";
import { REQUEST_PATHS_FOR } from "../http_client/RequestBuilder";
import {changePassword, deleteCurrentUser, forgetPassword, login, logout, refresh, register} from "../../auth";


export const HTTP_REQUESTS =
{
    BOOKING_SERVICE:
    {
        GET_ALL: (calendarId,search, successCallback, errorCallback) => {
            let searchParams = new URLSearchParams();
            if(search){
                searchParams.append('search',search)
            }
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.GET_ALL(calendarId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client._params = searchParams
            client.send();
        },
        GET_ALL_BY_ACCOUNT_ID: (accountId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.GET_ALL_BY_ACCOUNT_ID(accountId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ALL_BOOKINGS_ALL_CALENDARS: (tenantId, filter,search,successCallback, errorCallback) => {
            let searchParams = generateSearchParams(filter,search)
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.GET_ALL_BOOKINGS_ALL_CALENDARS(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client._params = searchParams
            client.send();
        },
        GET_A_EVENT: (bookingId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.GET_A_EVENT(bookingId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.setAuthTokenAccess();
            client.timeout = 10000; //For example 10 sec
            client.send();
        },
        UPDATE_A_BOOKING: (bookingId, bookingObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.UPDATE_A_BOOKING(bookingId);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.addParameters(bookingObj);
            client.setAuthTokenAccess();
            client.send();
        },
        DELETE_A_BOOKING: (bookingId, deleteObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.DELETE_A_BOOKING(bookingId);
            client.requestType = HTTPClient.REQUEST_TYPE.DELETE;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.addParameters(deleteObj);
            client.setAuthTokenAccess();
            client.send();
        },
        UPDATE_BOOKINGS_FOR_MULTIPLE_ROOMS: (eventGroupID, bookingObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.UPDATE_BOOKINGS_FOR_MULTIPLE_ROOMS(eventGroupID);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.addParameters(bookingObj);
            client.setAuthTokenAccess();
            client.send();
        },
        DELETE_BOOKINGS_FOR_MULTIPLE_ROOMS: (eventGroupID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.DELETE_BOOKINGS_FOR_MULTIPLE_ROOMS(eventGroupID);
            client.requestType = HTTPClient.REQUEST_TYPE.DELETE;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.setAuthTokenAccess();
            client.send();
        },
        CREATE_A_BOOKING: (dummy, eventObj, successCallback, errorCallback) => {
            if(dummy){
                successCallback()
            } else{
                let client = new HTTPClient();
                client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.CREATE_A_BOOKING();
                client.requestType = HTTPClient.REQUEST_TYPE.POST;
                client.successCallback = successCallback;
                client.failCallback = errorCallback;
                client.addParameters(eventObj);
                client.setAuthTokenAccess();
                client.send();
            }
        },
        GET_ALL_BOOKINGS_FOR_PUBLIC_USER: (filter,search,successCallback, errorCallback) => {
            let searchParams = generateSearchParams(filter,search)
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.GET_ALL_BOOKINGS_FOR_PUBLIC_USER();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client._params = searchParams
            client.send();
        },
        GET_ALL_ENTITIES: (tenantId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.GET_ALL_ENTITIES(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        CALCULATE_PRICE_OF_BOOKING: (calculateObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.CALCULATE_PRICE_OF_BOOKING();
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(calculateObj);
            client.setAuthTokenAccess();
            client.send();
        },
        CALCULATE_PRICE_OF_BOOKING_PUBLIC: (roomID,calculateObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.CALCULATE_PRICE_OF_BOOKING_PUBLIC(roomID);
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(calculateObj)
            client.send();
        },
        UPDATE_A_BOOKING_PAYMENT_STATUS: (tenantId,bookingId,statusObj,successCallback,errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.UPDATE_A_BOOKING_PAYMENT_STATUS(tenantId,bookingId);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.addParameters(statusObj);
            client.setAuthTokenAccess();
            client.send();
        },
    },
        USER_SERVICE:
            {
                LOGIN: (loginCredentials,successCallback,errorCallback)=>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.LOGIN();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(loginCredentials);
                    client.send();
                },
                REGISTER: (registerCredentials,successCallback,errorCallback)=>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.REGISTER();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(registerCredentials);
                    client.setAuthTokenAccess();
                    client.send();
                },
                REGISTER_MANY_USERS: (registerCredentials,successCallback,errorCallback)=>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.REGISTER_MANY_USERS();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(registerCredentials);
                    client.send();
                },
                CREATE_A_TENANT: (tenantCredentials,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.CREATE_A_TENANT();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(tenantCredentials);
                    client.setAuthTokenAccess();
                    client.send();
                },
                GET_USERS : (successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.GET_USERS();
                    client.requestType      = HTTPClient.REQUEST_TYPE.GET;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.setAuthTokenAccess();
                    client.send();
                },
                GET_ONE_TENANT : (tenantID,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.GET_ONE_TENANT(tenantID);
                    client.requestType      = HTTPClient.REQUEST_TYPE.GET;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(tenantID);
                    client.setAuthTokenAccess();
                    client.send();
                },
                GET_ONE_TENANTS_USERS : (tenantID,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.GET_ONE_TENANTS_USERS(tenantID);
                    client.requestType      = HTTPClient.REQUEST_TYPE.GET;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(tenantID);
                    client.setAuthTokenAccess();
                    client.send();
                },
                GET_ONE_TENANT_USER : (tenantID,userID,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.GET_ONE_TENANT_USER(tenantID,userID);
                    client.requestType      = HTTPClient.REQUEST_TYPE.GET;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.setAuthTokenAccess();
                    client.send();
                },
                CREATE_ONE_TENANTUSER : (tenantID,userEmail,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.CREATE_ONE_TENANTUSER(tenantID);
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(userEmail);
                    client.setAuthTokenAccess();
                    client.send();
                },
                UPDATE_ONE_TENANT_USER : (tenantID,userId,updateParams,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.UPDATE_ONE_TENANT_USER(tenantID,userId);
                    client.requestType      = HTTPClient.REQUEST_TYPE.PUT;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(updateParams);
                    client.setAuthTokenAccess();
                    client.send();
                },
                UPDATE_PROFILE : (updateParams,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.UPDATE_PROFILE();
                    client.requestType      = HTTPClient.REQUEST_TYPE.PUT;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(updateParams);
                    client.setAuthTokenAccess();
                    client.send();
                },
                DELETE_ONE_USER : (accountId,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.DELETE_ONE_USER(accountId);
                    client.requestType      = HTTPClient.REQUEST_TYPE.DELETE;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.setAuthTokenAccess();
                    client.send();
                },
                GET_TENANTS_NAMES : (tenantID,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.GET_TENANTS_NAMES();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(tenantID);
                    client.setAuthTokenAccess()
                    client.send();
                },
                VALIDATE_INVITED_USER : (token,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.VALIDATE_INVITED_USER();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; //5 seconds
                    client.addParameters(token);
                    client.setAuthTokenAccess();
                    client.send();
                },
                UPDATE_ONE_USER :  (userId,updateParams,successCallback,errorCallback) => {
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.UPDATE_ONE_USER(userId);
                    client.requestType      = HTTPClient.REQUEST_TYPE.PUT;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; //5 seconds
                    client.addParameters(updateParams);
                    client.setAuthTokenAccess();
                    client.send();
                },
                UPDATE_ONE_INVITE_USER :  (tenantId,userId,updateParams,successCallback,errorCallback) => {
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.UPDATE_ONE_INVITE_USER(tenantId,userId);
                    client.requestType      = HTTPClient.REQUEST_TYPE.PUT;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; //5 seconds
                    client.addParameters(updateParams);
                    client.setAuthTokenAccess();
                    client.send();
                },
                ACTIVATE_INVITATION :  (updateParams,successCallback,errorCallback) => {
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.ACTIVATE_INVITATION();
                    client.requestType      = HTTPClient.REQUEST_TYPE.PUT;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 10000; //10 seconds
                    client.addParameters(updateParams);
                    client.setAuthTokenAccess();
                    client.send();
                },
                UPDATE_ONE_USER_PHOTO :  (updateParams,successCallback,errorCallback) => {
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.UPDATE_ONE_USER_PHOTO();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.contentType      = HTTPClient.CONTENT_TYPE.MULTIPART;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; //5 seconds
                    client.formData         = updateParams;
                    client.setAuthTokenAccess();
                    client.send();
                },
                DELETE_ONE_USER_PHOTO : (updateParams,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.DELETE_ONE_USER_PHOTO();
                    client.requestType      = HTTPClient.REQUEST_TYPE.DELETE;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(updateParams);
                    client.setAuthTokenAccess();
                    client.send();
                },
                UPDATE_A_TENANT_USER :  (tenantId,updateParams,successCallback,errorCallback) => {
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.UPDATE_A_TENANT_USER(tenantId);
                    client.requestType      = HTTPClient.REQUEST_TYPE.PUT;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; //5 seconds
                    client.addParameters(updateParams);
                    client.setAuthTokenAccess();
                    client.send();
                },
                CREATE_NEW_TOKEN :  (tokenParams,successCallback,errorCallback) => {
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.CREATE_NEW_TOKEN();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; //5 seconds
                    client.addParameters(tokenParams);
                    client.setAuthTokenAccess();
                    client.send();
                },
                CHECK_PSW :  (userId,psw,successCallback,errorCallback) => {
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.CHECK_PSW(userId);
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; //5 seconds
                    client.addParameters(psw);
                    client.setAuthTokenAccess();
                    client.send();
                },
                AUTH_CALL: (successCallback,errorCallback)=>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.AUTH_CALL();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.setAuthTokenAccess();
                    //client.addParameters(loginCredentials);
                    client.send();
                },
                LOGOUT: (successCallback,errorCallback)=>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.LOGOUT();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.send();
                },
                GET_ONE_USER:(userId,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.GET_ONE_USER(userId);
                    client.requestType      = HTTPClient.REQUEST_TYPE.GET;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.setAuthTokenAccess();
                    client.send();
                },
                FORGOT_PASSWORD : (mail,successCallback,errorCallback)=>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.FORGOT_PASSWORD();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(mail);
                    client.send();
                },
                CREATE_NEW_PASSWORD_TOKEN :  (passwordToken,successCallback,errorCallback) => {
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.CREATE_NEW_PASSWORD_TOKEN();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; //5 seconds
                    client.addParameters(passwordToken);
                    client.send();
                },
                VALIDATE_PASSWORD : (token,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.VALIDATE_PASSWORD();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; //5 seconds
                    client.addParameters(token);
                    client.send();
                },
                CHANGE_PASSWORD : (passwordObj,successCallback,errorCallback)=>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.CHANGE_PASSWORD();
                    client.requestType      = HTTPClient.REQUEST_TYPE.PUT;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    client.addParameters(passwordObj);
                    client.send();
                },
                REFRESH: (successCallback,errorCallback)=>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.REFRESH();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 5000; // 5 seconds
                    //client.addParameters(loginCredentials);
                    client.send();
                },
                CREATE_ONE_USER : (userEmail,successCallback,errorCallback) =>{
                    let client              = new HTTPClient();
                    client.requestPath      = REQUEST_PATHS_FOR.USERS_SERVICE.CREATE_ONE_USER();
                    client.requestType      = HTTPClient.REQUEST_TYPE.POST;
                    client.successCallback  = successCallback;
                    client.failCallback     = errorCallback;
                    client.timeout          = 10000; // 10 seconds
                    client.addParameters(userEmail);
                    client.setAuthTokenAccess();
                    client.send();
                },
            },
            CATEGORY_SERVICE:
    {
        GET_ALL: (tenantID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CATEGORY_SERVICE.GET_ALL(tenantID);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        CREATE_A_CATEGORY: (tenantID,categoryParams,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CATEGORY_SERVICE.CREATE_A_CATEGORY(tenantID);
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.userNotification = { title: "Category created", type: "success" };
            client.timeout = 10000; //For example 10 sec
            client.addParameters(categoryParams);
            client.setAuthTokenAccess();
            client.send();
        },
        DELETE_A_CATEGORY: (tenantID,categoryId,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CATEGORY_SERVICE.DELETE_A_CATEGORY(tenantID,categoryId);
            client.requestType = HTTPClient.REQUEST_TYPE.DELETE;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.userNotification = { title: "Category Deleted", type: "success" };
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        UPDATE_A_CATEGORY: (tenantID,categoryId,categoryParams,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CATEGORY_SERVICE.UPDATE_A_CATEGORY(tenantID,categoryId);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.userNotification = { title: "Category updated", type: "success" };
            client.timeout = 10000; //For example 10 sec
            client.addParameters(categoryParams);
            client.setAuthTokenAccess();
            client.send();
        }
    },
    CALENDAR_SERVICE:
    {
        GOOGLE_GENEREATE_LINK: (eventObj,tenantId,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.GOOGLE_GENEREATE_LINK(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(eventObj);
            client.setAuthTokenAccess();
            client.send();
        },
        OUTLOOK_GENEREATE_LINK: (eventObj,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.OUTLOOK_GENEREATE_LINK();
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(eventObj);
            client.setAuthTokenAccess();
            client.send();
        },
        GOOGLE_AUTHORIZE: (eventObj,tenantID,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.GOOGLE_AUTHORIZE(tenantID);
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.userNotification = { title: "Authorize fetched", type: "success" };
            client.timeout = 10000; //For example 10 sec
            client.addParameters(eventObj);
            client.setAuthTokenAccess();
            client.send();
        },
        OUTLOOK_AUTHORIZE: (eventObj,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.OUTLOOK_AUTHORIZE();
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.userNotification = { title: "Authorize fetched", type: "success" };
            client.timeout = 10000; //For example 10 sec
            client.addParameters(eventObj);
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ALL_CALENDARS_WITH_BASIC_INFO: (successCallback, errorCallback) => {
            let searchParams = new URLSearchParams();
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.GET_ALL_CALENDARS_WITH_BASIC_INFO();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ALL_CALENDARS: (successCallback, errorCallback,filterObj) => {
            let searchParams = new URLSearchParams();
            let client = new HTTPClient();
            if(filterObj && filterObj.availableObj){
                searchParams.append('checkAvailable',filterObj.availableObj.checkAvailable)
                searchParams.append('start',filterObj.availableObj.start)
                searchParams.append('end',filterObj.availableObj.end)
                searchParams.append('method',filterObj.availableObj.method)
                if(filterObj.availableObj.method === "update"){
                    searchParams.append('selectedRoom',filterObj.availableObj.selectedRoom)
                    searchParams.append('selectedEvent',filterObj.availableObj.selectedEvent)
                }
                client._params = searchParams
            }
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.GET_ALL_CALENDARS();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        GET_CALENDARS_MINIMAL_DATA: (successCallback, errorCallback,filterObj) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.GET_CALENDARS_MINIMAL_DATA();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ALL_CALENDARS_PUBLIC: (successCallback, errorCallback,filterObj) => {
            let searchParams = new URLSearchParams();
            let client = new HTTPClient();
            if(filterObj && filterObj.availableObj){
                searchParams.append('checkAvailable',filterObj.availableObj.checkAvailable)
                searchParams.append('start',filterObj.availableObj.start)
                searchParams.append('end',filterObj.availableObj.end)
                searchParams.append('method',filterObj.availableObj.method)
                if(filterObj.availableObj.method === "update"){
                    searchParams.append('selectedRoom',filterObj.availableObj.selectedRoom)
                    searchParams.append('selectedEvent',filterObj.availableObj.selectedEvent)
                }
                client._params = searchParams
            }
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.GET_ALL_CALENDARS_PUBLIC();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.send();
        },
        UPDATE_CALENDAR: (calendarId, newCalendarObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.UPDATE_CALENDAR(calendarId);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(newCalendarObj);
            client.setAuthTokenAccess();
            client.send();
        },
        DELETE_CALENDAR: (calendarId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.DELETE_CALENDAR(calendarId);
            client.requestType = HTTPClient.REQUEST_TYPE.DELETE;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        CREATE_CALENDAR: (newCalendarObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.CREATE_CALENDAR();
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 30000; //For example 10 sec
            client.addParameters(newCalendarObj);
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ONE_CALENDAR: (calendarId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.CALENDAR_SERVICE.GET_ONE_CALENDAR( calendarId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        }
    },
    ENTITY_SERVICE:
    {
        ENTITY_CREATE: (tenantId,eventObj,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.ENTITY_SERVICE.ENTITY_CREATE(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(eventObj);
            client.setAuthTokenAccess();
            client.send();
        },
        ENTITY_DELETE: (tenantId,entityId,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.ENTITY_SERVICE.ENTITY_DELETE(tenantId,entityId);
            client.requestType = HTTPClient.REQUEST_TYPE.DELETE;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        ENTITY_UPDATE: (tenantId,entityId,eventObj,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.ENTITY_SERVICE.ENTITY_UPDATE(tenantId,entityId);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(eventObj);
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ONE_USERS_EVENTS: (userId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.ENTITY_SERVICE.GET_ONE_USERS_EVENTS(userId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        GET_AVAILABLE_ENTITIES: (startDate, endDate, successCallback, errorCallback) => {
            const params = {
                start: startDate,
                end: endDate
            }
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.ENTITY_SERVICE.GET_AVAILABLE_ENTITIES();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(params);
            client.setAuthTokenAccess();
            client.send();
        },
        GET_AVAILABLE_ENTITIES_PUBLIC: (startDate, endDate, successCallback, errorCallback) => {
            const params = {
                start: startDate,
                end: endDate
            }
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.ENTITY_SERVICE.GET_AVAILABLE_ENTITIES_PUBLIC();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(params);
            client.send();
        },
        GET_ONE_PUBLIC_ROOM: (roomID, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.ENTITY_SERVICE.GET_ONE_PUBLIC_ROOM(roomID);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.send();
        },
        GET_ONE_ENTITY: (tenantId, entityId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.ENTITY_SERVICE.GET_ONE_ENTITY(tenantId, entityId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ONE_USERS_OWNED_EVENTS: (userId, tenantId, successCallback, errorCallback,filter) => {
            let searchParams = generateSearchParams(filter)
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.ENTITY_SERVICE.GET_ONE_USERS_OWNED_EVENTS(userId, tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client._params = searchParams
            client.setAuthTokenAccess();
            client.send();
        },
    },
    DEVICE_SERVICE: {
        CREATE: (deviceObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.DEVICE_SERVICE.CREATE();
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(deviceObj);
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ALL: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.DEVICE_SERVICE.GET_ALL();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ONE: (id, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.DEVICE_SERVICE.GET_ONE_DEVICE(id);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        UPDATE: (id, deviceObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.DEVICE_SERVICE.UPDATE(id);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(deviceObj);
            client.setAuthTokenAccess();
            client.send();
        },
        DELETE_BY_ID : (id, successCallback, errorCallback) =>{
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.DEVICE_SERVICE.DELETE_BY_ID(id);
            client.requestType = HTTPClient.REQUEST_TYPE.DELETE;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        }
    },
    DEVICE_CALENDAR_SERVICE: {
        GET: (tenantId, deviceId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.DEVICE_CALENDAR_SERVICE.GET(tenantId, deviceId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        UPDATE: (tenantId, deviceId, calendarObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.DEVICE_CALENDAR_SERVICE.UPDATE(tenantId, deviceId);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(calendarObj);
            client.setAuthTokenAccess();
            client.send();
        },
        GET_EVENTS: (tenantId, deviceId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.DEVICE_CALENDAR_SERVICE.GET_EVENTS(tenantId, deviceId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        CREATE_EVENT: (tenantId, deviceId, eventObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.DEVICE_CALENDAR_SERVICE.CREATE_EVENT(tenantId, deviceId);
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(eventObj);
            client.setAuthTokenAccess();
            client.send();
        },
        UPDATE_EVENT: (tenantId, deviceId, eventId, eventObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.DEVICE_CALENDAR_SERVICE.UPDATE_EVENT(tenantId, deviceId, eventId);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(eventObj);
            client.setAuthTokenAccess();
            client.send();
        },
        DELETE_EVENT: (tenantId, deviceId, eventId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.DEVICE_CALENDAR_SERVICE.DELETE_EVENT(tenantId, deviceId, eventId);
            client.requestType = HTTPClient.REQUEST_TYPE.DELETE;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.setAuthTokenAccess();
            client.send();
        },
    },
    LOCATION_SERVICE: {
        GET_ALL: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.LOCATION_SERVICE.GET_ALL()
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.setAuthTokenAccess();
            client.send();
        },
        CREATE_A_LOCATION: (locationParams,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.LOCATION_SERVICE.CREATE_A_LOCATION();
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.addParameters(locationParams);
            client.setAuthTokenAccess();
            client.send();
        },
        UPDATE_A_LOCATION: (locationId,locationParams,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.LOCATION_SERVICE.UPDATE_A_LOCATION(locationId);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.addParameters(locationParams);
            client.setAuthTokenAccess();
            client.send();
        },
        DELEtE_A_LOCATION: (locationId,successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.LOCATION_SERVICE.UPDATE_A_LOCATION(locationId);
            client.requestType = HTTPClient.REQUEST_TYPE.DELETE;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ALL_LOCATIONS_WITH_ROOMS: (startDate, endDate, successCallback, errorCallback) => {
            const params = {
                start: startDate,
                end: endDate
            }
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.LOCATION_SERVICE.GET_ALL_LOCATIONS_WITH_ROOMS()
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.addParameters(params);
            client.setAuthTokenAccess();
            client.timeout = 5000;
            client.send();
        },
    },
    LAYOUTS_SERVICE: {
        GET_ALL_LAYOUTS:(successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.LAYOUTS_SERVICE.GET_ALL_LAYOUTS();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        }
    },
    IMAGE_SERVICE: {
        UPLOAD_TEMP_IMAGE :  (updateParams,successCallback,errorCallback) => {
            let client              = new HTTPClient();
            client.requestPath      = REQUEST_PATHS_FOR.IMAGE_SERVICE.UPLOAD_TEMP_IMAGE();
            client.requestType      = HTTPClient.REQUEST_TYPE.POST;
            client.contentType      = HTTPClient.CONTENT_TYPE.MULTIPART;
            client.successCallback  = successCallback;
            client.failCallback     = errorCallback;
            client.timeout          = 5000; //5 seconds
            client.formData         = updateParams;
            client.send();
        },
        UPLOAD_MULTIPLE_TEMP_IMAGE :  (updateParams,successCallback,errorCallback) => {
            let client              = new HTTPClient();
            client.requestPath      = REQUEST_PATHS_FOR.IMAGE_SERVICE.UPLOAD_MULTIPLE_TEMP_IMAGE();
            client.requestType      = HTTPClient.REQUEST_TYPE.POST;
            client.contentType      = HTTPClient.CONTENT_TYPE.MULTIPART;
            client.successCallback  = successCallback;
            client.failCallback     = errorCallback;
            client.timeout          = 10000; //10 seconds
            client.setAuthTokenAccess();
            client.formData         = updateParams;
            client.send();
        },
    },
    PROPERTY_SERVICE: {
        CREATE: (propertyObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.PROPERTY_SERVICE.CREATE();
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(propertyObj);
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ALL: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.PROPERTY_SERVICE.GET_ALL();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ONE: (propertyId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.PROPERTY_SERVICE.GET_ONE_DEVICE(propertyId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        UPDATE: (propertyId, propertyObj, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.PROPERTY_SERVICE.UPDATE(propertyId);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(propertyObj);
            client.setAuthTokenAccess();
            client.send();
        },
        DELETE_BY_ID : (propertyId, successCallback, errorCallback) =>{
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.PROPERTY_SERVICE.DELETE_BY_ID(propertyId);
            client.requestType = HTTPClient.REQUEST_TYPE.DELETE;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        }
    },
    PAYMENT_SERVICE : {
        CREATE_ORDER : (tenantId,orderObj,successCallback,errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.PAYMENT_SERVICE.CREATE_ORDER(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters(orderObj);
            client.setAuthTokenAccess();
            client.send();
        },
        CAPTURE_PAYMENT: (tenantId,orderId,successCallback,errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.PAYMENT_SERVICE.CAPTURE_PAYMENT(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters({orderId:orderId});
            client.setAuthTokenAccess();
            client.send();
        },
        IS_ORDER_MULTIPLE: (tenantId,bookingId,successCallback,errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.BOOKING_SERVICE.UPDATE_A_BOOKING_PAYMENT_STATUS(tenantId,bookingId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.setAuthTokenAccess();
            client.send();
        },
        CREATE_INVOICE: (tenantId,orderId,successCallback,errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.PAYMENT_SERVICE.CREATE_INVOICE(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.addParameters({orderId:orderId});
            client.setAuthTokenAccess();
            client.send();
        },
    },
    INVOICE_DATA_SERVICE: {
        GET_INVOICE_DATA:(tenantId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.INVOICE_DATA_SERVICE.GET_INVOICE_DATA(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
        CREATE_INVOICE_DATA:(tenantId, data, method, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.INVOICE_DATA_SERVICE.GET_INVOICE_DATA(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE[method];
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.addParameters(data);
            client.send();
        }
    },
    FIREBASE_SERVICE: {
        REGISTER:( email, psw,successCallback, errorCallback) => {
            register(email,psw).then((userCredential) => {
                successCallback(userCredential);
            }).catch((err) => {
                errorCallback(err);
            })
        },
        DELETE_USER:( currentUser, successCallback, errorCallback) => {
            deleteCurrentUser(currentUser).then( () => {
                successCallback();
            }).catch((err) => {
                errorCallback(err);
            })
        },
        SEND_PASSWORD_TO_EMAIL: ( email, successCallback, errorCallback) => {
            forgetPassword(email).then(() => {
                successCallback();
            }).catch((err) => {
                errorCallback(err);
            })
        },
        LOGIN: ( uname, psw, successCallback, errorCallback) => {
            login(uname, psw).then((data) => {
                successCallback(data);
            }).catch((err) => {
                errorCallback(err);
            })
        },
        UPDATE_PASSWORD: ( newPassword, successCallback, errorCallback) => {
            changePassword(newPassword)
                .then(() => successCallback())
                .catch((err) => errorCallback(err))
        },
        LOGOUT: (successCallback,errorCallback) => {
            logout()
                .then(() => successCallback())
                .catch((err) => errorCallback())
        },
        REFRESH_TOKEN: ( accessCallback, errorCallback) => {
            refresh()
                .then((idToken) => accessCallback(idToken))
                .catch((err) => errorCallback(err));
        }
    },
    TENANT_SERVICE: {
        GET_TENANT_CONFIG: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.TENANT_SERVICE.GET_TENANT_CONFIG();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000; //For example 10 sec
            client.setAuthTokenAccess();
            client.send();
        },
    
        /* =============== Super admin endpoints ===============*/
        GET_ALL_TENANTS: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.TENANT_SERVICE.GET_ALL_TENANTS();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000;
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ONE_TENANT: (tenantId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.TENANT_SERVICE.GET_ONE_TENANT(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000;
            client.setAuthTokenAccess();
            client.send();
        },
        CREATE_A_TENANT: (data, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.TENANT_SERVICE.CREATE_A_TENANT();
            client.requestType = HTTPClient.REQUEST_TYPE.POST;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000;
            client.addParameters(data);
            client.setAuthTokenAccess();
            client.send();
        },
        UPDATE_A_TENANT: (tenantId, data, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.TENANT_SERVICE.UPDATE_A_TENANT(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.PUT;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000;
            client.addParameters(data);
            client.setAuthTokenAccess();
            client.send();
        },
        DELETE_A_TENANT: (tenantId, successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.TENANT_SERVICE.DELETE_A_TENANT(tenantId);
            client.requestType = HTTPClient.REQUEST_TYPE.DELETE;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 10000;
            client.setAuthTokenAccess();
            client.send();
        },
        GET_ALL_USERS: (successCallback, errorCallback) => {
            let client = new HTTPClient();
            client.requestPath = REQUEST_PATHS_FOR.TENANT_SERVICE.GET_ALL_USERS();
            client.requestType = HTTPClient.REQUEST_TYPE.GET;
            client.successCallback = successCallback;
            client.failCallback = errorCallback;
            client.timeout = 30000;
            client.setAuthTokenAccess();
            client.send();
        }
    }
};

function generateSearchParams(filter,search){
    let searchParams = new URLSearchParams();
    const unusedParameters = ["locations","entities"]
    for (const [key, value] of Object.entries(filter)){
        if(!unusedParameters.includes(key) && searchParamsObject[key]){
            searchParams.append(searchParamsObject[key],value)
        }
    }
    if(search){
        searchParams.append('search',search)
    }
    return searchParams
}

const searchParamsObject = {
    roomIds:"rooms",
    locationIds:"locations",
    deleted:"deleted",
    cancelled:"cancelled",
    paymentStatus:"paymentStatus"
}
