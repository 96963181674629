import {HTTP_REQUESTS} from "../backendcomm/services/httpRequestService";
import CalendarEvent from "../models/CalendarEvent";
import BufferEvent from "../models/BufferEvent";
import moment from "moment/moment";
import { store } from '../store/store'

export function getAllEventsForPublicUser(loggedInUserId, cb,errorCallback,filter,search) {
    let events = [];
    HTTP_REQUESTS.BOOKING_SERVICE.GET_ALL_BOOKINGS_FOR_PUBLIC_USER(filter,search,(res) => {
        res.forEach(function (event) {
            let newCalendarEvent = new CalendarEvent(event.id, event.title,event.ownerAccountId, "", new Date(event.startDate), new Date(event.endDate), true, event.room.color, event.status , event.room.location.name, event.room.id, event).toUIJSON(loggedInUserId);
            events.push(newCalendarEvent);
            if(!event.state && store.getState().tenantConfig.features.bufferEvents){
                let bufferTime = event.room.bufferTime
                if(bufferTime > 0){
                    let newBufferEvent = new BufferEvent(`${event.id}+buffer`,'Buffer Event',new Date(moment(event.startDate).subtract(bufferTime,'minutes')),new Date(moment(event.startDate)),"#353b48").toUIJSON();
                    events.push(newBufferEvent)
                }
            }
        });
        cb(events);
    }, (err) => {
        errorCallback(err);
    });

}
