import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "@mui/material";
import {t} from "i18next";

const ConfirmationDialog = (props) => {

    const { onClose, open, message, title } = props;

    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        onClose(true);
    };

    return(
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleConfirm} variant='contained' color='success'>{t('ConfirmationDialog.confirm')}</Button>
                <Button onClick={handleCancel} variant='contained' color='error'>{t('ConfirmationDialog.cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog;
