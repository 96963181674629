import { MenuItem, Select } from '@mui/material';
import React from 'react';
import IconButton from "@mui/material/IconButton";
import UsFlag from "../UI/UsFlag/UsFlag";
import DeFlag from "../UI/DeFlag/DeFlag";
import {useTranslation} from "react-i18next";
const LanguageDropdown = (props) => {

    const [lang, setLang] = React.useState( (localStorage.getItem('language')) ? localStorage.getItem('language') : 'de-DE');
    const { i18n } = useTranslation();

    React.useEffect(() => {
        i18n.changeLanguage(lang); localStorage.setItem('language',lang);
    }, [lang]);

    const handleLanguageChange = (event) => {
        setLang(event.target.value);
    };

    return (
        <Select
            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 }, '.Mui-focused': {border: 0}}}
            value={lang}
            onChange={handleLanguageChange}
            className={props.classes?.selector}
            classes={props.classesObject}
        >
            <MenuItem value="de-DE" >
                <IconButton size="large"><DeFlag/></IconButton>
            </MenuItem> 
            <MenuItem value="en-US" >
                <IconButton size="large"><UsFlag/></IconButton>
            </MenuItem> 
        </Select>
);
 }
export default LanguageDropdown;
