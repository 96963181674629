import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import SignIn from './SignIn';
import FormControl from '@mui/material/FormControl';
import LanguageDropdown from '../Menu/LanguageDropdown';
import {useAppSelector} from "../../store/hooks";
import {selectTenantConfig} from "../../store/features/tenantConfigSlice";



const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(0),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    centered: {
        justifyContent: 'center',
        marginLeft: '0',
        marginRight:'0',
        display:'flex'
    },
    selector:{
        color:'white',
        fontSize:'medium',
        "& .MuiSvgIcon-root": { color: "black" }
    },
}));

function SignInSide(props) {
    const classes = useStyles();
    const tenantConfig = useAppSelector(selectTenantConfig);

    return (
        <Grid container component="main" className="signInRoot">
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} sx={{backgroundImage: `url(${tenantConfig.loginLeftImage})`}}/>
            <Grid item container justifyContent="center" alignItems="center" padding={"0px 30px"} xs={12} sm={8} md={5}>
                <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
                    <FormControl>
                        <LanguageDropdown classes={classes} />
                    </FormControl>
                </Grid>
                <Grid item container xs={12} justifyContent="center" alignItems="center">
                    <SignIn onSignUpClicked={()=>props.onSignUpClicked() } handelSigIn={() =>  window.location.href = ('/')} onForgotPassword={() => props.onForgotPassword()}/>
                </Grid>
                <Grid item xs={12}></Grid>
            </Grid>
        </Grid>
    );
}

export default withSnackbar(SignInSide);
