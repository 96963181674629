import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './i18n';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'
import App from "./App";
import {store} from "./store/store";
import {Provider} from "react-redux";
import SuperAdminApp from './superAdmin/SuperAdminApp';
// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
}

const hostname = window.location.hostname;
console.log(process.env.REACT_APP_ADMIN_HOSTNAME)

require(`./${process.env.REACT_APP_FONT_CSS_PATH}`)
const root = createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <SnackbarProvider
    ref={notistackRef}
    action={(key) => (
    <Button onClick={onClickDismiss(key)}><CloseIcon style={{ color: '#ffffff' }} /></Button>
)} anchorOrigin={{
    vertical: 'top',
    horizontal: 'right',
}} maxSnack={3}>
    <Provider store={store}>
        {
            hostname === process.env.REACT_APP_ADMIN_HOSTNAME ?
                <SuperAdminApp/>
                :
                <App/>
        }
    </Provider>
    </SnackbarProvider>
    </ThemeProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
