import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button, Grid} from "@mui/material";
import {t} from "i18next";
import React, {useEffect} from 'react'
import TextField from "@mui/material/TextField";

const FormDialog = (props) => {

    const { onClose, open, formInputs, title, formInputsAdress, formInputsDetail, formInputsCountry, formInputsPhone } = props;

    const [formValues, setFormValues] = React.useState({})
    const [formType, setFormType] = React.useState(FormType.create)


    const addNewValues = (inputs,newValues) =>{
        // Map input values in formInput object to formValues

        Object.entries(inputs).forEach(val => {
            const key = val[0]
            const input = val[1]
            // If it has an id key and truthful value, then the FormType is edit; else, the form type is create.
            // If the form type is edit, add id field to formValues; else remove id field
            if(key === 'id'){
                if(input){
                    setFormType(FormType.edit)
                    newValues = {...newValues, id: input};
                }
                else{
                    setFormType(FormType.create)
                    delete newValues.id
                }
                return;
            }
            newValues = {...newValues, [input.id]: input.value}
        });
        return newValues
    }

    useEffect(() => {
        let newValues = formValues
        newValues = addNewValues(formInputs,newValues)
        newValues = addNewValues(formInputsCountry,newValues)
        newValues = addNewValues(formInputsAdress,newValues)
        newValues = addNewValues(formInputsDetail,newValues)
        newValues = addNewValues(formInputsPhone,newValues)
        setFormValues(newValues)
    }, [formInputs, formInputsAdress, formInputsDetail, formInputsCountry, formInputsPhone])

    const handleInputChange = (id, e) => {
        setFormValues(prevState => ({
            ...prevState,
            [id]: e.target.value
        }))
    }


    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        onClose(formValues, formType);
    };

    return(
        <Dialog
            open={open}
            onClose={handleCancel}

        >
            <DialogTitle>{title}</DialogTitle>
            
            <DialogContent  style={{ overflow: "hidden" }}>
                <Grid >
                    {Object.entries(formInputs).filter(val => val[0] !== 'id').map(val => {
                    const input = val[1]
                    switch (input.type){
                        case(FormInputType.textField):
                            return <TextField
                                key={input.id}
                                fullWidth sx={{marginTop: 2, m:1, width: '52ch'}}
                                required={input.required}
                                id={input.id}
                                label={input.label}
                                onChange={(event) => (handleInputChange(input.id, event))}
                                value={formValues[input.id]}
                                inputProps={{ maxLength: 50 }}    
                            />
                    }   
                })}
                </Grid>
            </DialogContent>

            <DialogContent  style={{ overflow: "hidden" }}>
                <Grid  >
                    {Object.entries(formInputsCountry).filter(val => val[0] !== 'id').map(val => {
                    const input = val[1]
                    switch (input.type){
                        case(FormInputType.textField):
                            return <TextField
                                key={input.id}
                                required={input.required}
                                id={input.id}
                                label={input.label}
                                onChange={(event) => (handleInputChange(input.id, event))}
                                value={formValues[input.id]}
                                inputProps={{ maxLength: 50 }}
                                fullWidth sx={{ marginTop: 1, m: 1, width: '25ch' }}
                            />
                    }   
                })}
                </Grid>
            </DialogContent>
            
            <DialogContent  style={{ overflow: "hidden" }}> 
                <Grid  >
                    {Object.entries(formInputsAdress).filter(val => val[0] !== 'id').map(val => {
                    const input = val[1]
                    switch (input.type){
                        case(FormInputType.textField):
                            return <TextField
                                key={input.id}
                                required={input.required}
                                id={input.id}
                                label={input.label}
                                onChange={(event) => (handleInputChange(input.id, event))}
                                value={formValues[input.id]}
                                inputProps={{ maxLength: 50 }}
                                fullWidth sx={{ marginTop: 1, m: 1, width: '25ch' }}
                            />
                    }           
                })}
                </Grid>
            </DialogContent>

            <DialogContent  style={{ overflow: "hidden" }}>
                <Grid  >
                    {Object.entries(formInputsPhone).filter(val => val[0] !== 'id').map(val => {
                    const input = val[1]
                    switch (input.type){
                        case(FormInputType.textField):
                            return <TextField
                                key={input.id}
                                fullWidth sx={{marginTop: 1, m:1, width: '52ch'}}
                                required={input.required}
                                id={input.id}
                                label={input.label}
                                onChange={(event) => (handleInputChange(input.id, event))}
                                value={formValues[input.id]}
                                inputProps={{ maxLength: 50 }}
                            />
                    }  
                })}
                </Grid>  
            </DialogContent>

            <DialogContent  style={{ overflow: "hidden" }}>
                <Grid  >
                    {Object.entries(formInputsDetail).filter(val => val[0] !== 'id').map(val => {
                    const input = val[1]
                    switch (input.type){
                        case(FormInputType.textField):
                            return <TextField
                                key={input.id}
                                fullWidth sx={{marginTop: 1, m:1, width: '52ch'}}
                                required={input.required}
                                id={input.id}
                                label={input.label}
                                onChange={(event) => (handleInputChange(input.id, event))}
                                value={formValues[input.id]}
                                inputProps={{ maxLength: 50 }}
                            />
                    }  
                })}
                </Grid>  
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleConfirm} variant='contained' color='success'>{t('ConfirmationDialog.confirm')}</Button>
                <Button onClick={handleCancel} variant='contained' color='error'>{t('ConfirmationDialog.cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default FormDialog;

export const FormInputType = {
    textField: 'textField',
    select: 'select'
}

export const FormType = {
    create: 'create',
    edit: 'edit'
}
