import React from "react";
import {Button, Alert, Stack} from "@mui/material/";
import { makeStyles } from "@mui/styles";
import { useTranslation } from 'react-i18next';
import {restartSession, selectSessionData} from "../../store/features/sessionSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {useNavigate} from "react-router-dom";
import {HTTP_REQUESTS} from "../../backendcomm/services/httpRequestService";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles(theme => ({
    popover: {
        padding: '16px',
        "& .MuiDialog-paper": {
            borderRadius: "8px",
        }
    },
    popoverTitle: {
        color: 'black',
    },
    popoverText: {
        "& .MuiAlert-root": {
            padding: '0px 8px',
            borderRadius:'8px',
            width:'fit-content',
        }       
    },
    buttons: {
        "& .MuiStack-root": {
            justifyContent:"flex-end",
        },
        '& button': {
            marginRight: '8px',
            padding: '4px 8px',
            borderRadius: '8px',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
        },
        '& button:last-child': {
            marginRight: '0',
        },
        '& button:hover': {
            backgroundColor: '#0056b3',
        },
    },
}));

const InvitePopup = () => {
    const [dialogOpen, setDialogOpen] = React.useState(true);
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const sessionData = useAppSelector(selectSessionData)
    const confirmInvitation = () => {
        let params = {name: sessionData.name, status: "active", isSubscribed:sessionData.isSubscribed};
        HTTP_REQUESTS.USER_SERVICE.ACTIVATE_INVITATION(
            params,
            (success) => {
                setDialogOpen(false);
                window.location.reload();
            },
            (err) => {
                console.log('Error deleting user:', err);
            }
        );
    }
    const handleClose = () => {
        setDialogOpen(false);
        let language = localStorage.getItem('language');
        localStorage.clear();
        // @ts-ignore
        localStorage.setItem('language' , language);
        dispatch(restartSession())
        navigate('/login')
    };
    
    return (
    <div>
        <Dialog
            open={dialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.popover}
        >
            <DialogTitle id="alert-dialog-title" className={classes.popoverTitle}>
                {t("CompanyAvatar.Space System")}
            </DialogTitle>
            <DialogContent className={classes.popoverText}>
                <Alert severity="info">
                    {t("InvitationForm.Invited Join", {name: sessionData.tenantName})}
                </Alert>
            </DialogContent>
            <DialogActions className={classes.buttons}>
                <Stack direction='row' spacing={1}>
                    <Button id={'logout'} variant="contained" onClick={handleClose} color="error">
                        {t('LogoutConfirmDialog.Logout')}
                    </Button>
                    <Button id={'join'}variant="contained" color="primary" onClick={confirmInvitation}>
                        {t('InvitationForm.Join')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    </div>
              
    );
};
export default InvitePopup;
