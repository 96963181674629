import React from "react"; 
import "./UsFlag.css"

const UsFlag = () => {
    return (
        <div className="flag">
            <div className="canton">
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
                <div className="space"></div>
                <div className="star"></div>
            </div>
        </div>
    );
};

export default UsFlag;
