export default class PaypalUpdateOrderObj{
    constructor(status,isMultiple){
        this.status       = status;
        this.isMultiple   = isMultiple;
    }

    toUIJSON(){
        throw Error('Not implmeented yet'); //TODO: implement this
    }

    toAPIJSON(){
        throw Error('Not implmeented yet'); //TODO: implement this
    }

    
}