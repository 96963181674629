import {Box, Button, Card, Grid, Typography} from "@mui/material";
import {withSnackbar} from "notistack";
import React from "react";
import withRouter from '../../withRouter';
import {withTranslation} from 'react-i18next';
import theme from "../../theme";
import {HTTP_REQUESTS} from "../../backendcomm/services/httpRequestService";
import {withStyles} from "@mui/styles";

const styles = {
    filterButtons: {
        '& .MuiButton-textPrimary': {
            fontWeight: 600,
            '& > span.MuiButton-label': {
                borderBottom: '1px solid',
            },
        },
        display: 'flex',
        flexWrap: 'wrap',
    },
    roomButtons: {
        display: "flex",
        flexWrap: "wrap",
    }
};
class DashboardAdminFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                locations: [],
                locationIds: [],
                roomIds: [],
                entities: {}
            },
            locationsData: [],
            entitiesData: [],
            entities: [],
            isReady: false
        };
    }


    handleLocationClick = (location) => {
        if (!this.state.filter.locationIds.includes(location.id)) {
            this.setState(prevState => ({
                filter: {
                    ...prevState.filter,
                    locationIds: [...prevState.filter.locationIds, location.id],
                    locations: [...prevState.filter.locations, location.name],
                    roomIds: [...prevState.filter.roomIds, ...location.rooms.map(room => room.id)]
                }
            }), () => {
                this.entitiesLoad()
                this.props.setFilter(this.state.filter);
            });
        } else {
            this.setState(prevState => ({
                filter: {
                    ...prevState.filter,
                    locationIds: prevState.filter.locationIds.filter(id => id !== location.id),
                    locations: prevState.filter.locations.filter(name => name !== location.name),
                    roomIds: prevState.filter.roomIds.filter(roomId => !location.rooms.some(room => room.id === roomId))
                }
            }), () => {
                this.entitiesLoad();
                this.props.setFilter(this.state.filter);
            });
        }
    };

    handleRoomClick = (room) => {
        if (this.state.filter.roomIds.includes(room.id)) {
            this.setState(prevState => ({
                filter: {
                    ...prevState.filter,
                    roomIds: prevState.filter.roomIds.filter(id => id !== room.id),
                    entities: prevState.filter.roomIds.filter(item => item.id !== room.id)
                }
            }), () => {
                this.entitiesLoad();
                this.props.setFilter(this.state.filter);
            });
        } else {
            this.setState(prevState => ({
                filter: {
                    ...prevState.filter,
                    roomIds: [...prevState.filter.roomIds, room.id],
                    entities: [...prevState.filter.entities, room]
                }
            }), () => {
                this.entitiesLoad();
                this.props.setFilter(this.state.filter);
            });
        }
    }

    entitiesLoad = () => {
        this.setState(prevState => ({
            entities: this.props.locations
                .filter(location => prevState.filter.locationIds.includes(location.id))
                .flatMap(location => location.rooms.map(room => ({...room})))
        }), () => {
            this.setState({isReady: true});
        });
    }

    render() {
        const {t, classes} = this.props;
        return (
            <Card>
                <Box>
                    <Typography margin={2} component="h6"
                                variant="h6">{t('Dashboard.Dashboard Admin Filter.Locations Title')}</Typography>
                    <Grid container spacing={3} className={classes.filterButtons}>
                        {this.props.locations.map((item, index) =>
                            <Grid item xs={6} md={4} key={`${item}-${index}`}>
                                <Button
                                    style={{
                                        width: "100%", margin: theme.spacing(1),
                                        backgroundColor: this.state.filter.locationIds.includes(item.id) ? "#a3c3d9" : undefined
                                    }}
                                    onClick={() => this.handleLocationClick(item)}>
                                    {item.name}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Box mt={2}>
                    <Typography margin={2} component="h6"
                                variant="h6">{t('Dashboard.Dashboard Admin Filter.Room Title')}</Typography>
                    <Grid container spacing={3} className={classes.roomButtons}>
                        {this.state.isReady &&
                            this.state.entities.map((item) => {
                                let tempLocation = this.state.locationsData
                                    .filter(location => location.rooms.some(room => room.id === item.id))
                                    .map(location => location.name)[0];
                                return <Grid item xs={6} md={4} key={item.id}
                                             style={{display: "flex", justifyContent: "center"}}>
                                <Button
                                        style={{
                                            margin: "8px",
                                            width: "100%",
                                            flexGrow: 1,
                                            color: '#ffffff',
                                            backgroundColor: (this.state.filter.roomIds.includes(item.id)) ? item.color : '#e0e0e0'
                                        }}
                                        onClick={() => {
                                            this.handleRoomClick(item)
                                        }}
                                    >
                                        {item.name} ({tempLocation})
                                    </Button>
                                </Grid>
                            })
                        }

                        {(!this.state.entities || this.state.entities.length === 0) &&
                            <Grid item xs={12}><Typography margin={2}
                                                           component="p">{t('Dashboard.Dashboard Admin Filter.Select Location')}</Typography></Grid>
                        }
                    </Grid>
                </Box>
            </Card>

        )
    }
}

export default withTranslation()(withSnackbar(withRouter(withStyles(styles)(DashboardAdminFilter))));
