import React from "react";
import "./DeFlag.css"

const DeFlag = () => {
    return (
        <div className="flag">
            <span className="black stripe"></span>
            <span className="red stripe"></span>
            <span className="gold stripe"></span>
        </div>
    );
};

export default DeFlag;
