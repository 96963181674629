import React from "react";
import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";


const localizer = momentLocalizer(moment);


const LobbyCalendar = (props) => {   
  return (
    <Calendar
      events={props.events}
      toolbar={false}
      style={{ height: "95vh",width:'100%'  }}
      min={new Date(0, 0, 0, 7, 0, 0)}
      max={new Date(0, 0, 0, 19, 0, 0)}
      localizer={localizer}
      step={30}
      defaultView={Views.DAY}
      views={["day", "work_week"]}
      defaultDate={new Date()}
      resources={props.resourceMap}
      resourceIdAccessor="resourceId"
      resourceTitleAccessor="resourceTitle"
      
      
      components={{resourceHeader:(props) => {
        return (
          
          <div style={
            {
              height:"30vh",
              background:`${props.resource.currentEvent.title ? "url('/images/lobby/booked.png')" : "url('/images/lobby/available.png')" } `,
              backgroundSize: 'cover',
              padding: '15px'
            }} >
            <div style={{height:"80%",display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: 'hidden',
            whiteSpace:"break-spaces"
            }}>
                 {props.resource.currentEvent.title ?props.resource.currentEvent.title : "AVAILABLE"}
            </div>
            <div>
            {props.resource.summary}
              </div>
          </div>
        );
      },
      timeGutterHeader: () => null,
      toolbar: () => null
    }}
    />
  )
  
  };

export default LobbyCalendar;
