import CalendarEvent from '../models/CalendarEvent';
import { HTTP_REQUESTS } from '../backendcomm/services/httpRequestService';
import BufferEvent from '../models/BufferEvent';
import moment from 'moment'
import { store } from '../store/store'

export function getAllEvents(loggedInUserId, cb,errorCallback,filter,search) {
    /* TODO Pass LoggedInUserId to filter by owner id all events */
    let events = [];
    const currentTenantId = store.getState().sessionData.tenantId;

    HTTP_REQUESTS.BOOKING_SERVICE.GET_ALL_BOOKINGS_ALL_CALENDARS(currentTenantId,filter,search,(res) => {
        res.forEach(function (event) {
            // event.location = 'Lüneburg'
            let newCalendarEvent = new CalendarEvent(event.id, event.title,event.owner_id, event.owner_name, new Date(event.start), new Date(event.end), true, event.color, event.status , event.location, event.eventObj.roomId, event.eventObj).toUIJSON(loggedInUserId);
            events.push(newCalendarEvent);
            if(!event.eventObj.state && store.getState().tenantConfig.bufferEvents){
                let bufferTime = event.eventObj.Room.bufferTime
                if(bufferTime > 0){
                    let newBufferEvent = new BufferEvent(`${event.id}+buffer`,'Buffer Event',new Date(moment(event.start).subtract(bufferTime,'minutes')),new Date(moment(event.start)),"#353b48").toUIJSON();
                    events.push(newBufferEvent)
                }
            }
        });
        cb(events);

    }, (err) => {
        errorCallback(err);
    });

}
