import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { getEvents } from '../../data/getEvents'
import { HTTP_REQUESTS } from '../../backendcomm/services/httpRequestService';
import sampleEvents from '../../data/sample-events'
import {  Grid, Fab, Box, Button, Card } from '@mui/material';
import { withSnackbar } from 'notistack';
import CustomSpinner from '../CustomSpinner'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TodayIcon from '@mui/icons-material/Today';
import InfoIcon from '@mui/icons-material/Info';
import PlusIcon from '@mui/icons-material/Add';
import moment from "moment";
import DateBar from '../../components/Booking/dateBar';
import { getAllEvents } from '../../data/getAllEvents';
import { withTranslation } from 'react-i18next';
import withRouter from '../../withRouter';
import CreateBookingPopover from '../Booking/CreateBookingPopover';
import {dataToEventObj, mapSessionStateToProps} from '../../helpers/dataConverter';
import theme from '../../theme';
import { CancelRounded } from '@mui/icons-material';
import {connect} from "react-redux";
import {ROLE} from "../../utils/Constants";
import {getAllEventsForPublicUser} from "../../data/getAllEventsForPublicUser";


class RCalendar extends React.Component {
    state =
        {
            isClickedPlus: false,
            data: [],
            slotData: {
                action: "select",
                bounds: {
                  bottom: 558,
                  left: 1269,
                  right: 1271,
                  top: 507,
                  x: 1269,
                  y: 558
                },
                start: new Date(),
                end: new Date(),
                slots: [],
                box: null,
                resourceId: null,
              },
            newEventTitle: null,
            events: process.env.REACT_APP_LOAD_CALENDAR_WITH_DUMMY_DATA === "true" ? sampleEvents : [],
            newEventId: new Date(),
            entityID: null,
            entityName:null,
            currentPageDate:null,
            errorMessage:'',
            addNewEntityButton:false,
            t: this.props.t,
            personId: {},
            isLoading: false
        }

    componentDidMount() {
        const { t } = this.props;
        this.props.innerRef.current = this;
        this.setState({ loading:true });
        
        this.setState({
            selectedBook:
            {
                data: null,
                open: false,
            }
        });
        
        let url = window.location.href;
        let id = url.substring(url.lastIndexOf('/') + 1);

        if(this.props.calendarMode !== 'all') {
            
            HTTP_REQUESTS.CALENDAR_SERVICE.GET_ONE_CALENDAR(
                this.props.sessionData.tenantId,
                id,
                data => {
                    this.setState({entityName:data.room.name});
                },
                err => {
                    console.log("Error: " + err);
                    
                    this.props.enqueueSnackbar(t('errorProperties.RCALENDAR_MOBILE.COMPONENT_DID_MOUNT'), {
                        variant: 'error'
                    });
                }
            );
        }

       
    }
    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props.calendarFilter) !== JSON.stringify(this.state.calendarFilter)) {  // Object Check 
            this.setState({calendarFilter: JSON.parse(JSON.stringify(this.props.calendarFilter))},()=>{  // Deep object clone
                this.entityIDUpdated()
            })
        }
    }
    entityIDUpdated = (entityID, successCB) => {
        const callCbFuncIfAssigned = () => {
            if (!successCB) {
                return;
            }
            try {
                successCB();
            }
            catch (error) {
                console.log(error);
            }
        };

        const removeLoading = () =>{
            this.setState({loading:false });
            if(this.props.calendarMode === 'all') {
                this.props.removeLoading(true);
            }
        }
        const errorCallback = (error) =>{
            let errorMessage;
            if(error) {
                errorMessage = 'Entities.RCalendar.Error Occured'
                if(error.data) {
                    if(error.data.error.code === 2006) {
                        errorMessage ='errorProperties.ENTITIY_OPERATION_ERROR.NOT_FOUND'
                        this.setState({addNewEntityButton:true});
                    }
                }
            }
            this.setState({loading:false,errorOccured:true, errorMessage:errorMessage });
            if(this.props.calendarMode === 'all') {
                this.props.removeLoading(true);
            }
        }
        const getEvents__ = (newEvents) => {
            let dayStart;
            
            let dayEnd;
    
            if(this.state.currentPageDate != null){
                dayStart = new Date(this.state.currentPageDate);
                dayStart.setHours(0,0,0,0);

                dayEnd =  new Date(this.state.currentPageDate);
                dayEnd.setHours(23,59,59,999);

            }else{
                dayStart = new Date();
                dayStart.setHours(0,0,0,0);

                dayEnd = new Date();
                dayEnd.setHours(23,59,59,999);
            }
            
         
            let todayEvents = newEvents.filter((x) => (x.start > dayStart && x.end < dayEnd));

            this.setState({ events: todayEvents }, callCbFuncIfAssigned);
            removeLoading();
        };
        const getEvents_ = () => {
            if(this.props.calendarMode === 'all') {
                if (this.props.sessionData.role === ROLE.PUBLIC_USER){
                    getAllEventsForPublicUser(this.props.sessionData.id, getEvents__,errorCallback, this.props.calendarFilter,this.state.searchValue);
                }else {
                    getAllEvents(this.props.sessionData.id, getEvents__,errorCallback, this.props.calendarFilter);
                }
            } else {
                getEvents(this.props.sessionData.id, this.state.entityID, getEvents__,errorCallback);
            }
        };
        
        if(this.props.calendarMode === 'all') {
            getEvents_();
        } else {
        this.setState({ entityID: entityID }, getEvents_)
        }
    }
    
    componentWillUnmount() {
        this.props.innerRef.current = null;
    }

    handleShowUpdateModal = (obj) => {
        const { t } = this.props;
        let roleOfOccupant = this.props.sessionData.role
        if(obj.start< new Date() && roleOfOccupant === "user"){
            this.props.enqueueSnackbar(t('errorProperties.RCALENDAR_MOBILE.HANDLE_SHOW_INFO_MODAL.CREATED_PAST_DATE_EVENT'), {
                variant: 'error'
            });
        }

        let bookingHolderId = obj.eventObj.ownerAccountId
        let tempParticipants = obj.eventObj.participants
        let userId = this.props.sessionData.id


        this.setState({personId : bookingHolderId})
        
        if(obj.isBuffer === true){
            return
        } else if(obj.eventObj.state === "cancelled" && roleOfOccupant === "user"){
            return
        }
        else if (bookingHolderId !== userId && roleOfOccupant === "user" && obj.eventObj.state === "cancelled"){
            this.props.enqueueSnackbar(t('errorProperties.RCALENDAR_MOBILE.HANDLE_SHOW_INFO_MODAL.NOT_INVOLVED'), {
                variant: 'error'
            });
            return
        }
        this.setState({
            data: obj,
            showInfoModal: true
        })
    }

    handleCreateModalClose = () => {
        this.setState({ showInfoModal: false, isClickedPlus: false })
    }
    handleUpdateModalClose = () => {
        this.setState({ showInfoModal: false, isClickedPlus: false })
    }
    contactCustomerToParticipants = (contactCustomer) => {
        let tempParticipiants = [];
        for (const elementItem of contactCustomer) {
            tempParticipiants.push(elementItem.id);
        }
        return tempParticipiants;
    }
    saveModal = (data,modalMethod) => {
        const { t } = this.props;
        if(modalMethod === 'create') {
        let tenantId = this.props.sessionData.tenantId
        let eventObj = {};
        eventObj = dataToEventObj(data);
        // eventObj.startDate = DateFormatter.combineDates(data.selectedDate, data.selectedStart);
        // eventObj.endDate = DateFormatter.combineDates(data.selectedDate, data.selectedEnd);
        // eventObj.title = data.title;
        // eventObj.ownerid = data.bookingHolder;
        // eventObj.roomId = data.room;
        // eventObj.note = data.additionalFeatures.notes;
        // eventObj.tableLayout = data.additionalFeatures.tableLayout;
        // eventObj.seat = data.additionalFeatures.seats;
        // eventObj.status = data.bookingStatus;
        // eventObj.billingAddress = data.billingAddress;
        // eventObj.caterings = data.additionalFeatures.caterings;
        // eventObj.moderationEquipment = data.additionalFeatures.moderationEquipment;
        // eventObj.totalprice = data.totalPrice
        // eventObj.confirmMail = data.sendConfirmationTo
        // eventObj.participants = this.contactCustomerToParticipants(data.contactCustomer)
        this.setState({isLoading : true},()=>{
            HTTP_REQUESTS.BOOKING_SERVICE.CREATE_A_BOOKING( false, eventObj, (res) => {
                this.entityIDUpdated(this.state.entityID);
                this.setState({isEventCreated : true,isLoading: false},()=>{
                    this.handleCreateModalClose();
                });
                this.props.enqueueSnackbar("Event Created Successfully", {
                    variant: 'success'
                });
            }, (err) => {
                /*if(err.toString().includes('400')){
                }*/
                this.setState({isLoading: false});
                this.entityIDUpdated(this.state.entityID);
                if(err.data.error && err.data.error.code === 2001){
                    let errorDetails = err.data.error.details
                    if( errorDetails.code === 100){
                        let errorWord = t(`errorProperties.RCALENDAR.CREATE_EVENT.FIELDS.${errorDetails.key}`)
                        this.props.enqueueSnackbar(t(`errorProperties.RCALENDAR.CREATE_EVENT.${errorDetails.code}.${errorDetails.type}`,{fieldName:errorWord}), {
                            variant: 'error'
                        });
                    }else{
                        this.props.enqueueSnackbar(t(`errorProperties.RCALENDAR.CREATE_EVENT.${errorDetails.code}`), {
                            variant: 'error'
                        });
                    }
                }else{
                    this.props.enqueueSnackbar(t('errorProperties.RCALENDAR.CREATE_EVENT.109'), {
                        variant: 'error'
                    });
                }
                console.log(err);
            });
        });
        } else if(modalMethod === 'update') {
            this.setState({isLoading : true},()=>{
                this.updateBooking(data.id,data);
            });
        }
  }

    newEventTitleChanged = event => {
        this.setState({ newEventTitle: event.target.value });
    };

    itemDeletedSuccessfully = (res, bookingId,isCancel) => {
        const { t } = this.props;
        if(isCancel){
            this.props.enqueueSnackbar(t('Entities.RCalendar.Event Cancelled'), {
                variant: 'success'
            }); 
        }else{
            this.props.enqueueSnackbar(t('Entities.RCalendar.Event Deleted'), {
                variant: 'success'
            }); 
        }
        this.setState({isLoading: false});
        this.entityIDUpdated(this.state.entityID);
        this.handleUpdateModalClose();
    }

    deleteABookingEvent = (bookingId,entityID,deleteAll,isCancel) => {
        const { t } = this.props;
        let tenantId = this.props.sessionData.tenantId;
        let deleteObj = {deleteAll:deleteAll,isCancel:isCancel}
        let targetEvent = this.state.events.find(item => item.id = bookingId);
        this.setState({isLoading : true},()=>{
            HTTP_REQUESTS.BOOKING_SERVICE.DELETE_A_BOOKING(
                tenantId,
                entityID,
                bookingId,
                deleteObj,
                (res) => this.itemDeletedSuccessfully(res, bookingId,isCancel), (err) => {
                    //alert(` ${t('errorProperties.RCALENDAR.DELETE_A_BOOKING_EVENT')} \n #${JSON.stringify(targetEvent)}\n\nOperation failed. :Details->\n\n ${JSON.stringify(err)}`);
                    this.setState({isLoading: false})
                    if(isCancel){
                        this.props.enqueueSnackbar(t('Entities.RCalendar.Event Not Cancelled'), {
                            variant: 'error'
                        });
                    }else{
                        this.props.enqueueSnackbar(t('Entities.RCalendar.Event Not Deleted'), {
                            variant: 'error'
                        });
                    }
                }
            )
        });
    }

    updateBooking = (bookingId, newBookingObj) => {
        const { t } = this.props;
        // let ownerid = this.props.sessionData.id;
        let tempBookingObj = {};

        // tempBookingObj.startDate =  DateFormatter.combineDates(newBookingObj.selectedDate, newBookingObj.selectedStart);
        // tempBookingObj.endDate = DateFormatter.combineDates(newBookingObj.selectedDate, newBookingObj.selectedEnd);
        // tempBookingObj.title = newBookingObj.title;
        // tempBookingObj.ownerid = newBookingObj.bookingHolder;
        // tempBookingObj.roomId = newBookingObj.room;
        // tempBookingObj.note = newBookingObj.additionalFeatures.notes;
        // tempBookingObj.tableLayout = newBookingObj.additionalFeatures.tableLayout;
        // tempBookingObj.seat = newBookingObj.additionalFeatures.seats;
        // tempBookingObj.status = newBookingObj.bookingStatus;
        // tempBookingObj.billingAddress = newBookingObj.billingAddress;
        // tempBookingObj.confirmMail = newBookingObj.sendConfirmationTo;
        // tempBookingObj.participants = this.contactCustomerToParticipants(newBookingObj.contactCustomer)
        // tempBookingObj.caterings =newBookingObj.additionalFeatures.cateringDropdown;
        // tempBookingObj.moderationEquipment = newBookingObj.additionalFeatures.moderationEquipment;
        // tempBookingObj.totalprice = newBookingObj.totalPrice;
        tempBookingObj = dataToEventObj(newBookingObj)
        const newBookingObjParams = { id:bookingId,ownerid:tempBookingObj.ownerid, ...tempBookingObj };
        HTTP_REQUESTS.BOOKING_SERVICE.UPDATE_A_BOOKING(bookingId, newBookingObjParams, (res) => {
            this.handleUpdateModalClose();
            this.entityIDUpdated(this.state.entityID);
            this.props.enqueueSnackbar(t('Entities.RCalendarMobile.Event Updated'), {
                variant: 'success'
              });
            this.setState({isLoading: false});
        }, (err) => {
            this.setState({isLoading: false});
            if(err.data.error && err.data.error.code === 2002){
                let errorDetails = err.data.error.details
                if( errorDetails.code === 100){
                    let errorWord = t(`errorProperties.RCALENDAR.CREATE_EVENT.FIELDS.${errorDetails.key}`)
                    this.props.enqueueSnackbar(t(`errorProperties.RCALENDAR.CREATE_EVENT.${errorDetails.code}.${errorDetails.type}`,{fieldName:errorWord}), {
                        variant: 'error'
                    });
                }else{
                    this.props.enqueueSnackbar(t(`errorProperties.RCALENDAR.CREATE_EVENT.${errorDetails.code}`), {
                        variant: 'error'
                    });
                }
            }else{
                this.props.enqueueSnackbar(t('errorProperties.RCALENDAR.CREATE_EVENT.112'), {
                    variant: 'error'
                });
            }
            console.log(err);
        });
    }

    handleEditClick = (event) => {
        let eventId = event.currentTarget.id;
        let targetEvent = this.state.events.find(item => item.id == eventId);
        this.handleShowUpdateModal(targetEvent);
        //this.setState({editDialogOpen: true, instanceToEdit: eventObj});
        
    }

    handlePlusClick = () => {
       this.setState({
           isClickedPlus : true
       });
    }

    onDateChange = (newDate) => {

        let dayStart = new Date(newDate.getTime());
        dayStart.setHours(0,0,0,0);
        let dayEnd = new Date(newDate.getTime());
        dayEnd.setHours(23,59,59,999);
        // Adding half hour to start time
        let newDateEnd = moment(newDate).add(30, 'minutes') 
        /*let allEvents = this.state.allEvents;
        console.log(allEvents);
        let dayEvents = this.state.events.filter((x,i) => (x.start > dayStart && x.end < dayEnd));
        console.log(dayEvents);*/
        
        this.setState({currentPageDate: newDate});
        this.setState((prevState) => ({
            ...prevState,
            slotData: {...prevState.slotData, start:newDate, end:newDateEnd}
        }));
        this.entityIDUpdated(this.state.entityID);
        
    }
    render() {

        const { t } = this.props;

        if(this.state.loading == true){
            if(this.props.calendarMode !== 'all') {
                return (
                    <CustomSpinner />
                )
            } else {
                return null;
            }
        }else if(this.state.errorOccured == true){
            return (
                <div style={{ margin: "10px 0px" }}>
                    { (this.state.errorMessage != null && this.state.errorMessage.length > 0) ? t(this.state.errorMessage) : '' }
                </div>
                )
        }else{
            if (!this.state.addNewEntityButton && this.props.hasEntity === false) {
                const {navigate} = this.props;
                return  <Box>
                            <Button
                            variant="contained"
                            color="primary"
                            onClick={()=>{navigate("/entities/room/new")}}>
                                {t('Dashboard.FakeCalendar.Add Room')}
                            </Button>
                        </Box>
            } else {
                return (
                    <div style={{ maxHeight: "100%"}}>
                    <Grid item xs={12} md={6}>
                        <Card style={{'padding': '16px'}}>
                            {this.state.entityName &&
                                <Typography variant="h6"  style={{marginBottom:"12px"}}>
                                    {this.state.entityName}
                                </Typography>
                            }
                            <DateBar onDateChange = {this.onDateChange}/>
                            <div >
                                <List dense={true} style={{"margin-bottom" : "10vh"}}>
                                    {this.state.events.map((event) => {
                                        return event.isBuffer !== true &&
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <TodayIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={event.title}
                                                secondary={
                                                    <div>
                                                        <div>{t('Calendar.RCalendarMobile.Starts') + moment(event.start).format("DD/MM/YYYY HH:mm")}</div>
                                                        <div>{t('Calendar.RCalendarMobile.Ends') + moment(event.end).format("DD/MM/YYYY HH:mm")}</div>
                                                    </div>
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                {(this.props.sessionData.role !== ROLE.PUBLIC_USER || (event.eventObj && event.eventObj.isOwner)) &&
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        style ={{color: event.editable ? "green" : "green"}}
                                                        size="large"> {/* TODO: Editable green-gray */}
                                                        <div id={event.id} onClick={this.handleEditClick}>
                                                            {event.eventObj.state !== "cancelled" ? <InfoIcon /> : <CancelRounded style={{color:theme.palette.danger.backgroundColor}} /> }
                                                        </div>
                                                    </IconButton>
                                                }
                                            </ListItemSecondaryAction>
                                        </ListItem>;
                                        
                                    }
                                    )}
                                </List>
                            </div>
                        </Card>
                    </Grid>
    
                    <div style={{ position: "fixed", right: "15px", bottom: "15px" }} >
                        <Fab onClick={this.handlePlusClick} edge="end" aria-label="add" style={{ "border-radius": "50%", "background-color": "#43A047", "padding": "0px" }} >
                            <PlusIcon style={{height:"75%",width:"75%",color:"white"}}/>
                        </Fab>
                    </div>  
                    {this.state.isClickedPlus && <CreateBookingPopover
                    isLoading = {this.state.isLoading}
                    method='create' 
                    selectedEntity={this.state.entityID} 
                    isMobile={true} 
                    open= {this.state.isClickedPlus} 
                    data={this.state.slotData} 
                    handleClose={this.handleCreateModalClose}
                    handleSave={this.saveModal}  />}           
                    {this.state.showInfoModal && <CreateBookingPopover
                    isLoading = {this.state.isLoading}
                    method='update' 
                    bookingData={this.state.data} 
                    selectedEntity={this.state.entityID} 
                    isMobile={true} 
                    open= {this.state.showInfoModal} 
                    data={this.state.slotData} 
                    handleClose={this.handleUpdateModalClose}
                    handleSave={this.saveModal}
                    handleCancelBooking={this.deleteABookingEvent}  
                    personId={this.state.personId}/>}           
                </div>
                );
            }
        }
    }
}

export default withRouter(withTranslation()(connect(mapSessionStateToProps)(withSnackbar(RCalendar))));

