import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
        maxWidth: '100%',
        width: '100%'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}));


const NotesTextField = props => {
    const classes = useStyles();
    const [note, setNote] = useState((props.value) ? props.value : null);

    const handleNoteChange = (event) => {
        setNote(event.target.value);
        if(props.onChange) {
        props.onChange(event);
        }
    };

    const standartTextField = () =>{
        return (
            <FormControl className={classes.formControl}>
                <TextField
                    name={props.name}
                    id="notes_text_field"
                    multiline
                    fullWidth
                    rows={4}
                    variant="filled"
                    value={note || ""}
                    disabled={props.disabled}
                    onChange={handleNoteChange}
                >
                </TextField>
             </FormControl>

        )
    }

    const smallTextField = () =>{
        return (
            <FormControl className={classes.formControl}>
                <TextField
                    name={props.name}
                    id="notes_text_field"
                    multiline
                    margin="dense"
                    rows={2}
                    variant="filled"
                    value={note}
                    disabled={props.disabled}
                    onChange={handleNoteChange}
                >
                </TextField>
             </FormControl>

        )
    }

    return (
        props.small ? smallTextField() : standartTextField()

    )
}

export default NotesTextField;
