import moment, {isMoment} from 'moment';

import {t} from "i18next";
class DateFormatter{

}

DateFormatter.toHumanizeString = function(date){
    return moment(date).format('MMMM Do YYYY, h:mm:ss a'); 
}
DateFormatter.combineDates = (rootDate, secondDate) => {
    if(secondDate!==null) {
        return rootDate.toString().substring(0, 10) + secondDate.toString().substring(10);
    }}
DateFormatter.getTime = (date) => {
    return moment(date).format("HH:mm").toString();
}
DateFormatter.getFullDate = (date, time) => {
   let momentTime = moment(time, 'HH:mm');
    return moment(date).set({
        hour:   momentTime.get('hour'),
        minute: momentTime.get('minute'),
        second: momentTime.get('second')
    }).toString();
}

DateFormatter.getLocalizedCalendarFormat = (date) => {
    if(!isMoment(date)){
        date=moment(date)
    }
    return date.locale(localStorage.getItem('language')).calendar(null,{
        lastDay : `[${t('MomentCalendarLocalization.lastDay')}]`,
        sameDay : `[${t('MomentCalendarLocalization.sameDay')}]`,
        nextDay : `[${t('MomentCalendarLocalization.nextDay')}]`,
        lastWeek : `[${t('MomentCalendarLocalization.lastWeek')}] dddd`,
        nextWeek : 'dddd',
        sameElse : 'L'
    });
}

DateFormatter.combineDateAndTime = (date, time) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), 0, 0);
}

export default DateFormatter;
