import React, {useEffect, useRef, useState} from "react";
import {
    Grid,
    Typography,
    Skeleton,
    TextField,
    Stack,
    FormControlLabel,
    Box,
    Checkbox,
    Grow,
    Button,
    Tooltip,
} from "@mui/material";
import "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { makeStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import Alert from "@mui/material/Alert";
import ReplayIcon from "@mui/icons-material/Replay";
import {HTTP_REQUESTS} from "../../backendcomm/services/httpRequestService";
import {calendarLanguagesData} from "../../data/languages";
import roundTimeToTheNearestXXMinutes from "../PublicComponents/helpers/roundTimeToTheNearestXXMinutes";
import PublicRoomCard from "../PublicComponents/PublicDashboard/PublicRoomCard";
import dateFormatter from '../../helpers/DateFormatter'

const filterBorderColor = "#D3D3D3";
const iconColor = "#003db0";

const theme = createTheme();
theme.typography.h3 = {
    fontWeight: "normal",
    fontSize: "1.2rem",
    marginLeft: "6%",
    "@media (min-width:600px)": {
        fontSize: "1.5rem",
        marginLeft: "3%",
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "1.75rem",
        marginLeft: "230px",
    },
};
theme.typography.h5 = {
    fontWeight: "normal",
    fontSize: "1rem",
    marginLeft: "6%",
    "@media (min-width:600px)": {
        fontSize: "1rem",
        marginLeft: "3%",
    },

    [theme.breakpoints.up("lg")]: {
        fontSize: "1rem",
        marginLeft: "1%",
    },
};
const useStyles = makeStyles({
    card: {
        padding: "10px",
        textAlign: "center",
    },
    selected: {
        border: "2px solid",
        borderRadius: "16px",
    },
    flexDirectionRowReverse: {
        "flex-direction": "row-reverse",
    },
});

const RoomSelect = (props) => {
    const calendarLanguages = calendarLanguagesData;
    const [endDateError, setEndDateError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const { t } = useTranslation();
    const [filterError, setFilterError] = useState("");
    const [errorFlag, setErrorFlag] = useState(false);
    const [checkedFlag, setCheckedFlag] = useState(true);
    const [availableEntities, setAvailableEntities] = useState([]);

    useEffect(() => {
        const checkedLocations = [];
        const allEntities = [];
        const filteredEntities = [];

        props.locations.forEach(location => {
            if(location.isChecked){
                checkedLocations.push(location);
            }
            location.rooms.forEach(room => {
                room.locationName = location.name ? location.name : "";
                if(location.city && location.city !== "null") {
                    room.locationName += " - " + location.city;
                }
                allEntities.push(room);
                if(location.isChecked){
                    filteredEntities.push(room);
                }
            })
        })

        // If location filter is empty, show all entities
        if (checkedLocations.length === 0) {
            setCheckedFlag(true);
            setAvailableEntities(allEntities);
        }

        // If location filter has locations, show filtered entities
        else {
            setCheckedFlag(false);
            setAvailableEntities(filteredEntities);
        }

        // Error message decider
        if (filteredEntities.length === 0) {
            // If response length is 0, then there are no available room between selected dates.
            setFilterError("Dashboard.MakeBooking.DateError");
            setErrorFlag(true);
        } else if (allEntities.length !== 0 && filteredEntities.length === 0) {
            // If response length is not 0 but filtered entities are empty, then there are no room by the filtered locations
            setFilterError("Dashboard.MakeBooking.LocationError");
            setErrorFlag(true);
        }

    }, [props.locations])

    const handleCheckboxChange = (event) => {
        const cloneLocations = [...props.locations];
        if (event.target.name === "selectAll") {
            cloneLocations.forEach((location) => (location.isChecked = event.target.checked));
            props.setLocations(cloneLocations);
        } else {
            const location = cloneLocations.find((location) => location.name === event.target.name);
            location.isChecked = event.target.checked;
            props.setLocations(cloneLocations);
        }
    };

    const handleIfStartIsAfterThanEnd = () => {
        // Checks if selectedEndTime is before than the selectedStartTime and if so, it sets selectedEndTime to 30 min later than selectedStartTime
        if (props.selectedEndTime <= props.selectedStartTime) {
            let newEndDate = moment(props.selectedStartTime).add(30, "m").toDate();
            props.setSelectedEndTime(newEndDate);
        }
        setStartDateError(false);
    };

    const handleDateChange = (date) => {
        props.setSelectedDate(date);
    };

    const rounding = (time) => {
        const newTime = roundTimeToTheNearestXXMinutes(time, 15);
        return newTime;
    };

    const disableText = t("Dashboard.MakeBooking.Disable");

    const fixTimesWithLimits2 = (end, selectedDate) => {
        const last = selectedDate;
        last.setHours(23, 59, 59);
        return end <= last;
    };

    useEffect(() => {
        let currentDate = new Date();
        const diffMinutes = moment(props.selectedEndTime).diff(moment(props.selectedStartTime), 'minutes');
        console.log(diffMinutes)
        let newStartDate = dateFormatter.combineDateAndTime(props.selectedDate, new Date());
        newStartDate = rounding(newStartDate);
        let newEndDate = moment(newStartDate).add(30, "m").toDate();

        if (diffMinutes < 30 && fixTimesWithLimits2(newEndDate, props.selectedDate)) {
            props.setSelectedStartTime(new Date(newStartDate));
            props.setSelectedEndTime(new Date(newEndDate));
        }
        else if(diffMinutes < 30) {
            currentDate.setDate(currentDate.getDate() + 1);
            props.setSelectedStartTime(new Date(currentDate.setHours(9, 0, 0)));
            props.setSelectedEndTime(new Date(currentDate.setHours(9, 30, 0)));
            props.setSelectedDate(new Date(currentDate));
        }

        setEndDateError(false);
    }, []);

    const handleStartTimeChange = (startTime) => {
        startTime = dateFormatter.combineDateAndTime(props.selectedDate, startTime);
        let isStartTimePast = startTime < Date.now();
        if (isStartTimePast) {
            setStartDateError(true);
            props.enqueueSnackbar(t("errorProperties.MAKE_BOOKING.HANDLE_START_TIME_CHANGE.SET_START_DATE_ERROR"), {
                variant: "error",
            });
        } else {
            props.setSelectedStartTime(startTime);
        }
    };

    const handleEndTimeChange = (endTime) => {
        endTime = dateFormatter.combineDateAndTime(props.selectedDate, endTime);
        if (props.selectedStartTime >= endTime) {
            endTime.setHours(props.selectedStartTime.getHours(), props.selectedStartTime.getMinutes() + 30, 0, 0);
            endTime.setSeconds(0);
            endTime.setMilliseconds(0);
            setEndDateError(true);
            props.enqueueSnackbar(t("errorProperties.MAKE_BOOKING.HANDLE_END_TIME_CHANGE.SET_END_DATE_ERROR"), {
                variant: "error",
            });
        }
        props.setSelectedEndTime(endTime);
        setEndDateError(false);
    };

    const classes = useStyles();

    return (
        <Grid container sx={{ justifyContent: { xs: "center", lg: "flex-start" } }} alignItems="flex-start" spacing={2}>
            <Grid item container id="header" display="flex" alignItems="baseline">
                <ThemeProvider theme={theme}>
                    <Typography variant="h3">{t("Dashboard.MakeBooking.Public Booking Page First Title")}</Typography>
                    <Typography variant="h5" style={{ color: "#666666" }}>
                        {t("Dashboard.MakeBooking.Public Booking Page Second Title")}
                    </Typography>
                </ThemeProvider>
            </Grid>

            <Grid
                item
                container
                id="date-pickers"
                border={1}
                sx={{
                    backgroundColor: "white",
                    marginTop: "25px",
                    marginLeft: "30px",
                    borderRadius: "16px",
                    borderColor: filterBorderColor,
                    direction: "row",
                    alignContent: "baseline",
                    alignItems: "flex-start",
                    textAlign: "center",
                    padding: "1%",
                    minHeight: "max-content",
                    width: {
                        xs: "full-width",
                        md: "full-width",
                        lg: "190px",
                    },
                }}
            >
                <LocalizationProvider
                    adapterLocale={calendarLanguages[t("Calendar.MiniCalendar.Language")]}
                    dateAdapter={AdapterDateFns}
                >
                    <Stack spacing={1}>
                        <Typography
                            sx={{
                                marginTop: "7%",
                                marginLeft: "5%",
                                display: { xs: "inline-flex", lg: "none" },
                                fontWeight: "bold",
                                letterSpacing: "0px",
                            }}
                        >
                            {t("Dashboard.MakeBooking.Date and Time")}
                        </Typography>
                        <DatePicker
                            disablePast
                            showTodayButton
                            inputFormat="dd/MM/yyyy"
                            value={props.selectedDate}
                            onChange={handleDateChange}
                            onAccept={handleDateChange}
                            todayLabel={t("Dashboard.MakeBooking.Today")}
                            okLabel={t("Dashboard.MakeBooking.Ok")}
                            cancelLabel={t("Dashboard.MakeBooking.Cancel")}
                            InputProps={{
                                sx: { "& .MuiButtonBase-root": { padding: 0, marginRight: "1%" } },
                                classes: { root: classes.flexDirectionRowReverse },
                                disableUnderline: true,
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant="standard"
                                    {...params}
                                    sx={{ svg: { paddingLeft: 0, marginRight: "10px", color: iconColor } }}
                                />
                            )}
                            componentsProps={{ actionBar: { actions: ["accept"] } }}
                        />

                        <TimePicker
                            key="startTimePicker"
                            showTodayButton
                            format="HH:mm"
                            value={props.selectedStartTime}
                            error={startDateError}
                            ampm={false}
                            minutesStep={15}
                            onChange={handleStartTimeChange}
                            closeOnSelect={false}
                            onAccept={handleIfStartIsAfterThanEnd}
                            renderInput={(params) => (
                                <TextField
                                    variant="standard"
                                    {...params}
                                    sx={{ svg: { marginRight: "10px", color: iconColor } }}
                                />
                            )}
                            InputProps={{
                                sx: { "& .MuiButtonBase-root": { padding: 0, marginRight: "1%" } },
                                classes: { root: classes.flexDirectionRowReverse },

                                disableUnderline: true,
                            }}
                            componentsProps={{
                                actionBar: { actions: ["accept"] },
                            }}
                        />

                        <TimePicker
                            key="endTimePicker"
                            cancelLabel={t("Dashboard.MakeBooking.Back")}
                            okLabel={t("Dashboard.MakeBooking.Select")}
                            value={props.selectedEndTime}
                            ampm={false}
                            onChange={handleEndTimeChange}
                            error={endDateError != null && endDateError.length > 0}
                            minutesStep={15}
                            closeOnSelect={false}
                            renderInput={(params) => (
                                <TextField
                                    variant="standard"
                                    {...params}
                                    sx={{
                                        svg: {
                                            marginRight: "10px",
                                            color: iconColor,
                                        },
                                    }}
                                />
                            )}
                            InputProps={{
                                sx: { "& .MuiButtonBase-root": { padding: 0, marginRight: "1%" } },
                                classes: { root: classes.flexDirectionRowReverse },
                                disableUnderline: true,
                            }}
                            componentsProps={{
                                actionBar: { actions: ["accept"] },
                            }}
                        />
                    </Stack>
                </LocalizationProvider>

                <Stack>
                    <Typography
                        style={{
                            marginTop: "10%",
                            marginLeft: "6%",
                            display: "inline-flex",
                            fontWeight: "bold",
                            letterSpacing: "0px",
                        }}
                    >
                        {t("Dashboard.MakeBooking.Location")}
                    </Typography>
                    {props.isLoading ? (
                        !props.fetchError ? (
                            <Skeleton
                                style={{ marginRight: "10px", borderRadius: "16px" }}
                                variant="rect"
                                animation="wave"
                                width={150}
                                height={150}
                            />
                        ) : (
                            <Box height={150} width={150} sx={{ textAlign: "center" }}>
                                <Button
                                    variant="text"
                                    startIcon={<ReplayIcon />}
                                    onClick={props.tryAgain}
                                    sx={{ marginTop: "50px" }}
                                >
                                    {t("Dashboard.MakeBooking.Try Again")}
                                </Button>
                            </Box>
                        )
                    ) : (
                        <Box width={180}>
                            <FormControlLabel
                                label={t("Dashboard.MakeBooking.Select All")}
                                name="selectAll"
                                sx={{display:'flex', marginLeft: 0 }}
                                control={
                                    <Checkbox
                                        checked={props.locations.every((location) => location.isChecked)}
                                        indeterminate={
                                            props.locations.some((location) => location.isChecked) &&
                                            !Object.values(props.locations).every((location) => location.isChecked)
                                        }
                                        onChange={handleCheckboxChange}
                                    />
                                }
                            ></FormControlLabel>
                            <Box sx={{ display: "flex", flexDirection: "column", ml: 3, marginLeft:'16px' }}>
                                {props.locations.map((location, index) => {
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            name={location.name}
                                            label={<Tooltip title={location.name} followCursor>
                                                        <span style={{display:"grid"}}>
                                                            <Typography
                                                                component="div"
                                                                sx={{
                                                                    display: "-webkit-box",
                                                                    textOverflow: "ellipsis",
                                                                    textAlign: "start",
                                                                    overflow: "hidden",
                                                                    WebkitBoxOrient: "vertical",
                                                                    WebkitLineClamp: 2,
                                                                }}>
                                                                    {location.name}
                                                            </Typography>
                                                        </span>
                                            </Tooltip>
                                            }
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    checked={
                                                        props.locations.find(
                                                            (locationState) => locationState.name === location.name
                                                        ).isChecked
                                                    }
                                                    onChange={handleCheckboxChange}
                                                />
                                            }
                                        />
                                    );
                                })}
                            </Box>
                        </Box>
                    )}
                </Stack>
            </Grid>

            <Grid item container sx={{flexGrow: 1, width: 0}} id="rooms-list" justifyContent={"center"}>
                {props.isLoading ? (
                    !props.fetchError ? (
                        [...Array(8)].map((x, i) => (
                            <Skeleton
                                name={i}
                                key={i}
                                sx={{ marginTop: "20px", marginLeft: "1%", borderRadius: "16px" }}
                                variant="rect"
                                animation="wave"
                                width={500}
                                height={100}
                            />
                        ))
                    ) : (
                        <Box height={150} sx={{ textAlign: "center" }}>
                            <Button
                                variant="text"
                                startIcon={<ReplayIcon />}
                                onClick={props.tryAgain}
                                sx={{ marginTop: "50px" }}
                            >
                                {t("Dashboard.MakeBooking.Try Again")}
                            </Button>
                        </Box>
                    )
                ) : (
                    <Grid item container>
                        {availableEntities.length > 0 ? (
                            availableEntities.map((entity, index) => (
                                <Grow
                                    key={index}
                                    in={true}
                                >
                                    <Grid
                                        key={entity.name}
                                        item
                                        container
                                        className={`${classes.card} ${entity.id === props.selectedRoom.id ? classes.selected : ""} }`}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        sx={{minWidth: '410px'}}
                                    >
                                        <PublicRoomCard
                                            entity={entity}
                                            isCreateButtonClickedHandler={props.isCreateButtonClickedHandler}
                                            selectedDate={props.selectedDate}
                                            selectedEndTime={props.selectedEndTime}
                                            selectedStartTime={props.selectedStartTime}
                                            disableText={disableText}
                                            handleRoomSelect={props.handleRoomSelect}
                                        />
                                    </Grid>
                                </Grow>
                            ))
                        ) : (
                            <Grow in={errorFlag}>
                                <Alert
                                    severity="warning"
                                    sx={{ marginTop: "10px", marginLeft: "12px", borderRadius: "16px" }}
                                >
                                    {t(filterError)}
                                </Alert>
                            </Grow>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
export default withSnackbar(RoomSelect);
