import humanizeDuration from 'humanize-duration';

export const nextEvent = (events) => {
  const now = new Date().getTime();

  const sortedEvents = events.sort(function(a, b) {
    return new Date(a.start).getTime() - new Date(b.start).getTime();
  });

  const futureEvents = sortedEvents.filter(function(event) {
    return new Date(event.start).getTime() > now;
  });

  return futureEvents[0] || {};
};

export const nextEventIdx = (events) => {
  const nextEvent = exports.nextEvent(events);

  return events.indexOf(nextEvent);
};

export const currentEvent = (events) => {
  const now = new Date().getTime();
  let currentEvents = [];
  if(events) {
     currentEvents = events.filter(function(event) {
      const eventStart = new Date(event.start).getTime();
      const eventEnd = new Date(event.end).getTime();
  
      return eventStart <= now && eventEnd >= now;
    });
  } 

  return currentEvents[0] || {};
};

export const timeToEvent = (event) => {
  return (Date.parse(event.start) - Date.now());
};

export const timeLeft = (event) => {
  return Date.parse(event.end) - Date.now();
};

export const humanReadableDuration = (ms) => {
  // largest: max number of units to display, round: round to smallest unit displayed
  return humanizeDuration(ms, { largest: 1, round: true, units: ['d', 'h', 'm'] });
};

export const humanReadableDuration2 = (ms) => {
  // largest: max number of units to display, round: round to smallest unit displayed
  return humanizeDuration(ms, { largest: 1, round: true, units: ['d', 'h', 'm','s'] });
};

export const isCurrent = (event) => {
  return timeToEvent(event) <= 0 && timeLeft(event) >= 0;
};

export const isBeforeNow = (event) => {
  return timeLeft(event) < 0;
};

export const isAfterNow = (event) => {
  return timeToEvent(event) > 0;
};

export const isAllDayEvent = (event) => {
  return event.isAllDay;
};

export const groupEventsByRoom = (events) => {
  let result = events.reduce(function (r, a) {
    r[a.room] = r[a.room] || [];
    r[a.room].push(a);
    return r;
  }, Object.create(null));
  return result;
}

export const filterCancelledEvents = (events) => {
  let filteredEvents = []
  events.forEach(element => {
    if(element.eventObj.state === null){
      filteredEvents.push(element)
    }
  });
  return filteredEvents
}