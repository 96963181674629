import React, {useEffect} from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import "date-fns";
import moment from "moment";
import {makeStyles} from "@mui/styles";
import Grow from "@mui/material/Zoom";
import {HTTP_REQUESTS} from "../../backendcomm/services/httpRequestService";
import {withSnackbar} from "notistack";
import {getEntityDetailIconsGroup} from "../../helpers/IconSelecter";
import {useTranslation} from 'react-i18next';
import theme from "../../theme";
import DateFormatter from "../../helpers/DateFormatter";
import Tooltip from '@mui/material/Tooltip';
import CustomErrorBox from "../CustomErrorBox";
import {calendarLanguagesData} from '../../data/languages';
import {useNavigate} from "react-router-dom";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {connect} from "react-redux";
import {mapSessionStateToProps} from "../../helpers/dataConverter";

const iconColor = "#003db0";

const useStyles = makeStyles({
  root: {
    width:'100%',
    height:400,
    padding: "4px"
  },
  pickers: {
    padding: "5px",
  },
  tags: {
    margin: "5px",
    display: "flex",
    justifyContent: "center",

  },
  rooms: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
  },
  card: {
    padding: "10px",
    textAlign: "center"
  },
  CardAction: {
    flexDirection: 'column'
  },
  centeredItem: {
    width: '100%',
    textAlign: 'center'
  },
  secondaryButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    padding: "12px",
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    '&:disabled': theme.palette.disabled

  },
  hideWithLength: {
    textOverflow:"ellipsis",
    overflow:"hidden",
    whiteSpace:"nowrap"
  },
  flexDirectionRowReverse: {
    "flex-direction": "row-reverse",
  },

});
const categoryImages = {
  MeetingRoom0: "../../images/meetingRoom.png",
  MeetingRoom1: "../../images/meetingRoom2.png",
  MeetingRoom2: "../../images/meetingRoom3.png",
};
const MakeBooking = (props) => {
  const calendarLanguages = calendarLanguagesData

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedStartTime, setSelectedStartTime] = React.useState(new Date());
  const [selectedEndTime, setSelectedEndTime] = React.useState(new Date());
  const [isTimeRounded , setIsTimeRounded] = React.useState(false)
  const [availableEntities, setAvailableEntities] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [endDateError, setEndDateError] = React.useState(false);
  const [startDateError, setStartDateError] = React.useState(false);
  const [hasNoEntity, setHasNoEntity] = React.useState(false);
  const [selectTimeError, setselectTimeError] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const NO_ENTITY_MESSAGE   = t('Dashboard.MakeBooking.No Entity');
  const NO_AVAILABLE_ENTITY = t('Dashboard.MakeBooking.No Available');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const rounding = (newStartDate,selectedStartTime) => {
        if(selectedStartTime.getMinutes() === 0){
          newStartDate.setHours(selectedStartTime.getHours());
          newStartDate.setMinutes(0);
        }
        else if (selectedStartTime.getMinutes() > 0 && selectedStartTime.getMinutes() <= 15){
          newStartDate.setHours(selectedStartTime.getHours());
          newStartDate.setMinutes(15);
        }
        else if (selectedStartTime.getMinutes() > 15 && selectedStartTime.getMinutes() <= 30){
          newStartDate.setHours(selectedStartTime.getHours());
          newStartDate.setMinutes(30);
        }else if (selectedStartTime.getMinutes() > 30 && selectedStartTime.getMinutes() <= 45){
          newStartDate.setHours(selectedStartTime.getHours());
          newStartDate.setMinutes(45);
        } else{
          newStartDate.setHours(selectedStartTime.getHours()+1);
          newStartDate.setMinutes(0);
        }
        newStartDate.setSeconds(0)
        newStartDate.setMilliseconds(0)
        setIsTimeRounded(true)
        return newStartDate;
};
  const disableText = t('Dashboard.MakeBooking.Disable')
  useEffect(() => {
    selectedEndTime.setSeconds(0);
    selectedEndTime.setMilliseconds(0);
    selectedStartTime.setSeconds(0);
    selectedStartTime.setMilliseconds(0);
    if (selectedStartTime > selectedEndTime) {
      selectedEndTime.setMinutes(selectedEndTime.getMinutes() + 30);
    }
      if(isTimeRounded){
        searchForTime(selectedStartTime, selectedEndTime);
        setEndDateError(false);
      }

  }, [selectedEndTime, selectedStartTime,props.isEventCreated]);
  const fixTimesWithLimits = (start,end) => {
    let newStart = start
    let newEnd = end
    // Checking Late
    const early = new Date(); early.setHours(9,0,0);  moment(early).format("HH:mm");
    const late = new Date(); late.setHours(18,0,0);  moment(late).format("HH:mm");
    const last = new Date(); last.setHours(23,59,59);

    if((moment(start).format("HH:mm") > moment(late).format("HH:mm")) && ( moment(start).format("HH:mm") <= moment(last).format("HH:mm"))){
      /* TODO EventTimes Limits Fix with Tenant's Info */
      // let date = new Date()
      // date.setDate((start.getDate() + 1))
      // setSelectedDate(date);
    }

    if((moment(end).format("HH:mm") > moment(late).format("HH:mm")) ||( moment(end).format("HH:mm") < moment(early).format("HH:mm"))){
        /* TODO EventTimes Limits Fix with Tenant's Info */
        newEnd = new Date(end.setHours(17,30,0));
    }
    // Checking Early

    if((moment(start).format("HH:mm") < moment(early).format("HH:mm")) || ( moment(start).format("HH:mm") > moment(late).format("HH:mm"))) {
        /* TODO EventTimes Limits Fix with Tenant's Info */
        newStart = new Date(start.setHours(9,0,0));
    }
    if(newStart >= newEnd){
      newEnd.setHours(newStart.getHours());
      newEnd.setMinutes(newStart.getMinutes()+15);
    }
    // setSelectedStartTime(newStart)
    // setSelectedEndTime(newEnd)
}

const fixTimesWithLimits2 = (end,selectedDate) => {
  const last = selectedDate; last.setHours(23,59,59);
    return (end > last) ? false : true;
}


  useEffect(() => {
    let currentDate = new Date();
    let newStartDate = new Date();
    newStartDate.setFullYear(selectedDate.getFullYear());
    newStartDate.setMonth(selectedDate.getMonth());
    newStartDate.setDate(selectedDate.getDate());
    newStartDate = rounding(newStartDate,selectedStartTime)
    newStartDate.setSeconds(0);

    let newEndDate = new Date();
    newEndDate.setFullYear(selectedDate.getFullYear());
    newEndDate.setMonth(selectedDate.getMonth());
    newEndDate.setDate(selectedDate.getDate());
    newEndDate = rounding(newEndDate,selectedEndTime)
    newEndDate.setMinutes(newStartDate.getMinutes()+30);
    newEndDate.setSeconds(0);

    if(fixTimesWithLimits2(newEndDate,selectedDate))  {
      setSelectedStartTime(new Date(newStartDate))
      setSelectedEndTime(new Date(newEndDate))
    } else {
      currentDate.setDate(currentDate.getDate() + 1);
      setSelectedStartTime(new Date(currentDate.setHours(9,0,0)))
      setSelectedEndTime(new Date(currentDate.setHours(9,30,0)))
      setSelectedDate(new Date(currentDate))
    }
  }, [selectedDate]);

  const handleStartTimeChange = (date) => {
      date.setFullYear(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      rounding(date,date);


      if (date < Date.now()) {

        setStartDateError(true);
        props.enqueueSnackbar(
          t('errorProperties.MAKE_BOOKING.HANDLE_START_TIME_CHANGE.SET_START_DATE_ERROR'),
          {
            variant: "error",
          }
        );
      } else {
        setSelectedStartTime(date);
        if(selectedEndTime <= date){
          let newEndDate = new Date();
          newEndDate.setFullYear(date.getFullYear());
          newEndDate.setMonth(date.getMonth());
          newEndDate.setDate(date.getDate());
          newEndDate.setHours(date.getHours());
          newEndDate.setMinutes(date.getMinutes() + 30);
          newEndDate.setSeconds(0);
          newEndDate.setMilliseconds(0)
          setSelectedEndTime(newEndDate);
        }
        setStartDateError(false);
      }


  };

  const handleEndTimeChange = (date1) => {
      if (selectedStartTime >= date1) {
        date1.setHours(selectedStartTime.getHours(),selectedStartTime.getMinutes() + 30,0,0);
        date1.setSeconds(0);
        date1.setMilliseconds(0)

      }
        setSelectedEndTime(date1);
        setEndDateError(false);



  };


  const classes = useStyles();

  const searchForTime = (start, end) => {
    // let dateObj = { startDate: DateFormatter.combineDates(selectedDate ,start), endDate: DateFormatter.combineDates(selectedDate,end) };
    HTTP_REQUESTS.ENTITY_SERVICE.GET_AVAILABLE_ENTITIES(
        DateFormatter.combineDates(selectedDate ,start),
        DateFormatter.combineDates(selectedDate,end),
        (response) => {
        let locationArray = response.map((item) => {
          if(item.room.Location == null){
            item.room.Location = {name: "No Location"};
          }
          return {
            name: item.room.Location.name,
            isDisplayed: true };
        });
        const locationArrayUnique = locationArray.filter((thing, index) => {
          const _thing = JSON.stringify(thing);
          return (
            index ===
            locationArray.findIndex((obj) => {
              return JSON.stringify(obj) === _thing;
            })
          );
        });
        setCategories(locationArrayUnique);
        const reponseWithoutPrimary = response;
        setAvailableEntities(reponseWithoutPrimary);
        if (reponseWithoutPrimary.length === 0) {
          setHasNoEntity(true);
        } else {
          setHasNoEntity(false);
        }
      },
      (fail) => {
        //alert(fail);
      }
    );

  };
  const handleClick = (category) => {
    let newCategoriesArray = Object.create([...categories]);
    var foundIndex = newCategoriesArray.findIndex(
      (x) => x.name == category.name
    );
    let isDisplayedCategories = categories.filter((item) => item.isDisplayed === true);
    if(isDisplayedCategories.length === categories.length) {
      newCategoriesArray.map((item) => {item.isDisplayed = false});
      newCategoriesArray[foundIndex].isDisplayed = true;
    } else {
      newCategoriesArray[foundIndex].isDisplayed = !newCategoriesArray[foundIndex].isDisplayed;
    }
    setCategories(newCategoriesArray);
  };
  const isHidden = (clickedCategory) => {
    let newCategoriesArray = Object.create([...categories]);
    if(clickedCategory){
      var foundIndex = newCategoriesArray.findIndex(
        (x) => x.name == clickedCategory.name
      );
      if (typeof newCategoriesArray[foundIndex] !== "undefined") {
        return newCategoriesArray[foundIndex].isDisplayed;
      }
    } else {
        return true;
      }
  };

  const filterCalendarWithoutPrimary = (calendarArray) => {
    return calendarArray.filter((calendar) => {
      return !calendar.primary;
    });
  };


  return (
    <div>
      <div className={classes.pickers} id="datepickers">
        <LocalizationProvider adapterLocale={calendarLanguages[t('Calendar.MiniCalendar.Language')]} dateAdapter={AdapterDateFns}>
          <Grid container justifyContent="center" >
            <Grid item className={classes.pickers}>
              <DatePicker
                disablePast
                showTodayButton
                inputFormat="dd/MM/yyyy"
                label= {t('Dashboard.MakeBooking.Event Date')}
                // disabled={hasNoEntity}
                value={selectedDate}
                onChange={handleDateChange}
                onAccept={handleDateChange}
                todayLabel={t('Dashboard.MakeBooking.Today')}
                okLabel={t('Dashboard.MakeBooking.Ok')}
                cancelLabel={t('Dashboard.MakeBooking.Cancel')}
                InputProps={{
                  sx: { "& .MuiButtonBase-root": { padding: 0, marginRight: "1%" }},
                  classes: { root: classes.flexDirectionRowReverse },
                  disableUnderline: true,
                }}
                renderInput={(params) => (
                    <TextField variant="standard" {...params} sx={{ svg: { paddingLeft: 0, marginRight: "10px", color: iconColor, marginLeft: "1px" }}} />
                )}
                componentsProps={{ actionBar: { actions: ["accept"] }}}
              />
            </Grid>
            <Grid item className={classes.pickers}>
              <TimePicker
                key="startTimePicker"
                showTodayButton
                todayLabel={t('Dashboard.MakeBooking.Now')}
                label={t('Dashboard.MakeBooking.Event Start')}
                cancelLabel={t('Dashboard.MakeBooking.Back')}
                okLabel={t('Dashboard.MakeBooking.Select')}
                format = "HH:mm"
                value={selectedStartTime}

                error={startDateError != null && startDateError.length > 0}
                ampm={false}
                // disabled={hasNoEntity}
                minutesStep={15}
                onChange={handleStartTimeChange}
                closeOnSelect={false}
                renderInput={(params) => (
                    <TextField variant="standard" {...params} sx={{ svg: { marginRight: "10px", color: iconColor }}}/>
                )}
                InputProps={{
                  sx: { "& .MuiButtonBase-root": { padding: 0, marginRight: "1%"}},
                  classes: { root: classes.flexDirectionRowReverse },
                  disableUnderline: true,
                }}
                componentsProps={{
                  actionBar: { actions: ["accept"] },
                }}
              />
            </Grid>
            <Grid item className={classes.pickers}>
              <TimePicker
                key="endTimePicker"
                label={t('Dashboard.MakeBooking.Event Finish')}
                cancelLabel={t('Dashboard.MakeBooking.Back')}
                okLabel={t('Dashboard.MakeBooking.Select')}
                value={selectedEndTime}
                ampm={false}
                // disabled={hasNoEntity}
                error={endDateError != null && endDateError.length > 0}
                minutesStep={15}
                onChange={handleEndTimeChange}
                closeOnSelect={false}
                renderInput={(params) => (
                    <TextField
                        variant="standard"
                        {...params}
                        sx={{
                          svg: {
                            marginRight: "10px",
                            color: iconColor,
                          },
                        }}
                    />
                )}
                InputProps={{
                  sx: { "& .MuiButtonBase-root": { padding: 0, marginRight: "1%" }},
                  classes: { root: classes.flexDirectionRowReverse },
                  disableUnderline: true,
                }}
                componentsProps={{
                  actionBar: { actions: ["accept"] },
                }}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
        {(selectTimeError) && <CustomErrorBox content={selectTimeError} />}
      </div>
      <div justify = "center">
        {/* CATEGORY GRID */}
        <Grid >
          <div id="taglist" className={classes.tags}   >
            {categories.map((category) => (
              <Chip
                label={category.name}
                // style={{backgroundColor:category.status=="active"?"green":"grey"}}
                clickable
                id={category.name}
                key={category.name}
                variant={category.isDisplayed ? "default" : "outlined"}
                size="small"
                color="primary"
                onClick={() => {
                  handleClick(category);
                }}
                className={classes.tags}
              />
            ))}
          </div>
        </Grid>
      </div>
      <div className={classes.rooms} id="roomslist">
        {hasNoEntity ? (
          <Typography className={classes.centeredItem}>{NO_ENTITY_MESSAGE}</Typography>
        ) : availableEntities.length === 0 ? (
          <Typography className={classes.centeredItem}>{NO_AVAILABLE_ENTITY}</Typography>
        ) : (
          <Grid container className={classes.rooms}>
            <br />
            {availableEntities.map((entity, index) => (
              isHidden(entity.room.Location) ? <Grid xl={3} lg={4} md={4} sm={6} xs={12}  item className={classes.card}>
                <Grow
                  in={isHidden(entity.room.Location)}
                  style={{ transformOrigin: "0 1 2" }}
                  {...(true ? { timeout: 1000 } : {})}
                >
                  <Card
                    display= 'none'
                    hidden={!isHidden(entity.room.Location)}
                    className={classes.root}
                    style={{border: '3px solid', borderColor: entity.room.color}}
                  >
                    <CardActionArea style={{height:'280px',}}>
                      <CardMedia
                        component="img"
                        alt={entity.room.name}
                        height="140"
                        image = {(entity.room.images.length > 0) ? entity.room.images[0].imageUrl : categoryImages['MeetingRoom0'] }
                        title={entity.room.name}
                      />
                      <CardContent justify="center">
                        <Typography gutterBottom variant="h5" component="h2" className={classes.hideWithLength} >
                          {entity.room.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          gutterBottom
                        >
                          {entity.room.Location.name}
                        </Typography>
                        {this.props.tenantConfig.features.roomDetailIcon ?
                        <Typography align="center">
                          {getEntityDetailIconsGroup(entity)}
                        </Typography>
                        : null
                        }
                      </CardContent>
                    </CardActionArea>
                    <CardActions className={classes.CardAction} >
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                          navigate("/calendar/" + entity.room.id);
                        }}
                      >
                        {t('Dashboard.MakeBooking.Show Calendar')}
                      </Button>
                      <Tooltip title={ (selectedEndTime - selectedStartTime)/60000 < entity.room.minBookingTime ? disableText + '(' + entity.room.minBookingTime + ' min)' : ''}>
                        <div>
                        <Button
                          disabled= { (selectedEndTime - selectedStartTime)/60000 < entity.room.minBookingTime ? true : false}
                          size="small"
                          className={classes.secondaryButton}
                          onClick={() => props.isCreateButtonClickedHandler(entity.room.id, selectedStartTime, selectedEndTime)}

                          >
                          {t('Dashboard.MakeBooking.CreateNewBooking')}
                        </Button>
                        </div>
                      </Tooltip>

                    </CardActions>
                  </Card>
                </Grow>

              </Grid>
            : null))}
          </Grid>
        )}
      </div>
    </div>
  );
};
export default withSnackbar(connect(mapSessionStateToProps)(MakeBooking));
