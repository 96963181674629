import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import {FeatureType} from "../../guards/RoleFeatures";

interface SessionState {
    auth_token: string;
    isSubscribed: boolean;
    name: string;
    phoneNo: string;
    profilePic: string;
    allowBooked: boolean;
    status: string;
    role: string;
    tenantEndHour: string;
    tenantStartHour: string;
    id: string;
    isMobile: boolean;
    isLoading: boolean;
    tenantId: string;
    tenantName: string;
    username: string;
    email: string;
    welcomeMessageRequest: boolean;
    userFeatures: FeatureType[];
}

const initialState: SessionState = {
    auth_token: '',
    isSubscribed: false,
    name: '',
    phoneNo: '',
    profilePic: '',
    allowBooked: false,
    status: '',
    role: '',
    tenantEndHour: '',
    tenantStartHour: '',
    id: '',
    isMobile: false,
    isLoading: true,
    tenantId: '',
    tenantName: '',
    username: '',
    email: '',
    welcomeMessageRequest: true,
    userFeatures: []
}

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSession(state, action: PayloadAction<SessionState>)  {
            Object.assign(state, action.payload)
        },
        restartSession(state) {
            Object.assign(state, initialState)
        },
        setIsMobile (state, action: PayloadAction<boolean>) {
            state.isMobile = action.payload;
        },
        setIsLoading(state, action: PayloadAction<boolean>){
            state.isLoading = action.payload;
        }
    }
})

export const { setSession, setIsMobile, restartSession, setIsLoading } = sessionSlice.actions;
export const selectSessionData = (state: RootState) => state.sessionData;

export default sessionSlice.reducer;
