export default class InvoiceSettings {
    constructor(
        companyName,
        generalManager,
        city,
        state,
        country,
        industry,
        website,
        phoneNumber,
        invoiceEmail,
        postalCode,
        bankName,
        iban,
        bic,
        localCourt,
        tradeRegisterNumber,
        salesTaxId,
        taxRate,
        taxNumber,
        taxIncluded
    ) {
        this.companyName = (companyName) ? companyName : '';
        this.generalManager = (generalManager) ? generalManager : '';
        this.industry = (industry) ? industry : '';
        this.phoneNumber = (phoneNumber) ? phoneNumber : '';
        this.website = (website) ? website : '';
        this.invoiceEmail = (invoiceEmail) ? invoiceEmail : '';
        this.city = (city) ? city : '';
        this.state = (state) ? state : '';
        this.country = (country) ? country : '';
        this.localCourt = (localCourt) ? localCourt : '';
        this.postalCode = (postalCode) ? postalCode : '';
        this.bankName = (bankName) ? bankName : '';
        this.iban = (iban) ? iban : '';
        this.bic = (bic) ? bic : '';
        this.tradeRegisterNumber = (tradeRegisterNumber) ? tradeRegisterNumber : '';
        this.salesTaxId = (salesTaxId) ? salesTaxId : '';
        this.taxRate = (taxRate) ? taxRate : '';
        this.taxNumber = (taxNumber) ? taxNumber : '';
        this.taxIncluded = (taxIncluded) ? taxIncluded : false;
    }
}