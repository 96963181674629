import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import {FormLabel, Grid,  MenuItem, Select } from '@mui/material';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    mr2: {
        marginRight:theme.spacing(2)
    }
}));

const RepeatingBooking = props => {
    /* TODO: Improve structure */
    const [repeatingBooking, setrepeatingBooking] = useState({
    reccuredType:'',
    reccuredCount:'',
    reccuredPeriod:'',
    reccuredWeekDay :''
    });
    const [countErrorMessage, setCountErrorMessage] = useState('');
    const [periodErrorMessage, setPeriodErrorMessage] = useState('');
    const currentLang = localStorage.getItem('language').split('-')[0];
    moment.updateLocale(currentLang, {
        week: {
            dow: 1,
            doy: 1,
        },
    });
    const repeatBookingValidation = (repeatBookingObj) => {
        if(!repeatBookingObj.reccuredCount || repeatBookingObj.reccuredCount  === "0" ||  repeatBookingObj.reccuredCount  === 0){
            setCountErrorMessage(t("Booking.RepeatingBooking.COUNT_VALIDATION_ERROR"))
        }else{
            setCountErrorMessage("")
        }
        
        if(!repeatBookingObj.reccuredPeriod || repeatBookingObj.reccuredPeriod  === "0" ||  repeatBookingObj.reccuredPeriod  === 0){
            setPeriodErrorMessage(t("Booking.RepeatingBooking.PERIOD_VALIDATION_ERROR"))
        }
        else{
            setPeriodErrorMessage("")
        }
    }

    const isRepeatValidated = () => {
        if(countErrorMessage || periodErrorMessage){
            props.handleIsRepeatBookingValidated(false)
        }else{
            props.handleIsRepeatBookingValidated(true)
        }
    }

    const classes = useStyles();
    useEffect(() => {
        repeatBookingValidation(props.repeatingBookingData)
        setrepeatingBooking(
            {
                reccuredType : props.repeatingBookingData.reccuredType || 'Daily',
                reccuredCount : props.repeatingBookingData.reccuredCount,
                reccuredPeriod : props.repeatingBookingData.reccuredPeriod,
                reccuredWeekDay : props.repeatingBookingData.reccuredWeekDay // moment(props.selectedDate).format('dd').toUpperCase()
            }
            )
    }, [props.repeatingBookingData]);

    useEffect(() => {
        isRepeatValidated()
    }, [countErrorMessage,periodErrorMessage]);
    
    const inputGroupChangeHandler = (event) => {
        event.persist();
        props.repeatingBookingChanged(event);
    }

    const { t } = useTranslation();

    const repeatingTypes = {
        'day'   :t('Booking.RepeatingBooking.Daily'),
        'week'  :t('Booking.RepeatingBooking.Weekly'),
        'month' :t('Booking.RepeatingBooking.Monthly'),
        'year'  :t('Booking.RepeatingBooking.Yearly')
    }
    const repeatingTypesValues = {
        'day'   :'Daily',
        'week'  :'Weekly',
        'month' :'Monthly',
        'year'  :'Yearly'
    }
    const on = {
        Weekly : {
            'Monday'    :t('Booking.RepeatingBooking.Monday'),
            'Tuesday'   :t('Booking.RepeatingBooking.Tuesday'),
            'Wednesday' :t('Booking.RepeatingBooking.Wednesday'),
            'Thursday'  :t('Booking.RepeatingBooking.Thursday'),
            'Friday'    :t('Booking.RepeatingBooking.Friday'),
            'Saturday'  :t('Booking.RepeatingBooking.Saturday'),
            'Sunday'    :t('Booking.RepeatingBooking.Sunday')
        },
    }
    const onValues = {
        Weekly : {
            'Monday'    :'MO',
            'Tuesday'   :'TU',
            'Wednesday' :'WE',
            'Thursday'  :'TH',
            'Friday'    :'FR',
            'Saturday'  :'SA',
            'Sunday'    :'SU'
        },
    }

    return (
        <div>
        {/* <pre>{JSON.stringify(repeatingBooking, null, 2) }</pre>
        <pre>{JSON.stringify(props.repeatingBookingData, null, 2) }</pre> */}
        <h4>{t('Booking.RepeatingBooking.Repeating')}</h4>
        <div>{moment(props.selectedDate).format('DD MMMM YYYY')}</div>
        <div>{moment(props.selectedDate).format('dddd')}</div>
        <Grid container className={classes.formControl} spacing={2}>
        <Grid item container alignItems="center" xs={12} lg={12} spacing={2}  >   
            <Grid item xs ={3}>
                <FormLabel component="legend">{t('Booking.RepeatingBooking.Count')}</FormLabel>
            </Grid>            
            <Grid item xs={3}>
                <TextField
                    margin="dense"
                    id="reccuredCount"
                    label=""
                    type="text"
                    name="reccuredCount"
                    value={repeatingBooking.reccuredCount}
                    onChange={inputGroupChangeHandler}
                    error={countErrorMessage != null && countErrorMessage.length > 0}
                    helperText={(countErrorMessage != null && countErrorMessage.length > 0) ? countErrorMessage : ''}
                />
            </Grid>
        </Grid>   
        <Grid item container xs={12} lg={12} alignItems="center" spacing={2}>
            <Grid item container alignItems="center" xs={12} lg={12} spacing={2}  >   
                <Grid item xs ={3}>
                    <FormLabel component="legend">{t('Booking.RepeatingBooking.Every')}</FormLabel>
                </Grid>            
                <Grid item xs={5}>
                    <TextField
                        margin="dense"
                        id="reccuredPeriod"
                        label=""
                        type="text"
                        name="reccuredPeriod"
                        value={repeatingBooking.reccuredPeriod}
                        onChange={inputGroupChangeHandler}
                        error={periodErrorMessage != null && periodErrorMessage.length > 0}
                        helperText={(periodErrorMessage != null && periodErrorMessage.length > 0) ? periodErrorMessage : ''}
                    />
                </Grid>
                <Grid item xs={4} alignItems='center'>
                    <Select
                    fullWidth
                    id="reccuredType"
                    name="reccuredType"
                    value={repeatingBooking.reccuredType}
                    onChange={inputGroupChangeHandler}
                    >
                    {Object.keys(repeatingTypes).map((key, index) => (
                        <MenuItem value={repeatingTypesValues[key]} key={index}>{repeatingTypes[key]}</MenuItem>
                    ))}
                    </Select>
                </Grid>
            </Grid>
            {repeatingBooking.reccuredType === 'Weekly' &&
                <Grid item container alignItems="center" xs={12} lg={12} spacing={2} >
                    <Grid item xs={4}>
                        <FormLabel component="legend">{t('Booking.RepeatingBooking.On')}</FormLabel>
                    </Grid>
                    <Grid item xs={8}>
                        <Select
                        id="reccuredWeekDay"
                        name="reccuredWeekDay"
                        value={repeatingBooking.reccuredWeekDay}
                        onChange={inputGroupChangeHandler}
                        >
                        {Object.keys(on[repeatingBooking.reccuredType]).map((key, index) => (
                            <MenuItem value={onValues[repeatingBooking.reccuredType][key]} key={index}>{on[repeatingBooking.reccuredType][key]}</MenuItem>
                        ))}
                        </Select>
                    </Grid>
                </Grid>
            }
        </Grid>
        </Grid>
        </div>
    )

}
export default withSnackbar(RepeatingBooking);
