import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import emailStrIsValid from "../../helpers/Validation";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import { makeStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import UserMailList from "../Tenant/UserMailList";
import AddUsersForm from "../Tenant/AddUsersForm"
import errorProperties  from '../../errorProperties.json';
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import {useAppSelector} from "../../store/hooks";
import {selectSessionData} from "../../store/features/sessionSlice";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: 1,
    marginLeft: 1,
  },
  actions: {
    justifyContent: "flex-end",
  },
  avatar: {
    backgroundColor: "green",
    height: 35,
    alignSelf:"center",
    width: 35,
  },
  icon: {
    height: 25,
    width: 25,
  },
}));

const AddNewUsersDialog = (props) => {
  const [availableForInvite, setAvailableForInvite] = React.useState(false);
  const [mailInput, setMailInput] = React.useState("");
  const [aValidEmail, setAValidEmail] = React.useState(false);
  const [restoreMessage, setRestoreMessage] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const classes = useStyles();
  const { t } = useTranslation();

  const sessionData = useAppSelector(selectSessionData)

  const validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const onChangeMail = (event) => {
    setMailInput(event.target.value);
  };

  useEffect(() => {
    setAValidEmail(validateEmail(mailInput))
  }, [mailInput])

  const onAddUser = () => {
    if (!users.filter((item) => item.email === mailInput)[0]) {
      let createUserObj = {email:mailInput}
      if (restoreMessage){
        createUserObj.restore = true;
      }
       HTTP_REQUESTS.USER_SERVICE.CREATE_ONE_USER(createUserObj,res=> {
         if (res.canBeRestored) {
           setRestoreMessage(true)
         }else {
           if (!users.includes(mailInput)) {
             let organizedUsers = users;
             let obj= {
               email: res.savedUserMail,
               id: res.accountId
             }
             organizedUsers.unshift(obj);
             setUsers(organizedUsers);
             setMailInput('');
             setAValidEmail(false);
             setRestoreMessage(false)

           } else {
             props.enqueueSnackbar(t(`errorProperties.CREATE_WORKSPACE_PAPER.ON_ADD_USER`) , {
               variant: "error"
             });
             setMailInput('');
             setAValidEmail(false);
           } 
         }
        
       },err=> {
            let tempError = (err.data) ? t(`errorProperties.CREATE_WORKSPACE_PAPER.${err.data.error.details}`) : t(`errorProperties.CREATE_WORKSPACE_PAPER.ON_ADD_USER`);
            props.enqueueSnackbar(tempError , {
              variant: "error"
            });
       })
      } else {
        props.enqueueSnackbar(t(`errorProperties.CREATE_WORKSPACE_PAPER.ON_ADD_USER`) , {
          variant: "error"
        });
        setMailInput('');
        setAValidEmail(false);
      }
  };
  

  const deleteUser = (id) => {
    let obj = {}
    HTTP_REQUESTS.USER_SERVICE.DELETE_ONE_USER(id,obj,res=> {
      setUsers(users.filter((user) => user.id !== id))
    }, err => {
      console.log(err)
    })
  }
  

  const onSave = () => {
    props.getUsers()
    setUsers([])
    setMailInput('')
    setRestoreMessage(false)
  };

  return props.open ? (
    <div>
      <Dialog
        open={true}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        className="dialogRoot"
      >
        <DialogTitle style={{ textAlign: "center"}} id="form-dialog-title">
          {t('Dashboard.BulkUserInviteForm.Invite Users')}
        </DialogTitle>
        <Avatar className={classes.avatar}>
            <PeopleIcon className={classes.icon} />
          </Avatar>
        <DialogContent className="dialogContent">
          <DialogContentText style={{margin:"12px" }}>
            {t('Dashboard.BulkUserInviteForm.Enter Email')}
          </DialogContentText>
          <div id="add-users-form" className="addUsersForm">
            <Grid container alignItems="center" justifyContent="center" alignContent="center">
             
                <AddUsersForm
                  userList={users}
                  restoreMessage={restoreMessage}
                  onChangeUsers={onChangeMail}
                  onAddUser={onAddUser}
                  deletable={true}
                  deleteUser={deleteUser}
                  validMail={aValidEmail}
                  mailInput={mailInput}
                />
              
          </Grid>
        </div>


        </DialogContent>
        <DialogActions>
          {/*<Button onClick={props.handleClose} style={theme.palette.danger}>
            {t('Dashboard.BulkUserInviteForm.Cancel')}
          </Button>*/}
          <Button
            //disabled={users.length!=0?false:true}
            onClick={() => {
              props.handleClose();
              onSave();
            }}
            color="primary"
          >
            {t('Dashboard.BulkUserInviteForm.Invite')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
};
export default withSnackbar(AddNewUsersDialog);
