import React from "react";
import { useParams } from "react-router-dom";
import TenantFormContainer from "../components/TenantFormContainer";
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import { Tenant } from "../components/TenantForm";

const SuperAdminTenantsPage = () => {
  const params = useParams();
  const tenantId = params.id || "";

  const handleSubmit = (tenant: Tenant) => {
    HTTP_REQUESTS.TENANT_SERVICE.UPDATE_A_TENANT(
      tenantId,
      tenant,
      (success) => {
        console.log(success);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div>
      <TenantFormContainer handleSubmit={handleSubmit} tenantId={tenantId} />
    </div>
  );
};

export default SuperAdminTenantsPage;
