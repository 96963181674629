import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {PROPERTIES_DEFAULT_TABLE_LAYOUT} from './IconSelecter';


const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
        maxWidth: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TableLayoutDropdown = props => {
    const classes = useStyles();
    const [selectedLayout, setSelectedLayout] = useState((props.value) ? props.value : PROPERTIES_DEFAULT_TABLE_LAYOUT);

    const handleTableLayoutsChange = (event) => {
        setSelectedLayout(event.target.value);
        if(props.onChange) {
            props.onChange(event);
        }
    };
    React.useEffect(() => {
        setSelectedLayout(props.value);
    },[props]);

    return (
        <FormControl className={classes.formControl}>
                                {/* <pre>{JSON.stringify(props.value, null, 2) }</pre> */}
                                {/* <pre>{JSON.stringify(tableLayouts, null, 2) }</pre> */}
                                <Select 
                                        name={props.name}
                                        id="entity_tableLayouts"
                                        value={selectedLayout}
                                        onChange={handleTableLayoutsChange}
                                        style={{ minHeight: '45px' }} /* added to fix icon size.  */
                                        MenuProps={MenuProps}
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                    <div key={`${selected}`} className={classes.chip}>
                                                        {selected.name}
                                                    </div>
                                            </div>
                                        )}
                                        disabled={props.disabled}
                                    >
                                         {props.layoutsData.map((tableLayout,index)=>
                                            ( 
                                                <MenuItem key={`${tableLayout.id}_${index}`} value={tableLayout}>
                                                    {tableLayout.name}
                                                </MenuItem>
                                            )
                                        )     
                                        }
                                    </Select>
                                </FormControl>
        
    )
}

export default TableLayoutDropdown;
