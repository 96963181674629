import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Navigate,
  Route,
  Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import SuperAdminLoginPage from "./pages/SuperAdminLoginPage";
import { HTTP_REQUESTS } from "../backendcomm/services/httpRequestService";
import history from "../history";
import SuperAdminDashboardPage from "./pages/SuperAdminDashboardPage";
import SuperAdminErrorPage from "./pages/SuperAdminErrorPage";
import SuperAdminTopbar from "./components/SuperAdminTopbar";
import "./superAdminStyles.css";
import SuperAdminTenantsPage from "./pages/SuperAdminTenantsPage";
import SuperAdminTenantEditPage from "./pages/SuperAdminTenantEditPage";
import SuperAdminCreateNewTenantPage from "./pages/SuperAdminCreateNewTenantPage";
import SuperAdminUsersPage from "./pages/SuperAdminUsersPage";

const SuperAdminApp = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [sessionState, setSessionState] = React.useState({
    email: "",
    role: "",
  });
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  const resetSession = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setSessionState({
      email: "",
      role: "",
    });
  };

  const ProtectedRoute = ({ component }): JSX.Element => {
    const navigate = useNavigate();
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("accessToken");
    useEffect(() => {

      if (!accessToken && !refreshToken) {
        // redirect to login page
        navigate("/login");
      } else if (!sessionState.email && !sessionState.role) {
        callMe();
      } else if (sessionState.role !== "super_admin") {
        resetSession();
        navigate("/error");
      } else {
        setIsAuthenticated(true);
      }
    }, [sessionState, accessToken, refreshToken]);

    return <>{!isLoading && isAuthenticated && component}</>;
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      callMe();
    } else {
    }
  }, []);

  const callMe = () => {
    setIsLoading(true);
    HTTP_REQUESTS.USER_SERVICE.AUTH_CALL(
      (data) => {
        setIsLoading(false);
        setSessionState({
          email: data.email,
          role: data.tenants[0].role,
        });
      },
      (err) => {
        setIsLoading(false);
        //reset session state
        resetSession();
      }
    );
  };

  return (
    <div className="super-admin-body">
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={<SuperAdminLoginPage callMe={callMe} />}
          ></Route>
          <Route path="/error" element={<SuperAdminErrorPage />}></Route>
          <Route element={<ProtectedRoute component={<SuperAdminTopbar />} />}>
            <Route
              path="/"
              element={
                <ProtectedRoute component={<SuperAdminDashboardPage />} />
              }
            ></Route>
            <Route
              path="/tenants"
              element={<ProtectedRoute component={<SuperAdminTenantsPage />} />}
            ></Route>
            <Route
              path="/tenants/:id"
              element={
                <ProtectedRoute component={<SuperAdminTenantEditPage />} />
              }
            ></Route>
            <Route
              path="/tenants/:id"
              element={
                <ProtectedRoute component={<SuperAdminTenantEditPage />} />
              }
            ></Route>
            <Route
              path="/create-tenant"
              element={
                <ProtectedRoute component={<SuperAdminCreateNewTenantPage />} />
              }
            ></Route>
            <Route
              path="/users"
              element={<ProtectedRoute component={<SuperAdminUsersPage />} />}
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default SuperAdminApp;
