import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { withSnackbar } from "notistack";
import {HTTP_REQUESTS} from "../../../backendcomm/services/httpRequestService";
import {restartSession, setSession} from "../../../store/features/sessionSlice";
import {sessionStateFrom} from "../../../helpers/dataConverter";
import {useAppDispatch} from "../../../store/hooks";
import SignUp from "../../Login/SignUp";
import {useTranslation} from "react-i18next";
import SignIn from "../../Login/SignIn";
import ForgotPassword from "../../Login/ForgotPassword";


const LoginOrRegisterModal = (props) => {
    const [method, setMethod] = React.useState(props.method);
    const [email, setEmail] = React.useState(null);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    React.useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if(accessToken){
            callMe();
        }
    },[])

    const onSignInClicked = () =>{
        setMethod('login')
    }
    const onSignUpClicked = () =>{
        setMethod('register')
    }
    const onForgotPassword = () =>{
        setMethod('ForgotPassword')
    }

    const formDecider = () => {
        if(method==='login'){
            return <SignIn
                handelSigIn = {handelSigIn}
                email={email}
                onSignUpClicked = {onSignUpClicked}
                onForgotPassword={onForgotPassword}
            />
        } else if(method==='register'){
            return <SignUp
                onRegistrationSuccess={(email) => {
                    onSignInClicked()
                    setEmail(email)
                    props.enqueueSnackbar(t('SignUpSuccess.ToContinue'), {
                        variant: 'success',
                    })
                }}
                onSignInClicked={onSignInClicked} />
        } else if(method === 'ForgotPassword') {
            return <ForgotPassword onSignInClicked={onSignInClicked}/>
        } else {
            return null
        }
    }

    const handelSigIn = () => {
        let isLoggedIn = localStorage.getItem('accessToken')
        if(isLoggedIn){
            HTTP_REQUESTS.USER_SERVICE.AUTH_CALL(
                data => {
                    dispatch(setSession(sessionStateFrom(data)));
                },
                err => {
                    console.log("Error: ", err);
                    let language = localStorage.getItem('language') ?? '';
                    localStorage.clear();
                    localStorage.setItem('language' , language);
                    dispatch(restartSession())
                }
            )
        }
        props.setLoginFormOpen(false);
    }

    const callMe = () => {
        HTTP_REQUESTS.USER_SERVICE.AUTH_CALL(
            data => {
                dispatch(setSession(sessionStateFrom(data)));
                props.setLoginFormOpen(false);
            },
            err => {
                console.log("Error: ", err);
                let language = localStorage.getItem('language') ?? '';
                localStorage.clear();
                localStorage.setItem('language' , language);
                dispatch(restartSession())
            }
        )
    }

  return (
      <Dialog
        open={props.open}
        onClose={handelSigIn}
      >
        <DialogContent>
            {formDecider()}
        </DialogContent>

      </Dialog>
  )
};
export default withSnackbar(LoginOrRegisterModal);
