import React, { useEffect } from "react";
import { HTTP_REQUESTS } from "../../backendcomm/services/httpRequestService";
import CustomTable from "../../components/Core/CustomTable/CustomTable";
import { ActionButton, ActionButtonTypes, ActionButtonWrapper, ActionTypes } from "../../components/Core/CustomTable/TableActionButtons";
import { ColumnType } from "../../components/Core/CustomTable/TableConfig";
import { convertUuidToDisplay } from "../../utils/Converters";

const SuperAdminUsersPage = () => {
  const [users, setUsers] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

    const generateColumns = () => {
    return [
      {
        id: "actions",
        title: "actions",
        cell: ({ row }) => {
          return ActionButtonWrapper([
            ActionButton(
              "Edit",
              {
                actionType: ActionTypes.navigate,
                navigateTo: `${row.original.id}`,
              },
              ActionButtonTypes.edit
            ),
            ActionButton(
              "Delete",
              {
                actionType: ActionTypes.custom,
                action: () => {
                  console.log("delete", row.original.id);
                },
              },
              ActionButtonTypes.delete
            ),
          ]);
        },
        type: ColumnType.display,
        size: 100,
      },
      {
        id: "id",
        title: "User ID",
        type: ColumnType.accessor,
        render: (row) => convertUuidToDisplay(row),
      },
      {
        id: "name",
        title: "Name",
        type: ColumnType.accessor,
      },
      {
        id: "email",
        title: "E-mail",
        type: ColumnType.accessor,
      },
      {
        id: "role",
        title: "Role",
        type: ColumnType.accessor,
      },
      {
        id: "tenantId",
        title: "Tenant ID",
        type: ColumnType.accessor,
        render: (row) => convertUuidToDisplay(row),
      },
      {
        id: "tenantCompanyName",
        title: "Company",
        type: ColumnType.accessor,
      },
      {
        id: "tenantDomainName",
        title: "Domain",
        type: ColumnType.accessor,
      },
    ];
  };

  useEffect(() => {
    setIsLoading(true);
    HTTP_REQUESTS.TENANT_SERVICE.GET_ALL_USERS(
      (data) => {
        setIsLoading(false);
        const users: any[] = [];
        data.forEach((tenant) => { 
          tenant.accounts.forEach((user) => {
            const tempUser = {};
            tempUser['id'] = user.user.id;
            tempUser['name'] = user.user.name || '';
            tempUser['email'] = user.email;
            tempUser['role'] = user.tenantAccount.role;
            tempUser['tenantId'] = tenant.id;
            tempUser['tenantCompanyName'] = tenant.companyName;
            tempUser['tenantDomainName'] = tenant.domainName;
            users.push(tempUser);
          })
        })
        setUsers(users);
      },
      (err) => {
        setIsLoading(false);
      }
    );
  }, []);

  return (
        <>
      {isLoading ? (
        <h1>Loading...</h1>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CustomTable
            columns={generateColumns()}
            data={users}
            title="Users"
            loading={isLoading}
          />
        </div>
      )}
    </>
  );
};

export default SuperAdminUsersPage;
