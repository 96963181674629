import {styled} from "@mui/styles";
import Chip from "@mui/material/Chip";
const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '11px !important',
    marginBottom:'8px !important' ,
    maxWidth:'fit-content !important',
    backgroundColor:'#2D77FC !important',
    color:'white !important',
    "& .MuiChip-icon": {
        marginLeft: theme.spacing(1)  + '!important',
        color:'white !important',
        textAlign:'center',
        fill:'white'
    },
    "& .MuiChip-deleteIcon": {
        color:'white !important',
        textAlign:'center',
        fill:'white'
    },
    "&:focus": {
        backgroundColor:'#2D77FC'
    },
}));
export default StyledChip;