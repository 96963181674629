import Calendar from '../models/Calendar';
import { HTTP_REQUESTS } from '../backendcomm/services/httpRequestService';



export function getAllCalendars(cb,errorCallback) {
    let calendars = [];
    HTTP_REQUESTS.CALENDAR_SERVICE.GET_ALL_CALENDARS((res) => {
        res.returnArray.forEach(function (calendar) {
            let newCalendar= new Calendar(
                calendar.room.calendarId,
                "",
                calendar.room.name, 
                calendar.room.description, 
                calendar.room.seat,
                calendar.room.images,
                calendar.room.color,
                '',
                calendar.room.allDayRoomPrice,
                calendar.room.halfDayRoomPrice,
                calendar.room.additionalPrice,
                calendar.room.bufferTime,
                calendar.room.minBookingTime,
                calendar.room.locationId,
                calendar.room.categoryId,
                calendar.room.id,
                calendar.room.RoomLayouts,
                calendar.room.imageURL,
                calendar.room.restrictedPercantage
            ).toUIJSON();
            calendars.push(newCalendar);
            
        });
        cb(calendars);

    }, (err) => {
        errorCallback(err);
    });

}