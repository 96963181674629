import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'date-fns';
import { Button, Card } from '@mui/material';
import { useNavigate } from 'react-router-dom'

const localizer = momentLocalizer(moment);

export default function FakeCalendar(props) {
    const navigate = useNavigate();
  return (
     <div style={{marginTop:"16px", position:'relative'}}>
         {props.role === "admin" ? 
            <div style={{
                position:'absolute', 
                backgroundColor:"transparent",  
                height:"500px", 
                zIndex:"9", 
                display:'flex',
                textAlign:'center',
                justifyContent:'center',
                right:'0',
                left:'0',
                alignItems: 'center'
            }}>
            <Button
            variant="contained"
            color="primary"
            onClick={()=>{navigate(props.navigateUrl)}}>
            {props.buttonLabel}
            </Button>
            </div>:null
         }
        <div style={{filter:'blur(6px)', marginTop:"16px"}}>
            <Card >
                <Calendar
                localizer={localizer}
                longPressThreshold={100}
                events={[]}
                style={{ height: "500px"}}
            />
            </Card>
        </div>

     </div> 
  );
}
