import { configureStore } from '@reduxjs/toolkit'
import sessionReducer from './features/sessionSlice'
import navigationReducer from './features/navigationSlice'
import tenantConfigReducer from './features/tenantConfigSlice'

export const store = configureStore({
    reducer: {
        sessionData: sessionReducer,
        navigation: navigationReducer,
        tenantConfig: tenantConfigReducer
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
