
export default class BufferEvent{
    constructor(eventId,title,startDate,endDate,color,room,isBuffer){
        this.eventId    = eventId;
        this.title      = title;
        this.startDate  = startDate;
        this.endDate    = endDate;
        this.color      = color;
        this.room       = room;
    }

    toUIJSON(){
        return {
            id          : this.eventId,
            title       : this.title,
            start       : this.startDate,
            end         : this.endDate,
            color       : this.color,
            room        : this.room,
            editable    : false,
            isBuffer    : true
        }
    }

    toAPIJSON(){
        throw Error('Not implmeented yet'); //TODO: implement this
    }

    
}
