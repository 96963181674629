import { store } from '../store/store'

export type FeatureType =
    'view-public-booking' |
    'view-login-page' |
    'view-dashboard' |
    'view-event-checkout' |
    'view-room-details' |
    'view-room-table' |
    'view-location-table' |
    'view-user-table' |
    'view-profile' |
    'view-my-bookings' |
    'view-quick-booking' |
    'view-user-details' |
    'view-device-details' |
    'view-device-table' |
    'view-lobby' |
    'view-customize-table' |
    'view-customize-details' |
    'view-payments-table' |
    'view-invoice-settings' |
    'create-event' |
    'view-sidebar' |
    'change-language' |
    'view-top-bar' |
    'view-rooms' |
    'view-create-event' |
    'view-summary'

const commonFeatures: FeatureType[] = [
    // View Features
    'view-public-booking',
    'view-login-page',
    'view-dashboard',
    'view-event-checkout',
    'view-profile',
    'view-my-bookings',
    'view-invoice-settings',
    'view-sidebar',
    'view-top-bar',
    'view-create-event',
    'view-summary',

    // Action Features
    'create-event',
    'change-language'
]


export const publicUserFeatures: FeatureType[] = [
    ...commonFeatures
]

export const userFeatures: FeatureType[] = [
    ...commonFeatures,
    'view-quick-booking'
]

export const adminFeatures: FeatureType[] = [
    ...commonFeatures,
    'view-room-details',
    'view-room-table',
    'view-location-table',
    'view-user-table',
    'view-user-details',
    'view-device-details',
    'view-device-table',
    'view-lobby',
    'view-customize-table',
    'view-customize-details',
    'view-payments-table',
    'view-rooms',
    'view-quick-booking'
]

export function getUserFeatures(role: string): FeatureType[] {
    switch (role){
        case 'user':
            return userFeatures;
        case 'public_user':
            return publicUserFeatures;
        case 'admin':
            return adminFeatures;
    }
    return publicUserFeatures;
}

export function hasFeature(feature: FeatureType): boolean {
    const role = store.getState().sessionData.role;
    return getUserFeatures(role).some(f => f === feature);
}
