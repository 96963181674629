import React from 'react';
import { makeStyles } from '@mui/styles';
import { deepOrange, green } from '@mui/material/colors';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {store} from "../../store/store";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  square: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  rounded: {
    color: '#fff',
    backgroundColor: green[500],
  },
  large: {
    width: theme.spacing(14),
    height: theme.spacing(10),
  },
  logo: {
    width:"auto",
    maxWidth:"100%",
    height:"35px"
  }
}));

export default function VariantAvatars() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'}}><div className="logo">
        {/* <Avatar variant="square" src="/images/companyLogo.png" className={classes.large} /> */}
        <img alt="logo" src={store.getState().tenantConfig.logo} className={classes.logo} />
      </div>
      <Typography component="h1" variant="h6" className="title">
        {t('CompanyAvatar.Space System')}  
      </Typography>
    </div>


  );
}
