import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    maxHeight: "100",
  },
  content: {
    padding: 0,

  },
  image: {
    height: 48,
    width: 48,
  },
  actions: {
    justifyContent: "flex-end",
  },
  action:{//button alignment on table headers.
    marginTop:"0px",
    marginRight:"0px",
    alignSelf:"center"
  },
  header:{
    marginLeft:"15px"
  },
  centeredItem: {
    display: 'flex',
    justifyContent: 'center'
  }

}));

const BookingsList = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const NO_EVENT_MESSAGE = t('Dashboard.BookingList.No Events')
  const [userEventsList, setUserEventsList] = useState(props.userEventsList);
// This will launch only if propName value has chaged.
  useEffect(() => { setUserEventsList(props.userEventsList) }, [props.userEventsList]);
  return (
    <Card {...rest} className={clsx(classes.root)}>
      <CardHeader
      className={classes.headerButton}
        subtitle={userEventsList ? `${userEventsList.length} ` + t('Dashboard.BookingList.In Total') : null}
        title= {props.heading ? t('Dashboard.BookingList.Upcoming') : t('Dashboard.BookingList.all')}
        classes={{action:classes.action}}

      />

      <Divider />
      <CardContent style={{padding:'1px',textAlign:'initial'}}>

        {!userEventsList ? <Typography className={classes.centeredItem} variant="subtitle">{NO_EVENT_MESSAGE}</Typography>:<Timeline align='alternate' >
          {userEventsList.map((booking, index) => (
            console.log('book',booking),
            <TimelineItem key={index}>
              <TimelineOppositeContent

              >
                <Typography style={{fontSize:'0.75rem',color: 'rgba(0, 0, 0, 0.6)'}}>
                {moment(booking.start).format(
                  "DD/MM/YYYY"
                )}
                </Typography>
                <Typography variant='small' style={{fontSize:'0.75rem',color: 'rgba(0, 0, 0, 0.6)'}}>{moment(booking.start).format("HH:mm")}-{moment(booking.end).format("HH:mm")}</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot variant='outlined' style={{borderColor:booking.eventObj.room.color}}>
                  <AccessAlarmIcon style={{color:booking.eventObj.room.color}} fontSize='small' />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent color='primary'>
                <Typography style={{fontWeight:'500'}}>
                  {booking.title}
                </Typography>
                <Typography style={{fontSize:'0.75rem'}}>{booking.eventObj.room.location.name}</Typography>
                {booking.eventObj.state === "cancelled" ? <HighlightOffIcon />:null}
              </TimelineContent>
            </TimelineItem>


            ))}
        </Timeline>}

      </CardContent>
      <Divider />
    </Card>
  );
};

BookingsList.propTypes = {
  className: PropTypes.string,
};

export default BookingsList;
